<template>
  <v-card class="rounded-lg mb-5">
    <!-- title -->
    <template>
      <v-card-title class="d-flex justify-space-between">
        <span>
          {{ favLocation.name }}
        </span>
        <span>
          <v-btn icon @click="$emit('edit', favLocation)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="showDialog = true">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </span>
      </v-card-title>
      <v-card-subtitle>{{ favLocation.address }}</v-card-subtitle>
    </template>
    <!-- content -->
    <v-card-text>
      <ul style="list-style: none; padding-left: unset" class="pb-5">
        <li class="grey--text h6">Location Details</li>
        <li>visibility: {{ favLocation.visibility }}</li>
        <li>location type: {{ favLocation.type }}</li>
      </ul>
      <ul
        v-if="favLocation.planningData"
        style="list-style: none; padding-left: unset"
        class="pb-5"
      >
        <li class="grey--text h6">Trip Planning Defaults</li>
        <li v-if="favLocation.planningData.loadWeightChange">
          load weight change: {{ favLocation.planningData.loadWeightChange }} kg
        </li>
        <li
          v-if="
            favLocation.planningData.chargeHere &&
            favLocation.planningData.rating &&
            favLocation.planningData.currentType
          "
        >
          Charge here with {{ favLocation.planningData.rating }}kW
          {{ favLocation.planningData.currentType }} charger
        </li>
      </ul>
    </v-card-text>
    <v-dialog v-model="showDialog" max-width="400" content-class="rounded-lg">
      <v-card>
        <v-card-title>Delete Favourite Location</v-card-title>
        <v-card-text>This is permanent and cannot be undone</v-card-text>
        <v-card-actions>
          <ElevatedBtn @click="showDialog = false"> Cancel </ElevatedBtn>
          <ElevatedBtn
            @click="
              () => {
                $emit('remove', favLocation);
                showDialog = false;
              }
            "
          >
            Delete
          </ElevatedBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import FavouriteLocation from "@/logic/classes/favouriteLocation";
import Vue, { PropType } from "vue";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
export default Vue.extend({
  name: "FavLocationCard",
  props: {
    favLocation: {
      type: Object as PropType<FavouriteLocation>,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  components: {
    ElevatedBtn,
  },
});
</script>
