import { fetchEnergyNeeded } from "../../api/calls/ev_nav_calls";
import type { EVNavCar, EVNavEnergy } from "../../types/ev_nav_types";
import generateUniqueLocalID from "../../utils/generateUniqueLocalID";
import { combinePolylines } from "../../utils/polylineUtils";
import EVModel from "../vehicle_classes/evModel";
import { TspBaseLeg } from "./tspTrip";

export interface TspTripComparisonOptions {
  localId?: string;
  evModelID?: string;
  calcAsUsed: boolean;
  energyData?: EVNavEnergy;
}

const TspTripComparisonDefaults: TspTripComparisonOptions = {
  localId: undefined,
  evModelID: undefined,
  calcAsUsed: false,
  energyData: undefined,
};

export default class TspTripComparison {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** local unique id. */
  localId: string;

  /** The CDB UUID for the selected ev model. */
  evModelID?: string;

  /** The full class object used to calculate this comparison. */
  evModel?: EVModel;

  /** Data from the successful energy call for this comparison. */
  energyData?: EVNavEnergy;

  /** Data from the successful energy call for this comparison split by leg. */
  energyDataByLeg?: EVNavEnergy[];

  /** calculate stats based on if this is a brand new EV comparison compared with a new */
  calcAsUsed = false;

  /** list of compatible charger CDB ids along the trip */
  chargersAlongRouteCDBIDs?: string[];

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Total energy used in this comparison in kWh. */
  public get totalEnergy(): number | undefined {
    return this.energyData?.Energy;
  }

  /** Display ready string representing the total energy used in this comparison. */
  public get totalEnergyDisplayString(): string | undefined {
    if (!this.energyData) return;
    return Math.round(this.energyData.Energy) + "kWh";
  }

  public get totalEnergyDisplayStringPercentage(): string | undefined {
    if (!this.numberOfCharges) return;
    return Math.round(this.numberOfCharges * 100) + "%";
  }

  /** Number of charges required to complete this trip in this comparison. */
  public get numberOfCharges(): number | undefined {
    if (!this.totalEnergy) return;
    if (!this.evModel) return;
    if (this.calcAsUsed) {
      return this.totalEnergy / this.evModel.calcLinearDegradation();
    }
    return this.totalEnergy / this.evModel.batterySize;
  }

  /** Display ready string representing the number of charges required to complete this trip in this comparison. */
  public get numberOfChargesDisplayString(): string | undefined {
    if (!this.numberOfCharges) return;
    if (this.numberOfCharges < 1)
      return "can be completed in less than a single charge";
    if (this.numberOfCharges === 1)
      return "uses exactly one full charge to complete this trip"; // improbable but not impossible.
    return (
      Math.floor(this.numberOfCharges) +
      (this.numberOfCharges % 1 !== 0 ? 1 : 0) +
      " charges needed to complete this trip"
    );
  }

  /** The max batterySize used in this comparison. */
  public get batterySize(): number | undefined {
    return this.calcAsUsed
      ? this.evModel?.calcLinearDegradation()
      : this.evModel?.batterySize;
  }

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    evModelID = undefined,
    calcAsUsed = false,
    energyData = undefined,
  }: TspTripComparisonOptions | undefined = TspTripComparisonDefaults) {
    this.localId =
      localId ?? generateUniqueLocalID(TspTripComparison.usedIds, "comparison");
    this.evModelID = evModelID;
    this.calcAsUsed = calcAsUsed;
    this.energyData = energyData;

    // add id to list of used unique ids
    TspTripComparison.usedIds.push(this.localId);
  }

  // -------------------------------------------------------------------- //
  // -------------------------- Static Methods -------------------------- //
  // -------------------------------------------------------------------- //

  // -------------------------------------------------------------------- //
  // --------------------------- Public Methods ------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Calculate the energy usage required for this trip in the comparison EV.
   *
   * @param polyline the full polyline for the trip (note: this is expected to be with a precision of 6)
   * @returns outcome of operation.
   */
  public async calcEnergyUsage(
    polyline: string
  ): Promise<"failed" | "success"> {
    // exit early if no ev model to get energy consumption for.
    if (!this.evModelID) return "failed";
    // fetch energy data.
    const res = await fetchEnergyNeeded({
      Vehicle: {
        Id: this.evModelID,
      },
      Polyline: polyline,
    });
    // check data was fetched successfully.
    if (res) {
      this.energyData = res;
      return "success";
    }
    // exit as failed if not successful.
    return "failed";
  }

  public async calcEnergyUsageBreakdown(
    baseLegs: TspBaseLeg[]
  ): Promise<"failed" | "success"> {
    // exit early if no ev model to get energy consumption for.
    if (!this.evModelID || !this.evModel) return "failed";
    // create promise for each polyline.
    const promises = baseLegs.map((leg, index) => {
      const vehicle: EVNavCar = {
        Id: this.evModelID,
      };

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (leg.loadWeight) vehicle.Mass = this.evModel!.mass + leg.loadWeight; // evModel being present is already checked in guard clause.

      return fetchEnergyNeeded({
        Vehicle: vehicle,
        Polyline: leg.polyline,
        Name: "leg" + index,
      });
    });
    // fetch energy data.
    const resolvedPromises = await Promise.all(promises);
    // check if all promises resolved successfully
    if (resolvedPromises.every((item) => item !== undefined)) {
      this.energyDataByLeg = resolvedPromises as EVNavEnergy[];
      const energyDataTotals: EVNavEnergy = {
        Distance: 0,
        Energy: 0,
        Time: 0,
        Status: "OK",
      };
      (resolvedPromises as EVNavEnergy[]).forEach((dataItem) => {
        energyDataTotals.Distance += dataItem.Distance;
        energyDataTotals.Energy += dataItem.Energy;
        energyDataTotals.Time += dataItem.Time;
      });
      this.energyData = energyDataTotals;
      return "success";
    }

    // fallback to just basic data if not all promises could be resolved
    return await this.calcEnergyUsage(
      combinePolylines(baseLegs.map((leg) => leg.polyline))
    );
  }

  /** Sets the ev model. This will also set the ev model id if needed. */
  public setEVModel(evModel?: EVModel): void {
    this.evModel = evModel;
    this.evModelID = evModel?.id;
    if (!evModel) {
      // ASSUME: ev model is being removed and therefore comparison is no longer valid
      this.energyData = undefined;
      this.energyDataByLeg = undefined;
    }
  }

  // -------------------------------------------------------------------- //
  // -------------------------- Private Methods ------------------------- //
  // -------------------------------------------------------------------- //
}
