import generateUniqueLocalID from "../../utils/generateUniqueLocalID";
import { EVNavWaypoint } from "../../types/ev_nav_types";
import { Location as savedLocation } from "../../types/trip_specific_types";
import Charger from "../charger_classes/charger";
import haversineDistance from "../../utils/haversineDistance";
import { Directus_SavedRoutePlan_Location } from "../../api/calls/directus_calls/savedRoutePlans";
import Coordinate from "../common_classes/coordinate";

export interface TripLocationOptions {
  localId?: string;
  address?: string;
  coordinates?: Coordinate;
  time?: string;
  date?: string;
  chargeHere?: boolean;
  stateOfChargeAfterCharging?: number;
  stay?: number;
}

const tripLocationDefaults = {
  localId: undefined,
  address: "",
  coordinates: new Coordinate({
    latitude: 0,
    longitude: 0,
  }),
  time: undefined,
  date: undefined,
  chargeHere: false,
  stateOfChargeAfterCharging: undefined,
  stay: undefined,
};
export default class TripLocation {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** Unique identifier in scope of this trip.
   *
   * NOTE: This will be passed to `EV Nav` at the point
   * of calculation as well as returned from `EV Nav` in
   * the as the `Name` property on the waypoint object.
   */
  localId: string;

  /** Display String for location. */
  address: string;

  /** Lat/Lon coordinate object for this location.
   *
   * NOTE: The properties and values with in this object
   * forms part of the `EV Nav` waypoint object. See db
   * types `Waypoint` for details.
   */
  coordinates: Coordinate;

  /** The arrival time for this location. If no time is
   * set it will appear as an empty string */
  time?: string;

  /** Number of seconds the user is intending to stay at this
   * location. This is an optional property only used for
   * additional stops calculating times taking into account
   * this extra duration of the stay at this location. */
  stay?: number;

  /** Optional property used for calculations of overnight
   * trips. */
  date?: string;

  /** Optional property used for flagging this location as a
   * waypoint that the user will charge at.
   *
   * NOTE: This is not used for the starting or destination
   * locations, only additional stops. */
  chargeHere?: boolean;

  /** State Of Charge after charging at this location. Used as
   * SOCAct for planning the next leg of the trip.
   *
   * This should be between 0 and 1 as float representation of
   * a percentage e.g. 80% = 0.8
   * */
  stateOfChargeAfterCharging?: number;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    address = "",
    coordinates = new Coordinate({
      latitude: 0,
      longitude: 0,
    }),
    time = undefined,
    date = undefined,
    chargeHere = false,
    stateOfChargeAfterCharging = undefined,
    stay = undefined,
  }: TripLocationOptions | undefined = tripLocationDefaults) {
    this.localId =
      localId ?? generateUniqueLocalID(TripLocation.usedIds, "location");
    this.address = address;
    this.coordinates = coordinates;
    this.time = time;
    this.date = date;
    this.chargeHere = chargeHere;
    this.stateOfChargeAfterCharging = stateOfChargeAfterCharging;
    this.stay = stay;

    // add id to list of used unique ids
    TripLocation.usedIds.push(this.localId);
  }

  static fromLegacySavedData(data: savedLocation) {
    return new TripLocation({
      localId: data.id,
      address: data.address,
      coordinates: new Coordinate({
        latitude: data.coordinates.Latitude,
        longitude: data.coordinates.Longitude,
      }),
      chargeHere: data.chargeHere,
      stateOfChargeAfterCharging: data.SOC_AfterCharge,
      date: data.date,
      time: data.time,
      stay: data.stay,
    });
  }

  static fromDirectusData(data: Directus_SavedRoutePlan_Location) {
    return new TripLocation({
      localId: data.Location_id ?? undefined,
      address: data.address ?? undefined,
      coordinates: new Coordinate({
        latitude: data.Latitude ?? 0,
        longitude: data.Longitude ?? 0,
      }),
      chargeHere: data.Charge_Here ?? false,
      stateOfChargeAfterCharging: data.SOC_After_Charge ?? undefined,
      date: data.Date ?? undefined,
      time: data.Time ?? undefined,
      stay: data.Stay ?? undefined,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns true if lat/lon coordinates are null island. */
  public get isNullIsland(): boolean {
    return this.coordinates.latitude === 0 && this.coordinates.longitude === 0;
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //

  static reOrderTripLocations(
    currentLocations: TripLocation[],
    indexToMove: number,
    indexToMoveToo: number
  ): TripLocation[] {
    // extract data that needs to stay the same order and not move location. (mainly keeping date/time data in the same order).
    const oldIndex_time = currentLocations[indexToMove].time;
    const oldIndex_date = currentLocations[indexToMove].date;
    const newIndex_time = currentLocations[indexToMoveToo].time;
    const newIndex_date = currentLocations[indexToMoveToo].date;
    // prep data to move.
    const objToMove = currentLocations[indexToMove];
    objToMove.time = newIndex_time;
    objToMove.date = newIndex_date;
    if (
      indexToMoveToo === 0 ||
      indexToMoveToo === currentLocations.length - 1
    ) {
      // clean out unneeded data for starting location or destination location
      objToMove.stay = undefined;
      objToMove.stateOfChargeAfterCharging = undefined;
      objToMove.chargeHere = undefined;
    }
    const objToBeSwappedWith = currentLocations[indexToMoveToo];
    objToBeSwappedWith.time = oldIndex_time;
    objToBeSwappedWith.date = oldIndex_date;

    const newLocations = [...currentLocations];
    newLocations[indexToMoveToo] = objToMove;
    newLocations[indexToMove] = objToBeSwappedWith;
    // reorder array
    return newLocations;
  }

  /** Returns a `EVNavWaypoint` object populated with data from this `TripLocation`. */
  public convertToEVNavWaypoint(): EVNavWaypoint {
    return {
      Latitude: this.coordinates.latitude,
      Longitude: this.coordinates.longitude,
      Name: this.localId,
    };
  }

  /** Returns this location formatted ready to be saved as part of a saved trip. */
  public convertToSavedLocation(): Directus_SavedRoutePlan_Location {
    return {
      address: this.address,
      Latitude: this.coordinates.latitude,
      Longitude: this.coordinates.longitude,
      Time: this.time ?? "",
      Date: this.date,
      Stay: this.stay,
      Charge_Here: this.chargeHere,
      SOC_After_Charge: this.stateOfChargeAfterCharging,
      Location_id: this.localId,
    };
  }

  /**
   * gets an array of `Charger` class objects for stations with in 1km of a this trip location.
   *
   * @param stations the full global state array of `Charger` objects.
   * @param chargersAlongRouteIds the 'Trip` array of ids for chargers along the route.
   * @returns an array of `Charger` class objects.
   */
  public getCloseChargers(
    stations: Charger[],
    chargersAlongRouteIds?: string[]
  ) {
    const returnArray: Charger[] = [];

    if (chargersAlongRouteIds) {
      chargersAlongRouteIds.forEach((id) => {
        const station = stations.find((station) => station.id === id);

        if (station) {
          if (this.chargerIsWithIn1Km(station)) {
            returnArray.push(station);
          }
        }
      });
    } else {
      stations.forEach((station) => {
        if (this.chargerIsWithIn1Km(station)) {
          returnArray.push(station);
        }
      });
    }

    return returnArray;
  }

  private chargerIsWithIn1Km(charger: Charger): boolean {
    // bail out early if charger coordinates are default null island.
    if (charger.isNullIsland) return false;
    // calculate distance from charger in km.
    const distanceFromWaypoint = haversineDistance(
      [charger.coordinates.longitude, charger.coordinates.latitude],
      [this.coordinates.longitude, this.coordinates.latitude]
    );
    // return weather charger is with in 1km.
    return distanceFromWaypoint <= 1;
  }
}
