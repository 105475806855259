<template>
  <InfoPanelCardWrapper style="position: relative">
    <v-row class="flex-nowrap" align="center" no-gutters>
      <TripsHomeBtn />
      <v-breadcrumbs
        :items="items"
        class="flex-nowrap pl-2"
        style="max-width: 90%"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            class="text-truncate"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>

    <LocationsListRow
      :addressData="originAddressData"
      id="origin"
      @update="updateAddress"
      type="origin"
      :loading="addressLoading"
      :errorMsg="addressError"
    />
    <LocationsListRow
      :addressData="destinationAddressData"
      id="destination"
      @update="updateAddress"
      type="destination"
      :readonly="true"
      :loading="addressLoading"
    />
    <div
      class="px-7"
      style="
        position: fixed;
        top: calc(100dvh - 50px);
        left: 0;
        width: 40%;
        max-width: 600px;
      "
    >
      <ElevatedBlockBtn
        @click="toAddDetails"
        class="mb-3"
        :disabled="addressError || addressLoading || !originAddressData"
        :loading="addressLoading"
      >
        Add Details
      </ElevatedBlockBtn>
    </div>
  </InfoPanelCardWrapper>
</template>

<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import ElevatedBlockBtn from "../components/ui-elements/buttons/ElevatedBlockBtn.vue";
import InfoPanelCardWrapper from "../components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import { AddressAutocompleteInputUpdateObj } from "../components/ui-elements/inputs/AddressAutocompleteInput.vue";
import queryValueToString from "@/logic/utils/queryValueToString";
import queryValueToNumber from "@/logic/utils/queryValueToNumber";
import LocationsListRow from "../components/trips/planning/LocationsListRow.vue";
import { RawLocation } from "vue-router";
import TripsHomeBtn from "../components/ui-elements/buttons/TripsHomeBtn.vue";
import { State } from "@/logic/store/store_types";
import { quickTripCheck } from "@/logic/api/calls/valhalla_calls";
import { QuickTripCheckReturn } from "@/logic/types/valhalla_types";

export default Vue.extend({
  name: "PlanningV2AddOriginView",
  components: {
    ElevatedBlockBtn,
    InfoPanelCardWrapper,
    LocationsListRow,
    TripsHomeBtn,
  },
  data() {
    return {
      originAddressData: undefined as processedAddressObj | undefined,
      destinationAddressData: undefined as processedAddressObj | undefined,
      addressLoading: false,
      addressError: null as string | null,
    };
  },
  computed: {
    items(): { text: string; to: RawLocation }[] {
      if ((this.$store.state as State).selectedTrip)
        return [
          {
            text: "Add Destination",
            to: { name: RouteNames.tripV2AddDestination },
          },
          {
            text: "Add Origin",
            to: { name: RouteNames.tripV2AddOrigin },
          },
          {
            text: "Add Details",
            to: { name: RouteNames.tripV2AddDetails },
          },
          {
            text: "Add Stops",
            to: { name: RouteNames.tripV2AddStops },
          },
          {
            text: "Itinerary",
            to: { name: RouteNames.tripV2Itinerary },
          },
        ];
      return [
        {
          text: "Add Destination",
          to: { name: RouteNames.tripV2AddDestination },
        },
        {
          text: "Add Origin",
          to: { name: RouteNames.tripV2AddOrigin },
        },
      ];
    },
  },
  methods: {
    // Routing
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName, query: this.$route.query });
    },
    toAddDetails() {
      this.pushRoute(RouteNames.tripV2AddDetails);
    },
    updateAddress(address: AddressAutocompleteInputUpdateObj) {
      if (address.id === "origin") {
        this.originAddressData = address.addressData;
        this.updateRouteQuery();
      } else if (address.id === "destination") {
        this.destinationAddressData = address.addressData;
        this.updateRouteQuery();
      }
    },
    updateRouteQuery() {
      const newQuery = {
        ...this.$route.query,
      };

      if (this.destinationAddressData) {
        newQuery.destAddress = encodeURI(this.destinationAddressData.address);
        newQuery.destLat =
          this.destinationAddressData.coordinates.Latitude.toString();
        newQuery.destLon =
          this.destinationAddressData.coordinates.Longitude.toString();
        if (this.destinationAddressData.name) {
          newQuery.destName = this.destinationAddressData.name;
        }
      } else {
        delete newQuery.destAddress;
        delete newQuery.destLat;
        delete newQuery.destLon;
        delete newQuery.destName;
      }

      if (this.originAddressData) {
        newQuery.origAddress = encodeURI(this.originAddressData.address);
        newQuery.origLat =
          this.originAddressData.coordinates.Latitude.toString();
        newQuery.origLon =
          this.originAddressData.coordinates.Longitude.toString();
        if (this.originAddressData.name) {
          newQuery.origName = this.originAddressData.name;
        }
      } else {
        delete newQuery.origAddress;
        delete newQuery.origLat;
        delete newQuery.origLon;
        delete newQuery.origName;
      }

      this.$router.replace({
        query: newQuery,
      });
    },
  },
  mounted() {
    if (this.$route.query.destAddress) {
      const address = queryValueToString(this.$route.query.destAddress);
      const lat = queryValueToNumber(this.$route.query.destLat);
      const lon = queryValueToNumber(this.$route.query.destLon);
      const name = queryValueToString(this.$route.query.destName);
      if (address && lat && lon) {
        this.destinationAddressData = {
          address: address,
          coordinates: {
            Latitude: lat,
            Longitude: lon,
          },
          name: name,
        };
      }
    }

    if (this.$route.query.origAddress) {
      const address = queryValueToString(this.$route.query.origAddress);
      const lat = queryValueToNumber(this.$route.query.origLat);
      const lon = queryValueToNumber(this.$route.query.origLon);
      const name = queryValueToString(this.$route.query.origName);
      if (address && lat && lon) {
        this.originAddressData = {
          address: address,
          coordinates: {
            Latitude: lat,
            Longitude: lon,
          },
          name: name,
        };
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    // keep query params in the URL if navigating to other views in this multi
    // view form.
    if (
      (to.name === RouteNames.tripV2AddStops ||
        to.name === RouteNames.tripV2AddDetails ||
        to.name === RouteNames.tripV2AddOrigin ||
        to.name === RouteNames.tripV2AddDestination ||
        to.name === RouteNames.tripV2Stats) &&
      !Object.keys(from.query).every((key) =>
        Object.keys(to.query).includes(key)
      )
    ) {
      const toWithQuery = Object.assign({}, to, { query: from.query });
      next(toWithQuery as RawLocation);
    } else next();
  },
  watch: {
    async originAddressData(val: processedAddressObj | undefined) {
      if (!val || !this.destinationAddressData) {
        this.addressError = null;
        return;
      }
      if (val) {
        this.addressLoading = true;
        const quickCheckRes = await quickTripCheck([
          {
            lat: val.coordinates.Latitude,
            lon: val.coordinates.Longitude,
          },
          {
            lat: this.destinationAddressData.coordinates.Latitude,
            lon: this.destinationAddressData.coordinates.Longitude,
          },
        ]);
        if (quickCheckRes === QuickTripCheckReturn.routable) {
          // clear address errors as routable.
          this.addressError = null;
        } else if (quickCheckRes === QuickTripCheckReturn.unconnected_regions) {
          this.addressError =
            "Origin is in an unconnected region from destination";
        } else if (quickCheckRes === QuickTripCheckReturn.not_routable) {
          this.addressError = "Origin cannot reached destination";
        }
        this.addressLoading = false;
      }
    },
  },
});
</script>
