import Coordinate from "../common_classes/coordinate";
import TripLocation, {
  TripLocationOptions,
} from "../trip_classes/tripLocation";

export interface TspLocationOptions extends TripLocationOptions {
  kWChargerRating?: number;
  weightChange?: number;
  nonDrivingChargeUsed?: number;
}

const defaults: TspLocationOptions = {
  localId: undefined,
  address: "",
  coordinates: new Coordinate({
    latitude: 0,
    longitude: 0,
  }),
  kWChargerRating: undefined,
  weightChange: undefined,
  nonDrivingChargeUsed: undefined,
};

export default class TspLocation extends TripLocation {
  // -------------------------------------------------------------------- //
  // ------------------------------ State ------------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Weight change for the load carried at this scheduled stop in kgs.
   *
   * NOTE: this could be either a positive or negative depending on if it is an expected pick up or drop off. */
  weightChange?: number;

  /** kWh used by other process that make use of the traveling battery e.g. powered raised/lowered platform. */
  nonDrivingChargeUsed?: number;

  /** Max power that can be transferred from a charger at this site in kW. */
  kWChargerRating?: number;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    address = "",
    coordinates = new Coordinate({
      latitude: 0,
      longitude: 0,
    }),
    kWChargerRating = undefined,
    weightChange = undefined,
  }: TspLocationOptions | undefined = defaults) {
    super({ localId, address, coordinates });
    this.kWChargerRating = kWChargerRating;
    this.weightChange = weightChange;
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns the number of kWh added at this location. */
  public get addedCharge() {
    if (!this.kWChargerRating || !this.stay) return 0;
    return this.kWChargerRating * (this.stay / 3600);
  }

  // -------------------------------------------------------------------- //
  // --------------------------- Public Methods ------------------------- //
  // -------------------------------------------------------------------- //

  // -------------------------------------------------------------------- //
  // -------------------------- Private Methods ------------------------- //
  // -------------------------------------------------------------------- //
}
