<template>
  <TripStatsContent v-if="isTrip" />
  <TripStatsEmptyStateContent v-else />
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import TspTrip from "@/logic/classes/tsp_trip_classes/tspTrip";
import { GettersTypes } from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters } from "vuex";
import TripStatsContent from "./TripStatsContent.vue";
import TripStatsEmptyStateContent from "./TripStatsEmptyStateContent.vue";
import { RouteNames } from "@/logic/router";

export default Vue.extend({
  name: "TripStatsWrapper",
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    isTrip(): boolean {
      return (this.trip as Trip | TspTrip | undefined) instanceof Trip;
    },
    isTspTrip(): boolean {
      return (this.trip as Trip | TspTrip | undefined) instanceof TspTrip;
    },
  },
  components: {
    TripStatsContent,
    TripStatsEmptyStateContent,
  },
  mounted() {
    if (this.isTspTrip) this.$router.replace({ name: RouteNames.itinerary });
  },
});
</script>
