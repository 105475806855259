<template>
  <TextBtn
    class="mt-3"
    @click="
      () => {
        dialog = !dialog;
      }
    "
    :disabled="!numChargers"
  >
    <v-img
      :src="iconSrc"
      width="40px"
      height="40px"
      :style="numChargers ? '' : 'filter: grayscale(1)'"
    />
    {{
      numChargers
        ? "View " + numChargers + " charging options"
        : "No compatible chargers near by"
    }}
    <v-icon
      small
      color="primary"
      style="position: absolute; bottom: 2px; left: 2px"
      class="white rounded-circle pa-0"
    >
      mdi-plus-circle
    </v-icon>
    <v-dialog
      v-model="dialog"
      :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
      scrollable
      :max-width="$vuetify.breakpoint.mdAndUp ? '600px' : '350px'"
      :min-width="$vuetify.breakpoint.mdAndUp ? '450px' : '200px'"
      :fullscreen="$vuetify.breakpoint.xs"
      retain-focus
    >
      <v-card>
        <v-card-title>
          Charging options along this part of your route
        </v-card-title>
        <!-- charger listing section -->
        <v-card-text>
          <p>select a charger to add</p>
          <template v-if="chargers">
            <ChargerRow
              v-for="(charger, index) in chargers.privateChargers"
              :evNavCharger="charger"
              :key="'private-charger-card-' + index"
              @addMe="addCharger"
            />
            <ChargerRow
              v-for="(charger, index) in chargers.publicDCChargers"
              :evNavCharger="charger"
              :key="'public-dc-charger-card-' + index"
              @addMe="addCharger"
            />
            <ChargerRow
              v-for="(charger, index) in chargers.publicACChargers"
              :evNavCharger="charger"
              :key="'public-ac-charger-card-' + index"
              @addMe="addCharger"
            />
          </template>
          <template v-else>
            Whoops! something went wrong in getting the charger data.
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </TextBtn>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import TextBtn from "@/ui/components/ui-elements/buttons/TextBtn.vue";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import TspTrip from "@/logic/classes/tsp_trip_classes/tspTrip";
import type { OrderedChargerSuggestions } from "@/logic/utils/orderChargerSuggestions";
import ChargerRow from "./ChargerRow.vue";
import { EVNavCharger } from "@/logic/types/ev_nav_types";
import ChargerLocation from "@/logic/classes/itinerary_data_classes/ChargerLocation";
import { MutationTypes, State } from "@/logic/store/store_types";
import Charger from "@/logic/classes/charger_classes/charger";
import { reverseGeocode } from "@/logic/api/calls/maps_little_monkey_calls";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";

export default Vue.extend({
  name: "SuggestChargerBtn",
  components: { TextBtn, ChargerRow },
  computed: {
    iconSrc(): string {
      return getAssetSrc("icons/DCChargerCircle.svg");
    },
    numChargers(): number | undefined {
      if (!this.chargers) return;
      return (
        this.chargers.privateChargers.length +
        this.chargers.publicACChargers.length +
        this.chargers.publicDCChargers.length
      );
    },
  },
  props: {
    itineraryIndex: {
      required: true,
      type: Number,
    },
    trip: {
      required: true,
      type: Object as PropType<TspTrip>,
    },
  },
  data() {
    return {
      dialog: false,
      chargers: undefined as OrderedChargerSuggestions | undefined,
      loading: false,
    };
  },
  mounted() {
    this.getChargers();
  },
  methods: {
    async getChargers() {
      this.chargers = await (this.trip as TspTrip).suggestChargers(
        this.itineraryIndex
      );
    },
    async getAddressStr(CDBData: Charger): Promise<string> {
      if (CDBData.address) return CDBData.address;
      const addressData = await reverseGeocode(
        CDBData.coordinates.latitude,
        CDBData.coordinates.longitude
      );
      return addressData?.display_name
        ? addressData.display_name
        : "unknown address";
    },
    async addCharger(charger: EVNavCharger) {
      this.loading = true;
      this.dialog = false;
      // create new charging location
      const CDBData = (this.$store.state as State).chargers.find(
        (CDBCharger) => CDBCharger.id === charger.CDBID
      );
      // insert stop and update trip with charger DB data
      if (CDBData) {
        const address = await this.getAddressStr(CDBData);
        const newLocation = new ChargerLocation({
          addressDisplayStr: address,
          coordinate: new Coordinate({
            latitude: CDBData.coordinates.latitude,
            longitude: CDBData.coordinates.longitude,
          }),
          relatedCharger: CDBData.id,
          arrivalEnergy: 0, // insert stop will handle calculating arrival charge.
        });
        // find best compatible charger rating and current
        const evModel = (this.trip as TspTrip).displayedComparisonData?.evModel;
        const vehicle = new Vehicle({});
        if (evModel) vehicle.setEVModel(evModel);
        const bestConnector = evModel
          ? CDBData.bestCompatibleConnector(vehicle)
          : undefined;
        if (bestConnector) {
          newLocation.kWRating = bestConnector.maxElectricPower
            ? bestConnector.maxElectricPower / 1000
            : undefined;
          if (bestConnector.powerType === "DC") newLocation.currentType = "DC";
          if (
            bestConnector.powerType === "AC_1_PHASE" ||
            bestConnector.powerType === "AC_2_PHASE" ||
            bestConnector.powerType === "AC_2_PHASE_SPLIT" ||
            bestConnector.powerType === "AC_3_PHASE"
          )
            newLocation.currentType = "AC";
        }
        // update trip data
        const copyTrip = this.trip;
        await copyTrip.insertStop(this.itineraryIndex, newLocation);
        this.$store.commit(MutationTypes.updateIndividualTrip, copyTrip);
        this.loading = false;
        return;
      }
      // fallback: insert stop and update trip with assumed charger data
      const addressData = await reverseGeocode(
        charger.Location.Latitude,
        charger.Location.Longitude
      );
      const newLocation = new ChargerLocation({
        addressDisplayStr: addressData?.display_name
          ? addressData.display_name
          : "unknown address",
        coordinate: new Coordinate({
          latitude: charger.Location.Latitude,
          longitude: charger.Location.Longitude,
        }),
        relatedCharger: charger.CDBID,
        arrivalEnergy: 0, // insert stop will handle calculating arrival charge.
      });
      const copyTrip = this.trip;
      await copyTrip.insertStop(this.itineraryIndex, newLocation);
      await copyTrip.getCorridorChargerIds(true);
      this.$store.commit(MutationTypes.updateIndividualTrip, copyTrip);
      this.loading = false;
      return;
    },
  },
});
</script>
