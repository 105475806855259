<template>
  <!-- planning animation page -->
  <InfoPanelCardWrapper v-if="planning">
    <DescriptiveLoader message="Power Trip is calculating your trip stats" />
  </InfoPanelCardWrapper>
  <!-- trip stats page -->
  <InfoPanelCardWrapper v-else-if="trip">
    <!-- breadcrumb section -->
    <v-row class="flex-nowrap" align="center" no-gutters>
      <TripsHomeBtn />
      <v-breadcrumbs
        :items="items"
        class="flex-nowrap pl-2"
        style="max-width: 90%"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            style="max-width: 15%"
            class="text-truncate"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <TripStatsContent />
  </InfoPanelCardWrapper>
  <InfoPanelCardWrapper v-else>
    <NoTripContent />
  </InfoPanelCardWrapper>
</template>

<script lang="ts">
import Vue from "vue";
import InfoPanelCardWrapper from "@/ui/components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { mapGetters } from "vuex";
import { GettersTypes, MutationTypes, State } from "@/logic/store/store_types";
import NoTripContent from "../components/trips/NoTripContent.vue";
import { RouteNames } from "@/logic/router";
import TripsHomeBtn from "../components/ui-elements/buttons/TripsHomeBtn.vue";
import TripStatsContent from "../components/trips/statistics/info-panel-content/TripStatsContent.vue";
import TripLocationV2 from "@/logic/classes/trip_v2_classes/tripLocation_v2";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import TripV2 from "@/logic/classes/trip_v2_classes/trip_v2";
import queryValueToNumber from "@/logic/utils/queryValueToNumber";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import queryValueToString from "@/logic/utils/queryValueToString";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import { RawLocation } from "vue-router";
import DescriptiveLoader from "../components/ui-elements/loaders/DescriptiveLoader.vue";

export default Vue.extend({
  name: "V2TripStatsView",
  components: {
    InfoPanelCardWrapper,
    NoTripContent,
    TripsHomeBtn,
    TripStatsContent,
    DescriptiveLoader,
  },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
      fetching: GettersTypes.fetching,
    }),
  },
  data() {
    return {
      planning: true,
      // breadcrumb section
      items: [
        {
          text: "Add Destination",
          to: { name: RouteNames.tripV2AddDestination },
        },
        {
          text: "Add Origin",
          to: { name: RouteNames.tripV2AddOrigin },
        },
        {
          text: "Add Details",
          to: { name: RouteNames.tripV2AddDetails },
        },
        {
          text: "Add Stops",
          to: { name: RouteNames.tripV2AddStops },
        },
        {
          text: "Itinerary",
          to: { name: RouteNames.tripV2Itinerary },
        },
        {
          text: "Stats",
          to: { name: RouteNames.tripV2Stats },
        },
      ],
    };
  },
  methods: {
    async buildTripFromQuery() {
      this.planning = true; // trigger planning animation

      // get query values
      const originAddress = queryValueToString(this.$route.query.origAddress);
      const originLat = queryValueToNumber(this.$route.query.origLat);
      const originLon = queryValueToNumber(this.$route.query.origLon);
      const originName = queryValueToString(this.$route.query.origName);

      const destAddress = queryValueToString(this.$route.query.destAddress);
      const destLat = queryValueToNumber(this.$route.query.destLat);
      const destLon = queryValueToNumber(this.$route.query.destLon);
      const destName = queryValueToString(this.$route.query.destName);

      let waypoints: TripLocationV2[] = [];
      const stopKeys = Object.keys(this.$route.query).filter((key) =>
        key.startsWith("stop")
      );
      let groupedKeys = [];
      for (let i = 0; i < stopKeys.length; i++) {
        const key = stopKeys[i];
        const stopNumber = parseIntOrFloat(key[4]); // ASSUMES pattern of `stop[stop index + 1][stop property key]` is still being used
        if (!stopNumber) continue;
        if (!groupedKeys[stopNumber - 1]) groupedKeys[stopNumber - 1] = [key];
        else groupedKeys[stopNumber - 1].push(key);
      }
      for (let index = 0; index < groupedKeys.length; index++) {
        const group = groupedKeys[index];
        const newWaypoint = new TripLocationV2();
        for (let i = 0; i < group.length; i++) {
          const key = group[i];
          if (key === "stop1Address") {
            const parsedAddress = queryValueToString(this.$route.query[key]);
            if (parsedAddress) newWaypoint.address = parsedAddress;
          }
          if (key === "stop1Lat") {
            const parsedLat = queryValueToNumber(this.$route.query[key]);
            if (parsedLat) newWaypoint.coordinates.latitude = parsedLat;
          }
          if (key === "stop1Lon") {
            const parsedLon = queryValueToNumber(this.$route.query[key]);
            if (parsedLon) newWaypoint.coordinates.longitude = parsedLon;
          }
          if (key === "stop1Stay") {
            const parsedStay = queryValueToNumber(this.$route.query[key]);
            if (parsedStay) newWaypoint.stay = parsedStay;
          }
          if (key === "stop1LeavingSoC") {
            const parsedSoC = queryValueToNumber(this.$route.query[key]);
            if (parsedSoC) newWaypoint.stateOfChargeAfterCharging = parsedSoC;
          }
          if (key === "stop1Weight") {
            const parsedWeight = queryValueToNumber(this.$route.query[key]);
            if (parsedWeight) newWaypoint.weightChange = parsedWeight;
          }
          if (key === "stop1UsedEnergy") {
            const parsedEnergy = queryValueToNumber(this.$route.query[key]);
            if (parsedEnergy) newWaypoint.nonDrivingEnergyUsed = parsedEnergy;
          }
        }
        waypoints.push(newWaypoint);
      }

      // guard clauses
      if (!originAddress || !originLat || !originLon) {
        // TODO: show error
        this.planning = false;
        return;
      }

      if (!destAddress || !destLat || !destLon) {
        // TODO: show error
        this.planning = false;
        return;
      }

      // prep locations
      const locations: TripLocationV2[] = [];
      // add origin
      locations.push(
        new TripLocationV2({
          address: originAddress,
          coordinates: new Coordinate({
            latitude: originLat,
            longitude: originLon,
          }),
          name: originName,
        })
      );
      // add stops

      locations.push(...waypoints);
      // add destination
      locations.push(
        new TripLocationV2({
          address: destAddress,
          coordinates: new Coordinate({
            latitude: destLat,
            longitude: destLon,
          }),
          name: destName,
        })
      );
      // create trip
      const trip = new TripV2({
        locations: locations,
        roundTripFlag: false,
      });
      trip.SOCAct =
        queryValueToNumber(this.$route.query.SOCAct) ??
        (this.$store.state as State).SOCAct;
      trip.SOCEnd =
        queryValueToNumber(this.$route.query.SOCEnd) ??
        (this.$store.state as State).SOCEnd;
      trip.SOCMin =
        queryValueToNumber(this.$route.query.SOCMin) ??
        (this.$store.state as State).SOCMin;
      trip.SOCMax =
        queryValueToNumber(this.$route.query.SOCMax) ??
        (this.$store.state as State).SOCMax;
      trip.SpeedAdjustment = queryValueToNumber(this.$route.query.speedAdj);
      trip.startingLoad =
        queryValueToNumber(this.$route.query.extraWeight) ?? 0;
      trip.passengers = queryValueToNumber(this.$route.query.passengers) ?? 0;
      trip.vehicle = this.$store.getters[GettersTypes.selectedVehicleData] as
        | Vehicle
        | undefined;
      // plan trip
      await trip.planTrip();
      await trip.getRadarData();
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
      this.$store.commit(MutationTypes.setSelectedTrip, trip.local_id);
      this.planning = false;
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      // ASSUMES: if still fetching base optimiser data watch will be triggered once finished.
      if (!this.fetching) {
        if (this.trip) {
          await this.trip.getRadarData();
          this.planning = false;
        }
        if (!this.trip) await this.buildTripFromQuery();
      }
    });
  },
  watch: {
    async fetching(val) {
      if (!val) {
        if (this.trip) this.planning = false;
        if (!this.trip) await this.buildTripFromQuery();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // keep query params in the URL if navigating to other views in this multi
    // view form.
    if (
      (to.name === RouteNames.tripV2AddStops ||
        to.name === RouteNames.tripV2AddDetails ||
        to.name === RouteNames.tripV2AddOrigin ||
        to.name === RouteNames.tripV2AddDestination ||
        to.name === RouteNames.tripV2Stats) &&
      // to.name === RouteNames.tripV2Comparison) &&
      !Object.keys(from.query).every((key) =>
        Object.keys(to.query).includes(key)
      )
    ) {
      const toWithQuery = Object.assign({}, to, { query: from.query });
      next(toWithQuery as RawLocation);
    } else next();
  },
});
</script>
