<template>
  <v-dialog
    :value="activator"
    :width="$vuetify.breakpoint.mdAndUp ? '70%' : '80%'"
    :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
    style="z-index: 1200"
    @click:outside="emitClose()"
    @keydown="keyDown($event)"
    scrollable
    :max-width="
      $vuetify.breakpoint.mdAndUp
        ? small
          ? '600px'
          : '800px'
        : small
          ? '400px'
          : '650px'
    "
    :min-width="
      $vuetify.breakpoint.mdAndUp
        ? small
          ? '450px'
          : '600px'
        : small
          ? '250px'
          : '350px'
    "
    :fullscreen="$vuetify.breakpoint.xs"
    retain-focus
  >
    <v-card
      class="pa-5"
      :class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
      style="height: 100%"
    >
      <slot></slot>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "GenericDialog",
  props: {
    activator: {
      type: Boolean,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Escape") this.emitClose();
    },
  },
});
</script>
