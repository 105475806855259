<template>
  <v-card flat class="background">
    <v-card-title class="text-h5"> My Fleet </v-card-title>
    <v-card flat class="background">
      <v-card-title>
        <span> Fleet vehicles </span>
        <v-spacer />
        <TextBtn @click="emitViewAllVehicles"> View all </TextBtn>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-card
            flat
            v-for="(vehicle, index) in vehicleList"
            :key="'vehicle-' + index"
            style="width: 50%"
            class="pa-1"
          >
            <VehicleListCard
              :vehicle="vehicle"
              @click="emitViewVehicle(vehicle.localId)"
            />
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat class="background">
      <v-card-title>
        <span> Fleet locations </span>
        <v-spacer />
        <TextBtn @click="emitViewLocations"> View all </TextBtn>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-card
            v-for="(location, index) in fleetLocationsList"
            :key="'location-' + index"
            style="width: 50%"
            class="pa-5 ma-1 rounded-lg"
          >
            <v-card-title> {{ location.name }} </v-card-title>
            <v-card-subtitle> {{ location.address }} </v-card-subtitle>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import TextBtn from "../ui-elements/buttons/TextBtn.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { GettersTypes, State } from "@/logic/store/store_types";
import VehicleListCard from "../vehicles/VehicleListCard.vue";
import FavouriteLocation from "@/logic/classes/favouriteLocation";
// import Driver from "@/logic/classes/user_classes/driver";

export default Vue.extend({
  name: "MyFleetContent",
  components: { TextBtn, VehicleListCard },
  methods: {
    emitViewVehicle(id: string | undefined) {
      this.$emit("view-vehicle", id);
    },
    emitViewAllVehicles() {
      this.$emit("view-vehicles");
    },
    emitViewLocations() {
      this.$emit("view-locations");
    },
  },
  computed: {
    vehicleList(): Vehicle[] {
      const returnList: Vehicle[] = [];
      const fullList = this.$store.getters[
        GettersTypes.connectedVehicles
      ] as Vehicle[];

      fullList.forEach((vehicle, index) => {
        if (index < 4) returnList.push(vehicle);
      });

      return returnList;
    },
    // driverList(): Driver[] {
    //   const returnList: Driver[] = [];
    //   const fullList = this.$store.getters[
    //     GettersTypes.otherDrivers
    //   ] as Driver[];
    // }
    fleetLocationsList(): FavouriteLocation[] {
      const unfilteredList = (this.$store.state as State).favLocations;
      return unfilteredList.filter(
        (location) => location.visibility !== "private"
      );
    },
    privateLocationsList(): FavouriteLocation[] {
      const unfilteredList = (this.$store.state as State).favLocations;
      return unfilteredList.filter(
        (location) => location.visibility === "private"
      );
    },
  },
});
</script>
