var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.planning)?_c('InfoPanelCardWrapper',[_c('DescriptiveLoader',{attrs:{"message":"Power Trip is planning your trip"}})],1):_c('InfoPanelCardWrapper',{staticStyle:{"padding-bottom":"100px !important"}},[_c('v-row',{staticClass:"flex-nowrap",attrs:{"align":"center","no-gutters":""}},[_c('TripsHomeBtn'),_c('v-breadcrumbs',{staticClass:"flex-nowrap pl-2",staticStyle:{"max-width":"90%"},attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{staticClass:"text-truncate",attrs:{"to":item.to,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('LocationsListRow',{attrs:{"addressData":_vm.originAddressData,"id":"origin","type":"origin","loading":_vm.addressLoading,"readonly":true},on:{"update":_vm.updateAddress}}),_vm._l((_vm.waypoints),function(stop){return _c('LocationsListRow',{key:stop.local_id,attrs:{"addressData":{
      address: stop.address,
      ...stop.coordinates.asCapitalizedObj,
    },"id":stop.local_id,"type":"stop","loading":_vm.addressLoading,"errorMsg":_vm.addressErrors},on:{"update":_vm.updateAddress}})}),_c('LocationsListRow',{attrs:{"addressData":_vm.destinationAddressData,"id":"destination","type":"destination","loading":_vm.addressLoading,"readonly":true},on:{"update":_vm.updateAddress}}),_c('v-card-title',{staticClass:"pl-0"},[_vm._v(" Add Stops ")]),_c('WaypointPlanningCard',{attrs:{"remainingLoad":_vm.remainingLoad,"originCoordinates":_vm.originAddressData
        ? {
            lat: _vm.originAddressData.coordinates.Latitude,
            lon: _vm.originAddressData.coordinates.Longitude,
          }
        : { lat: 0, lon: 0 },"destinationCoordinates":_vm.destinationAddressData
        ? {
            lat: _vm.destinationAddressData.coordinates.Latitude,
            lon: _vm.destinationAddressData.coordinates.Longitude,
          }
        : { lat: 0, lon: 0 }},on:{"add-stop":_vm.handleAddWaypoint}}),_c('div',{staticClass:"pl-5 pr-7",staticStyle:{"position":"fixed","top":"calc(100dvh - 50px)","left":"0","width":"40%","max-width":"600px"}},[_c('div',{staticClass:"background rounded-lg"},[_c('ElevatedBlockBtn',{staticClass:"mb-3",attrs:{"disabled":_vm.preventPlaning || _vm.addressLoading,"loading":_vm.addressLoading},on:{"click":_vm.handleCreateItinerary}},[_vm._v(" Create Trip Itinerary ")])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }