<template>
  <InfoPanelCardWrapper>
    <v-card flat>
      <!-- address search section -->
      <v-card flat class="mb-10">
        <v-card-text>
          <AddressAutocompleteInput
            placeholder="Search Optimiser for a location..."
            :initialValue="addressData"
            :allowFavLocations="true"
            @update="updateAddress"
          />
          <v-row no-gutters>
            <v-spacer />
            <ElevatedBtn @click="toV2Planning"> Continue </ElevatedBtn>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- history preview section -->
      <v-card-title class="d-flex justify-space-between pt-5 tertiary--text">
        <span>Trip History</span>
        <OutlinedBtn @click="toUnsaved">View All</OutlinedBtn>
      </v-card-title>
      <v-card-text>
        <StoredTripCard
          v-if="unsavedTrips.length"
          :tripDetail="unsavedTrips[0]"
        />
        <StoredTripCard
          v-if="unsavedTrips.length >= 2"
          :tripDetail="unsavedTrips[1]"
        />
        <StoredTripCard
          v-if="unsavedTrips.length >= 3"
          :tripDetail="unsavedTrips[2]"
        />
      </v-card-text>
      <v-card-title class="d-flex justify-space-between pt-5 tertiary--text">
        <span>favourite Trips</span>
        <OutlinedBtn @click="toSaved">View All</OutlinedBtn>
      </v-card-title>
      <v-card-text>
        <StoredTripCard v-if="savedTrips.length" :tripDetail="savedTrips[0]" />
        <StoredTripCard
          v-if="savedTrips.length >= 2"
          :tripDetail="savedTrips[1]"
        />
        <StoredTripCard
          v-if="savedTrips.length >= 3"
          :tripDetail="savedTrips[2]"
        />
      </v-card-text>
    </v-card>
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import InfoPanelCardWrapper from "@/ui/components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import Trip from "@/logic/classes/trip_classes/trip";
import { GettersTypes } from "@/logic/store/store_types";
import TspTrip from "@/logic/classes/tsp_trip_classes/tspTrip";
import StoredTripCard from "@/ui/components/trips/history/StoredTripCard.vue";
import OutlinedBtn from "@/ui/components/ui-elements/buttons/OutlinedBtn.vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../components/ui-elements/inputs/AddressAutocompleteInput.vue";
import ElevatedBtn from "../components/ui-elements/buttons/ElevatedBtn.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import { Dictionary } from "vue-router/types/router";

export default Vue.extend({
  name: "HistoryMainView",
  components: {
    InfoPanelCardWrapper,
    StoredTripCard,
    OutlinedBtn,
    ElevatedBtn,
    AddressAutocompleteInput,
  },
  computed: {
    savedTrips(): (Trip | TspTrip)[] {
      return this.$store.getters[GettersTypes.savedTrips];
    },
    unsavedTrips(): (Trip | TspTrip)[] {
      return this.$store.getters[GettersTypes.unsavedTrips];
    },
  },
  methods: {
    // Routing
    pushRoute(
      routeName: RouteNames,
      query:
        | Dictionary<string | (string | null)[] | null | undefined>
        | undefined = undefined
    ) {
      this.$router.push({ name: routeName, query: query });
    },
    toV2Planning() {
      const address = this.addressData?.address;
      const lat = this.addressData?.coordinates.Latitude;
      const lon = this.addressData?.coordinates.Longitude;
      const name = this.addressData?.name;
      const newQuery = {
        ...this.$route.query,
      };

      if (address) {
        newQuery.destAddress = encodeURI(address);
      }

      if (lat) {
        newQuery.destLat = lat.toString();
      }

      if (lon) {
        newQuery.destLon = lon.toString();
      }

      if (name) {
        newQuery.destName = name;
      }

      this.pushRoute(RouteNames.tripV2AddDestination, newQuery);
    },
    toUnsaved() {
      this.pushRoute(RouteNames.unsaved);
    },
    toSaved() {
      this.pushRoute(RouteNames.saved);
    },
    updateAddress(val: AddressAutocompleteInputUpdateObj) {
      this.addressData = val.addressData;
    },
  },
  data() {
    return {
      addressData: undefined as processedAddressObj | undefined,
    };
  },
});
</script>
