import { evnavRadarCall } from "../api/calls/ev_nav_calls";
import Coordinate from "../classes/common_classes/coordinate";
import { EVNavCar, EVNavCharger } from "../types/ev_nav_types";
import haversineDistance from "./haversineDistance";

export default async function orderChargerSuggestions(
  startPoint: Coordinate,
  endPoint: Coordinate,
  polyline: string,
  vehicle: EVNavCar,
  range: number
): Promise<OrderedChargerSuggestions | undefined> {
  // fetch chargers from ev Nav
  const radarRes = await evnavRadarCall({
    Range: range,
    Polyline: polyline,
    Vehicle: vehicle,
  });
  if (!radarRes) return; // exit early if fetch was unsuccessful.

  // ASSUMES: fetch was successful to reach this point in the code.

  // split into groupings
  const unsortedPrivateChargers: EVNavCharger[] = []; // TODO find a way to link private chargers to this once private chargers exist.
  const unsortedPublicDCChargers = radarRes.Chargers.filter(
    (charger) => !!charger.Ports?.find((port) => port.PowerType === "DC")
  );
  const unsortedPublicACChargers = radarRes.Chargers.filter((charger) =>
    charger.Ports?.every((port) => port.PowerType !== "DC")
  );

  // map to charger with distance objects
  const unsortedPrivateChargersWDist: ChargerWithDistance[] =
    unsortedPrivateChargers.map((charger) => ({
      charger,
      hDistance: calcHDistance(
        startPoint,
        endPoint,
        new Coordinate({
          latitude: charger.Location.Latitude,
          longitude: charger.Location.Longitude,
        })
      ),
    }));
  const unsortedPublicDCChargersWDist: ChargerWithDistance[] =
    unsortedPublicDCChargers.map((charger) => ({
      charger,
      hDistance: calcHDistance(
        startPoint,
        endPoint,
        new Coordinate({
          latitude: charger.Location.Latitude,
          longitude: charger.Location.Longitude,
        })
      ),
    }));
  const unsortedPublicACChargersWDist: ChargerWithDistance[] =
    unsortedPublicACChargers.map((charger) => ({
      charger,
      hDistance: calcHDistance(
        startPoint,
        endPoint,
        new Coordinate({
          latitude: charger.Location.Latitude,
          longitude: charger.Location.Longitude,
        })
      ),
    }));

  // sort chargers with distance objects
  const sortedPrivateChargersWDist =
    unsortedPrivateChargersWDist.sort(sortByHaversine);
  const sortedPublicDCChargersWDist =
    unsortedPublicDCChargersWDist.sort(sortByHaversine);
  const sortedPublicACChargersWDist =
    unsortedPublicACChargersWDist.sort(sortByHaversine);

  // sort and return results
  return {
    privateChargers: sortedPrivateChargersWDist.flatMap(
      (chargerWDist) => chargerWDist.charger
    ),
    publicDCChargers: sortedPublicDCChargersWDist.flatMap(
      (chargerWDist) => chargerWDist.charger
    ),
    publicACChargers: sortedPublicACChargersWDist.flatMap(
      (chargerWDist) => chargerWDist.charger
    ),
  };
}

export type OrderedChargerSuggestions = {
  privateChargers: EVNavCharger[];
  publicDCChargers: EVNavCharger[];
  publicACChargers: EVNavCharger[];
};

type ChargerWithDistance = {
  charger: EVNavCharger;
  hDistance: number;
};

function sortByHaversine(
  a: ChargerWithDistance,
  b: ChargerWithDistance
): number {
  return a.hDistance - b.hDistance;
}

function calcHDistance(
  startPoint: Coordinate,
  endPoint: Coordinate,
  chargerPoint: Coordinate
): number {
  return (
    haversineDistance(startPoint.asLngLat, chargerPoint.asLngLat) +
    haversineDistance(endPoint.asLngLat, chargerPoint.asLngLat)
  );
}
