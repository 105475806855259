import TripLocation from "../classes/trip_classes/tripLocation";
import type { EVNavStep } from "../types/ev_nav_types";

/**
 * Helper function to return the accumulated number of seconds in a given strip up till its current step.
 *
 * @param steps an array of steps on this trip
 * @param index the index of the current step
 * @returns the number of accumulated seconds up until a given step
 */
export default function accumulatedTimeSeconds(
  steps: EVNavStep[],
  index: number,
  additionalStops: TripLocation[]
): number {
  let seconds = 0;

  const chargeHereStops: TripLocation[] = additionalStops.filter(
    (stop) => stop.chargeHere
  );
  let currentChargeHereIndex = 0;

  for (let i = 0; i < index; i++) {
    // check if scheduled stop
    if (steps[i].From.includes("location-stop")) {
      // find scheduled stop
      const scheduledStop = additionalStops.find(
        (stop) => stop.localId === steps[i].From
      );
      seconds =
        seconds +
        steps[i].TravelTime +
        (steps[i].ChargeTime ?? 0) +
        (scheduledStop?.stay || 0);
    } else if (steps[i].To === "Destination" && steps[i + 1].From === "Start") {
      // find charge here stop
      const chargeHereStop = chargeHereStops[currentChargeHereIndex];
      seconds =
        seconds +
        steps[i].TravelTime +
        (steps[i].ChargeTime ?? 0) +
        (chargeHereStop?.stay || 0);
      currentChargeHereIndex++;
    } else {
      seconds = seconds + steps[i].TravelTime + (steps[i].ChargeTime ?? 0);
    }
  }

  return seconds;
}
