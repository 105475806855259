<template>
  <v-card flat v-if="trip" class="px-5">
    <!-- title section -->
    <v-card-title> <BackBtn /> Refined Comparison </v-card-title>
    <!-- vehicle section -->
    <v-card class="ma-5 pa-5 rounded-lg">
      <v-row no-gutters>
        <v-col cols="3">
          <v-img
            contain
            :src="imageSrc"
            aspect-ratio="1"
            width="100%"
            height="100%"
          ></v-img>
        </v-col>
        <v-col cols="8" class="pl-8 pt-5">
          <!-- <v-row> REGO </v-row> -->
          <v-row> {{ trip.displayedComparisonData.evModel.name }} </v-row>
        </v-col>
      </v-row>
    </v-card>
    <!-- itinerary section -->
    <v-card flat>
      <v-card-title class="tertiary--text"> Itinerary </v-card-title>
      <v-card-text>
        <template v-for="(item, index) in trip.itinerary">
          <!-- travel leg card -->
          <v-card
            v-if="!item.locationType"
            :key="'travel-leg-' + index"
            flat
            class="pl-10"
          >
            <v-card-title
              class="text-body-2 pt-0 d-flex flex-column align-start"
              style="word-break: normal"
            >
              <v-row no-gutters>
                <span>
                  Drive
                  <span class="font-weight-bold">
                    {{ Math.round(item.distance) }}km
                  </span>
                  for
                  <span class="font-weight-bold">
                    {{ niceDuration(item.drivingTime) }}
                  </span>
                </span>
              </v-row>
              <v-row no-gutters>
                <span>
                  Using
                  <span class="font-weight-bold">
                    {{ getBatteryPercentage(item.energyUsed) }}%
                  </span>
                  of your battery
                </span>
              </v-row>
            </v-card-title>
            <v-card-subtitle
              class="grey--text text--darken-2 pt-1"
              v-if="item.ferryTime"
            >
              <v-row no-gutters>
                <v-icon color="primary" class="mr-1">mdi-ferry</v-icon>
                Ferry for {{ niceDuration(item.ferryTime) }}
              </v-row>
            </v-card-subtitle>
            <v-card-text class="pl-0">
              <BatteryChangeGage
                :startPercentage="getBatteryPercentage(item.startingEnergy)"
                :endPercentage="getBatteryPercentage(item.endingEnergy)"
              />
              <SuggestChargersBtn :itineraryIndex="index" :trip="trip" />
            </v-card-text>
          </v-card>
          <!-- origin location card -->
          <v-card
            v-else-if="item.locationType === 'origin'"
            :key="'origin-location-' + index"
            class="primary rounded-lg mb-5"
            dark
          >
            <v-card-title
              style="word-break: normal"
              class="font-weight-bold text-body-1"
            >
              <v-row align="center" no-gutters>
                <v-col cols="2" sm="1">
                  <div
                    class="primary rounded-circle"
                    style="
                      height: 20px;
                      width: 20px;
                      border: 3px solid white !important;
                    "
                  ></div>
                </v-col>
                <v-col cols="10" sm="11">
                  <v-row no-gutters> Origin </v-row>
                  <v-row no-gutters class="text-subtitle-1">
                    {{ item.addressDisplayStr }}
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pl-13 text-body-2 font-weight-bold">
              <v-row no-gutters justify="space-between">
                <span> Departing Load </span>
                <span> {{ item.departingLoadWeight }}kgs </span>
              </v-row>
              <v-row no-gutters justify="space-between">
                <span> Departing Charge </span>
                <span> {{ getBatteryPercentage(item.departingEnergy) }}% </span>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- destination location card -->
          <v-card
            v-else-if="item.locationType === 'destination'"
            :key="'destination-location-' + index"
            class="primary rounded-lg mb-5"
            dark
          >
            <v-card-title
              style="word-break: normal"
              class="font-weight-bold text-body-1"
            >
              <v-row align="center" no-gutters>
                <v-col cols="2" sm="1">
                  <div
                    class="primary rounded-circle"
                    style="
                      height: 20px;
                      width: 20px;
                      border: 3px solid white !important;
                    "
                  ></div>
                </v-col>
                <v-col cols="10" sm="11">
                  <v-row no-gutters> Destination </v-row>
                  <v-row no-gutters class="text-subtitle-1">
                    {{ item.addressDisplayStr }}
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pl-13 text-body-2 font-weight-bold">
              <v-row no-gutters justify="space-between">
                <span> Arrival Load </span>
                <span> {{ item.arrivalLoadWeight }}kgs </span>
              </v-row>
              <v-row no-gutters justify="space-between">
                <span> Arrival Charge </span>
                <span> {{ getBatteryPercentage(item.arrivalEnergy) }}% </span>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- waypoint location card -->
          <v-card
            v-else-if="item.locationType === 'waypoint'"
            :key="'waypoint-location-' + index"
            class="rounded-lg mb-5 grey lighten-2"
          >
            <v-card-title
              style="word-break: normal"
              class="font-weight-bold text-body-1"
            >
              <v-row align="center" no-gutters>
                <v-col cols="2" sm="1">
                  <div
                    class="rounded-circle grey darken-1"
                    style="width: 20px; height: 20px"
                  ></div>
                </v-col>
                <v-col cols="10" sm="11">
                  <span>
                    {{ item.addressDisplayStr }}
                  </span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="pl-13 text-body-2 font-weight-bold">
              <v-row no-gutters justify="space-between">
                <span> Arrival Load </span>
                <span> {{ item.arrivalLoadWeight }}kgs </span>
              </v-row>
              <v-row no-gutters justify="space-between">
                <span> Departing Load </span>
                <span> {{ item.departingLoadWeight }}kgs </span>
              </v-row>
              <v-row
                no-gutters
                justify="space-between"
                v-if="item.chargingTime"
              >
                <span> Charging Time </span>
                <span> {{ niceDuration(item.chargingTime) }} </span>
              </v-row>
              <v-row no-gutters justify="space-between">
                <span> Arrival Charge </span>
                <span> {{ getBatteryPercentage(item.arrivalEnergy) }}% </span>
              </v-row>
              <v-row no-gutters justify="space-between">
                <span> Departing Charge </span>
                <span> {{ getBatteryPercentage(item.departingEnergy) }}% </span>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- charger location card -->
          <ChargerLocationCard
            v-else-if="item.locationType === 'charger'"
            :key="'charger-location-' + index"
            :itineraryLocation="item"
            :chargeToPercentage="getBatteryPercentage(item.departingEnergy)"
            :batterySize="batterySize"
            @remove="removeStop(index)"
            @update="updateStop($event, index)"
          />
        </template>
      </v-card-text>
    </v-card>
    <!-- actions section -->
    <v-card flat class="pa-5">
      <ElevatedBlockBtn class="mb-3" :disabled="true">
        Save this trip
      </ElevatedBlockBtn>
      <ElevatedBlockBtn :disabled="true">
        Send to my Driver app
      </ElevatedBlockBtn>
    </v-card>
  </v-card>
  <NoTripContent v-else />
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { GettersTypes, MutationTypes } from "@/logic/store/store_types";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import { Valhalla_Leg } from "@/logic/types/valhalla_types";
import ElevatedBlockBtn from "@/ui/components/ui-elements/buttons/ElevatedBlockBtn.vue";
import NoTripContent from "../../../NoTripContent.vue";
import BatteryChangeGage from "@/ui/components/ui-elements/BatteryChangeGage.vue";
import TspTrip from "@/logic/classes/tsp_trip_classes/tspTrip";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import SuggestChargersBtn from "./SuggestChargersBtn.vue";
import ChargerLocationCard from "./ChargerLocationCard.vue";
import ChargerLocation from "@/logic/classes/itinerary_data_classes/ChargerLocation";
import WaypointLocation from "@/logic/classes/itinerary_data_classes/waypointLocation";
import BackBtn from "@/ui/components/ui-elements/buttons/BackBtn.vue";

export default Vue.extend({
  name: "TspRefinedComparison",
  components: {
    ElevatedBlockBtn,
    NoTripContent,
    BatteryChangeGage,
    SuggestChargersBtn,
    ChargerLocationCard,
    BackBtn,
  },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    imageSrc(): string {
      return (
        (this.trip as TspTrip)?.displayedComparisonData?.evModel?.imageSrc ??
        getAssetSrc("car_images/No_Image_Powersell.png")
      );
    },
    batterySize(): number | undefined {
      return (this.trip as TspTrip)?.displayedComparisonData?.batterySize;
    },
  },
  methods: {
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
    calcFerryTime(leg: Valhalla_Leg): string {
      const ferryTime = leg.maneuvers.reduce((accumulator, currentItem) => {
        if (currentItem.ferry) {
          return accumulator + currentItem.time;
        }

        return accumulator;
      }, 0);
      return this.niceDuration(ferryTime);
    },
    calcDrivingTime(leg: Valhalla_Leg): string {
      const drivingTime = leg.maneuvers.reduce((accumulator, currentItem) => {
        if (!currentItem.ferry) {
          return accumulator + currentItem.time;
        }

        return accumulator;
      }, 0);
      return this.niceDuration(drivingTime);
    },
    getBatteryPercentage(energy: number): number | undefined {
      if (!this.batterySize) return;
      return Math.round((energy / this.batterySize) * 100);
    },
    async removeStop(index: number): Promise<void> {
      const copyTrip = this.trip as TspTrip;
      const status = await copyTrip.removeStop(index);
      if (status === "success") {
        this.$store.commit(MutationTypes.updateIndividualTrip, copyTrip);
      }
    },
    updateStop(stop: ChargerLocation | WaypointLocation, index: number) {
      const oldStop = this.trip.itinerary[index];
      if (
        !(oldStop instanceof ChargerLocation) &&
        !(oldStop instanceof WaypointLocation)
      )
        return; // exit early as index is pointing at incorrect obj.
      const copyTrip = this.trip as TspTrip;
      copyTrip.itinerary.splice(index, 1, stop);
      copyTrip.recalculateItineraryFloatingData();
      this.$store.commit(MutationTypes.updateIndividualTrip, copyTrip);
    },
  },
});
</script>
