<template>
  <GenericDialog :activator="activator" @close="emitClose()">
    <v-card-title>
      Edit favourite location
      <v-spacer />
      <v-btn icon @click="emitClose()">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <FavLocationForm @close="emitClose()" :initialValue="favLocation" />
  </GenericDialog>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import GenericDialog from "../../dialog-wrappers/GenericDialog.vue";
import FavLocationForm from "./FavLocationForm.vue";
import FavouriteLocation from "@/logic/classes/favouriteLocation";
export default Vue.extend({
  name: "FavLocationAddDialog",
  components: { GenericDialog, FavLocationForm },
  props: {
    activator: {
      type: Boolean,
    },
    favLocation: {
      type: Object as PropType<FavouriteLocation | undefined>,
    },
  },
  methods: {
    /**
     * Emits a "close" event to the parent component.
     *
     * @return {void}
     */
    emitClose() {
      this.$emit("close");
    },
  },
});
</script>
