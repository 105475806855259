<template>
  <v-card elevation="4" class="rounded-lg error--text mb-5 pb-3">
    <v-card-title> We were unable to optimise your trip </v-card-title>
    <v-card-text class="error--text">
      You are unable to make one or more of the legs of this trip with your
      selected EV and your current settings.</v-card-text
    >
    <v-card-actions v-if="!showReason">
      <v-btn color="error" class="text-none rounded-lg" @click="findReason">
        Find out what went wrong
      </v-btn>
    </v-card-actions>
    <v-card-text
      v-if="showReason && !loading && reason"
      class="error--text pt-0"
    >
      <ul style="list-style: none" class="pa-0">
        <li>
          <v-card-title class="pl-0 pt-0"> What went wrong </v-card-title>
        </li>
        <li>{{ reason }}</li>
      </ul>
    </v-card-text>
    <v-card-text v-if="showReason && loading" class="d-flex justify-center">
      <LoadingCard />
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import LoadingCard from "@/ui/components/ui-elements/loaders/LoadingCard.vue";
import Trip from "@/logic/classes/trip_classes/trip";

export default Vue.extend({
  name: "WhatWentWrongCard",
  props: {
    trip: {
      required: true,
      type: Object as PropType<Trip>,
    },
  },
  data() {
    return {
      loading: true,
      showReason: false,
      reason: undefined as string | undefined,
    };
  },
  beforeDestroy() {
    this.loading = true;
    this.showReason = false;
    this.reason = undefined;
  },
  methods: {
    async findReason() {
      this.showReason = true;
      if (this.reason) {
        this.reason;
        this.loading = false;
        return;
      }
      this.reason = await this.trip.findCause();
      this.loading = false;
    },
  },
  components: { LoadingCard },
});
</script>
