<template>
  <v-row no-gutters>
    <v-col cols="1" class="d-flex align-center">
      <v-btn icon>
        <v-icon color="primary" x-large @click="emitAddMe">
          mdi-plus-circle
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="10">
      <v-card class="grey lighten-2 rounded-lg mb-3">
        <v-row no-gutters>
          <v-col :cols="branding ? 9 : 12">
            <v-card-title>
              {{
                evNavCharger.ParkName
                  ? evNavCharger.ParkName
                  : cDbCharger && cDbCharger.name
                    ? cDbCharger.name
                    : "Unnamed Charger"
              }}
            </v-card-title>
            <v-card-subtitle>
              {{
                cDbCharger && cDbCharger.address
                  ? cDbCharger.address
                  : "unknown address"
              }}
            </v-card-subtitle>
          </v-col>
          <v-col
            cols="3"
            v-if="branding"
            class="d-flex align-center justify-end pr-3"
          >
            <OperatorLogo :networkBranding="branding" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="1"></v-col>
  </v-row>
</template>
<script lang="ts">
import Charger from "@/logic/classes/charger_classes/charger";
import NetworkBranding from "@/logic/classes/charger_classes/networkBranding";
import { State } from "@/logic/store/store_types";
import { EVNavCharger } from "@/logic/types/ev_nav_types";
import OperatorLogo from "@/ui/components/charging-stations/details/common/OperatorLogo.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ChargerRow",
  props: {
    evNavCharger: {
      required: true,
      type: Object as PropType<EVNavCharger>,
    },
  },
  computed: {
    cDbCharger(): Charger | undefined {
      return (this.$store.state as State).chargers.find(
        (charger) => charger.id === (this.evNavCharger as EVNavCharger).CDBID
      );
    },
    branding(): NetworkBranding | undefined {
      return (this.$store.state as State).networkBranding.find((network) =>
        network.isThisNetwork((this.evNavCharger as EVNavCharger).Network)
      );
    },
  },
  methods: {
    emitAddMe(): void {
      this.$emit("addMe", this.evNavCharger);
    },
  },
  components: { OperatorLogo },
});
</script>
