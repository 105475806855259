<template>
  <v-card flat>
    <v-card-text> Limit chargers by network </v-card-text>
    <v-card-actions>
      <ElevatedBtn @click="openNetworkSettings">
        <v-icon class="mr-1"> mdi-cog </v-icon>
        Network Settings
      </ElevatedBtn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {
  MutationTypes,
  SecondaryDialogContent,
} from "@/logic/store/store_types";
import Vue from "vue";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";

/** Vue Component: renders the main setting icon button. To be used in the `App` component. */
export default Vue.extend({
  name: "SettingsBtn",
  components: { ElevatedBtn },
  methods: {
    openNetworkSettings() {
      this.$store.commit(
        MutationTypes.setSecondaryDialogContent,
        SecondaryDialogContent.NETWORK_SETTINGS
      );
    },
  },
});
</script>
