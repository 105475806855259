<template>
  <v-card flat>
    <v-card flat class="d-flex flex-column justify-center align-center">
      <v-card-title class="tertiary--text">No Trip Selected</v-card-title>
      <v-card-subtitle>
        Plan a new trip or select a previous one
      </v-card-subtitle>
    </v-card>
    <v-card-text>
      <ElevatedBlockBtn @click="toPlanning" class="mb-3">
        Plan a New Trip
      </ElevatedBlockBtn>
      <OutlinedBlockBtn @click="toTrips">
        Select previous trips
      </OutlinedBlockBtn>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { RouteNames } from "@/logic/router";
import ElevatedBlockBtn from "../ui-elements/buttons/ElevatedBlockBtn.vue";
import OutlinedBlockBtn from "../ui-elements/buttons/OutlinedBlockBtn.vue";

export default Vue.extend({
  name: "NoTripContent",
  components: { ElevatedBlockBtn, OutlinedBlockBtn },
  methods: {
    /**
     * Helper function to push a route to the vue router
     * @param routeName the name of the route to push
     */
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    /**
     * Navigate to the trip planning page
     */
    toPlanning() {
      this.pushRoute(RouteNames.tripV2AddDestination);
    },
    /**
     * Navigate to the trips home page
     */
    toTrips() {
      this.pushRoute(RouteNames.trips);
    },
  },
});
</script>
