<template>
  <v-card
    class="mt-3 rounded-lg"
    :class="isShowing ? 'primary' : ''"
    @click="emitShowMe"
  >
    <!-- <v-btn
      fab
      small
      absolute
      style="right: -5px; top: -5px"
      @click.stop="emitRemove"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn> -->
    <v-card-text :class="isShowing ? 'white--text' : ''">
      <v-img
        contain
        :src="imageSrc"
        aspect-ratio="1"
        width="100%"
        height="100%"
        max-height="50%"
      ></v-img>
      {{
        comparison.vehicle.evModel
          ? comparison.vehicle.evModel.name
          : "something"
      }}
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import TripComparison from "@/logic/classes/trip_v2_classes/tripComparison";

export default Vue.extend({
  name: "TspComparisonCard",
  props: {
    comparison: {
      type: Object as PropType<TripComparison>,
      required: true,
    },
    isShowing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitShowMe(): void {
      this.$emit("showMe");
    },
    emitRemove(): void {
      this.$emit("remove");
    },
  },
  computed: {
    imageSrc(): string {
      return (
        this.comparison.vehicle?.imageSrcPaths?.[0] ??
        this.comparison.vehicle?.evModel?.imageSrc ??
        getAssetSrc("car_images/No_Image_Powersell.png")
      );
    },
  },
});
</script>
