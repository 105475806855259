<template>
  <v-card flat v-if="!trip && !status">
    <v-card flat class="d-flex flex-column justify-center align-center">
      <v-card-title class="tertiary--text">No Trip Selected</v-card-title>
      <v-card-subtitle>
        Plan a new trip or select a previous one
      </v-card-subtitle>
    </v-card>
    <v-card-text>
      <ElevatedBlockBtn @click="toPlanning" class="mb-3">
        Plan a New Trip
      </ElevatedBlockBtn>
      <OutlinedBlockBtn @click="toOptimisedTripPlanning" class="mb-3">
        Plan a New Optimised Trip
      </OutlinedBlockBtn>
      <TextBlockBtn @click="toTrips"> Select previous trips </TextBlockBtn>
    </v-card-text>
  </v-card>
  <v-card
    v-else-if="status === 'PROCESSING'"
    flat
    class="d-flex justify-center align-center"
    style="height: 80vh"
  >
    <LoadingCard />
  </v-card>
  <ItineraryEVContent v-else-if="trip.status === 'EV-SUCCESS'" :trip="trip" />
  <ItineraryFallbackContent
    v-else-if="trip.status === 'FALLBACK'"
    :trip="trip"
  />
  <ItineraryICEContent v-else-if="trip.status === 'ICE-SUCCESS'" :trip="trip" />
  <FailedTripCard v-else-if="trip.status === 'FAILED'" />
  <TspTripItinerary
    v-else-if="trip.status === 'optimised-trip'"
    :trip="trip"
    @back="back"
  />
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import ItineraryEVContent from "./ev-nav-trips/ItineraryEVContent.vue";
import ItineraryFallbackContent from "./ev-nav-trips/ItineraryFallbackContent.vue";
import ItineraryICEContent from "./ev-nav-trips/ItineraryICEContent.vue";
import {
  AsyncStatus,
  GettersTypes,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import LoadingCard from "@/ui/components/ui-elements/loaders/LoadingCard.vue";
import FailedTripCard from "./ev-nav-trips/FailedTripCard.vue";
import { powerTripDarkBlue } from "@/logic/data/const";
import { RouteNames } from "@/logic/router";
import TspTripItinerary from "./tsp-trips/TspTripItinerary.vue";
import TextBlockBtn from "@/ui/components/ui-elements/buttons/TextBlockBtn.vue";
import OutlinedBlockBtn from "@/ui/components/ui-elements/buttons/OutlinedBlockBtn.vue";
import ElevatedBlockBtn from "@/ui/components/ui-elements/buttons/ElevatedBlockBtn.vue";

export default Vue.extend({
  name: "ItineraryMain",
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    ...mapState({
      status: (state: unknown): AsyncStatus | undefined =>
        (state as State).routePlanningStatus,
    }),
  },
  components: {
    ItineraryEVContent,
    ItineraryFallbackContent,
    ItineraryICEContent,
    LoadingCard,
    FailedTripCard,
    TspTripItinerary,
    TextBlockBtn,
    OutlinedBlockBtn,
    ElevatedBlockBtn,
  },
  methods: {
    back() {
      this.$router.back();
    },
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    toPlanning() {
      this.pushRoute(RouteNames.planning);
    },
    toTrips() {
      this.pushRoute(RouteNames.trips);
    },
    toOptimisedTripPlanning() {
      this.pushRoute(RouteNames.optimisedPlanning);
    },
  },
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === RouteNames.trips) {
      this.$store.commit(MutationTypes.setSelectedTrip, undefined);
    }
    next();
  },
});
</script>
