var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{staticStyle:{"z-index":"1200"},attrs:{"value":_vm.activator,"width":_vm.$vuetify.breakpoint.mdAndUp ? '70%' : '80%',"content-class":_vm.$vuetify.breakpoint.xs ? '' : 'rounded-lg',"scrollable":"","max-width":_vm.$vuetify.breakpoint.mdAndUp
      ? _vm.small
        ? '600px'
        : '800px'
      : _vm.small
        ? '400px'
        : '650px',"min-width":_vm.$vuetify.breakpoint.mdAndUp
      ? _vm.small
        ? '450px'
        : '600px'
      : _vm.small
        ? '250px'
        : '350px',"fullscreen":_vm.$vuetify.breakpoint.xs,"retain-focus":""},on:{"click:outside":function($event){return _vm.emitClose()},"keydown":function($event){return _vm.keyDown($event)}}},[_c('v-card',{staticClass:"pa-5 background",class:_vm.$vuetify.breakpoint.xs ? '' : 'rounded-lg',staticStyle:{"height":"100%"}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }