<template>
  <v-card flat class="pb-5 background" v-if="savingStatus === 'PROCESSING'">
    <DescriptiveLoader message="Power Trip is saving your data" />
  </v-card>
  <v-card flat class="pb-5 background" v-else-if="savingStatus === 'FAILED'">
    <v-alert type="error" class="mx-2">
      Settings where updated locally but failed to be saved to the database
    </v-alert>
  </v-card>
  <v-card flat class="pb-5 background" v-else-if="savingStatus === 'SUCCESS'">
    <v-alert type="success" class="mx-2">
      Settings updated successfully and saved to the database
    </v-alert>
  </v-card>
  <v-card flat class="pb-5 background" v-else>
    <v-row no-gutters class="pt-10 px-4 background">
      <v-card class="rounded-lg px-4 mb-3 mr-2" width="calc(50% - 4px)">
        <v-card-title> Departing charge</v-card-title>
        <v-card-text>
          <v-slider
            :label="startingCharge + '%'"
            v-model="startingCharge"
            min="1"
            max="100"
            track-color="grey lighten-2"
            @end="flagAsDirty"
          />
        </v-card-text>
      </v-card>
      <v-card class="rounded-lg px-4 mb-3" width="calc(50% - 4px)">
        <v-card-title> Arrival charge</v-card-title>
        <v-card-text>
          <v-slider
            :label="endingCharge + '%'"
            v-model="endingCharge"
            min="1"
            max="100"
            track-color="grey lighten-2"
            @end="flagAsDirty"
          />
        </v-card-text>
      </v-card>
    </v-row>

    <v-card-title> Extra details </v-card-title>

    <v-card class="pa-5 rounded-lg mx-4">
      <v-card-title> Speed adjustment </v-card-title>
      <v-card-subtitle>
        How fast do you drive compared to the speed?
      </v-card-subtitle>
      <v-card-text>
        <div
          class="d-flex justify-center align-center grey lighten-4 rounded-lg pa-3"
        >
          {{
            (relativeSpeed < 0
              ? `${Math.round(relativeSpeed).toString().replace("-", "")}% slower`
              : `${Math.round(relativeSpeed)}% faster`) +
            ` or ${Math.round(
              50 * ((100 + relativeSpeed) / 100)
            )}kmh in a 50kmh zone`
          }}
        </div>
      </v-card-text>
      <v-slider
        min="-50"
        max="50"
        class="mx-2"
        color="primary"
        track-color="grey lighten-2"
        v-model="relativeSpeed"
        @input="flagAsDirty"
      ></v-slider>

      <!-- charging range section -->
      <v-card-title> Charging Range </v-card-title>
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Minimum Charge"
              v-model="displayRange[0]"
              suffix="%"
              type="number"
              hide-spin-buttons
              :rules="[validateRange]"
              @input="flagAsDirty"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Maximum Charge"
              v-model="displayRange[1]"
              suffix="%"
              type="number"
              hide-spin-buttons
              :rules="[validateRange]"
              @input="flagAsDirty"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-range-slider
        @end="flagAsDirty"
        v-model="displayRange"
        color="primary"
        track-color="grey lighten-2"
        class="px-2 pt-0"
        min="1"
        max="100"
        hide-details
      ></v-range-slider>
      <!-- Extra Weight -->
      <v-row>
        <v-card flat class="pa-5" width="50%">
          <v-card-title> Extra Weight </v-card-title>
          <v-card-text>
            <v-row align="center" class="mb-7">
              <v-btn large icon @click="decrementVal('extraWeight')">
                <v-icon large> mdi-minus </v-icon>
              </v-btn>
              <div
                class="grey lighten-4 rounded-lg d-flex align-center"
                style="height: 56px; width: calc(100% - 88px)"
                id="battery-custom-input"
              >
                <v-text-field
                  v-model="extraWeight"
                  class="right-aligned-input none-underlined-input off-set-input-message pt-5"
                  style="max-width: 145px"
                  suffix="kg"
                  type="number"
                  hide-spin-buttons
                  :rules="[validateExtraWeight]"
                  @input="flagAsDirty"
                ></v-text-field>
              </div>

              <v-btn large icon @click="incrementVal('extraWeight')">
                <v-icon large> mdi-plus </v-icon>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat class="pa-5" width="50%">
          <v-card-title> Passengers </v-card-title>
          <v-card-text>
            <v-row align="center" class="mb-7">
              <v-btn large icon @click="decrementVal('passengers')">
                <v-icon large> mdi-minus </v-icon>
              </v-btn>
              <div
                class="grey lighten-4 rounded-lg d-flex align-center"
                style="height: 56px; width: calc(100% - 88px)"
                id="battery-custom-input"
              >
                <v-text-field
                  v-model="passengers"
                  class="right-aligned-input none-underlined-input off-set-input-message pt-5"
                  style="max-width: 145px"
                  type="number"
                  hide-spin-buttons
                  :rules="[validateExtraWeight]"
                  @input="flagAsDirty"
                ></v-text-field>
              </div>

              <v-btn large icon @click="incrementVal('passengers')">
                <v-icon large> mdi-plus </v-icon>
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-card>
    <v-card-text>
      <v-row no-gutters>
        <v-spacer />
        <ElevatedBtn @click="handleSave" :disabled="!dirty"> Save </ElevatedBtn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import {
  ActionTypes,
  BatchUpdatableSettings,
  type State,
} from "@/logic/store/store_types";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import to2DP from "@/logic/utils/to2DP";
import Vue from "vue";
import { mapState } from "vuex";
import ElevatedBtn from "@/ui/components/ui-elements/buttons/ElevatedBtn.vue";
import DescriptiveLoader from "../ui-elements/loaders/DescriptiveLoader.vue";

/** Vue Component: renders driver based adjustable settings. To be used in the `VehicleContent` component. */
export default Vue.extend({
  name: "TripPlanningDefaults",
  components: { ElevatedBtn, DescriptiveLoader },
  methods: {
    flagAsDirty() {
      if (this.loading) return;
      this.dirty = true;
    },
    handleSave() {
      const dataToUpdate: BatchUpdatableSettings = {
        passengers: this.passengers,
        extraWeight: this.extraWeight,
        SOCMin: to2DP(this.displayRange[0] / 100),
        SOCMax: to2DP(this.displayRange[1] / 100),
        SOCAct: to2DP(this.startingCharge / 100),
        SOCEnd: to2DP(this.endingCharge / 100),
        relativeSpeed: this.relativeSpeed / 100,
      };
      this.$store.dispatch(ActionTypes.updatedSettingsData, dataToUpdate);
      this.dirty = false;
    },
    validateRange(val: number | string): boolean | string {
      const parsedVal = parseIntOrFloat(val);
      if (!parsedVal) return "needs to be a number";
      if (parsedVal < 1 || parsedVal > 100) return "needs to be 1-100";
      return true;
    },
    decrementVal(property: "extraWeight" | "passengers"): void {
      // exit early if cant find property
      if (!(Object.hasOwn(this, property) as boolean)) return;
      // parse value
      const parsedVal = parseIntOrFloat(this[property]);
      // exit early if parsing value failed or would be less than 1(would be less than 0 after decrement).
      if (parsedVal === undefined || parsedVal < 1) return;
      // decrement property
      this[property] = parsedVal - 1;
      this.flagAsDirty();
    },
    incrementVal(property: "extraWeight" | "passengers"): void {
      // exit early if cant find property
      if (!(Object.hasOwn(this, property) as boolean)) return;
      // parse value
      const parsedVal = parseIntOrFloat(this[property]);
      // exit early if parsing value failed
      if (parsedVal === undefined) return;
      // increment property
      this[property] = parsedVal + 1;
      this.flagAsDirty();
    },
    validateExtraWeight(val: number | string | null): boolean | string {
      // allow no extra wight to pass validation
      if (val === null || val === 0 || val === "0") return true;
      const parsedVal = parseIntOrFloat(val);
      if (!parsedVal) return "needs to be a number";
      return true;
    },
  },
  data() {
    return {
      loading: true,
      dirty: false,
      displayRange: [10, 90],
      canBeRecalculate: false,
      startingCharge: 0,
      endingCharge: 0,
      passengers: 0,
      extraWeight: 0,
      relativeSpeed: 100,
    };
  },
  computed: {
    ...mapState({
      initialRange: (state: unknown): [number, number] => [
        Math.round((state as State).SOCMin * 100),
        Math.round((state as State).SOCMax * 100),
      ],
      initialExtraWeight: (state: unknown) => (state as State).extraWeight,
      initialRelativeSpeed: (state: unknown) =>
        (state as State).relativeSpeed * 100,
      initialPassengers: (state: unknown) => (state as State).passengers,
      initialSOCAct: (state: unknown) => (state as State).SOCAct,
      initialSOCEnd: (state: unknown) => (state as State).SOCEnd,
      savingStatus: (state: unknown) => (state as State).settingsSavingStatus,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.displayRange = this.initialRange;
      this.extraWeight = this.initialExtraWeight ?? 0;
      this.relativeSpeed = this.initialRelativeSpeed ?? 100;
      this.passengers = this.initialPassengers ?? 0;
      this.startingCharge = this.initialSOCAct
        ? Math.round(this.initialSOCAct * 100)
        : 0;
      this.endingCharge = this.initialSOCEnd
        ? Math.round(this.initialSOCEnd * 100)
        : 0;
      this.$nextTick(() => {
        this.loading = false;
      });
    });
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}

#battery-custom-input {
  /* position off set centered element 145px width within a 80% width container */
  padding-left: calc(40% - 108px);
  padding-right: calc(40% - 37px);
}
.right-aligned-input >>> input {
  text-align: right;
}

.none-underlined-input >>> .v-input__slot::before {
  border-style: none !important;
}

.off-set-input-message >>> .v-messages__message {
  padding-top: 16px;
}
</style>
