<template>
  <!-- processing card -->
  <DescriptiveLoader
    v-if="processing"
    message="Power Trip is updating your vehicles saved settings"
    class="background"
  />
  <!-- save successful card -->
  <v-card flat class="pa-5 background" v-else-if="saveStatus === 'success'">
    <v-alert type="success" class="mx-2">
      Vehicle updated successfully and saved to the database
    </v-alert>
  </v-card>
  <!-- save unsuccessful card -->
  <v-card flat class="pa-5 background" v-else-if="saveStatus === 'failed'">
    <v-alert type="error" class="mx-2">
      Vehicle was updated locally but failed to be saved to the database
    </v-alert>
  </v-card>
  <!-- vehicle details card -->
  <v-card flat class="pa-5 background" v-else>
    <!-- vehicle details card -->
    <v-card class="py-4 pl-md-12 rounded-lg background mb-10" flat>
      <v-row class="flex-column flex-md-row">
        <v-col cols-md="3">
          <!-- image section -->
          <v-skeleton-loader
            type="image"
            width="100%"
            height="140px"
            v-if="!vehicle"
          />
          <v-img
            v-else
            contain
            :src="imageSrc()"
            aspect-ratio="1"
            :width="$vuetify.breakpoint.mdAndUp ? '100%' : '40%'"
            :height="$vuetify.breakpoint.mdAndUp ? '100%' : 'auto'"
            style="margin: auto"
          ></v-img>
        </v-col>
        <v-col cols-md="9">
          <!-- details section -->
          <div v-if="vehicle">
            <v-card-title>
              {{ isSelected ? "Current vehicle" : "Unselected vehicle" }}
              <v-spacer></v-spacer>
              <OutlinedBtn
                @click="isSelected ? handleSelectOther() : handleSelect()"
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                {{
                  isSelected ? "Change selected vehicle" : "Select this vehicle"
                }}
              </OutlinedBtn>
            </v-card-title>
            <v-card-subtitle class="font-weight-medium pr-12">
              {{ vehicle.name ?? "unnamed vehicle" }}
            </v-card-subtitle>
            <v-card-text class="pr-12">
              <p class="mb-0">
                {{ vehicle.licensePlate ?? "unrecorded license plate" }}
              </p>
              <p v-if="vehicle.evModel">
                {{ vehicle.evModel.name }}
              </p>
              <v-slider
                style="max-width: 250px"
                :value="vehicle.stateOfCharge ?? 0"
                readonly
                :label="(vehicle.stateOfCharge ?? '??') + '% battery'"
                min="0"
                max="100"
                track-color="grey lighten-2"
                :messages="
                  !vehicle.stateOfCharge ? 'charge unknown' : undefined
                "
              />
              <OutlinedBlockBtn
                @click="isSelected ? handleSelectOther() : handleSelect()"
                v-if="$vuetify.breakpoint.smAndDown"
                class="mt-3"
              >
                {{
                  isSelected ? "Change selected vehicle" : "Select this vehicle"
                }}
              </OutlinedBlockBtn>
            </v-card-text>
          </div>
          <div v-else>
            <v-row no-gutters class="mb-4">
              <v-skeleton-loader type="heading" width="70%" />
              <v-skeleton-loader type="heading" width="30%" />
            </v-row>
            <v-skeleton-loader type="text" width="40%" />
            <v-skeleton-loader type="text" width="70%" class="mb-4" />
            <v-skeleton-loader type="heading" width="100%" />
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- settings section -->
    <v-form :disabled="processing || loading">
      <!-- weight section  of settings -->
      <v-card class="py-4 px-12 rounded-lg background mb-10" elevation="1">
        <!-- vehicle weight -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="4" align-self="center">
            <p class="grey--text lighten-2--text pt-2">Vehicle Weight</p>
          </v-col>
          <v-col cols-md="8" align-self="center">
            <v-text-field
              v-model="vehicleWeight"
              type="number"
              hide-spin-buttons
              suffix="kg"
              :rules="[validateNotNan, validateNotNegative, validateNotZero]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- additional weight -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="4" align-self="center">
            <p class="grey--text lighten-2--text pt-2">Additional Weight</p>
          </v-col>
          <v-col cols-md="8" align-self="center">
            <v-text-field
              v-model="additionalWeight"
              type="number"
              hide-spin-buttons
              suffix="kg"
              :rules="[validateNotNan, validateNotNegative]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- fuel section of settings -->
      <v-card class="py-4 px-12 rounded-lg background mb-10" elevation="1">
        <!-- fuel type -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="4" align-self="center">
            <p class="grey--text lighten-2--text pt-2">Fuel Type</p>
          </v-col>
          <v-col cols-md="8" align-self="center">
            <v-select
              :items="[
                'Petrol',
                'Diesel',
                'Hybrid',
                'Electric',
                'Plug in hybrid',
              ]"
              v-model="fuelType"
              :rules="[validateFuelType]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- if ev battery size -->
        <v-row v-if="fuelType === 'Electric'" class="flex-column flex-md-row">
          <v-col cols-md="4" align-self="center">
            <p class="grey--text lighten-2--text pt-2">Battery Size</p>
          </v-col>
          <v-col cols-md="8" align-self="center">
            <v-text-field
              v-model="batterySize"
              type="number"
              clearable
              :rules="[validateNotNan, validateNotNegative, validateNotZero]"
              suffix="kWh"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- if ev range adjustment/SoH slider -->
        <v-row
          v-if="fuelType === 'Electric'"
          class="mb-5 flex-column flex-md-row"
        >
          <v-col cols-md="4" align-self="center">
            <p class="grey--text lighten-2--text pt-2">
              Range Adjustment
              <StyledToolTip
                v-if="sohToolTipContent"
                :data="sohToolTipContent"
              />
            </p>
          </v-col>
          <v-col cols-md="8" align-self="center">
            <v-slider
              v-model="SoH"
              :label="SoH + '%'"
              min="1"
              max="100"
              track-color="grey lighten-2"
              :messages="batteryAgeMessage()"
              @end="flagAsDirty"
              :disabled="processing || loading"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- charging section -->
      <v-card
        class="py-4 px-8 rounded-lg background mb-10"
        elevation="1"
        v-if="fuelType === 'Electric' || fuelType === 'Plug in hybrid'"
      >
        <!-- AC (slow) charging speed/AC max kW rating -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="4" align-self="center">
            <p class="grey--text lighten-2--text pt-2">
              AC (slow) charging speed
            </p>
          </v-col>
          <v-col cols-md="8" align-self="center">
            <v-text-field
              v-model="acRating"
              type="number"
              hide-spin-buttons
              suffix="kW"
              :rules="[validateNotNan, validateNotNegative, validateNotZero]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- DC (slow) charging speed/DC max kW rating -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="4" align-self="center">
            <p class="grey--text lighten-2--text pt-2">
              DC (slow) charging speed
            </p>
          </v-col>
          <v-col cols-md="8" align-self="center">
            <v-text-field
              v-model="dcRating"
              type="number"
              hide-spin-buttons
              suffix="kW"
              :rules="[validateNotNan, validateNotNegative, validateNotZero]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- energy section -->
      <v-card
        class="py-4 px-12 rounded-lg background mb-10"
        elevation="1"
        v-if="fuelType === 'Electric' || fuelType === 'Plug in hybrid'"
      >
        <!-- Energy used when stopped in kW -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="4" align-self="center">
            <p class="grey--text lighten-2--text pt-2">
              Energy used when stopped
              <StyledToolTip
                v-if="sohToolTipContent"
                :data="sohToolTipContent"
              />
            </p>
          </v-col>
          <v-col cols-md="8" align-self="center">
            <v-text-field
              v-model="energyUsedWhenStopped"
              type="number"
              hide-spin-buttons
              suffix="kW"
              :rules="[validateNotNan, validateNotNegative]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
        <!-- Energy used when moving in kW -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="4" align-self="center">
            <p class="grey--text lighten-2--text pt-2">
              Energy used when moving
              <StyledToolTip
                v-if="sohToolTipContent"
                :data="sohToolTipContent"
              />
            </p>
          </v-col>
          <v-col cols-md="8" align-self="center">
            <v-text-field
              v-model="energyUsedWhenMoving"
              type="number"
              hide-spin-buttons
              suffix="kW"
              :rules="[validateNotNan, validateNotNegative]"
              @change="flagAsDirty"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- physics profile section -->
      <v-card
        class="py-4 px-12 rounded-lg background mb-10"
        elevation="1"
        v-if="fuelType === 'Electric' || fuelType === 'Plug in hybrid'"
      >
        <!-- Aerodynamics slider -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="5" align-self="center">
            <p class="grey--text lighten-2--text pt-2">
              Aerodynamics (drag)
              <StyledToolTip
                v-if="sohToolTipContent"
                :data="sohToolTipContent"
              />
            </p>
          </v-col>
          <v-col cols-md="7" align-self="center">
            <v-slider
              v-model="aerodynamics"
              :label="(aerodynamics / 100).toString()"
              min="10"
              max="1000"
              track-color="grey lighten-2"
              hide-details
              @end="flagAsDirty"
              :disabled="processing || loading"
            />
          </v-col>
        </v-row>
        <!-- Tyre dynamics slider -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="5" align-self="center">
            <p class="grey--text lighten-2--text pt-2">
              Tyre dynamics (grip)
              <StyledToolTip
                v-if="sohToolTipContent"
                :data="sohToolTipContent"
              />
            </p>
          </v-col>
          <v-col cols-md="7" align-self="center">
            <v-slider
              v-model="tyreDynamics"
              :label="(tyreDynamics / 1000).toString()"
              min="2"
              max="20"
              track-color="grey lighten-2"
              hide-details
              @end="flagAsDirty"
              :disabled="processing || loading"
            />
          </v-col>
        </v-row>
        <!-- Regenerative breaking efficiency slider -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="5" align-self="center">
            <p class="grey--text lighten-2--text pt-2">
              Regenerative braking efficiency
              <StyledToolTip
                v-if="sohToolTipContent"
                :data="sohToolTipContent"
              />
            </p>
          </v-col>
          <v-col cols-md="7" align-self="center">
            <v-slider
              v-model="regenerativeBraking"
              :label="regenerativeBraking + '%'"
              min="1"
              max="100"
              track-color="grey lighten-2"
              hide-details
              @end="flagAsDirty"
              :disabled="processing || loading"
            />
          </v-col>
        </v-row>
        <!-- Power train efficiency slider -->
        <v-row class="flex-column flex-md-row">
          <v-col cols-md="5" align-self="center">
            <p class="grey--text lighten-2--text pt-2">
              Power train efficiency
              <StyledToolTip
                v-if="sohToolTipContent"
                :data="sohToolTipContent"
              />
            </p>
          </v-col>
          <v-col cols-md="7" align-self="center">
            <v-slider
              v-model="powerTrain"
              :label="powerTrain + '%'"
              min="1"
              max="100"
              track-color="grey lighten-2"
              hide-details
              @end="flagAsDirty"
              :disabled="processing || loading"
            />
          </v-col>
        </v-row>
      </v-card>
      <!-- connectors and cables -->
      <div v-if="fuelType === 'Electric' || fuelType === 'Plug in hybrid'">
        <v-card-title> Connector Settings </v-card-title>
        <v-card-subtitle>
          These settings override the default settings based on your selected EV
          model.
        </v-card-subtitle>
        <v-card class="mb-8 rounded-lg background">
          <v-card-title> Tethered Charging Stations </v-card-title>
          <v-card-subtitle>
            Plan trips with compatible chargers by selecting desired connectors.
          </v-card-subtitle>
          <v-slide-group class="py-2">
            <v-slide-item
              v-for="(connector, index) in connectors"
              :key="'connector-styled-btn-' + index"
              style="width: 95px; height: 140px"
            >
              <v-card
                class="d-flex flex-column justify-space-between align-center pa-2 ma-2 rounded-lg background"
                style="width: 100%; height: 100%"
                @click="toggleConnectorSelect(connector)"
              >
                <div
                  class="rounded pa-1 d-flex align-center justify-center mb-2"
                  :class="
                    connectorIsSelected(connector)
                      ? 'selected_svg_container_background'
                      : ''
                  "
                  style="height: 64px; width: 64px"
                >
                  <img
                    v-if="!!connector.iconURL"
                    :src="connector.iconURL"
                    class="svg_base"
                    :class="
                      connectorIsSelected(connector)
                        ? 'svg_outline'
                        : 'svg_grayed_out'
                    "
                  />
                  <v-img
                    v-else
                    width="56"
                    height="56"
                    :src="fallbackImgSrc()"
                    contain
                    aspect-ratio="1"
                    max-height="56"
                    max-width="56"
                  ></v-img>
                </div>
                <span class="text-center text-caption">
                  {{ connector.displayName }}
                </span>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card>
        <v-card class="mb-8 rounded-lg background">
          <v-card-title> Your Cables </v-card-title>
          <v-card-subtitle>
            Select the cables you are bringing with you
          </v-card-subtitle>
          <v-slide-group class="py-2">
            <v-slide-item
              v-for="(connector, index) in cables"
              :key="'connector-styled-btn-' + index"
              style="width: 95px; height: 140px"
            >
              <v-card
                class="d-flex flex-column justify-space-between align-center pa-2 ma-2 rounded-lg background"
                style="width: 100%; height: 100%"
                @click="toggleConnectorSelect(connector)"
              >
                <div
                  class="rounded pa-1 d-flex align-center justify-center mb-2"
                  :class="
                    connectorIsSelected(connector)
                      ? 'selected_svg_container_background'
                      : ''
                  "
                  style="height: 64px; width: 64px"
                >
                  <img
                    v-if="!!connector.iconURL"
                    :src="connector.iconURL"
                    class="svg_base"
                    :class="
                      connectorIsSelected(connector)
                        ? 'svg_outline'
                        : 'svg_grayed_out'
                    "
                  />
                  <v-img
                    v-else
                    width="56"
                    height="56"
                    :src="fallbackImgSrc()"
                    contain
                    aspect-ratio="1"
                    max-height="56"
                    max-width="56"
                  ></v-img>
                </div>
                <span class="text-center text-caption">
                  {{ connector.displayName }}
                </span>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card>
      </div>
    </v-form>
    <!-- Actions section -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <TextBtn :disabled="!(vehicle && vehicle.evModel)">
        Reset to vehicle defaults
      </TextBtn>
      <ElevatedBtn :disabled="!dirty"> Save changes </ElevatedBtn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import TextBtn from "../ui-elements/buttons/TextBtn.vue";
import ElevatedBtn from "../ui-elements/buttons/ElevatedBtn.vue";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { mapState } from "vuex";
import {
  ActionTypes,
  MainDialogContent,
  ManagedContentNames,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import StyledToolTip from "../ui-elements/StyledToolTip.vue";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import EVModel from "@/logic/classes/vehicle_classes/evModel";
import OutlinedBtn from "../ui-elements/buttons/OutlinedBtn.vue";
import { FuelType } from "@/logic/types/sheared_local_types";
import {
  AdvancedConfig,
  DirectusVehicleVariableData,
  UserSelectedPlug,
} from "@/logic/types/directus_vehicle";
import to2DP from "@/logic/utils/to2DP";
import DescriptiveLoader from "../ui-elements/loaders/DescriptiveLoader.vue";
import { ConnectorFormat, ConnectorType } from "@/logic/types/charger_Db_types";
import {
  ConnectorDetailsData,
  connectorDetailsDataMap,
  ConnectorDetailsIconData,
  getAllSocketedConnectors,
  getAllTetheredConnectors,
} from "@/logic/data/connectorDetailsData";
import OutlinedBlockBtn from "../ui-elements/buttons/OutlinedBlockBtn.vue";

interface LocalConnectorData {
  iconURL?: string;
  displayName: string;
  standard: ConnectorType;
  format: ConnectorFormat;
}

export default Vue.extend({
  name: "VehicleDetails",
  components: {
    TextBtn,
    ElevatedBtn,
    StyledToolTip,
    OutlinedBtn,
    DescriptiveLoader,
    OutlinedBlockBtn,
  },
  props: {
    vehicle: {
      type: Object as PropType<Vehicle | undefined>,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // control
      dirty: false,
      loading: true,
      processing: false,
      saveStatus: undefined as undefined | "failed" | "success",
      // weights
      vehicleWeight: 1000,
      additionalWeight: 0,
      // fuel
      fuelType: "Electric" as FuelType,
      batterySize: 25,
      SoH: 100,
      // charging
      acRating: 10,
      dcRating: 50,
      // energy
      energyUsedWhenStopped: 0,
      energyUsedWhenMoving: 0,
      // physics profile
      aerodynamics: 50,
      tyreDynamics: 50,
      regenerativeBraking: 50,
      powerTrain: 50,
      // connectors
      selectedConnectors: [] as LocalConnectorData[],
    };
  },
  computed: {
    ...mapState({
      sohToolTipContent: (state: unknown) =>
        (state as State).managedContent.find(
          (content) => content.Name === ManagedContentNames.SoHToolTip
        ),
    }),
    /** List of all possible hard point connectors */
    connectors(): LocalConnectorData[] {
      // convert connector data to locally used connector data objs and order selected connectors at the start of the list.
      return this.orderConnectors(
        getAllTetheredConnectors().map((connector) =>
          this.convertConnectorDataToLocalConnectorData(connector)
        )
      );
    },
    /** List of all possible cables */
    cables(): LocalConnectorData[] {
      // convert connector data to locally used connector data objs and order selected connectors at the start of the list.
      return this.orderConnectors(
        getAllSocketedConnectors().map((connector) =>
          this.convertConnectorDataToLocalConnectorData(connector)
        )
      );
    },
  },
  methods: {
    /**
     * Validates the fuel type selected.
     *
     * @returns {true | string} Returns true if the fuel type is valid, otherwise returns an error message.
     */
    validateFuelType(): true | string {
      // Check if the fuel type is one of the valid options
      const isValidFuelType =
        this.fuelType === "Electric" ||
        this.fuelType === "Petrol" ||
        this.fuelType === "Diesel" ||
        this.fuelType === "Hybrid";

      // Return true if the fuel type is valid, otherwise return an error message
      return isValidFuelType ? true : "Please select a fuel type";
    },

    /**
     * Validates that the input value is not negative.
     *
     * @param {number|string|null} value - The value to be validated.
     * @return {true|string} Returns true if the value is not negative, otherwise returns an error message.
     */
    validateNotNegative(value: number | string | null): true | string {
      if (value) {
        // Validate that the value is not negative.
        const parsedNumber = parseIntOrFloat(value);
        if (parsedNumber === 0) return true; // prevent false negative from 0 being valid but not truthy.
        if (parsedNumber && parsedNumber >= 0) {
          return true;
        } else {
          return "Please enter a value greater than or equal to 0";
        }
      } else {
        return "Please enter a value";
      }
    },

    /**
     * Validates that the input value is not NaN.
     *
     * @param {number|string|null} value - The value to be validated.
     * @return {true|string} Returns true if the value is not NaN, otherwise returns an error message.
     */
    validateNotNan(value: number | string | null): true | string {
      if (value) {
        // Validate that the value is not NaN
        const parsedNumber = parseIntOrFloat(value);
        if (!parsedNumber || !isNaN(parsedNumber)) {
          return true;
        } else {
          return "Please enter a value";
        }
      } else {
        return "Please enter a value";
      }
    },

    /**
     * Validates that the input value is not zero.
     *
     * @param {number|string|null} value - The value to be validated.
     * @return {true|string} Returns true if the value is not zero, otherwise returns an error message.
     */
    validateNotZero(value: number | string | null): true | string {
      if (value) {
        // Validate that the value is not 0
        const parsedNumber = parseIntOrFloat(value);
        if (parsedNumber && parsedNumber !== 0) {
          return true;
        } else {
          return "Please enter a value greater than 0";
        }
      } else {
        return "Please enter a value";
      }
    },

    /**
     * Returns the maximum range of the vehicle in kilometers.
     *
     * @return {string} The maximum range of the vehicle, or " - ??km" if the range cannot be calculated.
     */
    getMaxRange(): string {
      const range = (this.vehicle as Vehicle | undefined)?.calcMaxRange(
        this.additionalWeight
      );
      if (range) return ` - ${Math.floor(range / 1000)}km`;
      return " - ??km";
    },

    /**
     * Calculates the battery age based on the state of health (SoH).
     *
     * @return {string} A message describing the battery age.
     */
    batteryAgeMessage(): string {
      const degradedPercentage = 100 - this.SoH;
      if (degradedPercentage >= 70)
        return "this looks like a poorly functioning battery";
      const yearsOld = Math.floor(degradedPercentage / 3);
      if (yearsOld <= 0) return "equivalent to a new battery";
      return (
        "equivalent to a " + yearsOld + " year old battery with normal usage"
      );
    },

    /**
     * Returns the source URL of the image associated with the vehicle.
     *
     * @return {string} The source URL of the image.
     */
    imageSrc(): string {
      if (this.vehicle as Vehicle | undefined) {
        // get uploaded image to car in first instance.
        const images = (this.vehicle as Vehicle).imageSrcPaths;
        if (images.length) return images[0];
        // get model image in second instance.
        if ((this.vehicle as Vehicle).evModel)
          return (
            ((this.vehicle as Vehicle).evModel as EVModel)?.imageSrc ??
            getAssetSrc("car_images/No_Image_Powersell.png")
          );
        // get company logo image in third instance.
        const company = (this.$store.state as State).fleet;
        if (company && company.logoSrc) {
          return company.logoSrc;
        }
      }
      // get default image in fourth instance.
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },

    /**
     * Initializes the component's data properties with default values from the vehicle object.
     *
     * @return {void} No return value.
     */
    setInitialValues(): void {
      this.loading = true;
      this.dirty = false;
      if (this.vehicle) {
        // weights
        this.vehicleWeight = this.vehicle.mass;
        this.additionalWeight = 0;
        // fuel
        this.fuelType = this.vehicle.fuelType ?? "Petrol";
        this.batterySize = this.vehicle.batterySize;
        this.SoH = this.vehicle.stateOfHealth
          ? this.vehicle.stateOfHealth * 100
          : 100;
        // charging
        this.acRating = this.vehicle.maxElectricPowerAC;
        this.dcRating = this.vehicle.maxElectricPowerDC;
        // energy
        this.energyUsedWhenStopped = 0;
        this.energyUsedWhenMoving = 0;
        // physics profile
        this.aerodynamics = this.vehicle.dragCoefficient * 100;
        this.tyreDynamics = this.vehicle.rollingResistanceCoefficient * 1000;
        this.regenerativeBraking =
          this.vehicle.regenerativeBreakingEfficiency * 100;
        this.powerTrain = this.vehicle.powerChainEfficiency * 100;
      }
      this.selectedConnectors = this.getInitialConnectors();
      this.$nextTick(() => {
        this.loading = false;
      });
    },

    /**
     * Retrieves the initial connectors for a vehicle based on its user-selected plugs or EV model.
     *
     * @return {LocalConnectorData[]} An array of LocalConnectorData objects representing the initial connectors.
     */
    getInitialConnectors(): LocalConnectorData[] {
      const typedVehicle: Vehicle | undefined = this.vehicle; // cast type as ts has trouble with typing vuex getters.
      if (typedVehicle) {
        if (
          typedVehicle.userSelectedPlugs &&
          typedVehicle.userSelectedPlugs.length
        )
          return this.getUserSelectedConnectors(typedVehicle.userSelectedPlugs);
        if (typedVehicle.evModel)
          return this.getModelConnectors(typedVehicle.evModel);
      }
      return [];
    },

    /**
     * Converts an array of user selected plugs into an array of LocalConnectorData objects.
     *
     * @param {UserSelectedPlug[]} userSelectedPlugs - An array of user selected plugs to be converted.
     * @return {LocalConnectorData[]} An array of LocalConnectorData objects representing the user selected connectors.
     */
    getUserSelectedConnectors(
      userSelectedPlugs: UserSelectedPlug[]
    ): LocalConnectorData[] {
      const tempArray: LocalConnectorData[] = [];
      userSelectedPlugs.forEach((connector) => {
        const extraData = connectorDetailsDataMap.get(connector.standard);
        if (extraData) {
          tempArray.push({
            format: connector.format,
            standard: connector.standard,
            displayName: extraData.displayName,
            iconURL: this.getSrc(
              extraData.iconURL,
              connector.format === "CABLE" ? "cable" : "socket"
            ),
          });
        }
      });
      return tempArray;
    },

    /**
     * Flags the component's data as dirty, indicating that it has been modified.
     *
     * @return {void} No return value.
     */
    flagAsDirty(): void {
      if (this.loading) return;
      this.dirty = true;
    },

    /**
     * Saves the vehicle data to the database and updates the global state.
     *
     * This function checks for changes in the vehicle's properties and updates the
     * corresponding fields in the database. It also updates the global state with
     * the latest vehicle data.
     *
     * @return {void} No return value.
     */
    async saveData(): Promise<void> {
      if (!this.vehicle) return;
      this.processing = true;
      const vehicle: Vehicle = this.vehicle;
      const dataToUpdate: DirectusVehicleVariableData = {};
      const newAdvConf: AdvancedConfig = vehicle.advancedConfig
        ? { ...vehicle.advancedConfig }
        : {};
      // weights
      if (
        this.vehicleWeight &&
        parseIntOrFloat(this.vehicleWeight) !== vehicle.mass
      ) {
        newAdvConf.Mass = parseIntOrFloat(this.vehicleWeight);
      }
      // fuel
      if (this.fuelType !== (vehicle.fuelType ?? null)) {
        vehicle.fuelType = this.fuelType ?? undefined;
        dataToUpdate.fuel_type = this.fuelType;
      }
      if (
        this.batterySize &&
        parseIntOrFloat(this.batterySize) !== vehicle.batterySize
      ) {
        newAdvConf.BatterySize = parseIntOrFloat(this.batterySize);
      }
      if (
        this.SoH !==
        (vehicle.stateOfHealth
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            vehicle.stateOfHealth! * 100
          : undefined)
      ) {
        const convertedSOH = to2DP(this.SoH / 100);
        vehicle.userProvidedStateOfHealth = convertedSOH;
        dataToUpdate.soh = convertedSOH;
      }
      // charging
      if (
        this.acRating &&
        parseIntOrFloat(this.acRating) !== vehicle.maxElectricPowerAC
      ) {
        newAdvConf.MaxElectricPowerAc = parseIntOrFloat(this.acRating);
      }
      if (
        this.dcRating &&
        parseIntOrFloat(this.dcRating) !== vehicle.maxElectricPowerDC
      ) {
        newAdvConf.MaxElectricPowerDc = parseIntOrFloat(this.dcRating);
      }
      // energy
      if (this.energyUsedWhenStopped) {
        const parsedVal = parseIntOrFloat(this.energyUsedWhenStopped);
        if (parsedVal) {
          newAdvConf.EnergyUsedWhenStopped = this.energyUsedWhenStopped;
        }
      }
      if (this.energyUsedWhenMoving) {
        const parsedVal = parseIntOrFloat(this.energyUsedWhenMoving);
        if (parsedVal) {
          newAdvConf.EnergyUsedWhenMoving = this.energyUsedWhenMoving;
        }
      }
      // physics profile
      if (
        this.aerodynamics &&
        parseIntOrFloat(this.aerodynamics / 100) !== vehicle.dragCoefficient
      ) {
        newAdvConf.DragCoefficient = parseIntOrFloat(this.aerodynamics / 100);
      }

      if (
        this.tyreDynamics &&
        parseIntOrFloat(this.tyreDynamics / 1000) !==
          vehicle.rollingResistanceCoefficient
      ) {
        newAdvConf.RollingResistanceCoefficient = parseIntOrFloat(
          this.tyreDynamics / 1000
        );
      }
      if (this.regenerativeBraking) {
        const parsedVal = parseIntOrFloat(this.regenerativeBraking);
        if (
          parsedVal &&
          parsedVal !== vehicle.rollingResistanceCoefficient * 100
        ) {
          newAdvConf.RegenerativeBreakingEfficiency = to2DP(parsedVal / 100);
        }
      }
      if (this.powerTrain) {
        const parsedVal = parseIntOrFloat(this.powerTrain);
        if (parsedVal && parsedVal !== vehicle.powerChainEfficiency * 100) {
          newAdvConf.PowerChainEfficiency = to2DP(parsedVal / 100);
        }
      }
      // compile connectors and cables

      // check if just model defaults
      if (this.vehicle.evModel) {
        // find models default connectors
        const modelDefaultConnectors = this.getModelConnectors(
          this.vehicle.evModel
        );
        if (
          this.selectedConnectors.length !== modelDefaultConnectors.length ||
          !modelDefaultConnectors.every((connector) =>
            this.connectorIsSelected(connector)
          )
        ) {
          // ASSUME: not the same and needs override
          const userSelectedPlugs =
            this.convertSelectedConnectorsToUserSelectedPlugs();
          vehicle.userSelectedPlugs = userSelectedPlugs;
          dataToUpdate.UserSelectedPlugs = userSelectedPlugs.map((obj) =>
            JSON.stringify(obj)
          );
        } else {
          // ASSUMES: is default for model and userSelectedPlugs can be removed.
          vehicle.userSelectedPlugs = undefined;
          dataToUpdate.UserSelectedPlugs = null;
        }
      } else {
        // ASSUME: just straight override as no model for comparison.
        const userSelectedPlugs =
          this.convertSelectedConnectorsToUserSelectedPlugs();
        vehicle.userSelectedPlugs = userSelectedPlugs;
        dataToUpdate.UserSelectedPlugs = userSelectedPlugs.map((obj) =>
          JSON.stringify(obj)
        );
      }

      // check advanced config has any entries
      if (Object.keys(newAdvConf).length) {
        vehicle.advancedConfig = newAdvConf;
        dataToUpdate.AdvancedConfig = JSON.stringify(newAdvConf);
      }

      // update DB
      this.saveStatus = await vehicle.updateDirectusData(dataToUpdate);
      // update global state
      this.$store.commit(MutationTypes.updateIndividualVehicle, vehicle);
      // indicate async process has completed
      this.processing = false;
      // clean up
      this.setInitialValues();
      setTimeout(() => {
        this.saveStatus = undefined;
      }, 2500);
    },

    /**
     * Handles the selection of a vehicle by dispatching the selectVehicle action.
     *
    /**
     * Handles the selection of the "Other" option in the vehicle details dialog.
     *
     * @return {void} This function does not return a value.
     */
    handleSelect(): void {
      this.$store.dispatch(ActionTypes.selectVehicle, this.vehicle);
    },

    /**
     * Handles the selection of the "Other" option in the vehicle details dialog.
     *
     * @return {void} This function does not return a value.
     */
    handleSelectOther(): void {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_GALLERY
      );
    },

    /**
     * Orders the given array of LocalConnectorData objects based on whether they are selected or not.
     *
     * @param {LocalConnectorData[]} connectors - The array of LocalConnectorData objects to order.
     * @return {LocalConnectorData[]} The ordered array of LocalConnectorData objects.
     */
    orderConnectors(connectors: LocalConnectorData[]): LocalConnectorData[] {
      const returnArray: LocalConnectorData[] = [];
      connectors.forEach((connector) => {
        if (this.connectorIsSelected(connector)) {
          returnArray.unshift(connector);
        } else {
          returnArray.push(connector);
        }
      });
      return returnArray;
    },

    /**
     * Converts ConnectorDetailsData to LocalConnectorData.
     *
     * @param {ConnectorDetailsData} connectorData - The connector data to be converted.
     * @return {LocalConnectorData} The converted local connector data.
     */
    convertConnectorDataToLocalConnectorData(
      connectorData: ConnectorDetailsData
    ): LocalConnectorData {
      return {
        displayName: connectorData.displayName,
        format: "CABLE",
        standard: connectorData.standard,
        iconURL: this.getSrc(connectorData.iconURL, "cable"),
      };
    },

    /**
     * Checks if a given connector is already selected.
     *
     * @param {LocalConnectorData} connector - The connector to check for selection.
     * @return {boolean} True if the connector is selected, false otherwise.
     */
    connectorIsSelected(connector: LocalConnectorData): boolean {
      return !!this.selectedConnectors.find(
        (selectedConnector) =>
          selectedConnector.standard === connector.standard &&
          selectedConnector.format === connector.format
      );
    },

    /** Adds/removes connector from selected connectors list.
     *
     * @param connector the target `LocalConnectorData` object for the connector.
     */
    toggleConnectorSelect(connector: LocalConnectorData): void {
      this.flagAsDirty();
      if (this.connectorIsSelected(connector)) {
        // filter out matching connector from array
        this.selectedConnectors = this.selectedConnectors.filter(
          (selectedConnector) => {
            if (selectedConnector.standard !== connector.standard) return true;
            if (
              selectedConnector.standard === connector.standard &&
              selectedConnector.format !== connector.format
            )
              return true;
            return false; // assumes this will be the selected connector that matches both standard and format.
          }
        );
      } else {
        // add connector to array
        this.selectedConnectors.push(connector);
      }
    },

    /**
     * Returns an array of LocalConnectorData objects representing the model's compatible connectors.
     *
     * @param {EVModel} model - The EV model to retrieve connectors for.
     * @return {LocalConnectorData[]} An array of LocalConnectorData objects.
     */
    getModelConnectors(model: EVModel): LocalConnectorData[] {
      const tempArray: LocalConnectorData[] = [];
      model.compatibleConnectors.forEach((connector) => {
        const extraData = connectorDetailsDataMap.get(connector.standard);
        const alreadyAdded = !!tempArray.find(
          (dataItem) => dataItem.standard === connector.standard
        );
        if (extraData && !alreadyAdded) {
          tempArray.push({
            format: "CABLE",
            standard: connector.standard,
            displayName: extraData.displayName,
            iconURL: this.getSrc(extraData.iconURL, "cable"),
          });
        }
      });
      return tempArray;
    },

    /**
     * Converts the selected connectors to an array of user selected plugs.
     *
     * @return {UserSelectedPlug[]} An array of user selected plugs.
     */
    convertSelectedConnectorsToUserSelectedPlugs(): UserSelectedPlug[] {
      const tempArray: UserSelectedPlug[] = [];

      this.selectedConnectors.forEach((connector) => {
        const extraData = connectorDetailsDataMap.get(connector.standard);
        if (extraData) {
          tempArray.push({
            standard: connector.standard,
            format: connector.format,
            powerType: extraData.powerType,
          });
        }
      });
      return tempArray;
    },

    /**
     * Returns the target connectors icon asset url if one exist
     *
     * @param iconUrlData the 'ConnectorDetailsData' `iconUrl` property.
     * @param format the target format
     * @returns complete file path if it exist or undefined if not.
     */
    getSrc(
      iconUrlData: string | ConnectorDetailsIconData | undefined,
      format: "cable" | "socket"
    ): string | undefined {
      let partialFilePath: string | undefined = undefined;
      if (!iconUrlData) return undefined;
      if (typeof iconUrlData === "string") partialFilePath = iconUrlData;
      if (typeof iconUrlData === "object") {
        if (Object.hasOwn(iconUrlData, format))
          partialFilePath = iconUrlData[format];
      }
      if (!partialFilePath) return undefined;
      return getAssetSrc(partialFilePath);
    },

    /**
     * Returns the fallback image source URL.
     *
     * @return {string} The fallback image source URL.
     */
    fallbackImgSrc(): string {
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },

    /**
     * Removes all user provided overrides from the base vehicle.
     *
     * @return {Promise<void>} A promise that resolves when the reset action is complete.
     */
    async handelReset(): Promise<void> {
      // guard clause.
      if (!this.vehicle) return;
      // indicate async process has begun.
      this.processing = true;
      // copy vehicle data.
      const vehicle: Vehicle = this.vehicle;
      // update DB.
      this.saveStatus = await vehicle.updateDirectusData({
        soh: null, // remove user provided SOH.
        AdvancedConfig: null, // remove advanced config.
        UserSelectedPlugs: null, // remove user provided connectors.
      });
      // update global state.
      this.$store.commit(MutationTypes.updateIndividualVehicle, vehicle);
      // indicate async process has completed.
      this.processing = false;
      // clean up.
      this.setInitialValues();
      setTimeout(() => {
        this.saveStatus = undefined;
      }, 2500);
    },
  },
  mounted() {
    this.setInitialValues();
  },
  watch: {
    /**
     * Sets the initial values for the vehicle properties.
     *
     * This function calls the `setInitialValues` method to initialize the
     * vehicle properties with default values. It is called when the `vehicle`
     * property is mutated.
     *
     * @return {void} This function does not return a value.
     */
    vehicle() {
      this.setInitialValues();
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}

* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}

.svg_base {
  width: 56px;
  height: 56px;
}

.svg_outline {
  filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white)
    drop-shadow(-1px 1px 0 white) drop-shadow(1px -1px 0 white);
}

.svg_grayed_out {
  filter: grayscale(1);
}

.selected_svg_container_background {
  background-color: #adedd2;
}
</style>
