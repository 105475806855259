import { DateTime } from "luxon";
import Coordinate from "../common_classes/coordinate";
import ItineraryLocation from "./itineraryLocation";

export default class WaypointLocation extends ItineraryLocation {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  // --- load data --- //

  /**
   * The load in kg arriving at this location.
   *
   * Note:
   * - should default to 0
   * - expected conditional UI to understand does not need to be displayed if 0
   */
  arrivalLoadWeight: number;

  /**
   * The difference between the arrival load and the leaving load.
   *
   * Note:
   * - should default to 0
   * - expected conditional UI to understand does not need to be displayed if 0
   */
  loadWeightChange: number;

  // --- energy data --- //

  /**
   * The number of kWh used at this stop for non travel related purposes.
   *
   * Note:
   * - should default to 0
   * - expected conditional UI to understand does not need to be displayed if 0
   */
  energyUsed: number;

  /**
   * The number of kWh added via charging at this location.
   *
   * Note:
   * - should default to 0
   * - expected conditional UI to understand does not need to be displayed if 0
   */
  energyAdded: number;

  /** The kWh remaining in the vehicles battery upon arriving at this location. */
  arrivalEnergy: number;

  // --- time data --- //

  /** the time stamp of the expected arrival time at this location. */
  expectedArrivalTime?: string;

  /**
   * The expected time you are staying at this location in seconds.
   *
   * Note:
   * - should default to 0
   * - expected conditional UI to understand does not need to be displayed if 0
   */
  stay: number;

  /** Max power that can be transferred from a charger at this site in kW. */
  kWRating?: number;

  /** The current type to used for charging calculations. */
  currentType?: "AC" | "DC";

  /** The time in seconds the EV will spend charging at this location. */
  chargingTime: number;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    addressDisplayStr,
    coordinate,
    arrivalLoadWeight = 0,
    loadWeightChange = 0,
    arrivalEnergy,
    energyUsed = 0,
    energyAdded = 0,
    expectedArrivalTime = undefined,
    stay = 0,
    kWRating = undefined,
    currentType = undefined,
    chargingTime = 0,
  }: {
    /** they display ready string for this locations address. */
    addressDisplayStr: string;
    /** Standardised format for coordinate values with supported converters and validation. */
    coordinate: Coordinate;
    /** The load in kg arriving at this location. */
    arrivalLoadWeight?: number;
    /** The difference between the arrival load and the leaving load. Can be negative for decreasing the load. */
    loadWeightChange?: number;
    /** The kWh remaining in the vehicles battery upon arriving at this location. */
    arrivalEnergy: number;
    /** The number of kWh used at this stop for non travel related purposes. */
    energyUsed?: number;
    /** The number of kWh added via charging at this location. */
    energyAdded?: number;
    /** The time stamp of the expected arrival time at this location. */
    expectedArrivalTime?: string;
    /** The expected time you are staying at this location in seconds. */
    stay?: number;
    /** Max power that can be transferred from a charger at this site in kW. */
    kWRating?: number;
    /** The current type to used for charging calculations. */
    currentType?: "AC" | "DC";
    /** The time in seconds the EV will spend charging at this location. */
    chargingTime?: number;
  }) {
    super({
      locationType: "waypoint",
      addressDisplayStr,
      coordinate,
    });

    this.arrivalLoadWeight = arrivalLoadWeight;
    this.loadWeightChange = loadWeightChange;
    this.arrivalEnergy = arrivalEnergy;
    this.energyUsed = energyUsed;
    this.energyAdded = energyAdded;
    this.expectedArrivalTime = expectedArrivalTime;
    this.stay = stay;
    this.kWRating = kWRating;
    this.currentType = currentType;
    this.chargingTime = chargingTime;
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  // --- load data --- //

  /** The load in kg leaving at this location. */
  public get departingLoadWeight(): number {
    return this.arrivalLoadWeight + this.loadWeightChange;
  }

  // --- energy data --- //

  /** The calculated departing energy from this location. */
  public get departingEnergy(): number {
    return this.arrivalEnergy - this.energyUsed + this.energyAdded;
  }

  // --- time data --- //

  /**
   * the time stamp of the calculated departure time at this location.
   *
   * Note:
   * - should bail out if no expected arrival time.
   * - should be calculated based on expected arrival time plus stay.
   */
  public get expectedDepartureTime(): string | undefined {
    if (!this.expectedArrivalTime) return; // exit if no time for the calculation
    return (
      DateTime.fromISO(this.expectedArrivalTime)
        .plus({ seconds: this.totalStay })
        .toISO() ?? undefined
    );
  }

  /**
   * the total stay in seconds be it charging time or scheduled stay whichever is larger.
   */
  public get totalStay(): number {
    return Math.max(this.stay, this.chargingTime);
  }
}
