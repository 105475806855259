import Coordinate from "../common_classes/coordinate";
import ItineraryLocation from "./itineraryLocation";

export default class DestinationLocation extends ItineraryLocation {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  // --- load data --- //

  /** The load in kg arriving at this location. */
  arrivalLoadWeight: number;

  // --- energy data --- //

  /** The kWh remaining in the vehicles battery upon arriving at this location. */
  arrivalEnergy: number;

  // --- time data --- //

  /** the time stamp of the expected arrival time at this location. */
  expectedArrivalTime?: string;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    addressDisplayStr,
    coordinate,
    arrivalLoadWeight = 0,
    arrivalEnergy,
    expectedArrivalTime = undefined,
  }: {
    /** they display ready string for this locations address. */
    addressDisplayStr: string;
    /** Standardised format for coordinate values with supported converters and validation. */
    coordinate: Coordinate;
    /** The load in kg arriving at this location. */
    arrivalLoadWeight?: number;
    /** The kWh remaining in the vehicles battery upon arriving at this location. */
    arrivalEnergy: number;
    /** the time stamp of the expected arrival time at this location. */
    expectedArrivalTime?: string;
  }) {
    super({
      locationType: "destination",
      addressDisplayStr,
      coordinate,
    });

    this.arrivalLoadWeight = arrivalLoadWeight;
    this.arrivalEnergy = arrivalEnergy;
    this.expectedArrivalTime = expectedArrivalTime;
  }
}
