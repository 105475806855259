import generateUniqueLocalID from "@/logic/utils/generateUniqueLocalID";
import Vehicle from "../vehicle_classes/vehicle";
import TripLocation from "./tripLocation";
import {
  Valhalla_CostingModel,
  Valhalla_Leg,
  Valhalla_Location,
  Valhalla_RouteRes,
} from "@/logic/types/valhalla_types";
import {
  fetchValhallaOptimizedRoutePlan,
  fetchValhallaRoutePlan,
} from "@/logic/api/calls/valhalla_calls";
import {
  EVNavCar,
  EVNavCharger,
  EVNavEnergy,
  EVNavFailedRoutePlan,
  EVNavRadarParams,
  EVNavRouteParams,
  EVNavRoutePlan,
} from "@/logic/types/ev_nav_types";
import {
  evnavRadarCall,
  fetchEnergyNeeded,
  fetchMultiRoutePlans,
  fetchRoutePlan,
} from "@/logic/api/calls/ev_nav_calls";
import OptimiserDefaultData from "@/logic/data/optimiserDefaultData";
import evNavDefaultData from "@/logic/data/eVNavDefaultData";
import { evNavMaxWaypoints, meanPassengerWeight } from "@/logic/data/const";
import Itinerary, { ItineraryStep } from "./itinerary";
import {
  SavedRouteData,
  SavedRouteWaypointData,
  TripFrequency,
  TripStats,
} from "@/logic/types/trip_specific_types";
import { CalcVsType } from "@/logic/store/store_types";
import { Duration } from "luxon";
import to2DP from "@/logic/utils/to2DP";
import TripRadarData from "../trip_classes/tripRadarData";
import haversineDistance from "@/logic/utils/haversineDistance";
import TripComparison from "./tripComparison";
import { decodePolyline, encodePolyline } from "@/logic/utils/polylineUtils";
import Charger from "../charger_classes/charger";
import {
  createSavedRoutePlan,
  Directus_SavedRoutePlan,
  updatedSavedRoutePlan,
} from "@/logic/api/calls/directus_calls/savedRoutePlans";

interface TripBaseLeg {
  /** The 6 point precision polyline for this leg. */
  polyline: string;
  /** The extra load expected for this leg in kgs. */
  loadWeight: number;
  /** The distance in metres for this leg. */
  distance: number;
  /** The time in seconds for this leg. */
  time: number;
}

interface TripOptions {
  /** local session scop unique id for this trip. */
  local_id?: string;

  /**
   * Optional external DB id for the saved trip record.
   *
   * NOTE: Currently refers to the directus `saved_trips` collection record id.
   */
  external_id?: number | string;

  /** displayable itinerary for this trip. */
  itinerary?: Itinerary;

  /** local session scop unique id for the vehicle associated with this trip.  */
  vehicle_local_id?: string;

  /**
   * Optional external DB id for the associated vehicle record.
   *
   * NOTE: Currently refers to the directus `car_record` collection record id.
   */
  vehicle_external_id?: number | string;

  /** `Vehicle` class object for the associated vehicle. */
  vehicle_data?: Vehicle;

  /**
   * The locations stopped at along the trip including the starting location
   * (always at index 0) and the destination location (always at the last
   * index).
   */
  locations?: TripLocation[];

  /**
   * flag indicating if this trip is a round trip (origin and destination are
   * the same location) or not.
   */
  roundTripFlag: boolean;

  /** The Valhalla costing model used to plan this trip */
  costingModel?: Valhalla_CostingModel;

  /**
   * SpeedAdjustment percentage difference in driving style compared to average
   * speed for the road. Range -0.5 - 0.5 with 0.0 an average driver. e.g., 0.5
   * would imply driving 75km/h in 50km/h zone.
   */
  SpeedAdjustment?: number;

  /**
   * AccelAdjustment is how 'heavy footed' the driver is. Range 0 - 1.0 with
   * 0.5 being average.
   */
  AccelerationAdjustment?: number;

  /**
   * WeatherFactor - 1.0 for fine weather, 1.1 for cold weather or adjust other
   * values as needed. Usually 1.0 or higher WeatherFactor
   */
  WeatherFactor?: number;

  /**
   * Flag indicating if some data was substituted for default data in evNav
   * planning.
   */
  defaultsUsedFlag?: boolean;

  /** State of charge at the beginning of this trip. */
  SOCAct?: number;

  /** Target state of charge at the end of this trip. */
  SOCEnd?: number;

  /** Target maximum state of charge that can be charged to for this trip. */
  SOCMax?: number;

  /**
   * Target minimum state of charge that can be reached before forcing a
   * charging session for this trip.
   */
  SOCMin?: number;

  /** Initial starting load for this trip in kilograms. */
  startingLoad?: number;

  /** Number of passengers on board for this trip. Excluding driver. */
  passengers?: number;

  /** the optional display name for the trip */
  name?: string;

  /**
   * The reoccurring frequency of the trip if it has one.
   *
   * NOTE: trips with a reoccurring frequency are used in the 5 year savings.
   * A one off trip with no reoccurring frequency will not be used for 5 year
   * savings calculations.
   */
  frequency?: TripFrequency;
}

export default class Trip {
  // ----------------------------------------------------------------------- //
  // -------------------------- Global class state ------------------------- //
  // ----------------------------------------------------------------------- //

  /** global record of class instance ids this session. */
  static usedIds: string[] = [];

  // ----------------------------------------------------------------------- //
  // ------------------------------- State --------------------------------- //
  // ----------------------------------------------------------------------- //

  /** local session scop unique id for this trip. */
  local_id: string;

  /**
   * Optional external DB id for the saved trip record.
   *
   * NOTE: Currently refers to the directus `???` collection record id.
   */
  external_id?: number | string;

  /** displayable itinerary for this trip. */
  itinerary: Itinerary;

  // --- vehicle data --- //

  /** local session scop unique id for the vehicle associated with this trip. */
  vehicle_local_id?: string;

  /**
   * Optional external DB id for the associated vehicle record.
   *
   * NOTE: Currently refers to the directus `car_record` collection record id.
   */
  vehicle_external_id?: number | string;

  /** `Vehicle` class object for the associated vehicle. */
  vehicle_data?: Vehicle;

  // --- locations data --- //

  /**
   * The locations stopped at along the trip including the starting location
   * (always at index 0) and the destination location (always at the last
   * index).
   */
  locations: TripLocation[] = [];

  /**
   * Flag indicating if this trip is a round trip (origin and destination are
   * the same location) or not.
   */
  roundTripFlag = false;

  /** The Valhalla costing model used to plan this trip */
  costingModel: Valhalla_CostingModel = "auto";

  /**
   * SpeedAdjustment percentage difference in driving style compared to average
   * speed for the road. Range -0.5 - 0.5 with 0.0 an average driver. e.g., 0.5
   * would imply driving 75km/h in 50km/h zone.
   */
  SpeedAdjustment?: number;

  /**
   * AccelAdjustment is how 'heavy footed' the driver is. Range 0 - 1.0 with
   * 0.5 being average.
   */
  AccelerationAdjustment?: number;

  /**
   * WeatherFactor - 1.0 for fine weather, 1.1 for cold weather or adjust other
   * values as needed. Usually 1.0 or higher WeatherFactor
   */
  WeatherFactor?: number;

  /**
   * Flag indicating if some data was substituted for default data in evNav
   * planning.
   */
  defaultsUsedFlag = false;

  /** State of charge at the beginning of this trip. */
  SOCAct: number = OptimiserDefaultData.SOCAct;

  /** Target state of charge at the end of this trip. */
  SOCEnd: number = OptimiserDefaultData.SOCEnd;

  /** Target maximum state of charge that can be charged to for this trip. */
  SOCMax: number = OptimiserDefaultData.SOCMax;

  /**
   * Target minimum state of charge that can be reached before forcing a
   * charging session for this trip.
   */
  SOCMin: number = OptimiserDefaultData.SOCMin;

  /** Initial starting load for this trip in kilograms. */
  startingLoad = 0;

  /** Number of passengers on board for this trip. Excluding driver. */
  passengers = 0;
  fallbackReason?: string;
  status: "unplanned" | "processing" | "success" | "failed" | "fallback" =
    "unplanned";

  /** the optional display name for the trip */
  name?: string;

  /** Data for chargers along the route from radar call. */
  radarData?: TripRadarData;

  comparisons: TripComparison[] = [];

  displayedComparisonId?: string;

  /**
   * The reoccurring frequency of the trip if it has one.
   *
   * NOTE: trips with a reoccurring frequency are used in the 5 year savings.
   * A one off trip with no reoccurring frequency will not be used for 5 year
   * savings calculations.
   */
  frequency?: TripFrequency;

  // ----------------------------------------------------------------------- //
  // ---------------------------- Constructor ------------------------------ //
  // ----------------------------------------------------------------------- //

  /**
   * Constructor for creating a new TripV2 object.
   *
   * @param {TripOptions | undefined} options - The options to initialize the TripV2 object.
   */
  constructor(options: TripOptions | undefined = undefined) {
    this.local_id =
      options?.local_id ?? generateUniqueLocalID(Trip.usedIds, "trip-v2");
    this.external_id = options?.external_id;
    this.itinerary = options?.itinerary ?? new Itinerary();
    this.vehicle_local_id = options?.vehicle_local_id;
    this.vehicle_external_id = options?.vehicle_external_id;
    this.vehicle_data = options?.vehicle_data;
    this.locations = options?.locations ?? [];
    this.roundTripFlag = options?.roundTripFlag ?? false;
    this.costingModel = options?.costingModel ?? "auto";
    this.SpeedAdjustment = options?.SpeedAdjustment;
    this.AccelerationAdjustment = options?.AccelerationAdjustment;
    this.WeatherFactor = options?.WeatherFactor;
    this.defaultsUsedFlag = options?.defaultsUsedFlag ?? false;
    if (options?.SOCAct) this.SOCAct = options.SOCAct;
    if (options?.SOCEnd) this.SOCEnd = options.SOCEnd;
    if (options?.SOCMax) this.SOCMax = options.SOCMax;
    if (options?.SOCMin) this.SOCMin = options.SOCMin;
    if (options?.startingLoad) this.startingLoad = options.startingLoad;
    if (options?.passengers) this.passengers = options.passengers;
    this.name = options?.name;
    this.frequency = options?.frequency;

    // add id to list of used unique ids
    // ASSUMES: if id already exists this is an overwrite to the original
    // object.
    if (!Trip.usedIds.includes(this.local_id)) {
      Trip.usedIds.push(this.local_id);
    }
  }

  // --- to class object converters --- //

  /**
   * Converts saved route plan data to a new TripV2 instance.
   *
   * @param {Directus_SavedRoutePlan} savedData - The saved route plan data to convert.
   * @return {Trip} A new TripV2 instance created from the saved data.
   */
  static fromSavedData(savedData: Directus_SavedRoutePlan): Trip {
    // convert saved data to new trip
    return new Trip({
      external_id: savedData.id,
      vehicle_external_id: savedData.vehicleId,
      roundTripFlag: savedData.roundTripFlag,
      AccelerationAdjustment: savedData.accelerationAdjustment,
      SpeedAdjustment: savedData.speedAdjustment,
      defaultsUsedFlag: savedData.defaultDataUsedFlag,
      name: savedData.name,
      passengers: savedData.passengerCount,
      SOCAct: savedData.departingSOC,
      SOCEnd: savedData.arrivalSOC,
      SOCMin: savedData.minimumSOC,
      SOCMax: savedData.maximumSOC,
      startingLoad: savedData.departingLoad,
      locations: savedData.waypoints.map((waypoint) => {
        return TripLocation.fromSavedData(waypoint);
      }),
      itinerary: Itinerary.buildFromSavedData({
        steps: savedData.itinerary.steps,
        destination: savedData.itinerary.destination,
      }),
      frequency: savedData.frequency,
    });
  }

  // ----------------------------------------------------------------------- //
  // ------------------------------- Getters ------------------------------- //
  // ----------------------------------------------------------------------- //

  /**
   * Returns the local ID of the object.
   *
   * @return {string} The local ID.
   */
  public get localId(): string {
    return this.local_id;
  }

  /**
   * Returns the vehicle ID of the object.
   *
   * NOTE: This is a legacy property that will be removed in the future. Please
   * use `vehicle_external_id`
   *
   * @return {string | number | undefined} The vehicle ID.
   */
  public get vehicleId(): string | number | undefined {
    return this.vehicle_external_id;
  }

  /**
   * Returns the Directus ID of the object.
   *
   * NOTE: This is a legacy property that will be removed in the future. Please
   * use `external_id`
   *
   * @return {string | number | undefined} The Directus ID.
   */
  public get directusId(): string | number | undefined {
    return this.external_id;
  }

  // TODO: refactor into a getter, will need to update consumers.
  public getPolylinePoints(): [number, number][][] {
    return this.itinerary.pointsByLeg;
  }

  /**
   * Returns the full polyline representation of the whole trip.
   *
   * @return {string} The encoded polyline string.
   */
  public get fullTripPolyline(): string {
    return encodePolyline(this.getPolylinePoints().flat());
  }

  /**
   * Returns the number of scheduled stops for this trip.
   *
   * @return {number} The number of scheduled stops.
   */
  public get numberOfScheduledStops(): number {
    return this.locations.length - (this.roundTripFlag ? 1 : 2);
  }

  /**
   * Returns an array of strings representing the CDB IDs of the charging stops in the itinerary.
   *
   * @return {string[]} An array of strings representing the CDB IDs of the charging stops in the itinerary.
   */
  public get chargingStopCDBIDs(): string[] {
    return this.itinerary.chargerIDs;
  }

  /**
   * Returns an array of CDBIDs of chargers along the planned trip's polyline.
   *
   * @return {string[]} An array of CDBIDs of chargers along the route. If the `radarData` is null or undefined, an empty array is returned.
   */
  public get chargersAlongRouteCDBIDs(): string[] {
    return this.radarData?.chargerCDBIDs ?? [];
  }

  /**
   * Checks if the vehicle associated with this trip is an electric vehicle.
   *
   * @return {boolean} True if the vehicle is an electric vehicle, false otherwise.
   */
  public get isElectric(): boolean {
    if (this.vehicle_data?.evModel) return true;
    return false;
  }

  /**
   * Returns the currently displayed trip comparison data.
   *
   * @return {TripComparison | undefined} The currently displayed trip comparison data, or undefined if no comparison data is displayed.
   */
  public get displayedComparisonData(): TripComparison | undefined {
    return this.comparisons.find(
      (comparison) => comparison.localId === this.displayedComparisonId
    );
  }

  // ----------------------------------------------------------------------- //
  // ------------------------------- Setters ------------------------------- //
  // ----------------------------------------------------------------------- //

  /**
   * Sets the vehicle for the TripV2 object.
   *
   * @param {Vehicle | undefined} v - The vehicle to set.
   */
  public set vehicle(v: Vehicle | undefined) {
    this.vehicle_data = v;
    this.vehicle_external_id = v?.directusId;
    this.vehicle_local_id = v?.localId;
    this.costingModel = v?.evModel?.costingType ?? "auto";
  }

  // ----------------------------------------------------------------------- //
  // ------------------------------- Methods ------------------------------- //
  // ----------------------------------------------------------------------- //

  // --- trip planning methods --- //

  /**
   * Plan the trip by determining the planning method to use, fetching trip data,
   * and handling fallback scenarios.
   */
  public async planTrip() {
    // update state
    this.fallbackReason = undefined;
    this.status = "processing";
    const res = await this._fetchTripData(this.vehicle_data);
    if (res) {
      this.itinerary = res;
      // if we have a fallback result from any method, get radar data.
      if (
        (this.status as
          | "unplanned"
          | "processing"
          | "success"
          | "failed"
          | "fallback") === "fallback" &&
        res
      )
        await this.getRadarData(res.totalDrivingDistance / 10);
    } else {
      this.status = "failed";
    }
  }

  /**
   * Fetches trip data based on the determined planning method.
   *
   * If trip needs to be split into multiple route plans,
   * it will be fetched using multiple route plans. If method is "evNav",
   * it will fetch trip data using EV Nav routing. If method is "valhalla",
   * it will fetch trip data using Valhalla routing. If we have a fallback
   * result from any method, it will get radar data.
   *
   * @param {Vehicle | undefined} vehicle_data - The vehicle data to use for
   * determining the planning method and fetching trip data.
   * @returns {Promise<Itinerary | undefined>} - A promise that resolves to
   * the ItineraryV2 object containing trip data, or undefined if no trip data
   * is found.
   */
  private async _fetchTripData(
    vehicle_data?: Vehicle
  ): Promise<Itinerary | undefined> {
    let res: Itinerary | undefined = undefined;

    // figure out which planning method to use.
    const method = this._findMethod(vehicle_data);

    // call the appropriate planning method based on the determined method.
    if (method === "evNav") {
      // check if trip needs to be split into multiple route plans.
      if (this._splitTrip()) {
        res = await this._fetchEVNavMultipleTrips(vehicle_data);
      } else {
        res = await this._fetchEVNavTrip(vehicle_data);
      }
    }

    if (method === "valhalla") {
      res = await this._fetchValhallaTrip(vehicle_data);
    }

    return res;
  }

  /**
   * figure out which planning method to use based on condition of current
   * data.
   *
   * conditions:
   * - no vehicle - use valhalla only
   * - vehicle with no known EV model - use valhalla only
   * - vehicle is ICE - use valhalla only
   * - vehicle with known ev model use - use ev nav
   * - locations have weight changes - use valhalla only
   *
   * @return {"valhalla" | "evNav"} the chosen planning method
   */
  private _findMethod(vehicle_data?: Vehicle): "valhalla" | "evNav" {
    // weight changes are currently not supported by EVNav - use valhalla
    if (this.locations.some((location) => location.weightChange)) {
      return "valhalla";
    }
    // too many waypoints - use valhalla
    if (
      (this.roundTripFlag ? this.locations.length + 1 : this.locations.length) >
      evNavMaxWaypoints
    ) {
      return "valhalla";
    }
    // SOCMin is more than socMax - use valhalla
    if (this.SOCMin > this.SOCMax) {
      this.fallbackReason =
        "Your minimum state of charge is greater than your maximum state of charge.";
      return "valhalla";
    }
    // no vehicle - use valhalla
    return vehicle_data?.evModel ? "evNav" : "valhalla";
  }

  // --- Valhalla specific: trip planning methods --- //

  /**
   * Fetches the trip plan using Valhalla routing and generates an itinerary.
   *
   * @return {Promise<Itinerary | undefined>} - A promise that resolves when the itinerary is generated.
   *   Returns undefined if the trip plan or itinerary generation fails.
   * @remarks
   * This method fetches the trip plan using the Valhalla routing API and then generates an itinerary.
   * It returns a promise that resolves to an instance of {@link Itinerary} if the trip plan and itinerary
   * generation are successful, or undefined if any of these steps fails.
   * The trip plan is fetched using the {@link fetchValhallaRoutePlan} method, and the itinerary is generated
   * using the {@link Itinerary.buildFromValhallaTrip} method.
   */
  private async _fetchValhallaTrip(
    vehicle_data?: Vehicle
  ): Promise<Itinerary | undefined> {
    // compile locations based on if is round trip or not.
    const locations = this._compileValhallaLocations();
    // fetch route plan.
    const res = await fetchValhallaRoutePlan(
      locations,
      vehicle_data?.evModel?.costingType ?? "auto"
    );
    if (!res) return;
    if (Object.hasOwn(res, "trip")) {
      this.status = "fallback";
      // fetch energy data
      const energyData: undefined | EVNavEnergy[] = vehicle_data?.evModel
        ? await this._calcEnergyUsageBreakdown(
            res as Valhalla_RouteRes,
            this.vehicle_data
          )
        : undefined;
      // generate itinerary.
      let batterySize = 0;
      if (vehicle_data) {
        batterySize = vehicle_data.totalBatteryKWh();
      } else {
        batterySize = OptimiserDefaultData.battery;
        this.defaultsUsedFlag = true;
      }
      return Itinerary.buildFromValhallaTrip({
        batterySize,
        startingSoC: this.SOCAct,
        locations: this.locations,
        trip: (res as Valhalla_RouteRes).trip,
        energyData: energyData,
        roundTripFlag: this.roundTripFlag,
        startingLoad: this.startingLoad,
      });
    }
  }

  /**
   * Compiles the Valhalla locations based on whether the trip is a round trip or not.
   *
   * @return {Valhalla_Location[]} An array of Valhalla_Location objects representing the compiled locations.
   */
  private _compileValhallaLocations(): Valhalla_Location[] {
    return this.roundTripFlag
      ? [
          ...this.locations.map((location) => location.asValhallaLocation),
          this.locations[0].asValhallaLocation,
        ]
      : this.locations.map((location) => location.asValhallaLocation);
  }

  /**
   * Converts the Valhalla trip data into an array of TripV2BaseLeg objects.
   *
   * @param {Valhalla_RouteRes} tripData - The Valhalla route response containing the trip data.
   * @return {TripBaseLeg[]} An array of TripV2BaseLeg objects representing the legs of the trip.
   */
  private _valhallaTripToBaseLegs(
    tripData: Valhalla_RouteRes | Itinerary
  ): TripBaseLeg[] {
    if (tripData instanceof Itinerary) {
      return tripData.steps.map((step) => {
        return {
          polyline: step.polyline,
          loadWeight: step.arrivalLoadWeight,
          distance: step.drivingDistance,
          time: step.travelTime + step.chargingTime + step.ferryTime,
        };
      });
    } else {
      return tripData.trip.legs.map((leg, index) => {
        let load = 0;
        for (let i = 0; i <= index; i++) {
          load += this.locations[i].weightChange ?? 0;
        }
        return {
          polyline: leg.shape,
          loadWeight: load,
          distance: leg.summary.length * 1000, // convert valhalla km to meters
          time: leg.summary.time,
        };
      });
    }
  }

  /**
   * Calculates the energy usage breakdown for a trip by fetching energy data for each leg of the trip.
   *
   * @param {Valhalla_RouteRes} tripData - The route data for the trip.
   * @return {Promise<undefined | EVNavEnergy[]>} A promise that resolves to an array of energy usage data for each leg of the trip, or undefined if the vehicle data is missing or the vehicle is an ICE vehicle.
   */
  private async _calcEnergyUsageBreakdown(
    tripData: Valhalla_RouteRes | Itinerary,
    vehicle_data?: Vehicle,
    loadWeight?: number
  ): Promise<undefined | EVNavEnergy[]> {
    if (!vehicle_data) return;
    if (
      vehicle_data.fuelType === "Petrol" ||
      vehicle_data.fuelType === "Diesel"
    )
      return; // no need to calculate energy usage for a ICE vehicle.
    const baseLegs: TripBaseLeg[] = this._valhallaTripToBaseLegs(tripData);
    let ev_model_id: string;
    if (vehicle_data.eVModelId) {
      ev_model_id = vehicle_data.eVModelId;
    } else {
      ev_model_id = OptimiserDefaultData.modelId;
      this.defaultsUsedFlag = true;
    }
    const vehicleWeight = vehicle_data.mass;
    const driverWeight = meanPassengerWeight;
    const passengerWeight = this.passengers * meanPassengerWeight;
    const mass =
      vehicleWeight + driverWeight + passengerWeight + (loadWeight ?? 0);
    // create promise for each polyline.
    const promises = baseLegs.map((leg, index) => {
      const vehicle: EVNavCar = {
        Id: ev_model_id,
      };

      if (leg.loadWeight) vehicle.Mass = mass + leg.loadWeight;

      return fetchEnergyNeeded({
        Vehicle: vehicle,
        Polyline: leg.polyline,
        Name: "leg" + index,
      });
    });
    // fetch energy data.
    const resolvedPromises = await Promise.all(promises);
    // check if all promises resolved successfully
    if (resolvedPromises.every((item) => item !== undefined)) {
      return resolvedPromises as EVNavEnergy[];
    }
  }

  private _calcLegFerryTime(leg: Valhalla_Leg): number {
    return leg.maneuvers.reduce((accumulator, currentItem) => {
      if (currentItem.ferry) {
        return accumulator + currentItem.time;
      }

      return accumulator;
    }, 0);
  }

  // --- EV Nav specific: trip planning methods --- //

  /**
   * Checks if the trip needs to be split into multiple route plans.
   *
   * @return {boolean} Returns true if the trip needs to be split, false otherwise.
   */
  private _splitTrip(): boolean {
    // check if trip needs to be split into multiple route plans.
    if (this.locations.length === 2) return false; // direct trip.
    for (let index = 0; index < this.locations.length; index++) {
      const location = this.locations[index];
      if (location.chargeHere) return true;
    }
    return false;
  }

  /**
   * Fetches an EV nav trip based on the compiled parameters.
   *
   * @return {Promise<Itinerary | undefined>}
   * Returns a Promise that resolves when the EV nav trip is fetched and processed.
   * If the trip cannot be fetched or processed successfully, the Promise resolves to undefined.
   */
  private async _fetchEVNavTrip(
    vehicle_data?: Vehicle
  ): Promise<Itinerary | undefined> {
    let res: Itinerary | undefined = undefined;
    const params = this._compileEVNavParameters(vehicle_data);
    if (!params) return;
    const data = await fetchRoutePlan(params);
    // check if query returned a successful response.
    if (data?.Status === "OK") {
      let batterySize = 0;
      if (vehicle_data) {
        batterySize = vehicle_data.totalBatteryKWh();
      } else {
        batterySize = OptimiserDefaultData.battery;
        this.defaultsUsedFlag = true;
      }
      this.status = "success";
      // compile into an itinerary
      res = Itinerary.buildFromEVNavTrip({
        trip: data as EVNavRoutePlan,
        locations: this.locations,
        batterySize,
        roundTripFlag: this.roundTripFlag,
        startingLoad: this.startingLoad,
      });
    } else {
      // update fallback reason.
      this._parseEVNavError(data as EVNavFailedRoutePlan | undefined);
      // generate fall back trip.
      res = await this._fetchValhallaTrip();
    }
    return res;
  }

  /**
   * Asynchronously fetches multiple EVNav route plans based on the compiled
   * parameters and updates the itinerary accordingly.
   *
   * If all the route plans are successful, it compiles them into an itinerary and returns it.
   * If any of the route plans are unsuccessful, it updates the fallback reason
   * and generates a fallback trip. In this case, it returns undefined.
   *
   * @return {Promise<Itinerary | undefined>} A Promise that resolves with the updated itinerary
   * if all the route plans are successful, or with undefined if any of the route plans are unsuccessful.
   */
  private async _fetchEVNavMultipleTrips(
    vehicle_data?: Vehicle
  ): Promise<Itinerary | undefined> {
    const params = this._compileEVNavLegParams(vehicle_data);
    const data = await fetchMultiRoutePlans(params);
    let batterySize = 0;
    if (vehicle_data) {
      batterySize = vehicle_data.totalBatteryKWh();
    } else {
      batterySize = OptimiserDefaultData.battery;
      this.defaultsUsedFlag = true;
    }
    let res: Itinerary | undefined = undefined;
    // check if query returned a successful response.
    if ((data as EVNavRoutePlan[]).every((plan) => plan.Status === "OK")) {
      this.status = "success";
      // compile into an itinerary
      res = Itinerary.buildFromEVNavTrip({
        trip: data as EVNavRoutePlan[],
        locations: this.locations,
        batterySize,
        roundTripFlag: this.roundTripFlag,
        startingLoad: this.startingLoad,
      });
    } else {
      // update fallback reason.
      this._parseEVNavError(data as EVNavFailedRoutePlan[] | undefined);
      // generate fall back trip.
      res = await this._fetchValhallaTrip();
    }
    return res;
  }

  // --- EV Nav specific: compile API parameters methods --- //

  /**
   * Compiles the EVNavRouteParams object based on the vehicle data and
   * locations.
   *
   * @return {EVNavRouteParams | undefined} The compiled EVNavRouteParams object.
   */
  private _compileEVNavParameters(vehicle_data?: Vehicle): EVNavRouteParams {
    const params: EVNavRouteParams = {
      Battery: vehicle_data
        ? vehicle_data.totalBatteryKWh()
        : OptimiserDefaultData.battery,
      IncludePolyline: true,
      Method: 0,
      SOCAct: this.SOCAct,
      SOCEnd: this.SOCEnd,
      SOCMax: this.SOCMax,
      SOCMin: this.SOCMin,
      Vehicle: this._compileEVNavCar(vehicle_data),
      Waypoints: this.locations.map((location) => location.asEVNavWaypoint),
    };
    return params;
  }

  /**
   * Splits the trip into legs based on if the locations have actions that EV
   * Nav cannot handle in one leg. Then applies leg specific parameters.
   *
   * Also takes into account if the trip is a round trip.
   *
   * @return {EVNavRouteParams[]} An array of EV Nav Route Params for each leg.
   */
  private _compileEVNavLegParams(vehicle_data?: Vehicle): EVNavRouteParams[] {
    const legParams: EVNavRouteParams[] = [];
    const legs: TripLocation[][] = [[]];
    for (let index = 0; index < this.locations.length; index++) {
      legs[legs.length - 1].push(this.locations[index]);
      if (index === 0) continue; // skip checks on first location.
      const location = this.locations[index];
      // check if need to start a new leg.
      if (location.chargeHere) legs.push([location]);
    }
    // check if is a round trip
    if (this.roundTripFlag) legs[legs.length - 1].push(this.locations[0]); // add return trip destination.

    // apply leg specific parameters.
    const baseParams = this._compileEVNavParameters(vehicle_data);
    legs.forEach((leg) => {
      legParams.push({
        ...baseParams,
        Waypoints: leg.map((location) => location.asEVNavWaypoint),
        SOCAct: leg[0].stateOfChargeAfterCharging ?? this.SOCAct,
      });
    });

    return legParams;
  }

  /**
   * Compiles the EVNavCar object based on the vehicle data and driver profile
   * parameters.
   *
   * @return {EVNavCar} The compiled EVNavCar object.
   */
  private _compileEVNavCar(vehicle_data?: Vehicle): EVNavCar {
    let vehicleParameters: EVNavCar = {};

    // figure out vehicle or use default
    if (vehicle_data) {
      vehicleParameters = vehicle_data.routePlanningCarParam;
    } else {
      vehicleParameters.Id = OptimiserDefaultData.modelId;
      this.defaultsUsedFlag = true;
    }

    // add driver profile parameters
    if (this.SpeedAdjustment)
      vehicleParameters.SpeedAdjustment = this.SpeedAdjustment;
    if (this.AccelerationAdjustment)
      vehicleParameters.AccelerationAdjustment = this.AccelerationAdjustment;

    // add additional factors
    if (this.WeatherFactor) vehicleParameters.WeatherFactor;
    let vehicleWeight = 0;
    if (vehicle_data?.mass) {
      vehicleWeight = vehicle_data.mass;
    } else {
      evNavDefaultData.Mass;
      this.defaultsUsedFlag = true;
    }
    const driverWeight = meanPassengerWeight;
    const passengerWeight = this.passengers * meanPassengerWeight;
    vehicleParameters.Mass =
      vehicleWeight + driverWeight + passengerWeight + this.startingLoad;

    return vehicleParameters;
  }

  // --- EV Nav specific: error handling methods --- //

  /**
   * Parses the EVNav error response and sets the fallback reason to a human
   * readable display string based on the error status.
   *
   * @param {EVNavFailedRoutePlan | EVNavFailedRoutePlan[] | undefined} error - The error response from the EVNav API.
   */
  private _parseEVNavError(
    error: EVNavFailedRoutePlan | EVNavFailedRoutePlan[] | undefined
  ) {
    if (!error) {
      this.fallbackReason = `Whoops!, looks like we are having trouble with 
      connecting to our server. Please try again later.`;
      return;
    }

    let status;
    if (Array.isArray(error)) {
      status = error[0].Status;
    } else {
      status = error.Status;
    }

    if (status.includes("failed to find vehicle")) {
      this.fallbackReason = "Unable to resolve your vehicles EV profile.";
    }

    if (status === "Too few waypoints") {
      this.fallbackReason = `That shouldn't be able to happen. Looks like you 
      are not going far, for some reason we let you try plan a trip with 
      only a starting location and no destination. This should have been 
      highlighted on the planning form before letting you get this far. Please 
      refresh and try again.`;
    }

    if (status === "Not Routable") {
      this.fallbackReason = this._findNotRoutableReason();
    }

    if (status === "Too many waypoints") {
      this.fallbackReason = `Looks like there are too many stops for our server
      to be able to plan your trip and optimise your charging. Please Add 
      charging stops manually.`;
    }
  }

  private _findNotRoutableReason(): string {
    // TODO: add more reasons
    // fall back if no reason could be found
    return `Whoops! Something went wrong and we are unable to 
    find a reason for why this trip did not work. Feedback has been sent to 
    our development team to improve our service.`;
  }

  /**
   * Optimizes the waypoints of the trip.
   *
   * @return {Promise<void>} A promise that resolves when the optimization is complete.
   */
  public async optimiseWaypoints(): Promise<void> {
    // checks if more than one waypoint/4 + locations. 3+ if round trip.
    // bails out if not (already in order that will be returned).
    if (!this.roundTripFlag && this.locations.length < 4) return;
    if (this.locations.length < 3) return;
    // fetch optimised order.
    const res = await this._fetchValhallaOptimizedTrip();
    // check if order has changed
    const originalIndexes = (res as Valhalla_RouteRes).trip.locations.map(
      (location) => location.original_index
    );
    const newIndexes = (res as Valhalla_RouteRes).trip.locations.map(
      (location, index) => index
    );
    if (
      newIndexes.some((newIndex, index) => newIndex !== originalIndexes[index])
    ) {
      // reorder locations based on res.
      const newLocations = newIndexes.map(
        (newIndex) => this.locations[originalIndexes[newIndex]]
      );
      this.locations = newLocations;
      // replan trip
      await this.planTrip();
    }
  }

  /**
   * Fetches the optimized (a trip the reorders waypoints to solve the
   * traveling salesman problem) trip from the Valhalla route endpoint.
   *
   * @return {Promise<Valhalla_RouteRes | void>} A Promise that resolves to the optimized trip or void.
   */
  private async _fetchValhallaOptimizedTrip(): Promise<Valhalla_RouteRes | void> {
    // call valhalla optimized route end point.
    const res = await fetchValhallaOptimizedRoutePlan(
      this._compileValhallaLocations(),
      this.costingModel
    );
    if (!res) return;
    if (Object.hasOwn(res, "trip")) {
      // handle success
      return res as Valhalla_RouteRes;
    }
    if (Object.hasOwn(res, "error")) {
      // handle error
    }
  }

  // --- save methods --- //

  /**
   * Saves the route plan to the database.
   *
   * @return {Promise<"failed" | "ok">} A promise that resolves to "failed" if the save operation fails, or "ok" if it succeeds.
   */
  public async saveRoutePlan() {
    if (!this.itinerary.destination) return "failed";
    const saveData = this._compileSaveData();
    if (this.external_id) {
      const res = await updatedSavedRoutePlan(this.external_id, saveData);
      if (!res) return "failed";
      return "ok";
    } else {
      const res = await createSavedRoutePlan(saveData);
      if (!res) return "failed";
      this.external_id = res.id;
      return "ok";
    }
  }

  /**
   * Compile the route data into a format suitable for saving.
   *
   * This function takes the current state of the trip and compiles it into a
   * SavedRouteData object. It includes both display data and planning data, as
   * well as summary statistics.
   *
   * @return {SavedRouteData} The compiled route data.
   */
  private _compileSaveData(): SavedRouteData {
    if (!this.itinerary.destination) throw new Error("No destination");

    return {
      // display data
      name: this.name,
      itinerary: {
        steps: this.itinerary.steps,
        destination: this.itinerary.destination,
      },
      // plaining data
      vehicleId: this.vehicle_external_id,
      vehicleEVModel: this.vehicle_data?.eVModelId,
      accelerationAdjustment: this.AccelerationAdjustment,
      speedAdjustment: this.SpeedAdjustment,
      departingLoad: this.startingLoad,
      departingSOC: this.SOCAct,
      arrivalSOC: this.SOCEnd,
      minimumSOC: this.SOCMin,
      maximumSOC: this.SOCMax,
      passengerCount: this.passengers,
      roundTripFlag: this.roundTripFlag,
      defaultDataUsedFlag: this.defaultsUsedFlag,
      waypoints: this.locations.map((waypoint) =>
        this._compileWaypointSaveData(waypoint)
      ),
      // summary statistics
      distance: this.itinerary.totalDrivingDistance,
      totalTime: this.itinerary.totalTime,
      drivingTime: this.itinerary.totalTravelTime,
      chargingTime: this.itinerary.totalChargingTime,
      ferryTime: this.itinerary.totalFerryTime,
      energyUsed: this.itinerary.totalEnergyUsed,
      energyAdded: this.itinerary.totalEnergyAdded,
      frequency: this.frequency,
    };
  }

  /**
   * Compiles a TripLocationV2 object into a SavedRouteWaypointData object.
   *
   * @param {TripLocation} waypoint - The TripLocationV2 object to compile.
   * @return {SavedRouteWaypointData} The compiled SavedRouteWaypointData object.
   */
  private _compileWaypointSaveData(
    waypoint: TripLocation
  ): SavedRouteWaypointData {
    return {
      name: waypoint.name ?? "Unnamed location",
      latitude: waypoint.coordinates.latitude,
      longitude: waypoint.coordinates.longitude,
      address: waypoint.address,
      weightChange: waypoint.weightChange ?? 0,
      nonDrivingEnergyUsed: waypoint.nonDrivingEnergyUsed ?? 0,
      chargeHereFlag: waypoint.chargeHere ?? false,
      stateOfChargeAfterCharging: waypoint.stateOfChargeAfterCharging,
      stayDuration: waypoint.stay ?? 0,
    };
  }

  // --- stats methods --- //

  /**
   * Calculates the trip statistics based on the given parameters.
   *
   * @param {Object} options - The options for calculating trip statistics.
   * @param {CalcVsType} options.calcVs - The calculation method for CO2 emissions.
   * @param {number} options.petrolKmPerLitre - The distance per litre of petrol.
   * @param {number} options.petrolCostPerLitre - The cost per litre of petrol.
   * @param {number} options.dieselKmPerLitre - The distance per litre of diesel.
   * @param {number} options.dieselCostPerLitre - The cost per litre of diesel.
   * @param {number} options.kWhCostHome - The cost per kilowatt-hour of home charging.
   * @return {TripStats | undefined} The calculated trip statistics or undefined if any required parameter is missing.
   */
  public getTripStats({
    calcVs, // Calculation method for CO2 emissions
    petrolKmPerLitre, // Distance per litre of petrol
    petrolCostPerLitre, // Cost per litre of petrol
    dieselKmPerLitre, // Distance per litre of diesel
    dieselCostPerLitre, // Cost per litre of diesel
    kWhCostHome, // Cost per kilowatt-hour of home charging
  }: {
    calcVs: CalcVsType;
    petrolKmPerLitre: number;
    petrolCostPerLitre: number;
    dieselKmPerLitre: number;
    dieselCostPerLitre: number;
    kWhCostHome: number;
  }): TripStats | undefined {
    // Create and return tripStats object
    return {
      // Calculate avoided CO2 emissions
      avoidedCO2: this._calcAvoidedCO2(
        calcVs, // Calculation method for CO2 emissions
        petrolKmPerLitre, // Distance per litre of petrol
        dieselKmPerLitre // Distance per litre of diesel
      ),
      // Calculate driving kilometers
      drivingKms: this._calcDrivingKms(),
      // Calculate driving time
      drivingTime: this._calcDrivingTime(),
      // Calculate charging time
      chargingTime: this._calcChargingTime(),
      // Calculate charge in kilowatt-hours
      chargeKWh: this._calcChargeKWh(),
      // Calculate total energy used
      totalEnergyUsed: this._calcTotalEnergy(),
      // Calculate emitted CO2 emissions
      emittedCO2: this._calcEmittedCO2({
        calcVs, // Calculation method for CO2 emissions
        petrolKmPerLitre, // Distance per litre of petrol
        dieselKmPerLitre, // Distance per litre of diesel
      }),
      // Calculate public charging cost
      publicChargingCost: this._calcPublicChargingCost(),
      // Calculate private charging cost
      privateChargingCost: this._calcPrivateChargingCost(kWhCostHome),
      // Calculate battery level and round to nearest integer
      battery: this._calcBattery()?.toFixed(0),
      // Calculate fuel cost
      fuelCost: this._calcFuelCost({
        calcVs, // Calculation method for CO2 emissions
        petrolKmPerLitre, // Distance per litre of petrol
        petrolCostPerLitre, // Cost per litre of petrol
        dieselKmPerLitre, // Distance per litre of diesel
        dieselCostPerLitre, // Cost per litre of diesel
      }),
      // Calculate total time
      totalTime: this._calcTotalTime(),
      // Calculate stay duration
      stayDuration: this._calcStayDuration(),
    };
  }

  /**
   * Calculates the avoided CO2 emissions for a trip based on the fuel type and efficiency.
   *
   * @param {CalcVsType} calcVs - The type of fuel used for calculation.
   * @param {number} petrolKmPerLitre - The kilometers per liter for petrol.
   * @param {number} dieselKmPerLitre - The kilometers per liter for diesel.
   * @return {number | undefined} The avoided CO2 emissions in 2 decimal places, or undefined if the vehicle is an ICE vehicle.
   */
  private _calcAvoidedCO2(
    calcVs: CalcVsType,
    petrolKmPerLitre: number,
    dieselKmPerLitre: number
  ): number | undefined {
    if (
      this.vehicle_data?.fuelType === "Petrol" ||
      this.vehicle_data?.fuelType === "Diesel"
    )
      return; // no need to calculate energy usage for a ICE vehicle.

    // efficiency = kilometers of travel per litre of fuel consumed.
    // co2PerLitre = CO₂ emitted per litre of fuel used including refinement
    // avoidedCO₂ = (distance in kilometers / efficiency) * co2PerLitre

    // user petrol or diesel km traveled per litre of fuel consumed
    const efficiency =
      calcVs === CalcVsType.PETROL ? petrolKmPerLitre : dieselKmPerLitre;

    // 2.8 for petrol and 3.2 for diesel
    const co2PerLitre = calcVs === CalcVsType.PETROL ? 2.8 : 3.2;

    const distance_km = this._calcDrivingKms();

    return to2DP((distance_km / efficiency) * co2PerLitre);
  }

  /**
   * Calculates the driving distance in kilometers based on the total driving distance in meters.
   *
   * @return {string} The driving distance in kilometers with 2 decimal places.
   */
  private _calcDrivingKms(): number {
    // n / 1000 = meters to kilometers conversion.
    return to2DP(this.itinerary.totalDrivingDistance / 1000);
  }

  /**
   * Calculates the driving time based on the total travel time.
   *
   * @return {string} The driving time in the format #hours #minutes.
   */
  private _calcDrivingTime(): string {
    // n - charging time / 60 = seconds to minutes conversion, further converted to #hours #minutes format
    return Duration.fromObject({
      hours: 0,
      minutes: Math.floor(this.itinerary.totalTravelTime / 60),
    })
      .normalize()
      .toHuman({ unitDisplay: "short" })
      .replace(",", "");
  }

  /**
   * Calculates the charging time for a trip based on the fuel type and efficiency.
   *
   * @return {string | undefined} The charging time in the format "HH:MM" or undefined if the vehicle is an ICE vehicle.
   */
  private _calcChargingTime(): string | undefined {
    if (
      this.vehicle_data?.fuelType === "Petrol" ||
      this.vehicle_data?.fuelType === "Diesel"
    )
      return; // no need to calculate energy usage for a ICE vehicle.
    return Duration.fromObject({
      hours: 0,
      minutes: Math.floor(this.itinerary.totalChargingTime / 60),
    })
      .normalize()
      .toHuman({ unitDisplay: "short" })
      .replace(",", "");
  }

  /**
   * Calculates the total charge in kilowatt-hours (kWh) based on the total charging time.
   *
   * @return {string | undefined} The total charge in kWh with 2 decimal places, or undefined if the vehicle is an ICE vehicle.
   */
  private _calcChargeKWh(): string | undefined {
    if (
      this.vehicle_data?.fuelType === "Petrol" ||
      this.vehicle_data?.fuelType === "Diesel"
    )
      return; // no need to calculate energy usage for a ICE vehicle.
    return this.itinerary.totalChargingTime.toFixed(2);
  }

  /**
   * Calculates the emitted CO₂ based on the fuel type, efficiency, and fuel consumption.
   *
   * @param {CalcVsType} calcVs - The calculation type, either PETROL or DIESEL.
   * @param {number} petrolKmPerLitre - The kilometers traveled per liter of petrol fuel.
   * @param {number} dieselKmPerLitre - The kilometers traveled per liter of diesel fuel.
   * @return {string} The emitted CO₂ in grams per kilometer, rounded to 2 decimal places.
   */
  private _calcEmittedCO2({
    calcVs,
    petrolKmPerLitre,
    dieselKmPerLitre,
  }: {
    calcVs: CalcVsType;
    petrolKmPerLitre: number;
    dieselKmPerLitre: number;
  }): number {
    if (
      this.vehicle_data?.fuelType === "Petrol" ||
      this.vehicle_data?.fuelType === "Diesel"
    ) {
      // user petrol or diesel km traveled per litre of fuel consumed
      const efficiency =
        calcVs === CalcVsType.PETROL ? petrolKmPerLitre : dieselKmPerLitre;

      // 2.8 for petrol and 3.2 for diesel
      const co2PerLitre = calcVs === CalcVsType.PETROL ? 2.8 : 3.2;

      return to2DP(
        (this.itinerary.totalDrivingDistance / efficiency) * co2PerLitre
      );
    }
    // 0.13 or 130g/kWh is a number gathered from google.
    // note a snapshot gathered on 17/04/2024 from https://app.em6.co.nz/
    // show average this month at 114g/kWh current estimate over
    // estimates carbon from energy creation therefore slightly under
    // estimates avoided figures in UI.
    // TODO: find a better solution to calculate/fetch this number.
    return to2DP(this.itinerary.totalEnergyUsed * 0.13);
  }

  /**
   * Calculates the public charging cost based on the vehicle's fuel type.
   *
   * @return {number | undefined} The public charging cost in 2 decimal places, or undefined if the vehicle is an ICE vehicle.
   */
  private _calcPublicChargingCost(): number | undefined {
    if (
      this.vehicle_data?.fuelType === "Petrol" ||
      this.vehicle_data?.fuelType === "Diesel"
    )
      return; // no need to calculate energy usage for a ICE vehicle.
    return to2DP(this.itinerary.totalChargingCost);
  }

  /**
   * Calculates the battery percentage based on the total energy used and the vehicle's battery capacity.
   *
   * @return {number | undefined} The battery percentage rounded to the nearest integer, or undefined if the vehicle data is not available.
   */
  private _calcBattery(): number | undefined {
    if (!this.vehicle_data) return;
    if (
      this.vehicle_data?.fuelType === "Petrol" ||
      this.vehicle_data?.fuelType === "Diesel"
    )
      return; // no need to calculate energy usage for a ICE vehicle.
    return Math.round(
      (this.itinerary.totalEnergyUsed / this.vehicle_data.totalBatteryKWh()) *
        100
    );
  }

  /**
   * Calculates the fuel cost based on the calculation type, kilometers per liter of fuel, and cost per liter of fuel.
   *
   * @param {CalcVsType} calcVs - The calculation type, either PETROL or DIESEL.
   * @param {number} petrolKmPerLitre - The kilometers traveled per liter of petrol fuel.
   * @param {number} dieselKmPerLitre - The kilometers traveled per liter of diesel fuel.
   * @param {number} petrolCostPerLitre - The cost per liter of petrol fuel.
   * @param {number} dieselCostPerLitre - The cost per liter of diesel fuel.
   * @return {number} The fuel cost in 2 decimal places.
   */
  private _calcFuelCost({
    calcVs,
    petrolKmPerLitre,
    dieselKmPerLitre,
    petrolCostPerLitre,
    dieselCostPerLitre,
  }: {
    calcVs: CalcVsType;
    petrolKmPerLitre: number;
    dieselKmPerLitre: number;
    petrolCostPerLitre: number;
    dieselCostPerLitre: number;
  }): number {
    const distance_km = this.itinerary.totalDrivingDistance / 1000;
    const kmPerLitre =
      calcVs === CalcVsType.PETROL ? petrolKmPerLitre : dieselKmPerLitre;
    const costPerLitre =
      calcVs === CalcVsType.PETROL ? petrolCostPerLitre : dieselCostPerLitre;
    return to2DP((distance_km / kmPerLitre) * costPerLitre);
  }

  /**
   * Calculates the total time in a human-readable format from the total time in minutes.
   *
   * @return {string} The total time in the format "HH:MM", without any commas.
   */
  private _calcTotalTime(): string {
    return Duration.fromObject({
      hours: 0,
      minutes: Math.floor(this.itinerary.totalTime / 60),
    })
      .normalize()
      .toHuman({ unitDisplay: "short" })
      .replace(",", "");
  }

  /**
   * Calculates the total stay duration in a human-readable format.
   *
   * @return {string | undefined} The total stay duration in the format "HH:MM", without any commas. Returns undefined if there is no stay duration.
   */
  private _calcStayDuration(): string | undefined {
    let time = 0;

    this.locations.forEach((location) => {
      time += location.stay || 0;
    });

    if (!time) return undefined;

    return Duration.fromObject({ hours: 0, minutes: Math.floor(time / 60) })
      .normalize()
      .toHuman({ unitDisplay: "short" })
      .replace(",", "");
  }

  /**
   * Calculates the total energy used in the trip.
   *
   * @return {number} The total energy used in the trip.
   */
  private _calcTotalEnergy(): number {
    return this.itinerary.totalEnergyUsed;
  }

  /** Calculates and returns the total cost of private charging for a single run of this trip. */
  public _calcPrivateChargingCost(kWhCostHome: number): number | undefined {
    if (
      this.vehicle_data?.fuelType === "Petrol" ||
      this.vehicle_data?.fuelType === "Diesel"
    )
      return; // no need to calculate energy usage for a ICE vehicle.
    let kwh = 0;
    // add starting charge
    kwh = this.itinerary.steps[0].energyBeforeTraveling;
    // add charge at waypoints flagged as charge here;
    kwh += this.itinerary.totalPrivateEnergyAdded;
    const totalCost = to2DP(
      Math.min(kwh, this.itinerary.totalEnergyUsed) * kWhCostHome
    ); // don't go over energy actually used.
    return totalCost;
  }

  /**
   * Get the radar data for the trip.
   *
   * @param {number} [range] The range (in km) to use for the radar data. If not
   *   provided, the range will be 100km.
   * @param {boolean} [calcLongestLeap] If false, do not calculate the longest
   *   leap data. Defaults to true.
   * @param {boolean} [includePolyline] If true, include the polyline in the
   *   returned data. Defaults to false.
   * @returns {Promise<"SUCCESS" | "FAILED">} A promise that resolves to
   *   "SUCCESS" if the radar data was successfully fetched, and "FAILED" if
   *   there was an error.
   */
  public async getRadarData(
    range: number | undefined = undefined,
    calcLongestLeap = true,
    includePolyline = false
  ): Promise<"SUCCESS" | "FAILED"> {
    // Only fetch data if not already fetched this session.
    if (this.radarData) return "SUCCESS";

    // Parameters are not null guard clause.
    if (
      this.vehicle_data?.fuelType === "Petrol" ||
      this.vehicle_data?.fuelType === "Diesel"
    )
      return "FAILED"; // no need to calculate energy usage for a ICE vehicle

    // Compile radar parameters.
    const radarParams: EVNavRadarParams =
      this.status === "success"
        ? {
            ...this._compileEVNavParameters(),
            Waypoints: undefined,
            Range: this._getRange(range),
            CalculateLongestLeap: calcLongestLeap,
            IncludePolyline: includePolyline,
            Polyline: this.fullTripPolyline,
          }
        : {
            ...this._compileEVNavParameters(),
            Range: this._getRange(range),
            CalculateLongestLeap: calcLongestLeap,
            IncludePolyline: includePolyline,
          };

    // Fetch data from EV Nav.
    const resData = await evnavRadarCall(radarParams);

    // Check EV Nav response status.
    if (resData) {
      this.radarData = TripRadarData.fromEVNavData(resData);
      return "SUCCESS";
    }
    return "FAILED";
  }

  /**
   * get the range for uses in ev nave radar calls. In meters.
   *
   * @param range optional parameter if a range is already specified.
   * @returns the range for uses in ev nave radar calls. In meters.
   */
  private _getRange(range?: number | undefined): number {
    if (range) return Math.floor(range);
    return Math.floor(this.itinerary.totalDrivingDistance / 10);
  }

  /**
   * Finds the charger IDs within a given range of a specified point.
   *
   * @param {Object} point - The point to calculate the range from. It should have the properties `longitude` and `latitude`.
   * @param {number} range - The range in kilometers from the specified point.
   * @return {string[]} An array of charger IDs that are within the specified range of the given point.
   */
  public findChargerIdsInRangeOfPoint(
    point: {
      longitude: number;
      latitude: number;
    },
    range: number
  ): string[] {
    // Get the chargers from radar data
    const chargers = this.radarData?.chargers;

    // If there are no chargers, return an empty array
    if (!chargers) return [];

    // Filter the chargers based on the distance from the given point
    const filteredChargers: {
      charger: EVNavCharger;
      distanceFromPoint: number;
    }[] = [];

    chargers.forEach((charger) => {
      // Calculate the distance using the haversine formula
      const distanceFromWaypoint = haversineDistance(
        [charger.Location.Longitude, charger.Location.Latitude],
        [point.longitude, point.latitude]
      );

      // Keep only the chargers within the specified range
      if (distanceFromWaypoint <= range)
        filteredChargers.push({
          charger,
          distanceFromPoint: distanceFromWaypoint,
        });
    });

    // Sort the filtered chargers by distance
    filteredChargers.sort((a, b) => a.distanceFromPoint - b.distanceFromPoint);
    // Return the CDBIDs of the filtered chargers
    return filteredChargers.map((e) => e.charger.CDBID);
  }

  /**
   * Adds a charger to the given step of the trip.
   *
   * This is done by splitting the step into two new steps: one from the start of
   * the step to the charger, and one from the charger to the end of the step.
   * The energy usage and charging data for the new steps is calculated based on
   * the energy usage and charging data of the original step.
   *
   * @param charger The charger to add to the step.
   * @param stepIndex The index of the step to add the charger to.
   * @param stateOfChargeAfterCharging The state of charge of the vehicle after
   * charging at the charger, as a percentage (i.e. 0.8 for 80%).
   * @returns A string indicating whether the operation was successful or not.
   * If the operation was successful, the string will be "SUCCESS". If the
   * operation failed, the string will be "FAILED".
   */
  public async addChargerToStep({
    charger,
    stepIndex,
    stateOfChargeAfterCharging,
  }: {
    charger: Charger;
    stepIndex: number;
    stateOfChargeAfterCharging: number;
    defaultCostPerKWh: number;
    defaultCostPerMinDC: number;
  }) {
    // locate step
    const step =
      this.itinerary.steps.length > stepIndex
        ? this.itinerary.steps[stepIndex]
        : undefined;
    if (!step) return "FAILED";
    const stepDecodedPolyline = decodePolyline(step.polyline);
    const stepStartingPoint = stepDecodedPolyline[0];
    const stepLastPoint = stepDecodedPolyline[stepDecodedPolyline.length - 1];
    const availableBattery = this.vehicle_data?.totalBatteryKWh();
    // get polylines from valhalla from start of step to charging stop and charging stop to end of step.
    const res = await fetchValhallaRoutePlan(
      [
        {
          lat: stepStartingPoint[0],
          lon: stepStartingPoint[1],
        },
        charger.coordinates.asAbbreviatedObj,
        {
          lat: stepLastPoint[0],
          lon: stepLastPoint[1],
        },
      ],
      this.vehicle_data?.evModel?.costingType ?? "auto"
    );
    if (!res) return "FAILED";
    if (Object.hasOwn(res, "error")) return "FAILED";
    // get energy data for polylines
    let energyData: undefined | EVNavEnergy[] = undefined;
    if (Object.hasOwn(res, "trip")) {
      const energyRes = await this._calcEnergyUsageBreakdown(
        res as Valhalla_RouteRes,
        this.vehicle_data,
        step.departureLoadWeight
      );
      energyData = energyRes;
    }

    // compile new steps
    const firstStep: ItineraryStep = {
      // location details
      addressStr: step.addressStr,
      name: step.name,
      userAdded: step.userAdded,
      locationStayTime: step.locationStayTime,
      locationCDBID: step.locationCDBID,
      // travel data
      polyline: (res as Valhalla_RouteRes).trip.legs[0].shape,
      drivingDistance:
        (res as Valhalla_RouteRes).trip.legs[0].summary.length * 1000,
      ferryTime: (res as Valhalla_RouteRes).trip.legs[0].summary.has_ferry
        ? (res as Valhalla_RouteRes).trip.legs[0].maneuvers.reduce(
            (acc, maneuver) => acc + (maneuver.ferry ? maneuver.time : 0),
            0
          )
        : 0,
      travelTime: (res as Valhalla_RouteRes).trip.legs[0].summary.has_ferry
        ? (res as Valhalla_RouteRes).trip.legs[0].maneuvers.reduce(
            (acc, maneuver) => acc + (!maneuver.ferry ? maneuver.time : 0),
            0
          )
        : (res as Valhalla_RouteRes).trip.legs[0].summary.time,
      // energy usage data
      chargeUsedAtLocation: step.chargeUsedAtLocation,
      energyUsedAtLocation: step.energyUsedAtLocation,
      chargeBeforeCharging: step.chargeBeforeCharging,
      chargeBeforeTraveling: step.chargeBeforeTraveling,
      energyBeforeTraveling: step.energyBeforeTraveling,
      energyUsedTraveling: energyData?.[0]?.Energy ?? 0,
      chargeUsedTraveling:
        energyData?.[0]?.Energy && availableBattery
          ? energyData[0].Energy / availableBattery
          : 0,
      energyAfterTraveling: energyData?.[0]?.Energy
        ? step.energyBeforeTraveling - energyData[0].Energy
        : 0,
      chargeAfterTraveling:
        energyData?.[0]?.Energy && availableBattery
          ? (step.energyBeforeTraveling - energyData[0].Energy) /
            availableBattery
          : 0,
      // charging data
      chargeAdded: step.chargeAdded,
      chargingCost: step.chargingCost,
      chargingTime: step.chargingTime,
      energyAdded: step.energyAdded,
      // load data
      arrivalLoadWeight: step.arrivalLoadWeight,
      departureLoadWeight: step.departureLoadWeight,
    };

    const secondStep: ItineraryStep = {
      // location details
      addressStr: charger.addressString,
      name: charger.name ?? "Unnamed",
      userAdded: true,
      locationCDBID: charger.id,
      locationStayTime: 0,
      // travel data
      polyline: (res as Valhalla_RouteRes).trip.legs[1].shape,
      drivingDistance:
        (res as Valhalla_RouteRes).trip.legs[1].summary.length * 1000,
      ferryTime: (res as Valhalla_RouteRes).trip.legs[1].summary.has_ferry
        ? (res as Valhalla_RouteRes).trip.legs[1].maneuvers.reduce(
            (acc, maneuver) => acc + (maneuver.ferry ? maneuver.time : 0),
            0
          )
        : 0,
      travelTime: (res as Valhalla_RouteRes).trip.legs[1].summary.has_ferry
        ? (res as Valhalla_RouteRes).trip.legs[1].maneuvers.reduce(
            (acc, maneuver) => acc + (!maneuver.ferry ? maneuver.time : 0),
            0
          )
        : (res as Valhalla_RouteRes).trip.legs[1].summary.time,
      // energy usage data
      energyUsedTraveling: energyData?.[1]?.Energy ?? 0,
      chargeUsedTraveling:
        energyData?.[1]?.Energy && availableBattery
          ? energyData[1].Energy / availableBattery
          : 0,
      chargeUsedAtLocation: 0,
      energyUsedAtLocation: 0,
      chargeBeforeCharging: firstStep.chargeAfterTraveling,
      chargeBeforeTraveling: stateOfChargeAfterCharging,
      energyBeforeTraveling: availableBattery
        ? availableBattery * stateOfChargeAfterCharging
        : 0,
      energyAfterTraveling: energyData?.[1]?.Energy
        ? firstStep.energyAfterTraveling +
          (availableBattery
            ? availableBattery *
              (stateOfChargeAfterCharging - firstStep.chargeAfterTraveling)
            : 0) -
          energyData[1].Energy
        : 0,
      chargeAfterTraveling:
        energyData?.[1]?.Energy && availableBattery
          ? (firstStep.energyAfterTraveling +
              (availableBattery
                ? availableBattery *
                  (stateOfChargeAfterCharging - firstStep.chargeAfterTraveling)
                : 0) -
              energyData[1].Energy) /
            availableBattery
          : 0,
      // charging data
      chargeAdded: stateOfChargeAfterCharging - firstStep.chargeAfterTraveling,
      energyAdded: availableBattery
        ? availableBattery *
          (stateOfChargeAfterCharging - firstStep.chargeAfterTraveling)
        : 0,
      chargingCost: 0,
      chargingTime: 0,
      // load data
      arrivalLoadWeight: firstStep.departureLoadWeight,
      departureLoadWeight: firstStep.departureLoadWeight,
    };

    const newSteps: [ItineraryStep, ItineraryStep] = [firstStep, secondStep];
    // replace previous step with the new two steps
    this.itinerary.steps.splice(stepIndex, 1, ...newSteps);
    this.itinerary.recalculateFloatingData(this.vehicle_data);
    this.chargingStopCDBIDs.push(charger.id);
    return "SUCCESS";
  }

  /**
   * Removes a charging stop from the trip itinerary.
   *
   * A charging stop is a stop at a charger along the route of the trip. This
   * function removes one of these stops from the itinerary, and regenerates the
   * polylines for the steps on either side of the removed stop. The energy usage
   * and charging data for the new steps is calculated based on the energy usage
   * and charging data of the original steps.
   *
   * @param stopCDBID The CDBID of the charger to remove from the itinerary.
   * @returns A string indicating whether the operation was successful or not.
   * If the operation was successful, the string will be "SUCCESS". If the
   * operation failed, the string will be "FAILED".
   */
  public async removeChargingStop(
    stopCDBID: string
  ): Promise<"SUCCESS" | "FAILED"> {
    if (!this.chargingStopCDBIDs.includes(stopCDBID)) return "FAILED"; // bail if not in list
    const stepIndex = this.itinerary.steps.findIndex(
      (step) => step.locationCDBID === stopCDBID
    );

    if (stepIndex === -1) return "FAILED"; // bail if not found
    const step = this.itinerary.steps[stepIndex];
    const stepDecodedPolyline = decodePolyline(step.polyline);
    const previousStep = this.itinerary.steps[stepIndex - 1];
    const previousStepLatLng = decodePolyline(previousStep.polyline)[0];
    const nextStepLatLng = stepDecodedPolyline[stepDecodedPolyline.length - 1]; // using last lat long of polyline covers both start of next step and destination
    const nextStep =
      this.itinerary.steps.length >= stepIndex + 1
        ? this.itinerary.steps[stepIndex + 1]
        : undefined; // assume is destination if no next step.

    // fetch new polyline
    const valhallaData = await fetchValhallaRoutePlan(
      [
        { lat: previousStepLatLng[0], lon: previousStepLatLng[1] },
        { lat: nextStepLatLng[0], lon: nextStepLatLng[1] },
      ],
      this.costingModel
    );

    // check success of valhalla calls bail out if failed
    if (!valhallaData || Object.hasOwn(valhallaData, "error")) return "FAILED";

    // get energy data for polylines
    let energyData: undefined | EVNavEnergy[] = undefined;
    if (Object.hasOwn(valhallaData, "trip")) {
      const energyRes = await this._calcEnergyUsageBreakdown(
        valhallaData as Valhalla_RouteRes,
        this.vehicle_data,
        previousStep.departureLoadWeight
      );
      energyData = energyRes;
    }

    // rebuild previous step with new destination location
    const newPreviousStep: ItineraryStep = {
      ...previousStep,
      polyline: (valhallaData as Valhalla_RouteRes).trip.legs[0].shape,
      drivingDistance: (valhallaData as Valhalla_RouteRes).trip.summary.length,
      travelTime:
        energyData?.[0].Time ??
        (valhallaData as Valhalla_RouteRes).trip.summary.time,
      energyUsedTraveling: energyData?.[0].Energy ?? 0,
      ferryTime: this._calcLegFerryTime(
        (valhallaData as Valhalla_RouteRes).trip.legs[0]
      ),
      energyAfterTraveling:
        previousStep.energyBeforeTraveling - (energyData?.[0].Energy ?? 0),
    };
    if (this.vehicle_data) {
      newPreviousStep.chargeAfterTraveling =
        newPreviousStep.energyAfterTraveling /
        this.vehicle_data.totalBatteryKWh();
    }
    const newNextStep: ItineraryStep | undefined = nextStep
      ? {
          ...nextStep,
        }
      : undefined;
    if (newNextStep && this.vehicle_data) {
      newNextStep.chargeBeforeCharging =
        newPreviousStep.energyAfterTraveling /
        this.vehicle_data.totalBatteryKWh();
    }

    // replace itinerary steps
    if (newNextStep) {
      this.itinerary.steps.splice(
        stepIndex - 1,
        3,
        newPreviousStep,
        newNextStep
      );
    } else {
      // handle case that this is the last leg being replaced
      this.itinerary.steps.splice(stepIndex - 1, 1, newPreviousStep).pop();
    }

    this.itinerary.recalculateFloatingData(this.vehicle_data);
    this.chargingStopCDBIDs.splice(
      this.chargingStopCDBIDs.indexOf(stopCDBID),
      1
    );
    return "SUCCESS";
  }

  // --- comparison methods --- //

  /**
   * Adds a comparison to the trip and sets it as the displayed comparison.
   *
   * @param {Vehicle} vehicle - The vehicle to compare against.
   * @return {void} This function does not return anything.
   */
  public async addComparison(vehicle: Vehicle) {
    const energyData = await this._calcEnergyUsageBreakdown(
      this.itinerary,
      vehicle
    );

    if (!energyData) return "FAILED";

    // create comparison capture object
    const comparison: TripComparison = new TripComparison({
      vehicle,
      energyData,
      chargeUsableInRange: this.SOCMax - this.SOCMin,
    });
    // add comparison to trip
    this.comparisons.push(comparison);
    // make comparison the displayed comparison
    this.displayedComparisonId = comparison.localId;
  }

  /**
   * Changes the base trip to use the current comparison as the base trip.
   *
   * @return {void} This function does not return anything.
   */
  public async useCurrentComparison() {
    // change base trip to use current comparison as the base trip.

    if (this.displayedComparisonId) {
      const comparison = this.comparisons.find(
        (comparison) => comparison.localId === this.displayedComparisonId
      );
      if (!comparison) return;
      if (comparison?.vehicle.localId === this.vehicle_local_id) return;

      this.vehicle = comparison.vehicle;
      await this.planTrip();
    }
  }

  /**
   * Converts the current trip to a comparison and adds it to the list of comparisons.
   * If any of the required properties (`vehicle_data`, `vehicle_external_id`, `vehicle_local_id`)
   * are missing, the function returns early without making any changes.
   *
   * @return {void} This function does not return anything.
   */
  public async currentTripToComparison() {
    // Check if the required properties are present
    if (
      !this.vehicle_data ||
      !this.vehicle_external_id ||
      !this.vehicle_local_id
    ) {
      return;
    }

    // check if itinerary is valid
    if (!this.itinerary.isValid) {
      // attempt to fetch itinerary data
      const res = await this._fetchValhallaTrip(this.vehicle_data);
      if (!res || !res.isValid) return;
      this.itinerary = res;
      this.status = "unplanned"; // prevent this from stopping further planning attempts this session
    }

    // Create a comparison object
    const comparison: TripComparison = new TripComparison({
      vehicle: this.vehicle_data,
      energyData: [
        {
          Energy: this.itinerary.totalEnergyUsed,
          Distance: this.itinerary.totalDrivingDistance,
          Time: this.itinerary.totalTravelTime,
          Status: "OK",
        },
      ],
    });
    // Add the comparison to the list of comparisons
    this.comparisons.push(comparison);

    // Set the index of the displayed comparison to the last comparison in the list
    this.displayedComparisonId = comparison.localId;
  }

  public async quickPlan(force_recalculate = false) {
    if (force_recalculate || !this.itinerary.isValid) {
      // attempt to fetch itinerary data
      const res = await this._fetchValhallaTrip(this.vehicle_data);
      if (!res || !res.isValid) return;
      this.itinerary = res;
      this.status = "unplanned"; // prevent this from stopping further planning attempts this session
    }
  }
}
