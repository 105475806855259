<template>
  <v-card flat class="background">
    <v-card-title>Saved Trips</v-card-title>
    <v-card-text>
      <v-slide-group>
        <v-slide-item v-for="(trip, index) in savedTrips" :key="index">
          <v-card
            style="max-width: 200px"
            class="mb-2 mr-2 pa-5 rounded-lg background"
            @click="showTrip(trip)"
          >
            <v-card-title class="text-truncate">
              {{ trip.name }}
            </v-card-title>
            <v-card-subtitle v-if="trip.itinerary.destination">
              Trip to {{ trip.itinerary.destination.name }}
            </v-card-subtitle>
            <v-card-text>
              <p class="mb-0">{{ niceDuration(trip.itinerary.totalTime) }}</p>
              <p class="mb-0">
                {{ trip.chargingStopCDBIDs.length }} charging stops
              </p>
            </v-card-text>
          </v-card>
        </v-slide-item>
        <v-slide-item v-if="$vuetify.breakpoint.smAndDown">
          <div
            class="d-flex flex-column align-center justify-center"
            style="width: 150px; height: 100%; min-height: 200px"
          >
            <v-card
              class="d-flex align-center justify-center rounded-lg background"
              width="100px"
              height="100px"
              @click="showSavedTrips()"
            >
              <span class="primary--text text-underline">view all</span>
            </v-card>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import { RouteNames } from "@/logic/router";
import { ActionTypes, GettersTypes } from "@/logic/store/store_types";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import Vue from "vue";
export default Vue.extend({
  name: "SavedTripCarousel",
  computed: {
    savedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.savedTrips];
    },
  },
  methods: {
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
    showTrip(trip: Trip) {
      this.$store.dispatch(ActionTypes.showTrip, trip);
      this.$emit("view-trip");
    },
    showSavedTrips() {
      this.$router.push({ name: RouteNames.saved });
      this.$emit("view-trip");
    },
  },
});
</script>
