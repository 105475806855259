import { type CollectionNames } from "../../clients/directusClient";
import {
  cleanOutSystemGeneratedProperties,
  directusCreateOne,
  directusDeleteOne,
  directusReadByQuery,
  directusUpdateOne,
} from "./helperFunctions";
import { PartialItem } from "@directus/sdk";

const collectionName: CollectionNames = "Saved_Optimised_Trips";

/**
 * Fetches all saved optimised trips from directus for the given user.
 *
 * @param userID the UUID for the directus user.
 * @returns a list of saved optimised trips if successful, undefined if not.
 */
export async function fetchSavedOptimisedTripsByUser(
  userID: string
): Promise<Directus_SavedOptimisedTrip[] | undefined> {
  return await directusReadByQuery<Directus_SavedOptimisedTrip>(
    collectionName,
    {
      filter: {
        user_created: { _eq: userID },
      },
    }
  );
}

/**
 * Create a new directus `Saved_Optimised_Trips` collection record.
 *
 * @param saveData partial object to create
 * @returns the newly created record if successful, undefined if not.
 */
export async function createSavedOptimisedTrip(
  saveData: PartialItem<Directus_SavedOptimisedTrip>
): Promise<Directus_SavedOptimisedTrip | undefined> {
  return await directusCreateOne<Directus_SavedOptimisedTrip>(
    collectionName,
    saveData
  );
}

/**
 * Updates an existing directus `Saved_Optimised_Trips` collection record.
 *
 * @param recordId the directus `Saved_Optimised_Trips` collection record id.
 * @param data partial object to update
 * @returns the updates record if successful, undefined if not.
 */
export async function updatedSavedOptimisedTrip(
  recordId: string | number,
  data: PartialItem<Directus_SavedOptimisedTrip>
): Promise<Directus_SavedOptimisedTrip | undefined> {
  // Ensure no system generate properties that should be only auto updated are included.
  const preppedData =
    cleanOutSystemGeneratedProperties<Directus_SavedOptimisedTrip>(data);

  // check there is still something to update. Exit early if not.
  if (!Object.keys(preppedData).length) return;

  // update directus record.
  return await directusUpdateOne<Directus_SavedOptimisedTrip>(
    collectionName,
    recordId,
    preppedData
  );
}

/**
 * Delete the target record from the directus `Saved_Optimised_Trips`
 * collection.
 *
 * @param directusId the record id of the target record.
 * @returns status message outlining the outcome of the operation.
 */
export async function deleteSavedOptimisedTrip(
  directusId: string | number
): Promise<"ok" | "failed"> {
  return await directusDeleteOne(collectionName, directusId);
}

export interface Directus_SavedOptimisedTrip {
  /** Saved_Optimised_Trips collection record id. */
  id: number;
  sort: number | null;
  /** Related UUID for user that created this booking. */
  user_created: string; // related UUID
  /** The DateTime string for when this booking was created. */
  date_created: string; // DateTime
  /** Related UUID for user that updated this booking. */
  user_updated: string | null; // related UUID
  /** The DateTime string for when this booking was updated. */
  date_updated: string | null; // DateTime
  /** List of locations for this trip */
  Locations: Directus_SavedOptimisedTrip_Location[];
  /** The Valhalla costing model used to plan this trip */
  Costing_Model: "auto" | "truck" | "motorcycle" | "bicycle";
  /** The optional display name of the trip */
  Name: string | null;
  /** round trip flag */
  isRoundTrip: boolean | null;
  /** keep user provided order of stops flag */
  keepOrder: boolean | null;
}

export interface Directus_SavedOptimisedTrip_Location {
  /** Address for this location in a displayable string  */
  address: string;
  /** Latitude of the point in decimal degree. Example: 50.770774. */
  latitude: number;
  /** Longitude of the point in decimal degree. Example: -126.104965. */
  longitude: number;
}
