<template>
  <v-form ref="favLocationForm" v-if="!status">
    <v-card-text class="pb-6 px-6">
      <p class="grey--text h6">Location Details</p>
      <v-text-field
        label="Name"
        v-model="name"
        clearable
        :error-messages="nameErrorMsg"
        @change="validateName"
      />
      <AddressAutocompleteInput
        :initialValue="address"
        @update="updateAddress"
        id="favLocationAddress"
        :errorMsg="addressErrorMsg"
        class="mb-5"
        label="Address"
      />
      <v-select
        v-model="locationType"
        label="Location Type"
        :items="['Work', 'Home', 'Depot', 'Other']"
      ></v-select>
      <v-select
        v-model="visibility"
        lang="Visibility"
        :items="['private', 'group', 'fleet']"
      ></v-select>
      <p class="grey--text h6">Trip Planning Defaults</p>
      <v-text-field
        v-model="weightChange"
        label="load Weight Change"
        suffix="kg"
        type="number"
      ></v-text-field>
      <v-checkbox label="charge here" v-model="chargeHere"></v-checkbox>
      <v-row no-gutters>
        <v-text-field
          v-model="rating"
          label="rating"
          :disabled="!chargeHere"
          type="number"
          class="mr-5"
          :error-messages="ratingErrorMsg"
        ></v-text-field>
        <v-select
          v-model="currentType"
          label="current type"
          :items="['AC', 'DC']"
          :disabled="!chargeHere"
          :error-messages="currentTypeErrorMsg"
        ></v-select>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-6">
      <ElevatedBlockBtn @click="saveFavLocation">
        Save as favourite Location
      </ElevatedBlockBtn>
    </v-card-actions>
  </v-form>
  <v-alert type="success" v-else-if="status === 'SUCCESS'" class="mx-2">
    Favourite location was created successfully and saved to the database
  </v-alert>
  <v-alert type="error" v-else-if="status === 'FAILED'" class="mx-2">
    Favourite location was created locally but failed to be saved to the
    database
  </v-alert>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../../ui-elements/inputs/AddressAutocompleteInput.vue";
import FavouriteLocation, {
  PlanningData,
} from "@/logic/classes/favouriteLocation";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import ElevatedBlockBtn from "../../ui-elements/buttons/ElevatedBlockBtn.vue";
import { MutationTypes } from "@/logic/store/store_types";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
export default Vue.extend({
  name: "FavLocationForm",
  components: { AddressAutocompleteInput, ElevatedBlockBtn },
  props: {
    initialValue: {
      type: Object as PropType<FavouriteLocation | undefined>,
      required: false,
    },
  },
  data() {
    return {
      status: null as "PROCESSING" | "SUCCESS" | "FAILED" | null,
      // form data
      name: undefined as string | null | undefined,
      nameErrorMsg: null as string | null,
      address: undefined as processedAddressObj | undefined,
      addressErrorMsg: null as string | null,
      chargeHere: false,
      rating: null as number | string | null, // This version of vuetify emits stringified numbers or empty strings for number type inputs.
      ratingErrorMsg: null as string | null,
      currentType: null as "AC" | "DC" | null,
      currentTypeErrorMsg: null as string | null,
      weightChange: null as number | string | null, // This version of vuetify emits stringified numbers or empty strings for number type inputs.
      locationType: "Home" as "Work" | "Home" | "Depot" | "Other",
      visibility: "private" as "private" | "group" | "fleet",
    };
  },
  mounted() {
    if (this.initialValue) {
      this.name = this.initialValue?.name;
      this.address = {
        address: this.initialValue.address ?? "Unknown Address",
        coordinates: this.initialValue.coordinates.asCapitalizedObj,
      };
      this.chargeHere = this.initialValue.planningData?.chargeHere ?? false;
      this.rating = this.initialValue.planningData?.rating ?? null;
      this.weightChange =
        this.initialValue.planningData?.loadWeightChange ?? null;
      this.currentType = this.initialValue.planningData?.currentType ?? null;
    }
  },
  beforeDestroy() {
    this.name = undefined;
    this.nameErrorMsg = null;
    this.address = undefined;
    this.addressErrorMsg = null;
    this.chargeHere = false;
    this.rating = null;
    this.ratingErrorMsg = null;
    this.currentType = null;
    this.currentTypeErrorMsg = null;
    this.weightChange = null;
    this.status = null;
  },
  methods: {
    /**
     * Validates the name input and sets an error message if the name is empty.
     *
     * @return {boolean} Returns true if the name is not empty, false otherwise.
     */
    validateName(): boolean {
      this.nameErrorMsg = null;
      if (!this.name) {
        this.nameErrorMsg = "Please enter a name";
      }
      return !!this.name;
    },
    /**
     * Validates the address input and sets an error message if the address is empty.
     *
     * @return {boolean} Returns true if the address is not empty, false otherwise.
     */
    validateAddress(): boolean {
      this.addressErrorMsg = null;
      if (!this.address) {
        this.addressErrorMsg = "Please enter an address";
      }
      return !!this.address;
    },
    /**
     * Validates the rating based on the charge selection.
     *
     * @return {boolean} Returns true if the rating is provided, false otherwise.
     */
    validateRating(): boolean {
      this.ratingErrorMsg = null;
      if (!this.chargeHere)
        true; // rating is not required if charge here is not selected.
      else if (!this.rating) {
        this.ratingErrorMsg = "Please enter a rating if charging here";
      } else if (parseIntOrFloat(this.rating) == undefined) {
        this.ratingErrorMsg = "Rating must be a number";
      }
      return !!this.rating;
    },
    /**
     * A description of the entire function.
     *
     * @return {boolean} description of return value
     */
    validateCurrentType(): boolean {
      this.currentTypeErrorMsg = null;
      if (!this.chargeHere)
        true; // current type is not required if charge here is not selected.
      else if (!this.currentType) {
        this.currentTypeErrorMsg =
          "Please enter a current type if charging here";
      } else if (!["AC", "DC"].includes(this.currentType)) {
        this.currentTypeErrorMsg = "Current type must be AC or DC";
        return false;
      }
      return !!this.currentType;
    },
    /**
     * Saves the favourite location after validating the name and address inputs.
     *
     * @return {Promise<void>} This function does not return anything.
     */
    async saveFavLocation(): Promise<void> {
      this.status = "PROCESSING";
      let isValid = false;
      isValid = this.validateName();
      isValid = this.validateAddress();
      isValid = this.validateRating();
      isValid = this.validateCurrentType();
      if (!isValid) {
        this.status = null;
        return;
      }
      let newFavLocation: FavouriteLocation | undefined = undefined;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const newFormattedName = this.name!.trim(); // already validated above
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const newFormattedAddress = this.address!.address; // already validated above
      const newLatitude = this.address?.coordinates.Latitude;
      const newLongitude = this.address?.coordinates.Longitude;
      const newCoordinates =
        newLatitude && newLongitude
          ? new Coordinate({ latitude: newLatitude, longitude: newLongitude })
          : undefined;
      if (!newCoordinates) {
        return;
      }

      let newPlanningData: PlanningData | undefined = undefined;
      // add charging data if needed
      if (this.chargeHere) {
        // assumes that if charge here is true, then rating is also true
        newPlanningData = {
          chargeHere: this.chargeHere,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          rating: parseIntOrFloat(this.rating!), // already validated above
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          currentType: this.currentType!, // already validated above
        };
      }

      if (this.weightChange) {
        if (newPlanningData) {
          newPlanningData.loadWeightChange = parseIntOrFloat(this.weightChange);
        } else {
          newPlanningData = {
            loadWeightChange: parseIntOrFloat(this.weightChange),
          };
        }
      }

      if (this.initialValue && newCoordinates) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        newFavLocation = this.initialValue!;
        newFavLocation.name = newFormattedName;
        newFavLocation.address = newFormattedAddress;
        newFavLocation.coordinates = newCoordinates;
        newFavLocation.planningData = newPlanningData;
        newFavLocation.type = this.locationType;
        newFavLocation.visibility = this.visibility;
      } else {
        newFavLocation = new FavouriteLocation({
          name: newFormattedName,
          address: newFormattedAddress,
          coordinates: newCoordinates,
          planningData: newPlanningData,
          type: this.locationType,
          visibility: this.visibility,
        });
      }

      if (newFavLocation) {
        this.$store.commit(
          MutationTypes.updateIndividualFavLocation,
          newFavLocation
        );

        const saveRes = await newFavLocation.saveFavouriteLocation();
        if (saveRes === "success") {
          this.status = "SUCCESS";
        } else {
          this.status = "FAILED";
        }
      } else {
        this.status = "FAILED";
      }

      this.closeAndReset();
    },
    /**
     * Updates the address based on the input data and validates the address.
     *
     * @param {AddressAutocompleteInputUpdateObj} address - The updated address data.
     * @return {void} This function does not return anything.
     */
    updateAddress(address: AddressAutocompleteInputUpdateObj): void {
      this.address = address.addressData ?? undefined;
      this.validateAddress();
    },
    /**
     * Resets the name, address, and error messages to default values and emits a close event after a delay.
     *
     * @return {void} This function does not return anything.
     */
    closeAndReset(): void {
      this.name = undefined;
      this.nameErrorMsg = null;
      this.address = undefined;
      this.addressErrorMsg = null;
      this.chargeHere = false;
      this.rating = null;
      this.ratingErrorMsg = null;
      this.currentType = null;
      this.currentTypeErrorMsg = null;
      this.weightChange = null;
      setTimeout(() => {
        this.$emit("close");
        this.status = null;
      }, 1000);
    },
  },
});
</script>
