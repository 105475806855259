var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.trip &&
    _vm.trip.fallbackTripData &&
    _vm.trip.parameters &&
    _vm.trip.locations &&
    _vm.isEVNavFallback
  )?_c('v-card',{staticClass:"px-5 mb-5",attrs:{"flat":""}},[_c('ItineraryHeader',{attrs:{"trip":_vm.trip}}),_c('WhatWentWrongCard',{attrs:{"trip":_vm.trip}}),_c('FallbackDepartureCard'),_c('FallbackTravelSection'),_c('FallbackArrivalCard')],1):(
    _vm.trip &&
    _vm.trip.fallbackTripData &&
    _vm.trip.parameters &&
    _vm.trip.locations &&
    !_vm.isEVNavFallback
  )?_c('v-card',{staticClass:"px-5 mb-5",attrs:{"flat":""}},[_c('ItineraryHeader',{attrs:{"trip":_vm.trip}}),_c('WhatWentWrongCard',{attrs:{"trip":_vm.trip}}),_c('ItineraryValhallaFallbackContent',{attrs:{"trip":_vm.trip}})],1):_c('v-card',[_c('v-card-title',[_vm._v("Whoops! something went wrong!")]),_c('v-card-text',[_vm._v(" We appear to be having issues loading this trip please unselect the trip and try again. If this continues please contact support. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }