export enum QuickTripCheckReturn {
  unconnected_regions = "unconnected_regions",
  not_routable = "not_routable",
  routable = "routable",
}

export interface Valhalla_Location {
  lat: number;
  lon: number;
}

export type Valhalla_CostingModel = "auto" | "truck" | "motorcycle" | "bicycle";

export interface Valhalla_RouteError {
  error_code: number;
  error: string;
  status_code: number;
  status: string;
}
export interface Valhalla_RouteRes {
  trip: Valhalla_Trip;
}

export interface Valhalla_Trip {
  locations: Valhalla_DetailedLocation[];
  legs: Valhalla_Leg[];
  summary: Valhalla_Summary;
  status_message: string;
  status: number;
  units: string;
  language: string;
}

export interface Valhalla_Leg {
  maneuvers: Valhalla_Maneuver[];
  summary: Valhalla_Summary;
  shape: string; // polyline
}

export interface Valhalla_Maneuver {
  type: number;
  instruction: string;
  verbal_succinct_transition_instruction?: string;
  verbal_pre_transition_instruction: string;
  verbal_post_transition_instruction?: string;
  street_names?: string[];
  time: number;
  /** Distance in kilometers */
  length: number;
  cost: number;
  begin_shape_index: number;
  end_shape_index: number;
  verbal_multi_cue?: boolean;
  travel_mode: Valhalla_TravelMode;
  travel_type: Valhalla_TravelType;
  verbal_transition_alert_instruction?: string;
  roundabout_exit_count?: number;
  highway?: boolean;
  sign?: Valhalla_Sign;
  ferry?: boolean;
}

export interface Valhalla_Sign {
  exit_branch_elements?: Valhalla_ExitElement[];
  exit_toward_elements?: Valhalla_ExitElement[];
  exit_name_elements?: Valhalla_ExitNameElement[];
}

export interface Valhalla_ExitElement {
  text: string;
  consecutive_count?: number;
}

export interface Valhalla_ExitNameElement {
  text: string;
}

export enum Valhalla_TravelMode {
  Drive = "drive",
}

export enum Valhalla_TravelType {
  TractorTrailer = "tractor_trailer",
  Ferry = "ferry",
}

export interface Valhalla_Summary {
  has_time_restrictions: boolean;
  has_toll: boolean;
  has_highway: boolean;
  has_ferry: boolean;
  min_lat: number;
  min_lon: number;
  max_lat: number;
  max_lon: number;
  time: number;
  /** Distance in kilometers */
  length: number;
  cost: number;
}

export interface Valhalla_DetailedLocation {
  type: string;
  lat: number;
  lon: number;
  original_index: number;
  side_of_street?: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toValhallaOptimizedRouteRes(json: string): Valhalla_RouteRes {
    return JSON.parse(json);
  }

  public static valhallaOptimizedRouteResToJson(
    value: Valhalla_RouteRes
  ): string {
    return JSON.stringify(value);
  }
}
