<template>
  <v-card flat v-if="loading" class="px-16 pb-16 pt-8">
    <v-card-text class="primary--text text-center text-h5">
      Power trip is planning your trip
    </v-card-text>
    <v-card-text class="d-flex justify-center primary--text text-center">
      <LoadingCard />
    </v-card-text>
    <v-card-text class="primary--text text-center text-subtitle-2">
      This may take a few moments
    </v-card-text>
  </v-card>

  <v-card flat class="px-5 pb-5" v-else>
    <v-card-title class="tertiary--text"> Plan a new trip </v-card-title>
    <!-- starting location section -->
    <v-card
      class="rounded-lg py-3 pr-5"
      :key="'location-start' + locations[0].address"
    >
      <v-row no-gutters>
        <v-col
          cols="1"
          class="align-center col col-1 d-flex flex-column pt-2 pl-4"
        >
          <div
            class="rounded-circle primary"
            style="height: 18px; width: 18px"
          ></div>
          <v-icon
            color="primary"
            @click="
              handleMoveLocation({
                id: locations[0].localId,
                oldIndex: 0,
                newIndex: 1,
              })
            "
          >
            mdi-arrow-down-bold
          </v-icon>
        </v-col>
        <v-col cols="11">
          <v-card-text class="py-0">
            <AddressAutocompleteInput
              v-if="!useCarLastKnownLocation"
              :id="locations[0].localId"
              label="Starting from"
              @update="handleAddressChange"
              :initialValue="{
                address: locations[0].address,
                waypoint: locations[0].coordinates,
              }"
              :errorMsg="addressErrors"
              :loading="addressLoading"
              :geoLocation="anchorGeoLocation"
              :allowFavLocations="true"
            />
            <v-switch
              v-if="selectedVehicleData"
              v-model="useCarLastKnownLocation"
              inset
              :label="
                hasNoLastKnownLocation
                  ? 'Your selected EV has no last known location'
                  : 'Use your EV last known location and charge'
              "
              color="primary"
              @change="handleUseCarToggle"
              :disabled="hasNoLastKnownLocation"
            />
            <SOCSlider
              :initialValue="startingCharge"
              :identifier="locations[0].localId"
              label="Departing charge"
              @update="handleSOCChange"
            />
            <!-- more details section -->
            <v-expansion-panels accordion v-model="startExpanded" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="primary--text px-0">{{
                  startExpanded === 0 ? "Less details" : "Add more details"
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="ml-n6 mr-n6">
                  <TimePickerInput
                    :identifier="locations[0].localId"
                    label="Depart at"
                    @update="handleTimeChange"
                    :initialValue="locations[0].time"
                  />
                  <DatePickerInput
                    :identifier="locations[0].localId"
                    label="Departing date"
                    @update="handleDateChange"
                    :initialValue="locations[0].date"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <!-- additional stop locations section -->
    <v-row no-gutters>
      <v-col cols="1">
        <v-divider vertical class="ml-7"></v-divider>
      </v-col>
      <v-col cols="11" class="pb-3">
        <v-row no-gutters class="pt-3">
          <TextBlockBtn class="mr-1" @click="handleAddWaypoint(1)">
            Add a stop
          </TextBlockBtn>
        </v-row>
        <template v-for="(location, index) in locations">
          <AdditionalWaypointCard
            v-if="index !== 0 && index !== locations.length - 1"
            :key="location.localId + location.address"
            :locationData="location"
            :index="index"
            @remove="handleRemoveWaypoint"
            @add="handleAddWaypoint"
            @update="handleUpdateWaypoint"
            @move="handleMoveLocation"
            :errorMsg="addressErrors"
            :loading="addressLoading"
            :geoLocation="anchorGeoLocation"
          />
        </template>
      </v-col>
    </v-row>

    <!-- destination location section -->
    <v-card
      class="rounded-lg primary white--text py-3"
      :key="'location-end' + locations[locations.length - 1].address"
    >
      <v-row no-gutters>
        <v-col
          cols="1"
          class="align-center col col-1 d-flex flex-column pt-2 pl-4"
        >
          <v-icon
            color="white"
            @click="
              handleMoveLocation({
                id: locations[locations.length - 1].localId,
                oldIndex: locations.length - 1,
                newIndex: locations.length - 2,
              })
            "
          >
            mdi-arrow-up-bold
          </v-icon>
          <div
            class="rounded-circle white"
            style="height: 18px; width: 18px"
          ></div>
        </v-col>
        <v-col cols="11">
          <v-card-text class="py-0 pr-9">
            <AddressAutocompleteInput
              :id="locations[locations.length - 1].localId"
              label="Final destination"
              @update="handleAddressChange"
              dark
              :initialValue="{
                address: locations[locations.length - 1].address,
                waypoint: locations[locations.length - 1].coordinates,
              }"
              :errorMsg="addressErrors"
              :loading="addressLoading"
              :geoLocation="anchorGeoLocation"
              :allowFavLocations="true"
            />
            <SOCSlider
              :initialValue="finalCharge"
              :identifier="locations[locations.length - 1].localId"
              label="Minimum charge on arrival"
              @update="handleSOCChange"
              dark
            />
            <!-- more details section -->
            <v-expansion-panels
              accordion
              class="primary"
              v-model="endExpanded"
              dark
              flat
            >
              <v-expansion-panel class="primary">
                <v-expansion-panel-header class="px-0">{{
                  endExpanded === 0 ? "Less details" : "Add more details"
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="ml-n6 mr-n6">
                  <TimePickerInput
                    :identifier="locations[locations.length - 1].localId"
                    label="Arrive at"
                    @update="handleTimeChange"
                    dark
                    :initialValue="locations[locations.length - 1].time"
                  />
                  <DatePickerInput
                    :identifier="locations[locations.length - 1].localId"
                    label="Arrival date"
                    @update="handleDateChange"
                    dark
                    :initialValue="locations[locations.length - 1].date"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <!-- frequency and actions section -->
    <v-row no-gutters>
      <v-col cols="1">
        <v-divider vertical class="ml-7"></v-divider>
      </v-col>
      <v-col cols="11"> </v-col>
    </v-row>

    <v-card flat>
      <v-card-text class="pb-0">
        Add this trip to your list of frequent trips to calculate your long-term
        savings
      </v-card-text>
      <FrequencySelectInput @update="handleFrequencyChange" />
      <PrimaryTimeSelectContent
        :locationsWithTime="locationsWithTime"
        :showDialog="showPrimaryTimeSelectDialog"
        @update="handlePrimaryTimeSelect"
      />
      <ElevatedBlockBtn
        class="mb-3"
        @click="planTrip"
        :disabled="preventPlaning || addressLoading"
        :loading="addressLoading"
      >
        Plan trip
      </ElevatedBlockBtn>
      <OutlinedBlockBtn @click="cancel"> Cancel </OutlinedBlockBtn>
      <v-card-text
        v-if="!!errorMsg || !!addressErrors"
        class="error--text pt-1"
      >
        Whoops! {{ errorMsg ?? addressErrors }}
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import AdditionalWaypointCard from "./AdditionalWaypointCard.vue";
import TimePickerInput, {
  TimePickerInputUpdateObj,
} from "../../../ui-elements/inputs/TimePickerInput.vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../../../ui-elements/inputs/AddressAutocompleteInput.vue";
import SOCSlider, {
  SOCSliderUpdateObj,
} from "../../../ui-elements/inputs/SOCSlider.vue";
import DatePickerInput, {
  DatePickerInputUpdateObj,
} from "../../../ui-elements/inputs/DatePickerInput.vue";
import PrimaryTimeSelectContent from "../dialog-content/PrimaryTimeSelectContent.vue";
import FrequencySelectInput from "../../../ui-elements/inputs/FrequencySelectInput.vue";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import type {
  TripFrequency,
  TripPlanningFormData,
} from "@/logic/types/trip_specific_types";
import LoadingCard from "../../../ui-elements/loaders/LoadingCard.vue";
import { ActionTypes, MutationTypes, State } from "@/logic/store/store_types";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { quickTripCheck } from "@/logic/api/calls/valhalla_calls";
import { QuickTripCheckReturn } from "@/logic/types/valhalla_types";
import TextBlockBtn from "@/ui/components/ui-elements/buttons/TextBlockBtn.vue";
import ElevatedBlockBtn from "@/ui/components/ui-elements/buttons/ElevatedBlockBtn.vue";
import OutlinedBlockBtn from "@/ui/components/ui-elements/buttons/OutlinedBlockBtn.vue";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import FavouriteLocation from "@/logic/classes/favouriteLocation";

export default Vue.extend({
  name: "TripPlanningForm",
  data() {
    return {
      locations: [new TripLocation({}), new TripLocation({})] as TripLocation[],
      frequency: undefined as TripFrequency | undefined,
      primaryTime: null as string | undefined | null,
      errorMsg: null as string | null,
      showPrimaryTimeSelectDialog: false,
      startExpanded: undefined as undefined | number,
      endExpanded: undefined as undefined | number,
      useCarLastKnownLocation: false,
      hasNoLastKnownLocation: false,
      addressErrors: null as string | null,
      addressLoading: false,
      preventPlaning: false,
    };
  },
  components: {
    AdditionalWaypointCard,
    TimePickerInput,
    AddressAutocompleteInput,
    FrequencySelectInput,
    SOCSlider,
    DatePickerInput,
    PrimaryTimeSelectContent,
    LoadingCard,
    TextBlockBtn,
    ElevatedBlockBtn,
    OutlinedBlockBtn,
  },
  methods: {
    /**
     * Initializes the form by setting default values for the locations, error message, frequency,
     * primary time, and other relevant properties.
     *
     * @return {void} This function does not return anything.
     */
    prepForm(): void {
      this.locations = [new TripLocation({}), new TripLocation({})];
      this.errorMsg = null;
      this.frequency = undefined;
      this.primaryTime = null;
      this.showPrimaryTimeSelectDialog = false;
      this.useCarLastKnownLocation = false;
      this.hasNoLastKnownLocation = false;
    },
    /**
     * Finds the location based on the selected vehicle data.
     *
     * This function checks if the selected vehicle data has latitude and longitude values.
     * If it does, it updates the first location in the `locations` array with the address "EV last known location"
     * and the corresponding coordinates. It also updates the state of charge in the store if the selected vehicle data
     * has a state of charge value.
     *
     * If the selected vehicle data does not have latitude and longitude values, it sets the `useCarLastKnownLocation`
     * flag to false and the `hasNoLastKnownLocation` flag to true.
     *
     * @return {void} This function does not return a value.
     */
    findLocation(): void {
      if (
        this.selectedVehicleData &&
        this.selectedVehicleData.latitude &&
        this.selectedVehicleData.longitude
      ) {
        this.locations[0] = new TripLocation({
          ...this.locations[0],
          address: "EV last known location",
          coordinates: new Coordinate({
            latitude: this.selectedVehicleData.latitude,
            longitude: this.selectedVehicleData.longitude,
          }),
        });
        if ((this.selectedVehicleData as Vehicle).stateOfCharge) {
          this.$store.commit(
            MutationTypes.setSOCAct,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (this.selectedVehicleData as Vehicle).stateOfCharge! / 100
          );
        }
        return;
      }
      this.useCarLastKnownLocation = false;
      this.hasNoLastKnownLocation = true;
    },
    /**
     * Handles toggling the use of car based on the provided value.
     *
     * @param {boolean} val - The value indicating whether to use the car.
     * @return {void} This function does not return a value.
     */
    handleUseCarToggle(val: boolean): void {
      if (val) {
        this.findLocation();
      } else {
        this.locations[0] = new TripLocation({});
      }
      this.emitDirty();
    },
    /**
     * Adds a waypoint to the `locations` array at the specified index.
     * Notifies the analytics server about the addition.
     *
     * @param {number} insertAtIndex - The index at which to insert the waypoint.
     * @return {void} This function does not return anything.
     */
    handleAddWaypoint(insertAtIndex: number): void {
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Waypoint added",
          count: 1,
        },
      ]);
      if (insertAtIndex === this.locations.length) {
        this.locations.push(new TripLocation({}));
      } else {
        this.locations.splice(insertAtIndex, 0, new TripLocation({}));
      }
      this.emitDirty();
    },
    /**
     * Removes a waypoint from the `locations` array based on the provided `waypointId`.
     * Notifies the analytics server about the removal.
     *
     * @param {string} waypointId - The ID of the waypoint to remove.
     * @return {void} This function does not return anything.
     */
    handleRemoveWaypoint(waypointId: string): void {
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Waypoint Removed",
          count: 1,
        },
      ]);

      this.locations = this.locations.filter(
        (location) => location.localId !== waypointId
      );
      this.emitDirty();
    },
    /**
     * Moves a location in the `locations` array to a new index.
     *
     * @param {Object} options - The options object.
     * @param {string} options.id - The ID of the location to move.
     * @param {number} options.oldIndex - The current index of the location.
     * @param {number} options.newIndex - The new index to move the location to.
     * @return {void} This function does not return a value.
     */
    handleMoveLocation({
      id,
      oldIndex,
      newIndex,
    }: {
      id: string;
      oldIndex: number;
      newIndex: number;
    }): void {
      const location = this.locations.find(
        (location) => location.localId === id
      );
      // not undefined guard clause.
      if (!location) return;

      this.locations = TripLocation.reOrderTripLocations(
        this.locations,
        oldIndex,
        newIndex
      );
      this.emitDirty();
      return;
    },
    /**
     * Updates a waypoint in the list of locations with the provided location data.
     *
     * @param {TripLocation} locationData - The updated location data for the waypoint.
     * @return {void} This function does not return a value.
     */
    handleUpdateWaypoint(locationData: TripLocation): void {
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === locationData.localId
      );
      if (locationIndex === -1) return;
      this.locations.splice(locationIndex, 1, locationData);
      this.emitDirty();
    },
    /**
     * Handles the change event of the address input field.
     *
     * @param {AddressAutocompleteInputUpdateObj} val - The updated value of the address input field.
     * @return {void} This function does not return anything.
     */
    handleAddressChange(val: AddressAutocompleteInputUpdateObj): void {
      // not null guard clause
      if (!val.addressData) return;
      // find location to update
      const location = this.locations.find(
        (location) => location.localId === val.id
      );
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === val.id
      );
      // find operation failed guard clause
      if (!location) return;
      if (locationIndex === -1) return;
      // create new object
      const tempObj: TripLocation = new TripLocation({
        ...location,
        address: val.addressData.address,
        coordinates: new Coordinate({
          latitude: val.addressData.coordinates.Latitude,
          longitude: val.addressData.coordinates.Longitude,
        }),
      });

      // update local state
      this.locations.splice(locationIndex, 1, tempObj);
      this.emitDirty();
    },
    /**
     * A description of the entire function.
     *
     * @param {TimePickerInputUpdateObj} val - The object containing the time update information.
     * @return {void} This function does not return a value.
     */
    handleTimeChange(val: TimePickerInputUpdateObj): void {
      // find location to update
      const location = this.locations.find(
        (location) => location.localId === val.identifier
      );
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );
      // find operation failed guard clause
      if (!location) return;
      if (locationIndex === -1) return;

      // create new object
      const tempObj: TripLocation = new TripLocation({
        ...location,
        time: val.time,
      });

      // update local state
      this.locations.splice(locationIndex, 1, tempObj);
      this.emitDirty();
    },
    /**
     * Updates the date of a location based on the provided identifier and date value.
     *
     * @param {DatePickerInputUpdateObj} val - The object containing the identifier and date value.
     * @return {void} This function does not return a value.
     */
    handleDateChange(val: DatePickerInputUpdateObj): void {
      // find location to update
      const location = this.locations.find(
        (location) => location.localId === val.identifier
      );
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );
      // find operation failed guard clause
      if (!location) return;
      if (locationIndex === -1) return;

      // create new object
      const tempObj: TripLocation = new TripLocation({
        ...location,
        date: val.date ? val.date : undefined,
      });

      // update local state
      this.locations.splice(locationIndex, 1, tempObj);
      this.emitDirty();
    },
    /**
     * Updates the state of charge of a location based on the provided SOC value.
     *
     * @param {SOCSliderUpdateObj} val - The object containing the SOC value and identifier of the location to update.
     * @return {void} This function does not return anything.
     */
    handleSOCChange(val: SOCSliderUpdateObj): void {
      // not null guard clause
      if (!val.SOC) return;

      // find location to update
      const location = this.locations.find(
        (location) => location.localId === val.identifier
      );
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );
      // find operation failed guard clause
      if (!location) return;
      if (locationIndex === -1) return;
      // create new object
      const tempObj: TripLocation = new TripLocation({
        ...location,
        stateOfChargeAfterCharging: val.SOC,
      });

      // update local state
      this.locations.splice(locationIndex, 1, tempObj);
      this.emitDirty();
      // update global state
      if (val.identifier === this.locations[0].localId) {
        this.$store.commit(MutationTypes.setSOCAct, val.SOC);
      }
      if (
        val.identifier === this.locations[this.locations.length - 1].localId
      ) {
        this.$store.commit(MutationTypes.setSOCEnd, val.SOC);
      }
    },
    /**
     * Handles the change in frequency for the trip planning form.
     *
     * @param {TripFrequency | undefined} val - The new frequency value.
     * @return {void} This function does not return anything.
     */
    handleFrequencyChange(val: TripFrequency | undefined): void {
      this.frequency = val;
      this.emitDirty();
    },
    /**
     * Handles the selection of a primary time for the trip planning form.
     *
     * @param {string | undefined} val - The selected primary time.
     * @return {void} This function does not return anything.
     */
    handlePrimaryTimeSelect(val: string | undefined): void {
      this.showPrimaryTimeSelectDialog = false;
      this.primaryTime = val;
      this.planTrip();
    },
    /**
     * Validates the trip planning form and creates a new trip if the form is valid.
     *
     * @return {void} This function does not return anything.
     */
    planTrip(): void {
      // clear error messages
      this.errorMsg = null;

      // validation
      const startLocation = this.locations[0];
      const hasStartLocation = !!startLocation;
      const startLocationHasAddress = !!startLocation?.address;
      const startLocationHasCoordinates = !!(
        !!startLocation?.coordinates.latitude &&
        !!startLocation?.coordinates.longitude
      );

      if (
        startLocation &&
        hasStartLocation &&
        startLocationHasAddress &&
        startLocationHasCoordinates
      ) {
        // do nothing as it passed validation.
      } else {
        // error start address failed validation.
        this.errorMsg = "Please add a starting location";
        return;
      }

      const destinationLocation = this.locations[this.locations.length - 1];
      const hasDestinationLocation = !!destinationLocation;
      const destinationLocationHasAddress = !!destinationLocation?.address;
      const destinationLocationHasCoordinates = !!(
        !!destinationLocation?.coordinates.latitude &&
        !!destinationLocation?.coordinates.longitude
      );

      if (
        destinationLocation &&
        hasDestinationLocation &&
        destinationLocationHasAddress &&
        destinationLocationHasCoordinates
      ) {
        // do nothing as it passed validation.
      } else {
        // error destination address failed validation.
        this.errorMsg = "Please add a destination location";
        return;
      }

      const locationsWithTime = this.locations.filter(
        (location) => location.time
      );

      if (!locationsWithTime.length && this.primaryTime === null) {
        this.primaryTime = undefined;
      }

      if (locationsWithTime.length === 1 && this.primaryTime === null) {
        this.primaryTime = locationsWithTime[0].localId;
      }

      if (locationsWithTime.length > 1 && this.primaryTime === null) {
        // open primary time picker
        this.showPrimaryTimeSelectDialog = true;
        return;
      }

      if (this.primaryTime === null) {
        this.errorMsg = "Please select a primary time";
        return;
      }

      const payload: TripPlanningFormData = {
        locations: this.locations,
        vehicle: this.selectedVehicleData as Vehicle,
        frequency: this.frequency,
        SOCAct: this.startingCharge,
        SOCEnd: this.finalCharge,
        primaryTimeLocation: this.primaryTime,
      };

      // find fav location
      const favLocation = this.favLocations.find(
        (fav) => fav.localId === this.locations[0].localId
      );
      // add fav location planning data to payload
      if (favLocation?.planningData?.loadWeightChange) {
        payload.extraWeight = favLocation.planningData.loadWeightChange;
      }

      // plan trip
      this.$store.dispatch(ActionTypes.createNewTrip, payload);

      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "New Trip Planned",
          count: 1,
        },
      ]);

      // clean up from
      this.prepForm();
    },
    cancel(): void {
      // clean up from
      this.prepForm();
      // notify parent
      this.$emit("cancel");
    },
    emitDirty(): void {
      this.$emit("dirty");
    },
  },
  computed: {
    anchorGeoLocation(): Coordinate | undefined {
      const waypoint = this.locations.find((stop) => !stop.isNullIsland);
      if (waypoint) return new Coordinate(waypoint.coordinates);
      return undefined;
    },
    ...mapGetters({
      selectedVehicleData: "selectedVehicleData",
    }),
    ...mapState({
      loading: (state: unknown): boolean => (state as State).routePlanningFlag,
      startingCharge: (state: unknown): number => (state as State).SOCAct,
      finalCharge: (state: unknown): number => (state as State).SOCEnd,
      favLocations: (state: unknown): FavouriteLocation[] =>
        (state as State).favLocations,
    }),
    locationsWithTime(): TripLocation[] {
      return this.locations.filter((location) => location.time);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.prepForm();
    });
  },
  beforeDestroy() {
    this.prepForm();
  },
  watch: {
    async locations(val: TripLocation[]) {
      if (val.length >= 2 && val.every((location) => !location.isNullIsland)) {
        this.addressLoading = true;
        const quickCheckRes = await quickTripCheck(
          val.map((location) => ({
            lat: location.coordinates.latitude,
            lon: location.coordinates.longitude,
          }))
        );
        if (quickCheckRes === QuickTripCheckReturn.routable) {
          // clear address errors as routable.
          this.addressErrors = null;
          this.preventPlaning = false;
        } else if (quickCheckRes === QuickTripCheckReturn.unconnected_regions) {
          this.addressErrors = "locations are in unconnected regions";
          this.preventPlaning = true;
        } else if (quickCheckRes === QuickTripCheckReturn.not_routable) {
          this.addressErrors = "locations are not routable";
          this.preventPlaning = true;
        }
        this.addressLoading = false;
      }
    },
  },
});
</script>
