<template>
  <v-btn
    :color="powerTripDarkBlue"
    block
    text
    :disabled="disabled"
    :loading="loading"
    class="text-none rounded-lg"
    @click="emitClick"
  >
    <slot></slot>
  </v-btn>
</template>
<script lang="ts">
import Vue from "vue";
import { powerTripDarkBlue } from "@/logic/data/const";

export default Vue.extend({
  name: "TextBlockBtn",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
});
</script>
