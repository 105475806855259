<template>
  <v-container
    class="primary lighten-2 white--text pa-0 rounded-0 overflow-hidden"
    fluid
    style="height: 100%; position: relative"
  >
    <l-map
      :zoom="zoom"
      :center="center"
      :options="{ attributionControl: true, zoomControl: false }"
      ref="mainMap"
      :min-zoom="3"
      @update:center="sendCenterToStore"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-marker-cluster
        :options="{
          showCoverageOnHover: false,
        }"
        id="pwt-marker-cluster-main"
      >
        <template v-for="(chargingStation, index) in filteredStations">
          <ChargingPin
            v-if="!!chargingStation.coordinates"
            :key="`charger-pin-${index}-${chargingStation.id}`"
            :chargingStation="chargingStation"
            :isChargingStop="getIsChargingStop(chargingStation.id)"
          />
        </template>
      </l-marker-cluster>
      <GradientPolyline
        v-for="(polyline, index) in polylineList"
        :key="`gradient-polyline-${index}`"
        :latLngList="polyline"
        :startCharge="
          chargeData.length ? chargeData[index].StartCharge : undefined
        "
        :endCharge="chargeData.length ? chargeData[index].EndCharge : undefined"
        :hasNoChargeData="!chargeData.length"
        :stepIndex="index"
        @closePopups="closePopups"
      />
      <template v-if="showFallbackLongestLeap">
        <LongestLeapPolyline
          v-for="(polyline, index) in longestLeapPolyline"
          :latLngList="polyline"
          :key="'longestLeapPolyline-' + index"
        ></LongestLeapPolyline>
      </template>
      <CarPin
        v-if="!!tripDetails && !!tripDetails.vehicle"
        :location="tripDetails.locations[0]"
        :id="tripDetails.vehicle.localId"
      />
      <template v-if="!!tripDetails">
        <LocationPin
          v-for="(location, index) in tripDetails.locations"
          :key="location.id"
          :locationData="location"
          :number="getLocationNumber(index)"
          :showStar="getIsStarLocation(index)"
        />
      </template>
      <template v-if="showingConnectedVehicles">
        <template v-for="(vehicle, index) in connectedVehicles">
          <CarPin
            v-if="vehicle && vehicle.latitude && vehicle.longitude"
            :key="`car-pin-${index}`"
            :location="getGeoLocation(vehicle.latitude, vehicle.longitude)"
            :id="vehicle.localId"
          />
        </template>
      </template>
    </l-map>
    <BottomLogo></BottomLogo>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { LMap, LTileLayer } from "vue2-leaflet";
import { mapGetters, mapState } from "vuex";
import ChargingPin from "./ChargingPin.vue";
import CarPin from "./CarPin.vue";
import GradientPolyline from "./GradientPolyline.vue";
import LocationPin from "./LocationPin.vue";
import {
  GettersTypes,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import Trip from "@/logic/classes/trip_classes/trip";
import TspTrip from "@/logic/classes/tsp_trip_classes/tspTrip";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import Charger from "@/logic/classes/charger_classes/charger";
import LongestLeapPolyline from "./LongestLeapPolyline.vue";
import BottomLogo from "../logo/BottomLogo.vue";
import TravelLeg from "@/logic/classes/itinerary_data_classes/travelLeg";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import TripV2 from "@/logic/classes/trip_v2_classes/trip_v2";

/**
 * `Vue leaflet component:` the main container for rendering the map and all its children components
 */
export default Vue.extend({
  name: "MapPanel",
  components: {
    LMap,
    LTileLayer,
    ChargingPin,
    CarPin,
    GradientPolyline,
    LocationPin,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "l-marker-cluster": Vue2LeafletMarkerCluster,
    LongestLeapPolyline,
    BottomLogo,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 7,
      center: [-41.270634, 173.283966],
    };
  },
  computed: {
    ...mapGetters({
      filteredStations: GettersTypes.filterChargingStations,
      tripDetails: GettersTypes.selectedTripData,
      connectedVehicles: GettersTypes.connectedVehicles,
      showFallbackLongestLeap: GettersTypes.showFallbackLongestLeap,
    }),
    isTrip(): boolean {
      const trip: Trip | TspTrip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      if (!trip) false;
      return trip instanceof Trip;
    },
    polylineList(): [number, number][][] {
      const trip: Trip | TspTrip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      if (!trip) return [];

      // convert steps to leaflet polyline lat-longs polyline property for conditional rendering.
      const convertedArray = trip.getPolylinePoints();
      // get full list of lat-longs for map centering
      const collapsedArray = convertedArray.flat();

      // center map
      this.reCenterMap(collapsedArray);

      // return array of polyline lat-longs list for conditional rendering.
      return convertedArray;
    },
    chargeData(): { StartCharge: number; EndCharge: number }[] {
      const trip: Trip | TspTrip | TripV2 | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      if (!trip) return [];
      if (trip instanceof Trip)
        return trip.evTripData?.flatMap((plan) => plan.steps) ?? [];
      if (trip instanceof TspTrip) {
        const batterySize = trip.displayedComparisonData?.batterySize;
        // no battery size guard clause
        if (!batterySize) return [];
        // determine if this is a refined trip or not
        if (trip.itinerary && trip.itinerary.length) {
          const legs: TravelLeg[] = trip.itinerary.filter(
            (itineraryItem) => itineraryItem instanceof TravelLeg
          ) as TravelLeg[];
          return legs.map((leg) => ({
            StartCharge: leg.startingEnergy / batterySize,
            EndCharge: leg.endingEnergy / batterySize,
          }));
        }
        // calculate the battery use for this leg
        let remainingBattery = batterySize;
        const returnArray: { StartCharge: number; EndCharge: number }[] = [];
        trip.displayedComparisonData?.energyDataByLeg?.forEach((energyData) => {
          if (remainingBattery <= 0)
            returnArray.push({ StartCharge: 0, EndCharge: 0 });
          returnArray.push({
            StartCharge: remainingBattery / batterySize,
            EndCharge: (remainingBattery - energyData.Energy) / batterySize,
          });
          remainingBattery -= energyData.Energy;
        });
        return returnArray;
      }
      if (trip instanceof TripV2) {
        return (
          trip.itinerary.steps.map((step) => ({
            StartCharge: step.chargeBeforeTraveling,
            EndCharge: step.chargeAfterTraveling,
          })) ?? []
        );
      }
      return [];
    },
    longestLeapPolyline(): [number, number][][] {
      const trip: Trip | TspTrip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      if (!trip || trip instanceof TspTrip) return [];
      return trip.getFallbackLongestLeapPoints();
    },
    ...mapState({
      highLightLongestLeap: (state: unknown): boolean =>
        (state as State).displayLongestStep,
      showingConnectedVehicles: (state: unknown) =>
        (state as State).displayConnectedVehicles,
      userGeoLocation: (state: unknown): Coordinate | undefined =>
        (state as State).userGeoIPData,
      showingNearbyChargers: (state: unknown): boolean =>
        (state as State).showNearbyChargersOnly,
    }),
  },
  mounted() {
    window.addEventListener("resize", this.resizeMap);
    this.$nextTick(() => {
      if (this.userGeoLocation && this.userGeoLocation.isValid) {
        this.center = this.userGeoLocation.asLatLng;
        // center map
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.mainMap as any)?.mapObject.flyTo(
          this.userGeoLocation.asLatLng
        );
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeMap);
  },
  methods: {
    sendCenterToStore(val: { lat: number; lng: number }) {
      this.$store.commit(MutationTypes.setPannedCenter, val);
    },
    getIsStarLocation(index: number): boolean {
      // check for cases where location is origin or destination.
      if (index === 0) return true; //case: origin. NOTE: if round trip this will also be the destination.
      if (
        this.isTrip &&
        index === (this.tripDetails as Trip).locations.length - 1
      )
        return true; //case: destination.
      if (
        !(this.tripDetails as TspTrip).isRoundTrip &&
        index === (this.tripDetails as TspTrip).locations.length - 1
      )
        return true; //case: destination.

      // case: not an origin or a destination.
      return false;
    },
    getLocationNumber(index: number): number {
      // check if tsp trip
      if (this.tripDetails instanceof TspTrip) {
        // find new index after optimization
        const number = this.tripDetails.tripData?.locations.findIndex(
          (location) => location.original_index === index
        );
        // return new index if valid
        if (number && number >= 0) return number;
      }
      // default to passed index
      return index;
    },
    resizeMap() {
      // timeout used to let browser handle resize animation before resetting
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.mainMap as any)?.mapObject.invalidateSize();
      }, 200);
    },
    getGeoLocation(latitude: number, longitude: number): Coordinate {
      return new Coordinate({ latitude, longitude });
    },
    reCenterMap(pointsList: [number, number][]) {
      // get map padding (40% in pixels)
      let options = {
        paddingTopLeft: [(this.$vuetify.breakpoint.width / 100) * 40, 0],
      };

      // center map
      if (pointsList.length)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.mainMap as any)?.mapObject.fitBounds(pointsList, options);
    },
    /**
     * Check if given charger id is flagged as a charging stop on the displayed trip.
     *
     * @param chargerID the charger database UUID for the charger.
     * @returns true if the charger is flagged false if not.
     */
    getIsChargingStop(chargerID: string): boolean {
      if (!this.tripDetails) return false; // fail fast guard clause.
      if (
        this.tripDetails instanceof Trip ||
        this.tripDetails instanceof TspTrip
      ) {
        return this.tripDetails.chargingStopCDBIDs.includes(chargerID); // return true if id is included.
      }
      return false; // failsafe return false if there is trip data but not the expected types.
    },
    closePopups() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.mainMap as any)?.mapObject.closePopup();
    },
  },
  watch: {
    showingNearbyChargers(val) {
      if (val) {
        const chargerLatLngList = (this.filteredStations as Charger[]).map(
          (charger) => charger.coordinates.asLatLng
        );
        // center map
        this.reCenterMap(chargerLatLngList);
      }
    },
  },
});
</script>
<style>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
/* @import "~leaflet.markercluster/dist/MarkerCluster.Default.css"; */

/* copied stiles from  "~leaflet.markercluster/dist/MarkerCluster.Default.css" for better control over colors*/
.marker-cluster-small {
  background-color: #42a5f5;
}

.marker-cluster-small div {
  background-color: #2196f3;
}

.marker-cluster-medium {
  background-color: #1e88e5;
}

.marker-cluster-medium div {
  background-color: #1976d2;
}

.marker-cluster-large {
  background-color: #1565c0;
}

.marker-cluster-large div {
  background-color: #0d47a1;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font:
    12px "Helvetica Neue",
    Arial,
    Helvetica,
    sans-serif;
}

.marker-cluster span {
  line-height: 30px;
}
</style>
