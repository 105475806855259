<template>
  <v-card class="mb-3 rounded-lg background" elevation="1">
    <v-card flat v-if="user" class="background pa-5">
      <v-row no-gutters>
        <v-menu v-model="showMenu" absolute offset-y content-class="rounded-lg">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              color="primary"
              class="mr-3 mt-1"
              v-bind="attrs"
              v-on="on"
            >
              <img v-if="avatarSrc" :src="avatarSrc" />
              <v-icon v-else dark> mdi-account-circle </v-icon>
            </v-avatar>
          </template>

          <v-list style="min-width: 250px" class="background rounded-lg pa-3">
            <v-list-item @click="emitViewAccount">
              <v-list-item-title> Manage account </v-list-item-title>
            </v-list-item>
            <v-list-item @click="emitViewAllVehicles">
              <v-list-item-title> My fleet </v-list-item-title>
            </v-list-item>
            <v-list-item @click="emitViewVehicle">
              <v-list-item-title> Vehicle settings </v-list-item-title>
            </v-list-item>
            <v-list-item @click="emitViewDefaults">
              <v-list-item-title> Trip planning defaults </v-list-item-title>
            </v-list-item>
            <v-divider class="mb-3" />
            <v-list-item @click="handleLogout">
              <v-list-item-title
                class="text-decoration-underline"
                :style="`text-underline-offset: 4px; color: ${powerTripDarkBlue}`"
              >
                Sign out
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-card-subtitle class="d-flex flex-column grey--text pt-0">
          <span class="font-weight-medium text-h6">
            {{ user.fullName }}
          </span>
          <span>
            {{ user.email }}
          </span>
        </v-card-subtitle>
        <v-spacer />
      </v-row>
      <v-row no-gutters align="center" class="pt-10" v-if="company">
        <img v-if="logoSrc" :src="logoSrc" width="auto" height="50px" />
        <v-card flat class="background">
          <v-card-title v-if="company">
            {{ company.name ?? "Unnamed fleet" }}
          </v-card-title>
        </v-card>
        <v-spacer />
        <ElevatedBtn @click="emitViewAllVehicles">
          View fleet details
        </ElevatedBtn>
      </v-row>
      <v-row no-gutters align="center" class="pt-10" v-else>
        <v-skeleton-loader
          type="image"
          width="150px"
          height="50px"
          class="mr-3"
        />
        <v-skeleton-loader type="text, text" width="30%" />
        <v-spacer />
        <v-skeleton-loader type="button" />
      </v-row>
    </v-card>
    <!-- loader skeleton -->
    <v-card flat v-else class="background pa-5">
      <v-row no-gutters align="center">
        <v-skeleton-loader type="avatar" class="mr-3" />
        <v-skeleton-loader type="text, text" width="30%" />
        <v-spacer />
      </v-row>
      <v-row no-gutters align="center" class="pt-10">
        <v-skeleton-loader
          type="image"
          width="150px"
          height="50px"
          class="mr-3"
        />
        <v-skeleton-loader type="text, text" width="30%" />
        <v-spacer />
        <v-skeleton-loader type="button" />
      </v-row>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Driver from "@/logic/classes/user_classes/driver";
import Fleet from "@/logic/classes/fleet";
import { ActionTypes, GettersTypes, State } from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { powerTripDarkBlue } from "@/logic/data/const";
import ElevatedBtn from "@/ui/components/ui-elements/buttons/ElevatedBtn.vue";

/** Vue Component: renders the user profile. To be used in the 'TripsContent' component. */
export default Vue.extend({
  name: "ProfileCard",
  components: { ElevatedBtn },
  methods: {
    /**
     * Closes the profile card and logs the user out.
     */
    handleLogout() {
      this.$emit("logout");
      this.$store.dispatch(ActionTypes.logoutUser);
    },
    /**
     * Emits the 'view-account' event to the parent component.
     * Used to navigate to the user account page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewAccount(): void {
      this.$emit("view-account");
    },
    /**
     * Emits the 'view-vehicle' event to the parent component.
     * Used to navigate to the user vehicle page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewVehicle(): void {
      this.$emit("view-vehicle");
    },
    /**
     * Emits the 'view-vehicles' event to the parent component.
     * Used to navigate to the user fleet page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewAllVehicles(): void {
      this.$emit("view-vehicles");
    },
    /**
     * Emits the 'view-defaults' event to the parent component.
     * Used to navigate to the user trip planning defaults page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewDefaults(): void {
      this.$emit("view-defaults");
    },
  },
  computed: {
    /**
     * Returns the src path for the users profile picture if they have one.
     *
     * @return {string | undefined} The src path for the users profile picture if they have one, undefined otherwise.
     */
    avatarSrc(): string | undefined {
      const driver: Driver | undefined =
        this.$store.getters[GettersTypes.currentDriver];
      return driver?.profilePicSrc;
    },
    /**
     * Returns the src path for this fleets logo pic if they have one.
     *
     * @return {string | undefined} The src path for this fleets logo pic if they have one, undefined otherwise.
     */
    logoSrc(): string | undefined {
      const fleet: Fleet | undefined = (this.$store.state as State).fleet;
      return fleet?.logoSrc;
    },
    ...mapGetters({
      driver: GettersTypes.currentDriver,
      vehicle: GettersTypes.selectedVehicleData,
    }),
    ...mapState({
      authenticated: (state: unknown): boolean =>
        (state as State).authorizedFlag,
      user: (state: unknown) => (state as State).user,
      company: (state: unknown): Fleet | undefined => (state as State).fleet,
    }),
  },
  data() {
    return {
      showMenu: false,
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
});
</script>
