<template>
  <v-card flat>
    <v-card-title class="tertiary--text">
      {{ isEditing ? "Edit Your Optimised Trip" : "Plan a New Optimised Trip" }}
    </v-card-title>
    <v-card-text v-if="!isEditing">
      Plan a trip that optimises your stops into the most efficient order to
      travel from your origin to your destination visiting all your stops along
      the way.
    </v-card-text>
    <!-- origin/destination card -->
    <v-card-text>
      <!-- trip name card -->
      <v-card class="rounded-lg mb-5 px-5" v-if="isEditing">
        <v-card-text>
          <v-text-field
            v-model="tripName"
            label="Trip name"
            clearable
            :append-icon="tripName ? '' : 'mdi-pencil'"
            @change="flagAsDirty"
          ></v-text-field>
        </v-card-text>
      </v-card>
      <!-- Origin and Destination card -->
      <v-card class="primary white--text rounded-lg pa-5">
        <v-card-title>Origin and Destination</v-card-title>
        <v-card-text class="pb-0">
          <AddressAutocompleteInput
            dark
            :label="roundTrip ? 'origin/destination' : 'origin'"
            :loading="planning"
            id="origin"
            :initialValue="{
              address: origin.address,
              waypoint: origin.coordinates,
            }"
            @update="handleAddressChange"
            :errorMsg="getAddressError(origin.localId)"
            :geoLocation="anchorGeoLocation"
            :allowFavLocations="true"
          />
          <AddressAutocompleteInput
            dark
            label="destination"
            v-if="!roundTrip"
            :loading="planning"
            id="destination"
            :initialValue="{
              address: destination.address,
              waypoint: destination.coordinates,
            }"
            @update="handleAddressChange"
            :errorMsg="getAddressError(destination.localId)"
            :geoLocation="anchorGeoLocation"
            :allowFavLocations="true"
          />
        </v-card-text>
        <v-card-actions class="px-5 pt-0">
          <v-switch inset v-model="roundTrip" :color="pwtDarkBlue" dense>
            <template v-slot:label>
              <span class="white--text">
                {{
                  roundTrip ? "This is a round trip" : "Make this a round trip"
                }}
              </span>
            </template>
          </v-switch>
        </v-card-actions>
        <v-card-text>
          <v-text-field
            dark
            v-model="startingLoad"
            label="starting load weight (kgs)"
            type="number"
            append-icon="mdi-weight-kilogram"
            class="white--text"
            hide-spin-buttons
            @change="flagAsDirty"
          />
        </v-card-text>
      </v-card>
    </v-card-text>
    <!-- stops section -->
    <v-card-title>Stops</v-card-title>
    <v-card-text>
      <TspStopCard
        v-for="(stop, index) in additionalStops"
        :key="'tsp-additional-stop-' + index"
        :stop="stop"
        :errorMsg="getAddressError(stop.localId)"
        @update="updateStop"
        @remove="removeStop(index)"
        :geoLocation="anchorGeoLocation"
      />
      <TextBlockBtn @click="addStop"> Add Another Stop </TextBlockBtn>
    </v-card-text>
    <v-card-text v-if="!!errorMsg">
      <v-alert v-if="!!errorMsg" type="error" color="error" class="rounded-lg">
        {{ errorMsg }}
      </v-alert>
    </v-card-text>
    <v-card-text class="py-0">
      <v-checkbox label="optimise order of stops" v-model="optimise" />
    </v-card-text>
    <v-card-actions class="flex-column">
      <ElevatedBlockBtn
        :loading="planning"
        :disabled="planning || !!addressErrors.length || !!errorMsg"
        @click="planOptimizedTrip"
      >
        {{ isEditing ? "Recalculate Trip" : "Optimise Trip" }}
      </ElevatedBlockBtn>
      <OutlinedBlockBtn @click="back" class="ml-0 mt-3">
        Cancel
      </OutlinedBlockBtn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import TspTrip, {
  TspTripPlanningStatus,
} from "@/logic/classes/tsp_trip_classes/tspTrip";
import { powerTripDarkBlue } from "@/logic/data/const";
import { RouteNames } from "@/logic/router";
import { GettersTypes, MutationTypes, State } from "@/logic/store/store_types";
import AddressAutocompleteInput, {
  type AddressAutocompleteInputUpdateObj,
} from "@/ui/components/ui-elements/inputs/AddressAutocompleteInput.vue";
import Vue from "vue";
import TspStopCard from "./TspStopCard.vue";
import TspLocation from "@/logic/classes/tsp_trip_classes/tspLocation";
import TextBlockBtn from "@/ui/components/ui-elements/buttons/TextBlockBtn.vue";
import ElevatedBlockBtn from "@/ui/components/ui-elements/buttons/ElevatedBlockBtn.vue";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import { mapGetters, mapState } from "vuex";
import OutlinedBlockBtn from "@/ui/components/ui-elements/buttons/OutlinedBlockBtn.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import TspTripComparison from "@/logic/classes/tsp_trip_classes/tspTripComparison";
import EVModel from "@/logic/classes/vehicle_classes/evModel";

enum AddressErrorMsg {
  nullIsland = "This address is invalid",
}

enum GeneralErrorMsg {
  roundTripNoStops = "A round trip needs one or more stops to plan a trip",
  toFewLocations = "Need at least two locations to plan a trip",
  notRoutable = "This trip is not routable",
}

interface LocalAddressErrorObj {
  localId: string;
  errorMsg: AddressErrorMsg;
}

export default Vue.extend({
  name: "TspTripPlanningForm",
  data() {
    return {
      pwtDarkBlue: powerTripDarkBlue,
      originID: "origin",
      destinationID: "destination",
      roundTrip: false,
      planning: false,
      origin: new TspLocation(),
      destination: new TspLocation(),
      additionalStops: [new TspLocation()],
      addressErrors: [] as LocalAddressErrorObj[],
      errorMsg: null as string | null,
      tripName: null as string | null,
      optimise: true,
      startingLoad: null as string | number | null,
      dirty: false,
    };
  },
  computed: {
    isEditing(): boolean {
      return this.$route.name === RouteNames.optimisedEdit;
    },
    anchorGeoLocation(): Coordinate | undefined {
      if (!this.origin.isNullIsland)
        return new Coordinate(this.origin.coordinates);
      if (!this.destination.isNullIsland)
        return new Coordinate(this.destination.coordinates);
      const waypoint = this.additionalStops.find((stop) => !stop.isNullIsland);
      if (waypoint) return new Coordinate(waypoint.coordinates);
      return undefined;
    },
    ...mapState({
      favLocations: (state: unknown) => (state as State).favLocations,
    }),
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
      vehicle: GettersTypes.selectedVehicleData,
    }),
  },
  components: {
    AddressAutocompleteInput,
    TspStopCard,
    TextBlockBtn,
    ElevatedBlockBtn,
    OutlinedBlockBtn,
  },
  methods: {
    flagAsDirty(): void {
      this.$emit("dirty");
    },
    /**
     * Navigates back using the Vue Router.
     *
     * @return {void} No return value for this function.
     */
    back(): void {
      this.$emit("cancel");
    },
    /**
     * Updates the stop with the provided `updatedStop` object.
     *
     * @param {TspLocation} updatedStop - The updated stop object.
     * @return {void} This function does not return anything.
     */
    updateStop(updatedStop: TspLocation): void {
      // clear general error
      this.errorMsg = null;
      this.flagAsDirty();
      // locate location index
      const locationIndex = this.additionalStops.findIndex(
        (location) => location.localId === updatedStop.localId
      );
      // exit early if unable to find index
      if (locationIndex === -1) return;
      this.additionalStops.splice(locationIndex, 1, updatedStop);
      // clear invalid error if needed
      if (
        this.getAddressError(updatedStop.localId) &&
        !updatedStop.isNullIsland
      ) {
        this.addressErrors = this.addressErrors.filter(
          (error) => error.localId !== updatedStop.localId
        );
      }
    },
    /**
     * Adds a new TspLocation to the additionalStops array.
     * Resets the errorMsg to null if it matches GeneralErrorMsg.roundTripNoStops.
     *
     * @return {void} No return value
     */
    addStop(): void {
      this.flagAsDirty();
      this.additionalStops.push(new TspLocation());
      if (this.errorMsg === GeneralErrorMsg.roundTripNoStops) {
        this.errorMsg = null;
      }
    },
    /**
     * Removes a stop from the additionalStops array at the specified index.
     *
     * @param {number} index - The index of the stop to remove.
     * @return {void} This function does not return anything.
     */
    removeStop(index: number): void {
      this.flagAsDirty();
      this.additionalStops.splice(index, 1);
    },
    /**
     * Handles the change event of the address input field.
     *
     * @param {AddressAutocompleteInputUpdateObj} val - The updated address input value.
     * @return {void} This function does not return anything.
     */
    handleAddressChange(val: AddressAutocompleteInputUpdateObj): void {
      // clear general error
      this.errorMsg = null;
      this.flagAsDirty();
      // not null guard clause
      if (!val.addressData) return;

      // find location to update
      const location = this.isOrigin(val)
        ? this.origin
        : this.isDestination(val)
          ? this.destination
          : undefined;

      // find operation failed guard clause
      if (!location) return;

      // create new object
      const tempObj: TspLocation = new TspLocation({
        ...location,
        address: val.addressData.address,
        coordinates: new Coordinate({
          latitude: val.addressData.coordinates.Latitude,
          longitude: val.addressData.coordinates.Longitude,
        }),
      });

      if (val.addressData.localId && this.isOrigin(val)) {
        // find fav location
        const favLocation = this.favLocations.find(
          (fav) => fav.localId === val.addressData?.localId
        );
        // check if fav location exists
        if (
          favLocation &&
          favLocation.planningData?.loadWeightChange &&
          favLocation.planningData.loadWeightChange > 0 &&
          !this.startingLoad
        ) {
          // add starting load
          tempObj.weightChange = favLocation.planningData.loadWeightChange;
          this.startingLoad = favLocation.planningData.loadWeightChange;
        }
      }

      // clear invalid error if needed
      if (this.getAddressError(tempObj.localId) && !tempObj.isNullIsland) {
        this.addressErrors = this.addressErrors.filter(
          (error) => error.localId !== tempObj.localId
        );
      }

      // update local state

      // check if origin
      if (this.isOrigin(val)) {
        this.origin = tempObj;
        return;
      }

      // check if destination
      if (this.isDestination(val)) {
        this.destination = tempObj;
        return;
      }
    },
    /**
     * Checks if the given value is the origin.
     *
     * @param {AddressAutocompleteInputUpdateObj} val - The value to check.
     * @return {boolean} Returns true if the value's id is "origin", otherwise false.
     */
    isOrigin(val: AddressAutocompleteInputUpdateObj): boolean {
      return val.id === "origin";
    },
    /**
     * Checks if the given value is the destination.
     *
     * @param {AddressAutocompleteInputUpdateObj} val - The value to check.
     * @return {boolean} Returns true if the value's id is "destination", otherwise false.
     */
    isDestination(val: AddressAutocompleteInputUpdateObj): boolean {
      return val.id === "destination";
    },
    /**
     * Asynchronously plans an optimized trip.
     *
     * This function performs the following steps:
     * 1. Indicates that an async process is in action.
     * 2. Clears previous attempts errors.
     * 3. Clears incomplete additional stops.
     * 4. Checks if addresses are valid.
     * 5. Checks if it is a round trip without any stops.
     * 6. Plans the trip based on the provided parameters.
     * 7. Adds the starting weight.
     * 8. Sets the trip's locations, name, and order.
     * 9. Checks the outcome for errors.
     * 10. Indicates that the async process has been completed.
     * 11. Saves the state to the store.
     * 12. Dispatches the saveTrip action.
     * 13. Navigates to the itinerary.
     *
     * @return {Promise<void>} A promise that resolves when the trip is planned.
     */
    async planOptimizedTrip() {
      // indicate async process is in action
      this.planning = true;

      // clear previous attempts errors
      this.addressErrors = [];
      this.errorMsg = null;

      // clear incomplete additional stops
      this.additionalStops = this.additionalStops.filter(
        (waypoint) => !waypoint.isNullIsland
      );

      // check addresses are valid
      if (this.origin.isNullIsland) {
        this.addressErrors.push({
          localId: this.origin.localId,
          errorMsg: AddressErrorMsg.nullIsland,
        });
        this.planning = false;
        return;
      }
      if (this.destination.isNullIsland && !this.roundTrip) {
        this.addressErrors.push({
          localId: this.destination.localId,
          errorMsg: AddressErrorMsg.nullIsland,
        });
        this.planning = false;
        return;
      }
      if (this.additionalStops.some((stop) => stop.isNullIsland)) {
        this.additionalStops.forEach((stop) => {
          if (stop.isNullIsland) {
            this.addressErrors.push({
              localId: stop.localId,
              errorMsg: AddressErrorMsg.nullIsland,
            });
          }
        });
        this.planning = false;
        return;
      }

      // check is not a round trip with out any stops
      if (this.roundTrip && !this.additionalStops.length) {
        this.errorMsg = GeneralErrorMsg.roundTripNoStops;
        this.planning = false;
        return;
      }

      // plan trip
      const newTrip: TspTrip = this.trip ?? new TspTrip();

      // add starting weight
      this.addStartingLoad();

      newTrip.locations = this.roundTrip
        ? [this.origin, ...this.additionalStops]
        : [this.origin, ...this.additionalStops, this.destination];
      newTrip.name = this.tripName ?? undefined;
      newTrip.isRoundTrip = this.roundTrip;

      if (!this.optimise) {
        // keep entered order
        newTrip.keepOrder = true;
        newTrip.locationOrder = [
          this.origin.localId,
          ...this.additionalStops.map((stop) => stop.localId),
          this.roundTrip ? this.origin.localId : this.destination.localId,
        ];
      }

      const outcome = await newTrip.planTrip();

      // check outcome for errors

      if (outcome === TspTripPlanningStatus.errorNotRoutable) {
        this.errorMsg = GeneralErrorMsg.notRoutable;
        this.planning = false;
        return;
      }

      if (outcome === TspTripPlanningStatus.errorNotEnoughLocations) {
        this.errorMsg = GeneralErrorMsg.toFewLocations;
        this.planning = false;
        return;
      }

      // add comparison for current selected EV if it has a known EV Model
      if ((this.vehicle as Vehicle | undefined)?.evModel) {
        const comparison = new TspTripComparison();
        comparison.setEVModel(this.vehicle.evModel as EVModel);
        comparison.calcAsUsed = true;
        comparison.calcEnergyUsageBreakdown(newTrip.baseLegs);
        newTrip.comparisons.push(comparison);
        newTrip.displayedComparison = comparison.localId;
      }

      // indicate async process has been completed
      this.planning = false;
      // save state to store
      this.$store.commit(MutationTypes.updateIndividualTrip, newTrip);
      this.$store.commit(MutationTypes.setSelectedTrip, newTrip.localId);
      this.$store.commit(MutationTypes.setShowNearbyChargersOnly, false);

      // navigate to itinerary
      this.$router.push({ name: RouteNames.optimisedVehicles });
    },
    /**
     * Retrieves the error message associated with a given address ID.
     *
     * @param {string} localId - The ID of the address.
     * @return {string | null} The error message associated with the address ID, or null if no error message is found.
     */
    getAddressError(localId: string): string | null {
      return (
        this.addressErrors.find((error) => error.localId === localId)
          ?.errorMsg ?? null
      );
    },
    /**
     * Adds a starting load to the origin weight change if the startingLoad property is not empty.
     *
     * @return {void} This function does not return anything.
     */
    addStartingLoad(): void {
      if (!this.startingLoad) return;
      const parsedVal = parseIntOrFloat(this.startingLoad);
      if (!parsedVal) return;
      this.origin.weightChange = parsedVal;
    },
  },
  mounted() {
    if (this.trip && this.trip instanceof TspTrip) {
      this.origin = this.trip.locations[0];
      this.destination = this.trip.locations[this.trip.locations.length - 1];
      this.additionalStops = this.trip.locations.filter(
        (location, index) =>
          index !== 0 && index !== (this.trip as TspTrip).locations.length - 1
      );
      if (
        this.origin.coordinates.latitude ===
          this.destination.coordinates.latitude &&
        this.origin.coordinates.longitude ===
          this.destination.coordinates.longitude
      )
        this.roundTrip = true;
    }
  },
});
</script>
