<template>
  <v-card @click="emitClick" class="rounded-lg">
    <v-card-title>
      {{ vehicle.name ?? "unnamed vehicle" }}
    </v-card-title>
    <v-card-subtitle class="font-weight-medium pr-12">
      {{ vehicle.licensePlate ?? "unrecorded license plate" }}
    </v-card-subtitle>
    <v-card-text>
      <v-slider
        v-if="
          vehicle.fuelType === 'Electric' ||
          vehicle.fuelType === 'Plug in hybrid'
        "
        style="max-width: 250px"
        :value="vehicle.stateOfCharge ?? 0"
        readonly
        :label="(vehicle.stateOfCharge ?? '??') + '% battery'"
        min="0"
        max="100"
        track-color="grey lighten-2"
        :messages="!vehicle.stateOfCharge ? 'charge unknown' : undefined"
      />
      {{ vehicle.fuelType ? vehicle.fuelType : "Electric" }}
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";

export default Vue.extend({
  name: "VehicleListCard",
  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
      required: true,
    },
  },
  methods: {
    /**
     * Emits a click event to the parent component.
     *
     * @return {void} No return value
     */
    emitClick() {
      this.$emit("click");
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
