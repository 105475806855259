import {
  fetchValhallaOptimizedRoutePlan,
  fetchValhallaRoutePlan,
} from "../../api/calls/valhalla_calls";
import {
  Valhalla_CostingModel,
  type Valhalla_Location,
  type Valhalla_Trip,
  Valhalla_RouteRes,
  Valhalla_RouteError,
  Valhalla_Leg,
} from "../../types/valhalla_types";
import generateUniqueLocalID from "../../utils/generateUniqueLocalID";
import * as polyline from "@mapbox/polyline";
import Vehicle from "../vehicle_classes/vehicle";
import { PartialObj } from "../../types/generic_types";
import {
  updatedSavedOptimisedTrip,
  type Directus_SavedOptimisedTrip,
  type Directus_SavedOptimisedTrip_Location,
  createSavedOptimisedTrip,
} from "../../api/calls/directus_calls/savedOptimisedTrips";
import { getNiceDuration } from "../../utils/timeUtils";
import TspTripComparison from "./tspTripComparison";
import TspLocation from "./tspLocation";
import { EVNavCar, EVNavEnergy } from "../../types/ev_nav_types";
import { ItineraryData } from "../itinerary_data_classes/types";
import OriginLocation from "../itinerary_data_classes/originLocation";
import Coordinate from "../common_classes/coordinate";
import TravelLeg from "../itinerary_data_classes/travelLeg";
import DestinationLocation from "../itinerary_data_classes/destinationLocation";
import WaypointLocation from "../itinerary_data_classes/waypointLocation";
import { DateTime } from "luxon";
import ChargerLocation from "../itinerary_data_classes/ChargerLocation";
import {
  evnavRadarCall,
  fetchEnergyNeeded,
} from "../../api/calls/ev_nav_calls";
import ItineraryLocation from "../itinerary_data_classes/itineraryLocation";
import orderChargerSuggestions, {
  OrderedChargerSuggestions,
} from "../../utils/orderChargerSuggestions";
import {
  calcChargingTimeAC,
  timeWithDCCurve,
} from "../../utils/calcChargingTime";
import { LatLngBounds } from "leaflet";

export interface TspTripOptions {
  localId?: string;
  directusId?: number | string;
  vehicleId?: number;
  locations?: TspLocation[];
  costingModel?: Valhalla_CostingModel;
  comparisons?: TspTripComparison[];
  name?: string;
  keepOrder?: boolean;
  isRoundTrip?: boolean;
  /** Decimal representation of the starting state of charge for this trip. e.g. 80% = 0.8 */
  startingSoC?: number;
}

export interface TspBaseLeg {
  polyline: string;
  loadWeight: number;
  addedCharge: number;
}

export interface TspDetailedLegs extends EVNavEnergy {
  StartCharge: number;
  EndCharge: number;
  startPercentage: number;
  endPercentage: number;
}

const TspTripDefaults = {
  localId: undefined,
  directusId: undefined,
  vehicleId: undefined,
  locations: [],
  costingModel: "truck" as Valhalla_CostingModel,
  name: undefined,
  keepOrder: false,
  isRoundTrip: false,
  startingSoC: 1,
};

export default class TspTrip {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  /** global record of class instance ids this session. */
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------ State ------------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Directus `SavedTrip` collection record id.
   *
   * NOTE: will only be populated once a successful save response have
   * been received, however will be needed for every update after that.
   */
  directusId?: string | number;

  /** local unique id. */
  localId: string;

  /**
   * Id of the vehicle used in the trip.
   *
   * NOTE: will match the directus `Vehicles` collection record id.
   */
  vehicleId?: number;

  /**
   * The locations stopped at along the trip including the starting
   * location (always at index 0) and the destination location
   * (always at the last index).
   *
   * Note: the stops in between the origin and destination can be
   * sorted into a different order in the planning.
   */
  locations: TspLocation[];

  /**
   * list of locations local ids in order they are visited in this trip. With the origin being the first entry and destination being the last entry
   *
   * Note: if a "valhalla optimized route" this should be the order provided by valhalla after it is optimized. If a "valhalla route" this should
   * be the order provided by the user.
   *
   * TODO: rethink if round trips will cause two entries for the same location as a separate copy for both the start and the final destination
   * or these should be referenced by having the local id for the same location object at different points for this trip. Will require additional
   * conditional rendering if is a round trip as well as the other conditional logic in route planning.
   */
  locationOrder: string[] = [];

  /** The Valhalla costing model used to plan this trip */
  costingModel: Valhalla_CostingModel;

  /**
   * The successfully planned Valhalla optimized route trip data for this
   * trip
   */
  tripData?: Valhalla_Trip;

  /** the whole `Vehicle` object of the vehicle used to plan this trip. */
  vehicle?: Vehicle;

  /** the optional display name for the trip */
  name?: string;

  /**
   * Type of trip.
   *
   * Note: this is mainly used for itinerary conditional rendering.
   */
  status = "optimised-trip"; // TODO: alter this property and its same named counterpart in the `Trip` class to be clearer on what they represent.

  /**
   * The outcome of the trip planning operation.
   *
   * NOTE: not to be confused with status that needs a rename in both `Trip` class and `TspTrip` class.
   */
  tripPlanningStatus?: TspTripPlanningStatus;

  /** List of calculated comparisons. */
  comparisons: TspTripComparison[];

  /** Local ID of the comparison flagged to display polylines */
  displayedComparison?: string;

  /**
   * Flag to indicate if the order of waypoints needs to be provided.
   *
   * Note: this flag should be used in conditional rendering and should not be set without also ensuring location Order has been set.
   */
  keepOrder: boolean;

  /**
   * Flag to indicate if this is a round trip i.e. a trip where both the origin and the destination is the same location.
   *
   * Note: this flag should be used to trigger conditional logic/rendering that is needed when it is a round trip.
   */
  isRoundTrip: boolean;

  /** Decimal representation of the starting state of charge for this trip. e.g. 80% = 0.8 */
  startingSoC: number;

  /** More detailed itinerary for trips that have chosen their vehicle and need to move on to a more detailed interface */
  itinerary: ItineraryData = [];

  /** IDs of chargers a */
  chargersAlongRouteCDBIDs: string[] = [];

  private _bounds: L.LatLngBounds | undefined;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    localId = undefined,
    directusId = undefined,
    vehicleId = undefined,
    locations = [],
    costingModel = "truck", // note default is truck as this was created as part of a truck routing solution piece of work.
    comparisons = [],
    name = undefined,
    keepOrder = false,
    isRoundTrip = false,
    startingSoC = 1,
  }: TspTripOptions | undefined = TspTripDefaults) {
    this.localId =
      localId ?? generateUniqueLocalID(TspTrip.usedIds, "tsp-trip");
    this.directusId = directusId;
    this.vehicleId = vehicleId;
    this.locations = locations;
    this.costingModel = costingModel;
    this.comparisons = comparisons;
    this.name = name;
    this.keepOrder = keepOrder;
    this.isRoundTrip = isRoundTrip;
    this.startingSoC = startingSoC;

    // add id to list of used unique ids
    TspTrip.usedIds.push(this.localId);
  }

  static fromDirectusData(directusData: Directus_SavedOptimisedTrip): TspTrip {
    return new TspTrip({
      directusId: directusData.id,
      locations: directusData.Locations.map(
        (directusLocation) =>
          new TspLocation({
            address: directusLocation.address,
            coordinates: new Coordinate({
              latitude: directusLocation.latitude,
              longitude: directusLocation.longitude,
            }),
          })
      ),
      costingModel: directusData.Costing_Model,
      name: directusData.Name ?? undefined,
      keepOrder: directusData.keepOrder ?? undefined,
      isRoundTrip: directusData.isRoundTrip ?? undefined,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** travel time for this trip in seconds. */
  public get totalTime(): number | undefined {
    return this.tripData?.summary.time;
  }

  /** displayable string for the travel time in a human readable format. */
  public get displayableTotalTime(): string {
    return getNiceDuration(this.totalTime ?? 0) + " travel time";
  }

  /** total distance of this trip in km. */
  public get totalDistance(): number | undefined {
    return this.tripData?.summary.length;
  }

  /** displayable string for the trips total distance in km. */
  public get displayableTotalDistance(): string {
    return `${Math.round(this.totalDistance ?? 0)}km`;
  }

  /** combines all legs into a single polyline. */
  public get fullTripPolyline(): string {
    if (this.itinerary.length) {
      const legs: TravelLeg[] = this.itinerary.filter(
        (leg) => leg instanceof TravelLeg
      ) as TravelLeg[];
      const points = legs.flatMap((leg) => leg.points);
      return polyline.encode(points, 6);
    }

    // get polyline points
    const points = this.getPolylinePoints().flat();
    return polyline.encode(points, 6);
  }

  /** list of polylines relating to each leg. */
  public get polylineList(): string[] {
    return this.tripData?.legs.map((leg) => leg.shape) ?? [];
  }

  /** the `TspTripComparison` data object relating to the displayed comparison. */
  public get displayedComparisonData(): TspTripComparison | undefined {
    return this.comparisons.find(
      (comparison) => comparison.localId === this.displayedComparison
    );
  }

  public get baseLegs(): TspBaseLeg[] {
    return this.polylineList.map((polyline, index) => {
      const location = this.locations.find(
        (location) => location.localId === this.locationOrder[index]
      );
      let load = 0;
      for (let i = 0; i <= index; i++) {
        load += this.locations[i].weightChange ?? 0;
      }
      return {
        polyline,
        loadWeight: load,
        addedCharge: location?.addedCharge ?? 0,
      };
    });
  }

  /** lists charger database id's for chargers that have been selected to be used in this trip. */
  public get chargingStopCDBIDs(): string[] {
    // fail fast
    if (!this.itinerary.length) return [];
    // filter itinerary stops to charging stops
    const chargingStops: ChargerLocation[] = this.itinerary.filter(
      (stop) => stop instanceof ChargerLocation
    ) as ChargerLocation[];
    // return CDBIDs
    return chargingStops.map((stop) => stop.relatedCharger);
  }

  // -------------------------------------------------------------------- //
  // --------------------------- Public Methods ------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Create the base itinerary to move to the refining step
   */
  public createBaseItinerary() {
    this.itinerary = this.generateItinerary();
  }

  /**
   * Returns a decoded polylines in an array retaining there order.
   *
   * Note: these points are in lat lon order
   */
  public getPolylinePoints(): [number, number][][] {
    if (this.itinerary.length) {
      const legs: TravelLeg[] = this.itinerary.filter(
        (itineraryItem) => itineraryItem instanceof TravelLeg
      ) as TravelLeg[];
      return legs.map((leg) => leg.points);
    }
    return (
      this.tripData?.legs.map((leg) => this.decodePolyline(leg.shape)) ?? []
    );
  } // TODO: refactor this an Trip as should be a getter but matching more complex code in the Trip class forced this to be a public method so less complex components and

  /**
   * Plan a traveling salesman problem type of trip.
   *
   * @returns stats of trip planing operations outcome
   */
  public async planTrip(): Promise<TspTripPlanningStatus> {
    // clear previous status
    this.tripPlanningStatus = undefined;

    // check at least two locations have been added to the trip
    if (this.locations.length < 2) {
      this.tripPlanningStatus = TspTripPlanningStatus.errorNotEnoughLocations;
      return TspTripPlanningStatus.errorNotEnoughLocations;
    }

    // plan trip
    const res = await this.fetchRoutePlan();

    // check res was successful and error if not
    if (!res) {
      this.tripPlanningStatus = TspTripPlanningStatus.errorUnknown;
      return TspTripPlanningStatus.errorUnknown;
    }

    if (Object.hasOwn(res, "trip")) {
      // update state
      this.tripData = (res as unknown as Valhalla_RouteRes).trip;
      this.tripPlanningStatus = TspTripPlanningStatus.success;
      // set location order.
      if (this.keepOrder) {
        // match order of locations provided by user.
        if (this.isRoundTrip) {
          // match order of locations provided by user with origin also recorded as the destination.
          this.locationOrder = [
            ...this.locations.map((location) => location.localId),
            this.locations[0].localId,
          ];
        } else {
          // match order of locations provided by user.
          this.locationOrder = this.locations.map(
            (location) => location.localId
          );
        }
      } else {
        // use order optimised by valhalla.
        const tempArray: string[] = [];
        (res as unknown as Valhalla_RouteRes).trip.locations.forEach(
          (location, index) => {
            // check if is the last location and is a round trip
            if (
              index ===
                (res as unknown as Valhalla_RouteRes).trip.locations.length -
                  1 &&
              this.isRoundTrip
            ) {
              // exception for last location and is a round trip
              tempArray.push(this.locations[0].localId);
            } else {
              // every other case
              tempArray.push(this.locations[location.original_index].localId);
            }
          }
        );
        this.locationOrder = tempArray;
      }
      // return outcome
      return TspTripPlanningStatus.success;
    }

    if (Object.hasOwn(res, "error")) {
      this.tripPlanningStatus = TspTripPlanningStatus.errorNotRoutable;
      return TspTripPlanningStatus.errorNotRoutable;
    }

    this.tripPlanningStatus = TspTripPlanningStatus.errorUnknown;
    return TspTripPlanningStatus.errorUnknown;
  }

  /**
   * Saves this individual trip to directus.
   */
  public async saveTrip(): Promise<"failed" | "ok"> {
    // format trip for saving
    const saveData: PartialObj<Directus_SavedOptimisedTrip> = {
      Costing_Model: this.costingModel,
      Locations: this.locations.map((location) =>
        this.TripLocationToDirectusOptimisedTripLocation(location)
      ),
    };
    if (this.name) saveData.Name = this.name;

    // check if trip has been saved before and needs to be overwritten.
    if (this.directusId) {
      // overwrite trip in directus.
      const updateOperationRes = await updatedSavedOptimisedTrip(
        this.directusId,
        saveData
      );
      if (updateOperationRes) return "ok";
      // operation was unsuccessful if it reaches this point, return failed.
      return "failed";
    }

    // create new trip record in directus
    const saveOperationRes = await createSavedOptimisedTrip(saveData);
    if (saveOperationRes) {
      this.directusId = saveOperationRes.id;
      return "ok";
    }

    // operation was unsuccessful if it reaches this point, return failed.
    return "failed";
  }

  /**
   * insert a charging stop or waypoint into a given travel leg.
   *
   * Causes this leg to be be split and three new itinerary objects (travel leg to location,
   * location its self and travel leg from location) to be added replacing the original travel
   * leg
   *
   * @param travelLegIndex - the index in the itinerary array for the target `TravelLeg` to have the stop inserted into.
   * @param stop - the `ChargerLocation` or `WaypointLocation` object to be inserted.
   * @returns the success status of the operation.
   */
  public async insertStop(
    travelLegIndex: number,
    stop: ChargerLocation | WaypointLocation
  ): Promise<"failed" | "success"> {
    // fail fast guard clauses.
    if (travelLegIndex < 0) return "failed"; // not a valid index.
    if (travelLegIndex === 0) return "failed"; // index 0 should always be the origin destination not a travel leg that can be split.
    if (travelLegIndex >= this.itinerary.length - 1) return "failed"; // Assumption: trying to add something at destination or after destination has been reached this should be another trip.
    if (!this.displayedComparisonData || !this.displayedComparisonData.evModel)
      return "failed"; // should not be able to reach this code if no comparison has been selected to be further refined.

    // find relevant objects.
    const travelLegData = this.itinerary[travelLegIndex];
    const previousStop = this.itinerary[travelLegIndex - 1];
    const nextStop = this.itinerary[travelLegIndex + 1];

    // check relevant objects where found correctly.
    if (!(travelLegData instanceof TravelLeg)) return "failed"; // index given is not a travel leg.
    if (
      !(previousStop instanceof OriginLocation) &&
      !(previousStop instanceof WaypointLocation) &&
      !(previousStop instanceof ChargerLocation)
    )
      return "failed"; // previous stop is not a location.
    if (
      !(nextStop instanceof DestinationLocation) &&
      !(nextStop instanceof WaypointLocation) &&
      !(nextStop instanceof ChargerLocation)
    )
      return "failed"; // next stop is not a location.

    // get new polylines
    const [firstLegRoute, secondLegRoute] = await Promise.all([
      fetchValhallaRoutePlan(
        [
          previousStop.coordinate.asAbbreviatedObj,
          stop.coordinate.asAbbreviatedObj,
        ],
        this.costingModel
      ),
      fetchValhallaRoutePlan(
        [
          stop.coordinate.asAbbreviatedObj,
          nextStop.coordinate.asAbbreviatedObj,
        ],
        this.costingModel
      ),
    ]);

    // check success of valhalla calls bail out if failed
    if (!firstLegRoute || Object.hasOwn(firstLegRoute, "error"))
      return "failed"; // firstLegRoute has either returned as undefined or with an error.
    if (!secondLegRoute || Object.hasOwn(secondLegRoute, "error"))
      return "failed"; // secondLeg has either returned as undefined or with an error.

    // create EV Nav vehicle obj
    const vehicle: EVNavCar = {};

    if (this.displayedComparisonData.evModelID) {
      vehicle.Id = this.displayedComparisonData.evModelID;
    }

    if (previousStop.departingLoadWeight > 0) {
      vehicle.Mass =
        this.displayedComparisonData.evModel.mass +
        previousStop.departingLoadWeight;
    }

    // get new energy calculations
    const [firstLegEnergy, secondLegEnergy] = await Promise.all([
      fetchEnergyNeeded({
        Vehicle: vehicle,
        Polyline: (firstLegRoute as Valhalla_RouteRes).trip.legs[0].shape, //ASSUMES only one leg due to how locations are passed in the earlier code block.
        Name: "firstLeg",
      }),
      fetchEnergyNeeded({
        Vehicle: vehicle,
        Polyline: (secondLegRoute as Valhalla_RouteRes).trip.legs[0].shape, //ASSUMES only one leg due to how locations are passed in the earlier code block.
        Name: "secondLeg",
      }),
    ]);

    // check success of energy calls
    if (!firstLegEnergy || !secondLegEnergy) return "failed"; // one or both ev nav energy calls failed.

    // compile new itinerary segment.
    const firstTravelLeg = new TravelLeg({
      polyline: (firstLegRoute as Valhalla_RouteRes).trip.legs[0].shape,
      distance: (firstLegRoute as Valhalla_RouteRes).trip.summary.length,
      drivingTime: firstLegEnergy.Time,
      ferryTime: this.calcLegFerryTime(
        (firstLegRoute as Valhalla_RouteRes).trip.legs[0]
      ),
      energyUsed: firstLegEnergy.Energy,
      startingEnergy: travelLegData.startingEnergy,
    });

    const updatedStop = stop;
    updatedStop.arrivalEnergy = firstTravelLeg.endingEnergy;
    if (updatedStop instanceof WaypointLocation) {
      updatedStop.arrivalLoadWeight = nextStop.arrivalLoadWeight;
    }

    if (updatedStop instanceof ChargerLocation) {
      updatedStop.loadWeight = nextStop.arrivalLoadWeight;
    }

    const secondTravelLeg = new TravelLeg({
      polyline: (secondLegRoute as Valhalla_RouteRes).trip.legs[0].shape,
      distance: (secondLegRoute as Valhalla_RouteRes).trip.summary.length,
      drivingTime: secondLegEnergy.Time,
      ferryTime: this.calcLegFerryTime(
        (secondLegRoute as Valhalla_RouteRes).trip.legs[0]
      ),
      energyUsed: secondLegEnergy.Energy,
      startingEnergy: updatedStop.departingEnergy,
    });

    const newItinerarySegment: [
      TravelLeg,
      ChargerLocation | WaypointLocation,
      TravelLeg,
    ] = [firstTravelLeg, updatedStop, secondTravelLeg];

    // insert new itinerary segment and delete old travel leg.
    this.itinerary.splice(travelLegIndex, 1, ...newItinerarySegment);

    // trigger floating values to be recalculated.
    this.recalculateItineraryFloatingData();
    // invalidate bounds
    this._bounds = undefined;

    // return outcome
    return "success";
  }

  /**
   * removeStop
   */
  public async removeStop(stopIndex: number): Promise<"failed" | "success"> {
    // fail fast guard clauses
    if (stopIndex < 0) return "failed"; // not a valid index.
    if (stopIndex === 0) return "failed"; // index 0 should always be the origin destination not a waypoint/charging stop that can be removed.
    if (stopIndex === 1) return "failed"; // index 1 should always be a travel leg heading from origin.
    if (stopIndex >= this.itinerary.length - 1) return "failed"; // Assumption: trying to remove something at destination or after destination has been reached this should be another trip.
    if (stopIndex + 2 >= this.itinerary.length) return "failed"; // expected index for next location is outside bounds of array.
    if (!this.displayedComparisonData || !this.displayedComparisonData.evModel)
      return "failed"; // should not be able to reach this code if no comparison has been selected to be further refined.
    // find relevant objects.
    const targetStop = this.itinerary[stopIndex];
    const previousStop = this.itinerary[stopIndex - 2];
    const nextStop = this.itinerary[stopIndex + 2];
    // guard vs wrong type of objects
    if (
      !(targetStop instanceof ChargerLocation) &&
      !(targetStop instanceof WaypointLocation)
    )
      return "failed"; // not a valid stop to remove.
    if (
      !(previousStop instanceof OriginLocation) &&
      !(previousStop instanceof WaypointLocation) &&
      !(previousStop instanceof ChargerLocation)
    )
      return "failed"; // previous stop is not a location.
    if (
      !(nextStop instanceof DestinationLocation) &&
      !(nextStop instanceof WaypointLocation) &&
      !(nextStop instanceof ChargerLocation)
    )
      return "failed"; // next stop is not a location.

    // fetch new polyline
    const valhallaData = await fetchValhallaRoutePlan(
      [
        previousStop.coordinate.asAbbreviatedObj,
        nextStop.coordinate.asAbbreviatedObj,
      ],
      this.costingModel
    );

    // check success of valhalla calls bail out if failed
    if (!valhallaData || Object.hasOwn(valhallaData, "error")) return "failed"; // valhallaData has either returned as undefined or with an error.

    // create EV Nav vehicle obj
    const vehicle: EVNavCar = {};

    if (this.displayedComparisonData.evModelID) {
      vehicle.Id = this.displayedComparisonData.evModelID;
    }

    if (previousStop.departingLoadWeight > 0) {
      vehicle.Mass =
        this.displayedComparisonData.evModel.mass +
        previousStop.departingLoadWeight;
    }

    // fetch new energy data
    const evNavData = await fetchEnergyNeeded({
      Vehicle: vehicle,
      Polyline: (valhallaData as Valhalla_RouteRes).trip.legs[0].shape, //ASSUMES only one leg due to how locations are passed in the earlier code block.
    });

    // check success of energy calls
    if (!evNavData) return "failed"; // one or both ev nav energy calls failed.

    // compile new itinerary segment.
    const newItinerarySegment = new TravelLeg({
      polyline: (valhallaData as Valhalla_RouteRes).trip.legs[0].shape,
      distance: (valhallaData as Valhalla_RouteRes).trip.summary.length,
      drivingTime: evNavData.Time,
      ferryTime: this.calcLegFerryTime(
        (valhallaData as Valhalla_RouteRes).trip.legs[0]
      ),
      energyUsed: evNavData.Energy,
      startingEnergy: previousStop.departingEnergy,
    });

    // insert new itinerary segment and delete old travel leg.
    this.itinerary.splice(stopIndex - 1, 3, newItinerarySegment);

    // trigger floating values to be recalculated.
    this.recalculateItineraryFloatingData();
    // invalidate bounds
    this._bounds = undefined;

    // return outcome
    return "success";
  }

  /** Recalculates all floating data like current energy/load etc. in the itinerary replacing values where needed.  */
  public recalculateItineraryFloatingData(): void {
    // fail fast guard clauses
    if (!this.itinerary.length) return;

    // find starting data
    let remainingLoadWeight = 0;
    let remainingEnergy = 0;
    let currentStepTime: DateTime | undefined = undefined;

    // Update itinerary
    for (let index = 0; index < this.itinerary.length; index++) {
      if (this.itinerary[index] instanceof OriginLocation) {
        // ASSUMES: this is always the starting point and as such updates the floating data starting values.

        // set load.
        remainingLoadWeight =
          (this.itinerary[index] as OriginLocation).departingLoadWeight > 0
            ? (this.itinerary[index] as OriginLocation).departingLoadWeight
            : 0;
        // set energy.
        remainingEnergy = (this.itinerary[index] as OriginLocation)
          .departingEnergy;
        // set time.
        currentStepTime = (this.itinerary[index] as OriginLocation)
          .expectedDepartureTime
          ? DateTime.fromISO(
              (this.itinerary[index] as OriginLocation)
                .expectedDepartureTime as string
            )
          : undefined;
      }

      if (this.itinerary[index] instanceof TravelLeg) {
        // update energy.
        (this.itinerary[index] as TravelLeg).startingEnergy = remainingEnergy;

        remainingEnergy = (this.itinerary[index] as TravelLeg).endingEnergy;

        // update time.
        if (currentStepTime) {
          currentStepTime.plus({
            seconds: (this.itinerary[index] as TravelLeg).totalTravelTime,
          });
        }
      }

      if (this.itinerary[index] instanceof ChargerLocation) {
        // update load
        (this.itinerary[index] as ChargerLocation).loadWeight =
          remainingLoadWeight;

        // update energy
        (this.itinerary[index] as ChargerLocation).arrivalEnergy =
          remainingEnergy;

        remainingEnergy = (this.itinerary[index] as ChargerLocation)
          .departingEnergy;
        // update time
        if (currentStepTime) {
          (this.itinerary[index] as ChargerLocation).expectedArrivalTime =
            currentStepTime.toISO() ?? undefined;

          currentStepTime.plus({
            seconds: (this.itinerary[index] as ChargerLocation).stay,
          });
        }
        // update charge time estimate
        (this.itinerary[index] as ChargerLocation).chargingTime =
          this.calcChargingTimeForLocation(
            this.itinerary[index] as ChargerLocation
          ) ?? 0;
      }

      if (this.itinerary[index] instanceof WaypointLocation) {
        // update load
        (this.itinerary[index] as WaypointLocation).arrivalLoadWeight =
          remainingLoadWeight;

        remainingLoadWeight = (this.itinerary[index] as WaypointLocation)
          .departingLoadWeight;

        // update energy
        (this.itinerary[index] as WaypointLocation).arrivalEnergy =
          remainingEnergy;

        remainingEnergy = (this.itinerary[index] as WaypointLocation)
          .departingEnergy;

        // update time
        if (currentStepTime) {
          (this.itinerary[index] as WaypointLocation).expectedArrivalTime =
            currentStepTime.toISO() ?? undefined;

          currentStepTime.plus({
            seconds: (this.itinerary[index] as WaypointLocation).stay,
          });
        }

        // update charge time estimate
        (this.itinerary[index] as WaypointLocation).chargingTime =
          this.calcChargingTimeForLocation(
            this.itinerary[index] as WaypointLocation
          ) ?? 0;
      }

      if (this.itinerary[index] instanceof DestinationLocation) {
        // update load
        (this.itinerary[index] as DestinationLocation).arrivalLoadWeight =
          remainingLoadWeight;

        // update energy
        (this.itinerary[index] as DestinationLocation).arrivalEnergy =
          remainingEnergy;

        // update time
        if (currentStepTime) {
          (this.itinerary[index] as DestinationLocation).expectedArrivalTime =
            currentStepTime.toISO() ?? undefined;
        }
      }
    }
  }

  /**
   * Provides grouped lists of suggested chargers to for a target travel leg.
   *
   * chargers are broken up into the categories of private chargers, public DC fast chargers and public AC slow chargers.
   *
   * @param itineraryItemIndex the itinerary array index value for the travel leg item you need suggested charges for.
   * @returns an object containing the arrays for the three catagories of chargers if successful undefined if failed.
   */
  public async suggestChargers(
    itineraryItemIndex: number
  ): Promise<OrderedChargerSuggestions | undefined> {
    // fail fast guard clauses
    if (itineraryItemIndex < 0) return; // not a valid index.
    if (itineraryItemIndex >= this.itinerary.length) return; // index is outside bounds of array.
    if (!(this.itinerary[itineraryItemIndex] instanceof TravelLeg)) return; // index is not referencing a travel leg object.
    if (!(this.itinerary[itineraryItemIndex - 1] instanceof ItineraryLocation))
      return; // previous item is not referencing a location object.
    if (!(this.itinerary[itineraryItemIndex + 1] instanceof ItineraryLocation))
      return; // next item is not referencing a location object.
    if (!this.displayedComparisonData?.evModelID) return; // no selected comparison model.

    // fetch and sort results
    return await orderChargerSuggestions(
      (this.itinerary[itineraryItemIndex - 1] as ItineraryLocation).coordinate,
      (this.itinerary[itineraryItemIndex + 1] as ItineraryLocation).coordinate,
      (this.itinerary[itineraryItemIndex] as TravelLeg).polyline,
      {
        Id: this.displayedComparisonData.evModelID,
      },
      this.totalDistance ? Math.floor((this.totalDistance / 10) * 1000) : 1000
    );
  }

  /**
   * Retrieves the CDB IDs of chargers along the route based on the selected comparison model.
   *
   * @return {Promise<string[]>} An array of chargers' CDB IDs. Returns an empty array if no comparison model is selected.
   */
  public async getCorridorChargerIds(reset = false): Promise<string[]> {
    if (!reset && this.displayedComparisonData?.chargersAlongRouteCDBIDs) {
      this.chargersAlongRouteCDBIDs =
        this.displayedComparisonData.chargersAlongRouteCDBIDs;
      return this.displayedComparisonData.chargersAlongRouteCDBIDs;
    }
    // Check if there is a selected comparison model
    if (!this.displayedComparisonData?.evModelID) {
      return []; // No selected comparison model, return an empty array
    }

    // Calculate the range based on the total distance of the trip
    const range = this.totalDistance
      ? Math.floor((this.totalDistance / 10) * 1000)
      : 1000;

    // Make the EVNav radar call to get the chargers along the route
    const res = await evnavRadarCall({
      Range: range, // Use the calculated range
      Polyline: this.fullTripPolyline,
      Vehicle: {
        Id: this.displayedComparisonData.evModelID, // Use the selected comparison model's ID
      },
    });

    if (res) {
      // If the call is successful, extract the CDB IDs of the chargers along the route
      const flatChargerIds = res.Chargers.flatMap((charger) => charger.CDBID);
      this.chargersAlongRouteCDBIDs = flatChargerIds;
      this.displayedComparisonData.chargersAlongRouteCDBIDs = flatChargerIds;
      return this.chargersAlongRouteCDBIDs;
    }

    return []; // Return an empty array if the call fails
  }

  /**
   * Check if the given coordinate is within the bounds.
   *
   * @param {Coordinate} coordinate - The coordinate to check.
   * @return {boolean} True if the coordinate is within the bounds, false otherwise.
   */
  public isInBounds(coordinate: Coordinate): boolean {
    /**
     * Get the bounds of the polyline points of the trip.
     * If the bounds have been previously calculated, it returns the cached value.
     * If no bounds exist, it assumes the coordinate is within bounds.
     *
     * @return {LatLngBounds | undefined} The bounds of the polyline points of the trip.
     */
    const bounds = this.getBounds();

    // Check if the coordinate is within the bounds.
    // If no bounds exist, assume it is within bounds.
    return bounds?.contains(coordinate.asLatLng) ?? true;
  }

  // -------------------------------------------------------------------- //
  // -------------------------- Private Methods ------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Returns the bounds of the polyline points of the trip.
   * If the bounds have been previously calculated, it returns the cached value.
   *
   * @return {LatLngBounds | undefined} The bounds of the polyline points of the trip.
   *                                   Returns undefined if the trip has no polyline points.
   */
  private getBounds(): LatLngBounds | undefined {
    // If the bounds have been previously calculated, return the cached value.
    if (this._bounds) return this._bounds;

    // Calculate the bounds of the polyline points.
    const points = this.getPolylinePoints().flat();
    if (!points.length) return; // If there are no polyline points, return undefined.
    const bounds = new LatLngBounds(points).pad(0.1);

    // Cache the calculated bounds.
    this._bounds = bounds;

    // Return the calculated bounds.
    return bounds;
  }

  /**
   * Converts a `TspLocation` class object to a `Valhalla_Location` object.
   *
   * @param location the whole `TspLocation` object.
   * @returns a `Valhalla_Location` object.
   */
  private TripLocationToValhallaLocation(
    location: TspLocation
  ): Valhalla_Location {
    return {
      lat: location.coordinates.latitude,
      lon: location.coordinates.longitude,
    };
  }

  /**
   * Converts a `TripLocation` class object to a
   * `Directus_SavedOptimisedTrip_Location` object.
   *
   * @param location the whole `TripLocation` object.
   * @returns a `Directus_SavedOptimisedTrip_Location` object.
   */
  private TripLocationToDirectusOptimisedTripLocation(
    location: TspLocation
  ): Directus_SavedOptimisedTrip_Location {
    return {
      address: location.address,
      ...location.coordinates,
    };
  }

  /**
   * Converts a polyline into a coordinates array.
   * @param encodedPolyline an encoded polyline string
   * @returns Array of arrays each containing a latitude longitude coordinate.
   */
  private decodePolyline(encodedPolyline: string): [number, number][] {
    return polyline.decode(encodedPolyline, 6);
  }

  private async fetchRoutePlan(): Promise<
    Valhalla_RouteRes | Valhalla_RouteError | undefined
  > {
    // non optimised route cases

    if (this.isRoundTrip && this.keepOrder) {
      // case: keep the user provided order, origin and destination are the same location
      const res = await fetchValhallaRoutePlan(
        [
          ...this.locations.map((location) =>
            this.TripLocationToValhallaLocation(location)
          ),
          this.TripLocationToValhallaLocation(this.locations[0]),
        ],
        this.costingModel
      );

      return res;
    }

    if (this.keepOrder) {
      // case: keep the user provided order
      const res = await fetchValhallaRoutePlan(
        this.locations.map((location) =>
          this.TripLocationToValhallaLocation(location)
        ),
        this.costingModel
      );

      return res;
    }

    // optimised route cases

    if (this.isRoundTrip) {
      // case: let valhalla decide the order of stops, origin and destination are the same location
      const res = await fetchValhallaOptimizedRoutePlan(
        [
          ...this.locations.map((location) =>
            this.TripLocationToValhallaLocation(location)
          ),
          this.TripLocationToValhallaLocation(this.locations[0]),
        ],
        this.costingModel
      );

      return res;
    }

    // case: let valhalla decide the order of stops
    const res = await fetchValhallaOptimizedRoutePlan(
      this.locations.map((location) =>
        this.TripLocationToValhallaLocation(location)
      ),
      this.costingModel
    );

    return res;
  }

  private generateItinerary(): ItineraryData {
    // fail fast guard clauses
    if (!this.displayedComparisonData)
      throw new Error(
        "attempted to generate itinerary before comparison EV has been selected"
      ); // guards vs no selected comparison.
    if (!this.tripData)
      throw new Error(
        "attempted to generate itinerary before route data has been fetched"
      ); // guards vs no trip data.
    if (!this.displayedComparisonData.energyDataByLeg)
      throw new Error(
        "attempted to generate itinerary before energy data has been fetched"
      ); // guards vs no trip data.

    const batterySize = this.displayedComparisonData.batterySize;
    if (!batterySize)
      throw new Error("can't find energy data to generate itinerary"); // guards vs no battery data.

    let remainingEnergy: number = batterySize * this.startingSoC;
    let remainingLoadWeight = 0;
    let currentStepTime: DateTime | undefined = undefined;

    const itineraryBuildingData: ItineraryData = [];
    // iterate through order of locations
    for (let index = 0; index < this.locationOrder.length; index++) {
      if (index === 0) {
        // find origin data
        const location = this.orderedLocationData(0);
        if (!location)
          throw new Error("location data not found while generating itinerary");

        // calc load
        const load =
          location.weightChange && location.weightChange > 0
            ? location.weightChange
            : 0;

        currentStepTime = location.time
          ? DateTime.fromISO(location.time)
          : undefined;

        // add origin
        itineraryBuildingData.unshift(
          new OriginLocation({
            addressDisplayStr: location.address,
            coordinate: new Coordinate(location.coordinates),
            departingEnergy: remainingEnergy,
            departingLoadWeight: load,
            departingSoC: this.startingSoC,
            expectedDepartureTime: location.time,
          })
        );

        remainingLoadWeight = load;
      } else {
        // add legs and waypoints/destination

        // find leg data
        const legMapData = this.tripData.legs[index - 1];
        const legEnergyData =
          this.displayedComparisonData.energyDataByLeg[index - 1];

        // find location data
        const location = this.orderedLocationData(index);
        if (!location)
          throw new Error("location data not found while generating itinerary");

        // create leg
        const leg = new TravelLeg({
          polyline: legMapData.shape,
          drivingTime: legEnergyData.Time,
          energyUsed: legEnergyData.Energy,
          ferryTime: this.calcLegFerryTime(legMapData),
          startingEnergy: remainingEnergy,
          distance: legMapData.summary.length,
        });

        const arrivalTime = currentStepTime?.plus({
          seconds: leg.totalTravelTime,
        });

        if (index === this.locationOrder.length - 1) {
          const itineraryLocation = new DestinationLocation({
            addressDisplayStr: location.address,
            coordinate: new Coordinate(location.coordinates),
            arrivalLoadWeight: remainingLoadWeight,
            arrivalEnergy:
              remainingEnergy - leg.energyUsed >= 0
                ? remainingEnergy - leg.energyUsed
                : 0,
            expectedArrivalTime: arrivalTime?.toISO() ?? undefined,
          });

          itineraryBuildingData.push(leg, itineraryLocation);
        } else {
          const itineraryLocation = new WaypointLocation({
            addressDisplayStr: location.address,
            coordinate: new Coordinate(location.coordinates),
            arrivalLoadWeight: remainingLoadWeight,
            loadWeightChange: location.weightChange,
            arrivalEnergy:
              remainingEnergy - leg.energyUsed >= 0
                ? remainingEnergy - leg.energyUsed
                : 0,
            energyUsed: location.nonDrivingChargeUsed,
            energyAdded: location.addedCharge,
            stay: location.stay,
            expectedArrivalTime: arrivalTime?.toISO() ?? undefined,
            kWRating: location.kWChargerRating,
            currentType: location.kWChargerRating
              ? location.kWChargerRating < 28
                ? "AC"
                : "DC"
              : undefined,
          });

          itineraryBuildingData.push(leg, itineraryLocation);
          remainingEnergy = itineraryLocation.departingEnergy;
          remainingLoadWeight = itineraryLocation.departingLoadWeight;
          currentStepTime = arrivalTime?.plus({
            seconds: itineraryLocation.stay,
          });
        }
      }
    }
    return itineraryBuildingData;
  }

  private orderedLocationData(orderIndex: number): TspLocation | undefined {
    if (orderIndex >= this.locationOrder.length) return; // guard vs index outside bounds of array.
    return this.locations.find(
      (listLocation) => listLocation.localId === this.locationOrder[orderIndex]
    );
  }

  private calcLegFerryTime(leg: Valhalla_Leg): number {
    return leg.maneuvers.reduce((accumulator, currentItem) => {
      if (currentItem.ferry) {
        return accumulator + currentItem.time;
      }

      return accumulator;
    }, 0);
  }

  /**
   * Calculates estimated charging time in seconds if one can be calculated.
   *
   * @param location the whole `ChargerLocation` or `WaypointLocation` object for this location.
   * @returns charging time in seconds if one can be calculated. Undefined if not calculable.
   */
  private calcChargingTimeForLocation(
    location: ChargerLocation | WaypointLocation
  ): number | undefined {
    // fail fast guard clauses
    if (!location.kWRating) return;
    if (!this.displayedComparisonData?.batterySize) return;

    // determine which calculation to use.
    let useCalc: "AC" | "DC" | undefined = location.currentType;
    if (!useCalc) useCalc = location.kWRating < 28 ? "AC" : "DC"; // fallback: base current type on rating if current type is not known.

    // calculate charger time in second.
    if (useCalc === "DC") {
      return (
        (timeWithDCCurve(
          location.arrivalEnergy,
          location.departingEnergy,
          this.displayedComparisonData.batterySize,
          location.kWRating
        ) ?? 0) * 3600 // convert hours to seconds before returning
      );
    }

    if (useCalc === "AC") {
      return (
        (calcChargingTimeAC(
          location.arrivalEnergy,
          location.departingEnergy,
          location.kWRating
        ) ?? 0) * 3600 // convert hours to seconds before returning
      );
    }
  }
}

export enum TspTripPlanningStatus {
  errorUnknown = "errorUnknown",
  errorNotEnoughLocations = "errorNotEnoughLocations",
  errorNotRoutable = "errorNotRoutable",
  success = "success",
}
