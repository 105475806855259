import { Valhalla_Trip } from "@/logic/types/valhalla_types";
import { decodePolyline } from "@/logic/utils/polylineUtils";
import TripLocationV2 from "../trip_v2_classes/tripLocation_v2";
import {
  EVNavEnergy,
  EVNavRoutePlan,
  EVNavStep,
} from "@/logic/types/ev_nav_types";
import haversineDistance from "@/logic/utils/haversineDistance";
import store from "@/logic/store";
import Vehicle from "../vehicle_classes/vehicle";

export interface ItineraryV2Options {
  steps?: ItineraryV2Step[];
  destination?: ItineraryV2Destination;
}

export interface ItineraryV2Step {
  addressStr: string;
  name: string;
  polyline: string;
  energyBeforeTraveling: number;
  energyUsedTraveling: number;
  energyAfterTraveling: number;
  chargeBeforeCharging: number;
  chargeBeforeTraveling: number;
  chargeUsedTraveling: number;
  chargeAfterTraveling: number;
  energyUsedAtLocation: number;
  chargeUsedAtLocation: number;
  energyAdded: number;
  chargeAdded: number;
  chargingTime: number;
  chargingCost: number;
  drivingDistance: number;
  ferryTime: number;
  travelTime: number;
  locationStayTime: number;
  locationCDBID?: string;
  userAdded: boolean;
  arrivalLoadWeight: number;
  departureLoadWeight: number;
}

export interface ItineraryV2Destination {
  addressStr: string;
  name: string;
  arrivalCharge: number;
}

export default class ItineraryV2 {
  steps: ItineraryV2Step[];
  destination?: ItineraryV2Destination;
  constructor(options: ItineraryV2Options | undefined = undefined) {
    this.steps = options?.steps ?? [];
    this.destination = options?.destination;
  }

  static buildFromValhallaTrip({
    batterySize,
    trip,
    locations,
    energyData,
    startingSoC,
    roundTripFlag,
    startingLoad,
  }: {
    batterySize: number;
    trip: Valhalla_Trip;
    locations: TripLocationV2[];
    energyData: EVNavEnergy[] | undefined;
    startingSoC: number;
    roundTripFlag: boolean;
    startingLoad: number;
  }): ItineraryV2 {
    const steps: ItineraryV2Step[] = [];
    const destination: ItineraryV2Destination = {
      addressStr: roundTripFlag
        ? locations[0].address
        : locations[locations.length - 1].address,
      name:
        (roundTripFlag
          ? locations[0].name
          : locations[locations.length - 1].name) ?? "unnamed location",
      arrivalCharge: 0,
    };
    let remainingEnergy = batterySize * startingSoC;
    let remainingLoad = startingLoad;

    // step through legs to create steps
    trip.legs.forEach((leg, index) => {
      // get corresponding starting locations original index
      const originalLocationIndex = trip.locations[index].original_index;
      // get corresponding location
      const location =
        originalLocationIndex >= 0
          ? originalLocationIndex > locations.length - 1
            ? roundTripFlag
              ? locations[0]
              : undefined
            : locations[originalLocationIndex]
          : undefined;
      if (!location)
        throw new Error(`starting location relating to leg ${index} not found`);
      // get corresponding energy data
      const legEnergyData = energyData ? energyData[index] : undefined;
      // calculate remaining load
      const departureLoadWeight = Math.max(
        remainingLoad + (location.weightChange ?? 0),
        0
      );
      // create step
      steps.push({
        addressStr: location.address,
        name: location.name ?? "unnamed location",
        polyline: leg.shape,
        energyBeforeTraveling:
          remainingEnergy +
          (location.stateOfChargeAfterCharging
            ? batterySize *
              (location.stateOfChargeAfterCharging -
                remainingEnergy / batterySize)
            : 0) -
          (location.nonDrivingEnergyUsed ?? 0),
        energyUsedTraveling: legEnergyData?.Energy ?? 0,
        energyAfterTraveling:
          remainingEnergy +
          (location.stateOfChargeAfterCharging
            ? batterySize *
              (location.stateOfChargeAfterCharging -
                remainingEnergy / batterySize)
            : 0) -
          (location.nonDrivingEnergyUsed ?? 0) -
          (legEnergyData?.Energy ?? 0),
        chargeBeforeCharging: remainingEnergy / batterySize,
        chargeBeforeTraveling:
          (remainingEnergy +
            (location.stateOfChargeAfterCharging
              ? batterySize *
                (location.stateOfChargeAfterCharging -
                  remainingEnergy / batterySize)
              : 0) -
            (location.nonDrivingEnergyUsed ?? 0)) /
          batterySize,
        chargeUsedTraveling: legEnergyData?.Energy
          ? legEnergyData.Energy / batterySize
          : 0,
        chargeAfterTraveling:
          (remainingEnergy +
            (location.stateOfChargeAfterCharging
              ? batterySize *
                (location.stateOfChargeAfterCharging -
                  remainingEnergy / batterySize)
              : 0) -
            (location.nonDrivingEnergyUsed ?? 0) -
            (legEnergyData?.Energy ?? 0)) /
          batterySize,
        energyUsedAtLocation: location.nonDrivingEnergyUsed ?? 0,
        chargeUsedAtLocation: location.nonDrivingEnergyUsed
          ? location.nonDrivingEnergyUsed / batterySize
          : 0,
        energyAdded: location.stateOfChargeAfterCharging
          ? batterySize *
            (location.stateOfChargeAfterCharging -
              remainingEnergy / batterySize)
          : 0,
        chargeAdded: location.stateOfChargeAfterCharging
          ? location.stateOfChargeAfterCharging - remainingEnergy / batterySize
          : 0,
        chargingTime: 0,
        chargingCost: 0,
        drivingDistance: leg.summary.length * 1000,
        ferryTime: leg.summary.has_ferry
          ? leg.maneuvers.reduce(
              (acc, maneuver) => acc + (maneuver.ferry ? maneuver.time : 0),
              0
            )
          : 0,
        travelTime: leg.summary.has_ferry
          ? leg.maneuvers.reduce(
              (acc, maneuver) => acc + (!maneuver.ferry ? maneuver.time : 0),
              0
            )
          : leg.summary.time,
        locationCDBID: undefined,
        locationStayTime: location.stay ?? 0,
        userAdded: !!location,
        arrivalLoadWeight: remainingLoad,
        departureLoadWeight,
      });
      // update remaining energy
      remainingEnergy =
        remainingEnergy -
        (legEnergyData?.Energy ?? 0) -
        (location.nonDrivingEnergyUsed ?? 0) +
        (location.stateOfChargeAfterCharging
          ? location.stateOfChargeAfterCharging - remainingEnergy / batterySize
          : 0);
      // update remaining load
      remainingLoad = departureLoadWeight;
    });

    // calculate arrival charge
    destination.arrivalCharge = remainingEnergy / batterySize;

    // generate and return itinerary
    return new ItineraryV2({
      steps,
      destination,
    });
  }

  static buildFromEVNavTrip({
    batterySize,
    trip,
    locations,
    roundTripFlag,
    startingLoad,
  }: {
    batterySize: number;
    trip: EVNavRoutePlan | EVNavRoutePlan[];
    locations: TripLocationV2[];
    roundTripFlag: boolean;
    startingLoad: number;
  }): ItineraryV2 {
    const flatEVNavSteps: EVNavStep[] =
      trip instanceof Array ? trip.flatMap((plan) => plan.Steps) : trip.Steps;
    const destination: ItineraryV2Destination = {
      addressStr: roundTripFlag
        ? locations[0].address
        : locations[locations.length - 1].address,
      name:
        (roundTripFlag
          ? locations[0].name
          : locations[locations.length - 1].name) ?? "unnamed location",
      arrivalCharge: flatEVNavSteps[flatEVNavSteps.length - 1].EndCharge,
    };
    let remainingLoad = startingLoad;
    const steps: ItineraryV2Step[] = [];
    flatEVNavSteps.forEach((step) => {
      // get corresponding starting location
      const location = locations.find(
        (location) => location.local_id === step.From
      );
      // calculate remaining load
      // calculate remaining load
      const departureLoadWeight = Math.max(
        remainingLoad + (location?.weightChange ?? 0),
        0
      );
      // create step
      steps.push({
        addressStr:
          location?.address ?? step.Charger?.ParkName ?? "Unknown address",
        name:
          location?.name ??
          step.Charger?.ParkName ??
          (step.Charger?.Network
            ? step.Charger.Network + " charger"
            : "unknown network charger"),
        polyline: step.Polyline,
        energyBeforeTraveling: batterySize * step.StartCharge,
        energyUsedTraveling: step.Energy,
        energyAfterTraveling: batterySize * step.EndCharge,
        chargeBeforeCharging: step.ArrivalCharge,
        chargeBeforeTraveling: step.StartCharge,
        chargeUsedTraveling: step.Energy / batterySize,
        chargeAfterTraveling: step.EndCharge,
        energyUsedAtLocation: location?.nonDrivingEnergyUsed ?? 0,
        chargeUsedAtLocation: location?.nonDrivingEnergyUsed
          ? batterySize * location.nonDrivingEnergyUsed
          : 0,
        energyAdded: step.Charge,
        chargeAdded: step.Charge / batterySize,
        chargingTime: step.ChargeTime ?? 0,
        chargingCost: step.ChargeCost ?? 0,
        drivingDistance: step.Distance,
        ferryTime: step.FerryTime,
        travelTime: step.TravelTime,
        locationCDBID: step.Charger?.CDBID ?? undefined,
        locationStayTime: location?.stay ?? 0,
        userAdded: !!location,
        arrivalLoadWeight: remainingLoad,
        departureLoadWeight,
      });
      // update remaining load
      remainingLoad = departureLoadWeight;
    });

    // generate and return itinerary
    return new ItineraryV2({
      steps,
      destination,
    });
  }

  // ----------------------------------------------------------------------- //
  // -------------------------------- Getters ------------------------------ //
  // ----------------------------------------------------------------------- //

  /**
   * Checks if the ItineraryV2 instance is valid.
   *
   * @return {boolean} Returns true if the instance has at least one step and a
   * destination, otherwise false.
   */
  public get isValid(): boolean {
    return !!this.steps.length && !!this.destination;
  }

  /**
   * Calculate the total travel time spent driving for the trip.
   *
   * @return {number} The total driving time in seconds.
   */
  public get totalTravelTime(): number {
    return this.steps.reduce((acc, step) => acc + step.travelTime, 0);
  }

  /**
   * Returns the total time spent at scheduled stops and charging stops for all
   * steps in the itinerary.
   *
   * @return {number} The total stopped time in seconds.
   */
  public get totalStoppedTime(): number {
    return this.steps.reduce(
      (acc, step) => acc + Math.max(step.locationStayTime, step.chargingTime),
      0
    );
  }

  /**
   * Calculate the total ferry time for the trip.
   *
   * @return {number} The total ferry time in seconds.
   */
  public get totalFerryTime(): number {
    return this.steps.reduce((acc, step) => acc + step.ferryTime, 0);
  }

  /**
   * Returns the total time for the trip in seconds.
   *
   * @return {number} The total time for the trip in seconds.
   */
  public get totalTime(): number {
    return this.totalTravelTime + this.totalStoppedTime + this.totalFerryTime;
  }

  /**
   * Calculates the total driving distance for the trip by summing up the
   * driving distances of each step in meters.
   *
   * @return {number} The total driving distance for the trip in meters.
   */
  public get totalDrivingDistance(): number {
    return this.steps.reduce((acc, step) => acc + step.drivingDistance, 0);
  }

  /**
   * Returns the points of each leg in the trip as latitude and longitude
   * coordinate arrays.
   *
   * @return {[number, number][][]} The points of each leg in the trip.
   */
  public get pointsByLeg(): [number, number][][] {
    return this.steps.map((step) => decodePolyline(step.polyline));
  }

  /**
   * Returns an array of charger IDs for chargers stopped at as part of the trip.
   *
   * @return {string[]} An array of charger IDs.
   */
  public get chargerIDs(): string[] {
    return (
      this.steps
        .filter((step) => !!step.locationCDBID)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((step) => step.locationCDBID!)
    );
  }

  /**
   * Calculates the total cost of charging for all steps in the itinerary.
   *
   * @return {number} The total cost of charging.
   */
  public get totalChargingCost(): number {
    return this.steps.reduce((acc, step) => acc + step.chargingCost, 0);
  }

  /**
   * Calculates the total charging time for all steps in the itinerary.
   *
   * @return {number} The total charging time.
   */
  public get totalChargingTime(): number {
    return this.steps.reduce((acc, step) => acc + step.chargingTime, 0);
  }

  /**
   * Calculates the total energy used by all steps in the itinerary.
   *
   * @return {number} The total energy used.
   */
  public get totalEnergyUsed(): number {
    return this.steps.reduce(
      (acc, step) => acc + step.energyUsedAtLocation + step.energyUsedTraveling,
      0
    );
  }

  /**
   * Calculates the total private energy added in the itinerary.
   *
   * Note: private is defined by charging at a charger that is not recognised by the charger DB.
   *
   * @return {number} The total private energy added.
   */
  public get totalPrivateEnergyAdded(): number {
    return this.steps
      .filter((step) => !step.locationCDBID)
      .reduce((acc, step) => acc + step.energyAdded, 0);
  }

  /**
   * Calculates the total public energy added in the itinerary.
   *
   * Note: public is defined by charging at a charger that is recognised by the charger DB.
   *
   * @return {number} The total public energy added.
   */
  public get totalPublicEnergyAdded(): number {
    return this.steps
      .filter((step) => step.locationCDBID)
      .reduce((acc, step) => acc + step.energyAdded, 0);
  }

  /**
   * Calculates the total energy added in the itinerary.
   *
   * @return {number} The total energy added.
   */
  public get totalEnergyAdded(): number {
    return this.totalPrivateEnergyAdded + this.totalPublicEnergyAdded;
  }

  /**
   * Recalculates all floating data like current energy/load etc. in the itinerary replacing values where needed.
   *
   * Note: This should be called after any changes to the itinerary or its data.
   *
   * @param vehicle - Vehicle object to calculate energy usage and charging times with. If undefined, will not update energy usage and charging times.
   */
  recalculateFloatingData(vehicle?: Vehicle) {
    const tempStepsArray: ItineraryV2Step[] = [];
    this.steps.forEach((step, index) => {
      if (index === 0) {
        tempStepsArray.push(step);
      } else {
        // charging data
        const chargeBeforeCharging =
          tempStepsArray[index - 1].chargeAfterTraveling;
        const chargeAdded = Math.min(
          step.chargeAdded,
          1 - chargeBeforeCharging
        );
        const chargeBeforeTraveling = Math.min(
          chargeBeforeCharging + chargeAdded - step.chargeUsedAtLocation,
          1
        );

        const chargeAfterTraveling =
          chargeBeforeTraveling - step.chargeUsedTraveling;
        const energyAdded = vehicle?.totalBatteryKWh()
          ? vehicle.totalBatteryKWh() * chargeAdded
          : step.energyAdded;
        const energyBeforeTraveling = vehicle?.totalBatteryKWh()
          ? Math.min(
              tempStepsArray[index - 1].energyAfterTraveling -
                step.energyUsedAtLocation +
                energyAdded,
              vehicle.totalBatteryKWh()
            )
          : tempStepsArray[index - 1].energyAfterTraveling -
            step.energyUsedAtLocation +
            energyAdded;
        const energyAfterTraveling =
          energyBeforeTraveling - step.energyUsedTraveling;
        // charging time
        const chargers = store.state.chargers;
        const charger = chargers.find(
          (charger) => charger.id === step.locationCDBID
        );
        const connector = vehicle
          ? charger?.bestCompatibleConnector(vehicle)
          : undefined;
        const chargingEstimate = vehicle
          ? connector?.getChargingEstimateDetails(
              vehicle,
              {
                min: chargeBeforeCharging * 100,
                max: chargeBeforeTraveling * 100,
              },
              connector.powerType === "DC"
                ? store.state.defaultPublicCostPerKWh
                : store.state.defaultHomeCostPerKWh,
              connector.powerType === "DC" ? store.state.defaultCostPerMinDC : 0
            )
          : undefined;
        const chargingTime = chargingEstimate?.chargingTimeInSeconds ?? 0;
        const chargingCost = chargingEstimate?.chargingCost ?? 0;

        tempStepsArray.push({
          ...step,
          chargeBeforeCharging,
          chargeBeforeTraveling,
          chargeAfterTraveling,
          chargeAdded,
          energyBeforeTraveling,
          energyAfterTraveling,
          energyAdded,
          chargingTime,
          chargingCost,
        });
      }
    });

    this.steps = tempStepsArray;
    if (this.destination)
      this.destination.arrivalCharge =
        this.steps[this.steps.length - 1].chargeAfterTraveling;
  }

  findClosestStepIndex({ lat, lon }: { lat: number; lon: number }): number {
    const stepDistances = this.steps.map((step, index) => {
      const points = decodePolyline(step.polyline);
      const distanceToStartPoint = haversineDistance(
        [lon, lat],
        [points[0][1], points[0][0]]
      );
      const distanceToEndPoint = haversineDistance(
        [lon, lat],
        [points[points.length - 1][1], points[points.length - 1][0]]
      );
      return {
        distanceScore: distanceToStartPoint + distanceToEndPoint,
        index: index,
      };
    });
    stepDistances.sort((a, b) => a.distanceScore - b.distanceScore);
    return stepDistances[0].index;
  }
}
