<template>
  <v-card class="py-4 pl-md-12 rounded-lg mb-10 background">
    <v-row class="flex-column flex-md-row">
      <v-col cols-md="3">
        <!-- image section -->
        <v-skeleton-loader
          type="image"
          :width="$vuetify.breakpoint.mdAndUp ? '100%' : '40%'"
          height="140px"
          v-if="!selectedVehicle"
          class="ml-4 ml-md-0"
        />
        <v-row no-gutters v-else-if="$vuetify.breakpoint.smAndDown">
          <v-img
            contain
            :src="imageSrc(selectedVehicle)"
            aspect-ratio="1"
            width="40%"
            height="auto"
            class="ml-4 flex-grow-0"
          ></v-img>
          <v-spacer />
          <v-btn icon @click="emitViewVehicle(selectedVehicle)">
            <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
          </v-btn>
        </v-row>
        <v-img
          v-else
          contain
          :src="imageSrc(selectedVehicle)"
          aspect-ratio="1"
          width="100%"
          height="100%"
        ></v-img>
      </v-col>
      <v-col cols-md="9">
        <!-- details section -->
        <div v-if="selectedVehicle">
          <v-card-title>
            {{ selectedVehicle.name ?? "unnamed vehicle" }}
            <v-spacer></v-spacer>

            <TextBtn
              @click="emitViewVehicle(selectedVehicle)"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              Vehicle settings
            </TextBtn>
          </v-card-title>
          <v-card-subtitle class="font-weight-medium pr-12">
            <p class="mb-0">
              {{ selectedVehicle.licensePlate ?? "unrecorded license plate" }}
            </p>
            <p v-if="selectedVehicle.evModel">
              {{ selectedVehicle.evModel.name }}
            </p>
          </v-card-subtitle>
          <v-card-text class="pr-md-12">
            <BatteryDisplay :battery="selectedVehicle.stateOfCharge ?? 0" />
            <OutlinedBlockBtn
              @click="handleSelectOther()"
              v-if="$vuetify.breakpoint.smAndDown"
              class="mt-5"
            >
              Change selected vehicle
            </OutlinedBlockBtn>
          </v-card-text>
        </div>
        <div v-else>
          <v-row no-gutters class="mb-4 ml-5">
            <v-skeleton-loader type="heading" width="70%" />
            <v-skeleton-loader
              v-if="$vuetify.breakpoint.mdAndUp"
              type="heading"
              width="30%"
            />
          </v-row>
          <v-skeleton-loader type="text" width="40%" class="ml-5" />
          <v-skeleton-loader type="text" width="70%" class="mb-4 ml-5" />
          <v-skeleton-loader type="heading" width="100%" class="ml-5" />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import EVModel from "@/logic/classes/vehicle_classes/evModel";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import {
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Vue from "vue";
import TextBtn from "../ui-elements/buttons/TextBtn.vue";
import OutlinedBlockBtn from "../ui-elements/buttons/OutlinedBlockBtn.vue";
import BatteryDisplay from "../ui-elements/BatteryDisplay.vue";

export default Vue.extend({
  name: "DefaultVehicleCard",
  computed: {
    selectedVehicle(): Vehicle | undefined {
      return this.$store.getters[GettersTypes.selectedVehicleData];
    },
  },
  components: {
    TextBtn,
    OutlinedBlockBtn,
    BatteryDisplay,
  },
  methods: {
    /**
     * Returns the source URL of the image associated with the vehicle.
     *
     * @param {Vehicle} vehicle - The vehicle object.
     * @return {string} The source URL of the image.
     */
    imageSrc(vehicle: Vehicle): string {
      // get uploaded image to car in first instance.
      const images = vehicle.imageSrcPaths;
      if (images.length) return images[0];
      // get model image in second instance.
      if (vehicle.evModel)
        return (
          (vehicle.evModel as EVModel)?.imageSrc ??
          getAssetSrc("car_images/No_Image_Powersell.png")
        );
      // get company logo image in third instance.
      const company = (this.$store.state as State).fleet;
      if (company && company.logoSrc) {
        return company.logoSrc;
      }
      // get default image in fourth instance.
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },

    /**
     * Emits an event to view a vehicle. If the vehicle being viewed is the
     * same as the selected vehicle, the event emits `undefined` as the vehicle
     * ID. Otherwise, the event emits the local ID of the vehicle.
     *
     * @param {Vehicle} vehicle - The vehicle to be viewed.
     * @return {void} This function does not return anything.
     */
    emitViewVehicle(vehicle: Vehicle) {
      this.$emit("view-vehicle", vehicle);
    },
    /**
     * Handles the selection of the "Other" option in the vehicle details dialog.
     *
     * @return {void} This function does not return a value.
     */
    handleSelectOther(): void {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_GALLERY
      );
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}

.forty-eight-percent-width {
  width: 48%;
}
</style>
