<template>
  <v-card flat class="pa-5 background">
    <!-- selected vehicle card -->
    <SelectedVehicleCardLong @view-vehicle="emitViewVehicle" />
    <!-- saved vehicles side scroller list -->
    <v-card-text class="text-h6">
      Saved vehicles
      <v-slide-group show-arrows class="pt-2" center-active>
        <v-slide-item
          v-for="(vehicle, index) in customVehicles"
          :key="'saved-vehicle-' + index"
          style="width: 300px"
          class="pr-2"
          :class="index == 0 ? 'pl-1' : ''"
        >
          <VehicleListCard
            :vehicle="vehicle"
            class="ma-1"
            @click="emitViewVehicle(vehicle)"
          />
        </v-slide-item>
      </v-slide-group>
    </v-card-text>
    <!-- fleet vehicles two column list -->
    <v-card-text class="text-h6">
      Fleet vehicles
      <v-text-field
        v-model="searchQuery"
        prepend-inner-icon="mdi-magnify"
        rounded
        filled
        clearable
        hide-details
        dense
        class="my-3 mr-2"
        placeholder="Search vehicles available to you"
      />
      <v-row no-gutters>
        <VehicleListCard
          v-for="(vehicle, index) in connectedVehicles"
          :key="'fleet-vehicle-' + index"
          :vehicle="vehicle"
          class="forty-eight-percent-width ma-1 background"
          @click="emitViewVehicle(vehicle)"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import EVModel from "@/logic/classes/vehicle_classes/evModel";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { GettersTypes, State } from "@/logic/store/store_types";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Vue from "vue";
import VehicleListCard from "./VehicleListCard.vue";
import SelectedVehicleCardLong from "./SelectedVehicleCardLong.vue";

export default Vue.extend({
  name: "VehicleList",
  components: {
    VehicleListCard,
    SelectedVehicleCardLong,
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    connectedVehicles(): Vehicle[] {
      const unfilteredData: Vehicle[] =
        this.$store.getters[GettersTypes.connectedVehicles];
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        return unfilteredData.filter((vehicle) => {
          if (vehicle.name && vehicle.name.toLowerCase().includes(query))
            return vehicle;
          if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query))
            return vehicle;
          if (
            vehicle.licensePlate &&
            vehicle.licensePlate.toLowerCase().includes(query)
          )
            return vehicle;
          if (vehicle.evModel) {
            if (vehicle.evModel.name.toLowerCase().includes(query))
              return vehicle;
          }
        });
      }
      return unfilteredData;
    },
    customVehicles(): Vehicle[] {
      return this.$store.getters[GettersTypes.customVehicles];
    },
    selectedVehicle(): Vehicle | undefined {
      return this.$store.getters[GettersTypes.selectedVehicleData];
    },
  },
  methods: {
    /**
     * Returns the source URL of the image associated with the vehicle.
     *
     * @param {Vehicle} vehicle - The vehicle object.
     * @return {string} The source URL of the image.
     */
    imageSrc(vehicle: Vehicle): string {
      // get uploaded image to car in first instance.
      const images = vehicle.imageSrcPaths;
      if (images.length) return images[0];
      // get model image in second instance.
      if (vehicle.evModel)
        return (
          (vehicle.evModel as EVModel)?.imageSrc ??
          getAssetSrc("car_images/No_Image_Powersell.png")
        );
      // get company logo image in third instance.
      const company = (this.$store.state as State).fleet;
      if (company && company.logoSrc) {
        return company.logoSrc;
      }
      // get default image in fourth instance.
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },
    /**
     * Emits an event to view a vehicle. If the vehicle being viewed is the
     * same as the selected vehicle, the event emits `undefined` as the vehicle
     * ID. Otherwise, the event emits the local ID of the vehicle.
     *
     * @param {Vehicle} vehicle - The vehicle to be viewed.
     * @return {void} This function does not return anything.
     */
    emitViewVehicle(vehicle: Vehicle) {
      const id =
        vehicle.localId === this.selectedVehicle?.localId
          ? undefined
          : vehicle.localId;
      this.$emit("view-vehicle", id);
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}

.forty-eight-percent-width {
  width: 48%;
}
</style>
