<template>
  <v-card class="rounded-lg mb-3 pa-5">
    <v-row no-gutters justify="end" align="center">
      <v-btn icon @click="removeStop" class="mr-n2 mt-n2">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <AddressAutocompleteInput
      :loading="planning"
      :id="stop.localId"
      :initialValue="{
        address: stop.address,
        waypoint: stop.coordinates,
      }"
      @update="handleAddressChange"
      :errorMsg="errorMsg"
      :geoLocation="geoLocation"
      :allowFavLocations="true"
    />
    <!-- more details section -->
    <v-expansion-panels accordion v-model="expanded" flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="primary--text px-0">{{
          expanded === 0 ? "Less details" : "Add more details"
        }}</v-expansion-panel-header>
        <v-expansion-panel-content class="ml-n6 mr-n10 pr-4">
          <v-text-field
            v-model="weightChange"
            label="load change (kgs)"
            type="number"
            append-icon="mdi-weight-kilogram"
            hide-spin-buttons
            @change="handleWeightChange"
          />
          <v-text-field
            label="Change used for non travel purposes (kWh)"
            type="number"
            append-icon="mdi-lightning-bolt"
            hide-spin-buttons
            @change="handleWeightChange"
          />
          Stay Duration
          <StayDurationInput
            :identifier="stop.localId"
            :initialValue="stop.stay"
            @update="handleStayChange"
          />
          <v-checkbox
            label="charge here"
            color="primary"
            v-model="chargeHere"
            @change="handleToggleChargeHere"
          />
          <v-text-field
            v-model="rating"
            label="charger rating (max kW)"
            type="number"
            hide-spin-buttons
            append-icon="mdi-ev-station"
            v-if="chargeHere"
            @change="handleChargeChange"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script lang="ts">
import Coordinate from "@/logic/classes/common_classes/coordinate";
import TspLocation from "@/logic/classes/tsp_trip_classes/tspLocation";
import { State } from "@/logic/store/store_types";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import AddressAutocompleteInput, {
  type AddressAutocompleteInputUpdateObj,
} from "@/ui/components/ui-elements/inputs/AddressAutocompleteInput.vue";
import StayDurationInput, {
  StayDurationInputUpdateObj,
} from "@/ui/components/ui-elements/inputs/StayDurationInput.vue";
import Vue, { PropType } from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "TspStopCard",
  props: {
    errorMsg: {
      type: Array as PropType<string[]>,
      default: [] as string[],
    },
    planning: {
      type: Boolean,
      default: false,
    },
    stop: Object as PropType<TspLocation>,
    geoLocation: {
      type: Object as PropType<Coordinate | undefined>,
    },
  },
  methods: {
    /**
     * Emits an "update" event with the updated stop object.
     *
     * @param {TspLocation} updatedStop - The updated stop object.
     * @return {void} This function does not return anything.
     */
    emitUpdatedDetails(updatedStop: TspLocation) {
      this.$emit("update", updatedStop);
    },
    /**
     * Removes the stop by emitting a "remove" event.
     *
     * @return {void} This function does not return anything.
     */
    removeStop() {
      this.$emit("remove");
    },
    /**
     * Handles the change event of the address input field.
     *
     * @param {AddressAutocompleteInputUpdateObj} val - The updated value of the address input field.
     * @return {void} This function does not return anything.
     */
    handleAddressChange(val: AddressAutocompleteInputUpdateObj): void {
      // not null guard clause
      if (!val.addressData) return;

      // create new object
      const tempObj: TspLocation = new TspLocation({
        ...this.stop,
        address: val.addressData.address,
        coordinates: new Coordinate({
          latitude: val.addressData.coordinates.Latitude,
          longitude: val.addressData.coordinates.Longitude,
        }),
      });

      if (val.addressData.localId) {
        // find fav location
        const favLocation = this.favLocations.find(
          (fav) => fav.localId === val.addressData?.localId
        );
        // check if fav location exists
        if (favLocation) {
          // add fav location planning data to tempObj
          if (favLocation.planningData?.loadWeightChange) {
            tempObj.weightChange = favLocation.planningData.loadWeightChange;
            this.weightChange = favLocation.planningData.loadWeightChange;
          }
          if (favLocation.planningData?.chargeHere) {
            tempObj.chargeHere = favLocation.planningData.chargeHere;
            this.chargeHere = favLocation.planningData.chargeHere;
          }
          if (favLocation.planningData?.rating) {
            tempObj.kWChargerRating = favLocation.planningData.rating;
            this.rating = favLocation.planningData.rating;
          }
        }
      }

      // emit updated object
      this.emitUpdatedDetails(tempObj);
    },
    /**
     * Handles the toggle of charging at the current stop.
     *
     * If charging is not enabled and there is a charger rating,
     * it purges the value from the stop and creates a new TspLocation object
     * without the kWChargerRating. It then emits the updated object.
     *
     * If charging is enabled and there is no charger rating but a rating is provided,
     * it calls the handleChargeChange() method.
     *
     * @return {void} This function does not return anything.
     */
    handleToggleChargeHere() {
      // purge value from stop.
      if (!this.chargeHere && this.stop.kWChargerRating) {
        // create new object
        const tempObj: TspLocation = new TspLocation({
          ...this.stop,
          kWChargerRating: undefined,
        });

        // emit updated object
        this.emitUpdatedDetails(tempObj);
      }

      // reinstate form value to stop.
      if (this.chargeHere && !this.stop.kWChargerRating && this.rating) {
        this.handleChargeChange();
      }
    },
    /**
     * Handles the change in charge by performing the following steps:
     * 1. Checks if the charge is enabled and a rating is provided. If not, returns early.
     * 2. Parses the rating into an integer or float. If parsing fails, returns early.
     * 3. Validates if the parsed rating is greater than 0. If not, returns early.
     * 4. Creates a new TspLocation object with the updated kWChargerRating.
     * 5. Emits the updated object using the emitUpdatedDetails method.
     *
     * @return {void}
     */
    handleChargeChange() {
      // not null guard clause
      if (!this.chargeHere || !this.rating) return;

      // parse rating
      const parsedRating = parseIntOrFloat(this.rating);

      // validate parsed result
      if (!parsedRating) return;
      if (parsedRating <= 0) return;

      // create new object
      const tempObj: TspLocation = new TspLocation({
        ...this.stop,
        kWChargerRating: parsedRating,
      });

      // emit updated object
      this.emitUpdatedDetails(tempObj);
    },
    /**
     * A function that handles the change in weight by performing the following steps:
     * 1. Checks if the weightChange is not null. If it is, returns early.
     * 2. Parses the weightChange into an integer or float using parseIntOrFloat.
     * 3. Validates if the parsed weightChange is truthy. If not, returns early.
     * 4. Creates a new TspLocation object with the updated weightChange.
     * 5. Emits the updated object using the emitUpdatedDetails method.
     *
     * @return {void} This function does not return anything.
     */
    handleWeightChange() {
      // not null guard clause
      if (!this.weightChange) return;

      // parse rating
      const parsedWeightChange = parseIntOrFloat(this.weightChange);

      // validate parsed result
      if (!parsedWeightChange) return;

      // create new object
      const tempObj: TspLocation = new TspLocation({
        ...this.stop,
        weightChange: parsedWeightChange,
      });

      // emit updated object
      this.emitUpdatedDetails(tempObj);
    },
    handleStayChange(val: StayDurationInputUpdateObj) {
      // not null guard clause
      if (!val.duration) return;

      // create new object
      const tempObj: TspLocation = new TspLocation({
        ...this.stop,
        stay: val.duration,
      });

      // emit updated object
      this.emitUpdatedDetails(tempObj);
    },
  },
  components: { AddressAutocompleteInput, StayDurationInput },
  data() {
    return {
      weightChange: null as number | string | null, // This version of vuetify emits stringified numbers or empty strings for number type inputs.
      chargeHere: false,
      rating: null as number | string | null, // This version of vuetify emits stringified numbers or empty strings for number type inputs.
      expanded: undefined as number | undefined,
    };
  },
  computed: {
    ...mapState({
      favLocations: (state: unknown) => (state as State).favLocations,
    }),
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
