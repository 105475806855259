import Coordinate from "../common_classes/coordinate";
import { ItineraryDataType } from "./types";

/**
 * Base object for itinerary location classes.
 *
 * Note: intended to be extended if using by its self please be sure this is intentional.
 */
export default class ItineraryLocation extends Object {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** type of location this stop refers to. */
  locationType: "origin" | "destination" | "waypoint" | "charger";
  /** they display ready string for this locations address. */
  addressDisplayStr: string;
  /** Standardised format for coordinate values with supported converters and validation. */
  coordinate: Coordinate;

  /** Option name for this location */
  name?: string;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    locationType,
    addressDisplayStr,
    coordinate,
    name = undefined,
  }: {
    /** type of location this stop refers to. */
    locationType: "origin" | "destination" | "waypoint" | "charger";
    /** they display ready string for this locations address. */
    addressDisplayStr: string;
    /** Standardised format for coordinate values with supported converters and validation. */
    coordinate: Coordinate;
    /** Option name for this location */
    name?: string;
  }) {
    super();
    this.locationType = locationType;
    this.addressDisplayStr = addressDisplayStr;
    this.coordinate = coordinate;
    this.name = name;
  }

  // ----------------------------------------------------------------------- //
  // ------------------------------- Getters ------------------------------- //
  // ----------------------------------------------------------------------- //

  /**
   * Returns the type of itinerary item this object relates to.
   *
   * NOTE: for use in vue templates where instance of is not available.
   */
  public get itineraryDataType(): ItineraryDataType {
    return this.locationType;
  }

  public get shortDisplayString(): string {
    if (this.name) return this.name;
    // TODO: make this better
    const addressPeaces = this.addressDisplayStr.split(" ");
    if (addressPeaces[1] && addressPeaces[2])
      return addressPeaces[1] + " " + addressPeaces[2];
    return "Origin";
  }
}
