<template>
  <v-card flat>
    <!-- main title -->
    <v-card-title>
      <v-btn icon @click="back">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
      <v-spacer />
      <!-- padding to offset back btn so spacers align title in the center -->
      <span class="pr-9"> Optimised Trip </span>
      <v-spacer />
    </v-card-title>

    <!-- comparison and summary section -->
    <v-card-title class="tertiary--text"> EV Comparison </v-card-title>
    <v-card-subtitle>
      See how an EV would preform in this trip.
    </v-card-subtitle>
    <v-card-text>
      <v-slide-group show-arrows>
        <v-slide-item
          v-for="(comparison, index) in trip.comparisons"
          :key="'comparison-' + index"
          style="width: 150px"
          class="pr-2"
          :class="index == 0 ? 'pl-1' : ''"
        >
          <TspComparisonCard
            :comparison="comparison"
            :isShowing="comparison.localId === trip.displayedComparison"
            @showMe="showComparison"
            @remove="removeComparison"
          />
        </v-slide-item>
      </v-slide-group>
    </v-card-text>
    <v-card-text v-if="showAddComparisonForm">
      <TspAddComparisonForm :baseLegs="trip.baseLegs" @create="addComparison" />
    </v-card-text>
    <v-card-text v-else>
      <ElevatedBlockBtn
        @click="
          () => {
            showAddComparisonForm = true;
          }
        "
      >
        Add a Comparison
      </ElevatedBlockBtn>
    </v-card-text>
    <v-card-text>
      <SOCSlider
        :initialValue="1"
        :identifier="trip.localId"
        label="Departure Charge"
        @update="changeSoC"
      />
      <v-card class="grey lighten-2">
        <v-card-title class="text-subtitle-2 font-weight-bold">
          Summery
        </v-card-title>
        <v-card-text>
          <v-row justify="space-between" no-gutters>
            <span> Distance </span>
            <span> {{ trip.displayableTotalDistance }} </span>
          </v-row>
          <v-row justify="space-between" no-gutters>
            <span> Time </span>
            <span> {{ trip.displayableTotalTime }} </span>
          </v-row>
        </v-card-text>
        <v-card-title class="text-subtitle-2 font-weight-bold pt-0">
          {{
            trip.displayedComparisonData
              ? "Based on your comparison EV"
              : "Select a comparison for more details"
          }}
        </v-card-title>
        <v-card-text v-if="trip.displayedComparisonData">
          <v-row justify="space-between" no-gutters>
            <span> Energy </span>
            <span>
              {{
                trip.displayedComparisonData.totalEnergyDisplayString
                  ? trip.displayedComparisonData.totalEnergyDisplayString
                  : "??kWh"
              }}
              or
              {{
                trip.displayedComparisonData.totalEnergyDisplayStringPercentage
                  ? trip.displayedComparisonData
                      .totalEnergyDisplayStringPercentage
                  : "??%"
              }}
            </span>
          </v-row>
          <v-row justify="space-between" no-gutters>
            <span>
              {{ trip.displayedComparisonData.numberOfChargesDisplayString }}
            </span>
          </v-row>
          <v-row justify="space-between" no-gutters v-if="emissionsAvoided">
            <span> Estimated emissions avoided </span>
            <span> {{ emissionsAvoided }}kg of CO₂ </span>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import TspTrip from "@/logic/classes/tsp_trip_classes/tspTrip";
import TspTripComparison from "@/logic/classes/tsp_trip_classes/tspTripComparison";
import { CalcVsType, MutationTypes, State } from "@/logic/store/store_types";
import Vue, { PropType } from "vue";
import TspComparisonCard from "./TspComparisonCard.vue";
import SOCSlider, {
  SOCSliderUpdateObj,
} from "@/ui/components/ui-elements/inputs/SOCSlider.vue";
import TspAddComparisonForm from "./TspAddComparisonForm.vue";
import ElevatedBlockBtn from "@/ui/components/ui-elements/buttons/ElevatedBlockBtn.vue";

export default Vue.extend({
  name: "TspTripStatsContent",
  components: {
    TspComparisonCard,
    SOCSlider,
    TspAddComparisonForm,
    ElevatedBlockBtn,
  },
  props: {
    trip: {
      required: true,
      type: Object as PropType<TspTrip>,
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    async addComparison(comparison: TspTripComparison) {
      const updatedTrip = this.trip;
      updatedTrip.comparisons.push(comparison);
      updatedTrip.displayedComparison = comparison.localId;
      this.showAddComparisonForm = false;
      await updatedTrip.getCorridorChargerIds();
      this.updateTrip(updatedTrip);
    },
    updateTrip(trip: TspTrip) {
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
    },
    updateComparison(updatedComparison: TspTripComparison, index: number) {
      const updatedTrip = this.trip;
      updatedTrip.comparisons[index] = updatedComparison;
      this.updateTrip(updatedTrip);
    },
    async showComparison(localId: string) {
      const updatedTrip = this.trip;
      updatedTrip.displayedComparison = localId;
      await updatedTrip.getCorridorChargerIds();
      this.updateTrip(updatedTrip);
    },
    removeComparison(comparisonId: string) {
      const updatedTrip = this.trip;
      updatedTrip.comparisons = updatedTrip.comparisons.filter(
        (comparison) => comparison.localId !== comparisonId
      );
      if (updatedTrip.displayedComparison === comparisonId) {
        updatedTrip.displayedComparison = undefined;
      }
      this.updateTrip(updatedTrip);
    },
    changeSoC(SoCObj: SOCSliderUpdateObj) {
      const updatedTrip = this.trip;
      updatedTrip.startingSoC = SoCObj.SOC;
      this.updateTrip(updatedTrip);
    },
  },
  computed: {
    emissionsAvoided(): string | undefined {
      // guard clauses
      if (
        !this.trip.totalDistance ||
        !this.trip.displayedComparisonData?.totalEnergy
      )
        return undefined;

      // user petrol or diesel km traveled per litre of fuel consumed
      const efficiency =
        (this.$store.state as State).calcVs === CalcVsType.PETROL
          ? (this.$store.state as State).petrolKmPerLitre
          : (this.$store.state as State).dieselKmPerLitre;

      const co2PerLitre =
        (this.$store.state as State).calcVs === CalcVsType.PETROL ? 2.8 : 3.2; // 2.8 for petrol and 3.2 for diesel
      const avoidedCO2 = (this.trip.totalDistance / efficiency) * co2PerLitre;
      const emittedCO2 = this.trip.displayedComparisonData.totalEnergy * 0.13;

      return (avoidedCO2 - emittedCO2).toFixed(2);
    },
  },
  data() {
    return {
      showAddComparisonForm: false,
    };
  },
});
</script>
