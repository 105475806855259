<template>
  <v-dialog
    @click:outside="setNoPrimaryTime"
    :value="showDialog"
    :width="getWidth"
  >
    <v-card class="rounded-lg">
      <v-card-title class="primary--text" style="word-break: normal">
        Please choose your most important appointment
      </v-card-title>
      <v-card-text>
        If the timing of your travel plans doesn't quite work out, we'll plan
        your charging stops and travel itinerary around this appointment.
        <v-radio-group v-model="radioGroup">
          <v-radio
            v-for="(location, index) in locationsWithTime"
            :key="`time-select-${index}`"
            :label="location.address"
            :value="index"
          />
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <ElevatedBtn class="flex-grow-1" @click="setPrimaryTime">
          Set time
        </ElevatedBtn>
        <OutlinedBtn @click="setNoPrimaryTime"> No primary time </OutlinedBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import { powerTripDarkBlue } from "@/logic/data/const";
import ElevatedBtn from "@/ui/components/ui-elements/buttons/ElevatedBtn.vue";
import OutlinedBtn from "@/ui/components/ui-elements/buttons/OutlinedBtn.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "PrimaryTimeSelectContent",
  components: { ElevatedBtn, OutlinedBtn },
  props: {
    showDialog: Boolean,
    locationsWithTime: Array as PropType<TripLocation[]>,
  },
  data() {
    return {
      pwtDarkBlue: powerTripDarkBlue,
      radioGroup: 0,
    };
  },
  methods: {
    setNoPrimaryTime() {
      this.$emit("update", undefined);
    },
    setPrimaryTime() {
      this.$emit("update", this.locationsWithTime[this.radioGroup].localId);
    },
  },
  computed: {
    getWidth(): number | string {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "90%";
        case "sm":
          return 350;
        default:
          return 450;
      }
    },
  },
});
</script>
