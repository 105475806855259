import { DateTime } from "luxon";
import Coordinate from "../common_classes/coordinate";
import ItineraryLocation from "./itineraryLocation";

/**
 * an itinerary location for scheduling a stop at a known charger accessible
 * vie the charger DB for charging purposes only.
 *
 * This extends `ItineraryLocation`.
 * */
export default class ChargerLocation extends ItineraryLocation {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  // --- location data --- //

  /** Charger DB UUID for related charger. */
  relatedCharger: string;

  // --- load data --- //

  /**
   * The load in kg carried through this location.
   *
   * Note:
   * - should default to 0
   * - expected conditional UI to understand does not need to be displayed if 0
   * - assumes stopping at s specific charger no load is picked up or dropped off.
   *   If this is a site manipulation the load and the charge this should be a
   *   `WaypointLocation`
   */
  loadWeight: number;

  // --- energy data --- //

  /**
   * The number of kWh used at this stop for non travel related purposes.
   *
   * Note:
   * - should default to 0
   * - expected conditional UI to understand does not need to be displayed if 0
   */
  energyUsed: number;

  /**
   * The number of kWh added via charging at this location.
   *
   * Note:
   * - should default to 0
   * - expected conditional UI to understand does not need to be displayed if 0
   */
  energyAdded: number;

  /** The kWh remaining in the vehicles battery upon arriving at this location. */
  arrivalEnergy: number;

  // --- time data --- //

  /** the time stamp of the expected arrival time at this location. */
  expectedArrivalTime?: string;

  /**
   * The expected time you are staying at this location in seconds.
   *
   * Note:
   * - should default to 0
   * - expected conditional UI to understand does not need to be displayed if 0
   */
  stay: number;

  // --- charging data --- //

  /** Max power that can be transferred from a charger at this site in kW. */
  kWRating?: number;

  /** The current type to used for charging calculations. */
  currentType?: "AC" | "DC";

  /** The time in seconds the EV will spend charging at this location. */
  chargingTime: number;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    addressDisplayStr,
    coordinate,
    loadWeight = 0,
    arrivalEnergy,
    energyUsed = 0,
    energyAdded = 0,
    expectedArrivalTime = undefined,
    stay = 0,
    relatedCharger,
    kWRating = undefined,
    currentType = undefined,
    chargingTime = 0,
  }: {
    /** they display ready string for this locations address. */
    addressDisplayStr: string;
    /** Standardised format for coordinate values with supported converters and validation. */
    coordinate: Coordinate;
    /** The load in kg carried through this location. */
    loadWeight?: number;
    /** The kWh remaining in the vehicles battery upon arriving at this location. */
    arrivalEnergy: number;
    /** The number of kWh used at this stop for non travel related purposes. */
    energyUsed?: number;
    /** The number of kWh added via charging at this location. */
    energyAdded?: number;
    /** The time stamp of the expected arrival time at this location. */
    expectedArrivalTime?: string;
    /** The expected time you are staying at this location in seconds. */
    stay?: number;
    /** Charger DB UUID for related charger. */
    relatedCharger: string;
    /** Max power that can be transferred from a charger at this site in kW. */
    kWRating?: number;
    /** The current type to used for charging calculations. */
    currentType?: "AC" | "DC";
    /** The time in seconds the EV will spend charging at this location. */
    chargingTime?: number;
  }) {
    super({
      locationType: "charger",
      addressDisplayStr,
      coordinate,
    });

    this.loadWeight = loadWeight;
    this.arrivalEnergy = arrivalEnergy;
    this.energyUsed = energyUsed;
    this.energyAdded = energyAdded;
    this.expectedArrivalTime = expectedArrivalTime;
    this.stay = stay;
    this.relatedCharger = relatedCharger;
    this.kWRating = kWRating;
    this.currentType = currentType;
    this.chargingTime = chargingTime;
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  // --- energy data --- //

  /** The calculated departing energy from this location. */
  public get departingEnergy(): number {
    return this.arrivalEnergy - this.energyUsed + this.energyAdded;
  }

  // --- time data --- //

  /**
   * the time stamp of the calculated departure time at this location.
   *
   * Note:
   * - should bail out if no expected arrival time.
   * - should be calculated based on expected arrival time plus stay.
   */
  public get expectedDepartureTime(): string | undefined {
    if (!this.expectedArrivalTime) return; // exit if no time for the calculation
    return DateTime.fromISO(this.expectedArrivalTime)
      .plus({ seconds: this.totalStay })
      .toLocaleString(DateTime.TIME_SIMPLE);
  }

  /**
   * the total stay in seconds be it charging time or scheduled stay whichever is larger.
   */
  public get totalStay(): number {
    return Math.max(this.stay, this.chargingTime);
  }

  // --- load data --- //

  /** Alias for `loadWeight` to support mapping departing load through itinerary stop objects. */
  public get departingLoadWeight(): number {
    return this.loadWeight;
  }

  /** Alias for `loadWeight` to support mapping arriving load through itinerary stop objects. */
  public get arrivalLoadWeight(): number {
    return this.loadWeight;
  }
}
