import * as polyline from "@mapbox/polyline";

/**
 * Converts an encoded polyline into a lat/lon points array.
 *
 * @param encodedPolyline an encoded precision 6 polyline string
 * @returns an array of lat,lon points in order
 */
export function decodePolyline(encodedPolyline: string): [number, number][] {
  return polyline.decode(encodedPolyline, 6);
}

/**
 * Converts a lat/lon points array into an encoded polyline.
 *
 * @param points an array of lat,lon points in order
 * @returns an encoded precision 6 polyline string
 */
export function encodePolyline(points: [number, number][]): string {
  return polyline.encode(points, 6);
}

/**
 * Converts an encoded polyline and charge data into a points format array that supports a gradient polylines.
 *
 * @param encodedPolyline the string value for the precision 6 polyline
 * @param startingCharge the starting charge in a decimal representation of state of charge e.g. 20% charge = 0.2
 * @param endingCharge the ending charge in a decimal representation of state of charge e.g. 20% charge = 0.2
 * @returns and array of lat/lon/zValue items to support a gradient polyline.
 */
export function createGradientPolylineLatLonData(
  encodedPolyline: string,
  startingCharge: number,
  endingCharge: number
): [number, number, number][] {
  // get polyline points
  const points = decodePolyline(encodedPolyline);
  return (points as [number, number][]).map((item, index) => {
    // use linier interpolation to get the zValue for the point.
    const positionValue = (1 / points.length) * index;
    const zValue =
      (1 - positionValue) * startingCharge + positionValue * endingCharge;
    // return the expanded point item.
    return [item[0], item[1], zValue];
  });
}

/**
 * Combines multiple polylines into a single polyline.
 *
 * @param polylinesList a list of precision 6 polylines in order.
 * @returns a single precision 6 polyline.
 */
export function combinePolylines(polylinesList: string[]): string {
  // convert polylines into a single list or points retaining order.
  const combinedPoints: [number, number][] = polylinesList.flatMap((polyline) =>
    decodePolyline(polyline)
  );
  // convert points into a precision 6 polyline and return.
  return encodePolyline(combinedPoints);
}
