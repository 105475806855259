<template>
  <!-- processing animation card -->
  <DescriptiveLoader
    v-if="savingStatus === 'processing'"
    message="Power trip is saving your trip"
  />
  <!-- save successful card -->
  <v-card flat class="pa-5 background" v-else-if="savingStatus === 'ok'">
    <v-alert type="success" class="mx-2">
      Trip updated successfully and saved to the database
    </v-alert>
  </v-card>
  <!-- save unsuccessful card -->
  <v-card flat class="pa-5 background" v-else-if="savingStatus === 'failed'">
    <v-alert type="error" class="mx-2">
      Trip was updated locally but failed to be saved to the database
    </v-alert>
  </v-card>
  <!-- save trip card -->
  <v-card flat v-else>
    <v-card-title>
      <v-icon class="mr-3" color="primary" large> mdi-content-save </v-icon>
      Name it and save it
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="inputtedName"
        label="name"
        clearable
      ></v-text-field>
    </v-card-text>
    <FrequencySelectInput @update="handleFrequencyChange" />
    <v-card-actions>
      <v-spacer />
      <ElevatedBtn @click="nameAndSave"> Save </ElevatedBtn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import TripV2 from "@/logic/classes/trip_v2_classes/trip_v2";
import Vue, { PropType } from "vue";
import ElevatedBtn from "../ui-elements/buttons/ElevatedBtn.vue";
import FrequencySelectInput from "../ui-elements/inputs/FrequencySelectInput.vue";
import DescriptiveLoader from "../ui-elements/loaders/DescriptiveLoader.vue";
import { TripFrequency } from "@/logic/types/trip_specific_types";
import { MutationTypes } from "@/logic/store/store_types";

export default Vue.extend({
  name: "SaveRoutePlanContent",
  props: {
    routePlan: {
      type: Object as PropType<TripV2>,
      required: true,
    },
  },
  components: { ElevatedBtn, FrequencySelectInput, DescriptiveLoader },
  data() {
    return {
      inputtedName: "",
      savingStatus: undefined as "processing" | "ok" | "failed" | undefined,
      frequency: undefined as TripFrequency | undefined,
    };
  },
  methods: {
    /**
     * Handles the change in trip frequency.
     *
     * @param {TripFrequency | undefined} frequencyObj - The new trip frequency object.
     * @return {void} No return value.
     */
    handleFrequencyChange(frequencyObj: TripFrequency | undefined) {
      this.frequency = frequencyObj;
    },
    /**
     * Saves the trip with the name and frequency inputted by the user.
     *
     * @return {Promise<void>} No return value.
     */
    async nameAndSave() {
      // indicate async action is in progress.
      this.savingStatus = "processing";
      // copy the trip object.
      const trip = this.routePlan;
      // update the trip.
      trip.name = this.inputtedName;
      trip.frequency = this.frequency;
      this.savingStatus = await trip.saveRoutePlan();
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
      // clear status after some time.
      setTimeout(() => {
        this.savingStatus = undefined;
        this.$emit("close");
      }, 2500);
    },
  },
});
</script>
