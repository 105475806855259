<template>
  <v-card class="pb-5" flat>
    <v-card-text>
      Find chargers you can reach from as specific location
    </v-card-text>
    <v-card-text>
      <AddressAutocompleteInput
        @update="(val) => (address = val)"
        allowFavLocations
      />
    </v-card-text>
    <v-card-text class="py-0">
      <SOCSlider
        :initialValue="soc"
        label="Current charge"
        @update="handleSOCChange"
      />
    </v-card-text>
    <v-card-actions :class="processing ? 'justify-center align-center' : ''">
      <template v-if="processing">
        <LoadingCard />
      </template>
      <template v-else>
        <ElevatedBtn @click="handleFindChargers" class="flex-grow-1">
          Find chargers
        </ElevatedBtn>
        <OutlinedBtn class="flex-grow-1" @click="handleClear">
          Clear chargers in range
        </OutlinedBtn>
        <span class="error--text ml-3" v-if="errorMsg">
          Whoops! {{ errorMsg }}
        </span>
      </template>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import SOCSlider, {
  type SOCSliderUpdateObj,
} from "../../ui-elements/inputs/SOCSlider.vue";
import {
  ActionTypes,
  GettersTypes,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import { EVNavNearbyParams } from "@/logic/types/ev_nav_types";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import EVModel from "@/logic/classes/vehicle_classes/evModel";
import LoadingCard from "../../ui-elements/loaders/LoadingCard.vue";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
import OutlinedBtn from "../../ui-elements/buttons/OutlinedBtn.vue";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../../ui-elements/inputs/AddressAutocompleteInput.vue";

export default Vue.extend({
  name: "ChargersNearbyCard",
  data() {
    return {
      expand: false,
      address: null as AddressAutocompleteInputUpdateObj | null,
      errorMsg: null as string | null,
      soc: 1,
    };
  },
  computed: {
    EVmodelDetails(): EVModel | undefined {
      const vehicle: Vehicle | undefined =
        this.$store.getters[GettersTypes.selectedVehicleData];
      return vehicle?.evModel;
    },
    batteryCapacity(): number | undefined {
      const vehicle: Vehicle | undefined =
        this.$store.getters[GettersTypes.selectedVehicleData];
      return vehicle?.totalBatteryKWh();
    },
    ...mapGetters({
      vehicle: GettersTypes.selectedVehicleData,
    }),
    ...mapState({
      startingCharge: (state: unknown): number => (state as State).SOCAct,
      minCharge: (state: unknown): number => (state as State).SOCMin,
      userGeoLocation: (state: unknown): Coordinate | undefined =>
        (state as State).userGeoIPData,
      processing: (state: unknown): boolean =>
        (state as State).nearbyFetchingFlag,
      pannedCenter: (state: unknown) => (state as State).pannedCenter,
    }),
  },
  methods: {
    handleSOCChange(val: SOCSliderUpdateObj) {
      this.soc = val.SOC;
    },
    handleFindChargers() {
      // clear error messages
      this.errorMsg = null;
      // guard clauses
      if (!this.address) {
        this.errorMsg = "Please enter an address.";
        return;
      }
      if (!this.address.addressData) {
        this.errorMsg = "Please enter an address.";
        return;
      }
      if (!this.vehicle) {
        this.errorMsg = "Please select a vehicle to user.";
        return;
      }
      // create API call parameters object.
      const tempObj: EVNavNearbyParams = {
        Location: this.address.addressData.coordinates,
        Vehicle: (this.vehicle as Vehicle).routePlanningCarParam,
        Battery: (this.vehicle as Vehicle).totalBatteryKWh() * this.soc,
        SOCAct: this.startingCharge,
        SOCMin: this.minCharge,
      };
      // dispatch to store to call async function as process results
      this.$store.dispatch(ActionTypes.fetchNearbyChargersData, tempObj);
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Find chargers nearby feature used",
          count: 1,
        },
      ]);
    },
    handleClear() {
      this.$store.commit(MutationTypes.setNearbyChargersData, undefined);
      this.$store.commit(MutationTypes.setShowNearbyChargersOnly, false);
    },
  },
  components: {
    SOCSlider,
    LoadingCard,
    ElevatedBtn,
    OutlinedBtn,
    AddressAutocompleteInput,
  },
});
</script>
