var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"rounded-lg mb-5 background"},[[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.favLocation.name)+" ")]),_c('span',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit', _vm.favLocation)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showDialog = true}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)],1)]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.favLocation.address))])],_c('v-card-text',[_c('ul',{staticClass:"pb-5",staticStyle:{"list-style":"none","padding-left":"unset"}},[_c('li',{staticClass:"grey--text h6"},[_vm._v("Location Details")]),_c('li',[_vm._v("visibility: "+_vm._s(_vm.favLocation.visibility))]),_c('li',[_vm._v("location type: "+_vm._s(_vm.favLocation.type))])]),(_vm.favLocation.planningData)?_c('ul',{staticClass:"pb-5",staticStyle:{"list-style":"none","padding-left":"unset"}},[_c('li',{staticClass:"grey--text h6"},[_vm._v("Trip Planning Defaults")]),(_vm.favLocation.planningData.loadWeightChange)?_c('li',[_vm._v(" load weight change: "+_vm._s(_vm.favLocation.planningData.loadWeightChange)+" kg ")]):_vm._e(),(
          _vm.favLocation.planningData.chargeHere &&
          _vm.favLocation.planningData.rating &&
          _vm.favLocation.planningData.currentType
        )?_c('li',[_vm._v(" Charge here with "+_vm._s(_vm.favLocation.planningData.rating)+"kW "+_vm._s(_vm.favLocation.planningData.currentType)+" charger ")]):_vm._e()]):_vm._e()]),_c('v-dialog',{attrs:{"max-width":"400","content-class":"rounded-lg"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete Favourite Location")]),_c('v-card-text',[_vm._v("This is permanent and cannot be undone")]),_c('v-card-actions',[_c('ElevatedBtn',{on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Cancel ")]),_c('ElevatedBtn',{on:{"click":() => {
              _vm.$emit('remove', _vm.favLocation);
              _vm.showDialog = false;
            }}},[_vm._v(" Delete ")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }