<template>
  <v-card class="mt-3 rounded-lg">
    <v-card-text class="pb-0">
      <v-autocomplete
        :value="comparison.evModel"
        :items="EVModels"
        item-text="name"
        label="EV make/model"
        :menu-props="{ contentClass: 'pwt-scrollbar-styles' }"
        return-object
        clearable
        @change="handleModelSelect"
        hide-details
        :loading="loading"
        :disabled="loading"
        auto-focus
      ></v-autocomplete>
      <v-radio-group
        :value="comparison.calcAsUsed"
        @change="handleUsedNewSelect"
        :disabled="loading"
      >
        <v-radio label="Calculate as new (100% SoH)" :value="false"></v-radio>
        <v-radio
          label="Calculate as used (degraded SoH)"
          :value="true"
        ></v-radio>
      </v-radio-group>
      <v-card-text v-if="!!comparison.energyData">
        <v-row justify="space-between" no-gutter>
          <span> Energy </span>
          <span> {{ comparison.totalEnergyDisplayString }} </span>
        </v-row>
        <v-row justify="space-between" no-gutter>
          {{ comparison.numberOfChargesDisplayString }}
        </v-row>
      </v-card-text>
    </v-card-text>
    <v-card-text v-if="showError">
      <v-alert type="error" color="error" class="rounded-lg">
        Whoops! something went wrong calculating this comparison
      </v-alert>
    </v-card-text>
    <v-card-text>
      <ElevatedBlockBtn
        :disabled="showError || loading || !comparison.evModelID"
        :loading="loading"
        @click="emitCreateComparison"
      >
        Add This Comparison
      </ElevatedBlockBtn>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { mapState } from "vuex";
import Vue, { PropType } from "vue";
import EVModel from "@/logic/classes/vehicle_classes/evModel";
import { type State } from "@/logic/store/store_types";
import TspTripComparison from "@/logic/classes/tsp_trip_classes/tspTripComparison";
import ElevatedBlockBtn from "@/ui/components/ui-elements/buttons/ElevatedBlockBtn.vue";
import { type TspBaseLeg } from "@/logic/classes/tsp_trip_classes/tspTrip";

export default Vue.extend({
  name: "TspAddComparisonForm",
  components: { ElevatedBlockBtn },
  props: {
    baseLegs: {
      type: Array as PropType<TspBaseLeg[]>,
      required: true,
    },
  },
  computed: {
    ...mapState({
      EVModels: (state: unknown): EVModel[] => (state as State).evModels,
    }),
  },
  methods: {
    emitCreateComparison(): void {
      this.$emit("create", this.comparison);
    },
    handleModelSelect(val: EVModel | null) {
      this.comparison.setEVModel(val ?? undefined);
      if (val) {
        this.calculateEnergy();
      }
    },
    handleUsedNewSelect(val: boolean) {
      this.comparison.calcAsUsed = val;
      if (this.comparison.evModel) {
        this.calculateEnergy();
      }
    },
    async calculateEnergy(): Promise<void> {
      // identify async operations has started.
      this.loading = true;
      // clear previous error if any.
      this.showError = false;
      // fetch energy data from service.
      const outcome = await this.comparison.calcEnergyUsageBreakdown(
        this.baseLegs
      );
      // show error if failed
      if (outcome === "failed") this.showError = true;
      // identify async operations has completed.
      this.loading = false;
    },
  },
  data() {
    return {
      loading: false,
      showError: false,
      comparison: new TspTripComparison(),
    };
  },
});
</script>
