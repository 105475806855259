import { EVNavRoutePlan, EVNavStep } from "../../types/ev_nav_types";
import generateUniqueLocalID from "../../utils/generateUniqueLocalID";

export class TripEVRoutePlan {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** local unique id. */
  localId: string;

  /** The total distance of this route plan. In meters. */
  distance: number;

  /** The total expected time for this route. In seconds. */
  time: number;

  /** The total expected cost in NZD. */
  cost?: number;

  /** The list of steps */
  steps: EVNavStep[];

  /** The expected number of kWh of energy consumed in this route plan. */
  energy: number;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //
  constructor({
    localId = undefined,
    distance = 0,
    time = 0,
    cost = undefined,
    steps = [],
    energy = 0,
  }: {
    localId?: string;
    distance?: number;
    time?: number;
    cost?: number;
    steps?: EVNavStep[];
    energy?: number;
  }) {
    this.localId =
      localId ?? generateUniqueLocalID(TripEVRoutePlan.usedIds, "route-plan");

    this.distance = distance;
    this.time = time;
    this.cost = cost;
    this.steps = steps;
    this.energy = energy;

    // add id to list of used unique ids
    TripEVRoutePlan.usedIds.push(this.localId);
  }

  /**
   * Creates a new `TripEVRoutePlan` object from the expected data received form the EV Nav api.
   *
   * @param data the whole data object for the `EVNavRoutePlan` returned by the EV Nav api.
   * @returns new `TripEVRoutePlan` class object.
   */
  static fromEVNavData(data: EVNavRoutePlan) {
    return new TripEVRoutePlan({
      cost: data.Cost,
      distance: data.Distance,
      steps: data.Steps,
      time: data.Time,
      energy: data.Energy,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //
}
