<template>
  <v-card flat>
    <v-card-text>
      <v-card class="primary white--text rounded-lg mb-3">
        <v-row no-gutters justify="space-between">
          <v-col cols="10">
            <v-card-title>Trip to...</v-card-title>
            <v-card-subtitle class="text-h6 pt-0">
              {{ trip.locations[trip.locations.length - 1].address }}
            </v-card-subtitle>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-btn
              color="white"
              outlined
              class="rounded-lg text-none mt-5 mr-5"
              @click="toEdit"
            >
              Edit
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text class="white--text pt-2 pb-6">
          {{ trip.displayableTotalTime }}
        </v-card-text>
      </v-card>
      <OutlinedBlockBtn class="mb-3" @click="toStats">
        Review Trip Stats
      </OutlinedBlockBtn>
      <TextBlockBtn @click="toTrips"> Return to Trips </TextBlockBtn>
    </v-card-text>
    <v-card-title class="tertiary--text"> Itinerary </v-card-title>
  </v-card>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import TspTrip from "@/logic/classes/tsp_trip_classes/tspTrip";
import { powerTripDarkBlue } from "@/logic/data/const";
import { RouteNames } from "@/logic/router";
import { MutationTypes } from "@/logic/store/store_types";
import OutlinedBlockBtn from "@/ui/components/ui-elements/buttons/OutlinedBlockBtn.vue";
import TextBlockBtn from "@/ui/components/ui-elements/buttons/TextBlockBtn.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ItineraryHeader",
  components: { OutlinedBlockBtn, TextBlockBtn },
  methods: {
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    toStats() {
      this.pushRoute(RouteNames.stats);
    },
    toEdit() {
      if (this.trip instanceof TspTrip) {
        this.pushRoute(RouteNames.optimisedEdit);
        return;
      }
      this.pushRoute(RouteNames.tripEdit);
    },
    toTrips() {
      this.$store.commit(MutationTypes.setSelectedTrip, undefined);
      this.$store.commit(MutationTypes.setPannedCenter, undefined);
      this.pushRoute(RouteNames.trips);
    },
  },
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
  props: {
    trip: {
      required: true,
      type: Object as PropType<Trip | TspTrip>,
    },
  },
});
</script>
