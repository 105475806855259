import { AuthMode, Directus, DirectusOptions } from "@directus/sdk";

// get base path

// Assuming optimiser URL is something like "https://sandbox.optimiser.powertrip.earth" and directus is "https://sandbox.gameplan.powertrip.earth"
const currentLocation = window.location;
const hostParts = currentLocation.hostname.split("."); // splits "sandbox.optimiser.powertrip.earth" to ["sandbox","optimiser","powertrip", "earth"]
const extrapolatedBasePath = `${currentLocation.protocol}//${hostParts[0]}.gameplan.powertrip.earth`; // only works if the pattern of naming is that same between instances.

const elementData = document?.querySelector("powertrip-optimiser");
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const passedBasePath = elementData?.attributes?.["backend" as any]?.nodeValue; // this version of ts has issue typing dom objects correctly hence the any.

/** Base URL for the directus services and storage. */
export const directusBaseURL: string = passedBasePath ?? extrapolatedBasePath;

export const chargerCustomEndpoint = directusBaseURL + "/charger/locations/all";
export const modelsCustomEndpoint = directusBaseURL + "/charger/vehicles/all";

/** Directus asset storage path extension. */
export const directusAssetsRoute = directusBaseURL + "/assets/";

/**
 * Accepted strings for directus collection names.
 *
 * NOTE: there should also be a file handling all interactions with each collection
 * e.g. `assetBookingsCalls.ts` for `"Asset_Bookings"`.
 */
export type CollectionNames =
  | "Asset_Bookings"
  | "Car_Record"
  | "Company"
  | "Driver"
  | "EV_Model_Assets"
  | "ManagedContent"
  | "NetworkBranding"
  | "Optimiser_Settings"
  | "ScoringConfig"
  | "Vehicle_files"
  | "Favourite_Locations"
  | "Saved_Optimised_Trips"
  | "Saved_Route_Plans"
  | "SavedRoutePlans";

// Directus SDK options
const config: DirectusOptions = {
  auth: {
    mode: "json" as AuthMode,
    autoRefresh: true,
    msRefreshBeforeExpires: 30000,
  },
  storage: {
    prefix: "",
    mode: "LocalStorage",
  },
  transport: {
    url: directusBaseURL,
    params: {},
    headers: {
      "Cache-Control": "no-store",
    },
  },
};

/** Local directus client. */
const directus = new Directus(directusBaseURL, config);

export default directus;
