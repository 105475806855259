<template>
  <v-card flat v-if="trip">
    <v-card-title
      class="primary--text font-weight-regular"
      v-if="orderedList.length"
    >
      Top {{ Math.min(orderedList.length, 3) }} suitable EVs for this trip
    </v-card-title>
    <v-card-title class="primary--text font-weight-regular" v-else>
      There are no EVs in your fleet suitable for this trip
    </v-card-title>

    <v-card-text class="d-flex justify-space-between flex-column flex-lg-row">
      <span> Rough time you have for charging on this trip </span>
      <span class="primary--text font-weight-bold">
        {{ getNiceDuration(allowedChargingTime) }}
      </span>
    </v-card-text>
    <v-slider
      v-model="allowedChargingTime"
      :min="min"
      :max="max"
      class="mx-5"
      color="primary"
      track-color="grey lighten-2"
      @end="findSuggestedEVs()"
    ></v-slider>

    <template v-if="loadingRoutePlan">
      <v-card class="d-flex justify-center align-center flex-column" flat>
        <v-card-title class="primary--text">
          Recalculating <PulseLoader />
        </v-card-title>
        <LoadingCard />
      </v-card>
    </template>
    <template v-else>
      <VehicleListCard
        v-if="orderedList.length >= 1"
        :selected="orderedList[0].vehicle.localId === selectedId"
        :vehicle="orderedList[0].vehicle"
      />
      <VehicleListCard
        v-if="orderedList.length >= 2"
        :selected="orderedList[1].vehicle.localId === selectedId"
        :vehicle="orderedList[1].vehicle"
      />
      <VehicleListCard
        v-if="orderedList.length >= 3"
        :selected="orderedList[2].vehicle.localId === selectedId"
        :vehicle="orderedList[2].vehicle"
      />
    </template>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { Duration } from "luxon";
import VehicleListCard from "./VehicleListCard.vue";
import PulseLoader from "../../ui-elements/loaders/PulseLoader.vue";
import LoadingCard from "../../ui-elements/loaders/LoadingCard.vue";
import { GettersTypes, State } from "@/logic/store/store_types";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import recommendEV from "@/logic/utils/recommendEV";
import Trip from "@/logic/classes/trip_classes/trip";

export default Vue.extend({
  name: "SuggestedEVsSection",
  methods: {
    findSuggestedEVs() {
      if (this.trip && this.vehicles) {
        const data = recommendEV(
          this.trip,
          this.vehicles,
          this.allowedChargingTime / 60
        );
        this.orderedList = data.rankedList;
        this.min = data.min / 60;
        this.max = Math.min(
          data.max / 60,
          (this.trip as Trip).totalTime
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              (this.trip as Trip).totalTime! / 60
            : 300
        );
        if (this.allowedChargingTime > data.max / 60) {
          this.allowedChargingTime = data.max / 60;
        }
      }
    },
    getNiceDuration(minutes: number | undefined): string {
      const time = minutes ?? 0;
      return Duration.fromObject({
        hours: 0,
        minutes: Math.round(time),
      })
        .normalize()
        .toHuman({ listStyle: "narrow" })
        .replace(",", "");
    },
  },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    ...mapState({
      vehicles: (state: unknown): Vehicle[] => (state as State).vehicles,
      selectedId: (state: unknown): string | undefined =>
        (state as State).selectedVehicle,
      loadingRoutePlan: (state: unknown): boolean =>
        (state as State).routePlanningFlag,
    }),
  },
  data() {
    return {
      orderedList: [] as {
        vehicle: Vehicle;
      }[],
      allowedChargingTime: 60,
      min: 0,
      max: 120,
    };
  },
  components: { VehicleListCard, PulseLoader, LoadingCard },
  watch: {
    trip() {
      this.findSuggestedEVs();
    },
    vehicles() {
      this.findSuggestedEVs();
    },
    selectedId() {
      this.findSuggestedEVs();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.findSuggestedEVs();
    });
  },
});
</script>
