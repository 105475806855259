<template>
  <v-row no-gutters>
    <!-- mobile size avatar and menu -->
    <v-dialog
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="showMenu"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
      content-class="background"
      class="background"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="primary" class="mr-3 mt-1" v-bind="attrs" v-on="on">
          <img v-if="avatarSrc" :src="avatarSrc" />
          <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>
      </template>
      <v-card class="background">
        <v-toolbar flat class="background">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="showMenu = false">
              <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <AvatarMenuListContent
          :avatarSrc="avatarSrc"
          :user="user"
          :emitViewAccount="emitViewAccount"
          :emitViewAllVehicles="emitViewAllVehicles"
          :emitViewVehicle="emitViewVehicle"
          :emitViewDefaults="emitViewDefaults"
          :handleLogout="handleLogout"
          :powerTripDarkBlue="powerTripDarkBlue"
        />
      </v-card>
    </v-dialog>
    <!-- normal size avatar and menu -->
    <v-menu
      v-else
      v-model="showMenu"
      absolute
      offset-y
      content-class="rounded-lg"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="primary" class="mr-3 mt-1" v-bind="attrs" v-on="on">
          <img v-if="avatarSrc" :src="avatarSrc" />
          <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>
      </template>

      <AvatarMenuListContent
        :avatarSrc="avatarSrc"
        :user="user"
        :emitViewAccount="emitViewAccount"
        :emitViewAllVehicles="emitViewAllVehicles"
        :emitViewVehicle="emitViewVehicle"
        :emitViewDefaults="emitViewDefaults"
        :handleLogout="handleLogout"
        :powerTripDarkBlue="powerTripDarkBlue"
      />
    </v-menu>
    <v-card-subtitle class="d-flex flex-column grey--text pt-0">
      <span class="font-weight-medium text-h6">
        {{ user.fullName }}
      </span>
      <span>
        {{ user.email }}
      </span>
    </v-card-subtitle>
    <v-spacer />
  </v-row>
</template>

<script lang="ts">
import User from "@/logic/classes/user_classes/user";
import Vue from "vue";
import { powerTripDarkBlue } from "@/logic/data/const";
import AvatarMenuListContent from "./AvatarMenuListContent.vue";
export default Vue.extend({
  name: "AvatarRow",
  props: {
    avatarSrc: String,
    emitViewAccount: Function,
    emitViewAllVehicles: Function,
    emitViewVehicle: Function,
    emitViewDefaults: Function,
    handleLogout: Function,
    user: User,
  },
  data() {
    return {
      showMenu: false,
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
  components: { AvatarMenuListContent },
});
</script>
