import { settings } from "@/logic/types/directus_types";
import { directusUpdateOne } from "./helperFunctions";
import { PartialObj } from "@/logic/types/generic_types";
import { CollectionNames } from "../../clients/directusClient";

const collectionName: CollectionNames = "Optimiser_Settings";

/**
 * Updates an existing directus `Optimiser_Settings` collection record.
 *
 * @param recordId the directus `Optimiser_Settings` collection record id.
 * @param dataToUpdate partial object to update
 * @returns the updated record if successful, undefined if not.
 */
export async function updateSettingsRecord(
  recordId: string | number,
  dataToUpdate: PartialObj<settings>
): Promise<settings | undefined> {
  return directusUpdateOne<settings>(collectionName, recordId, dataToUpdate);
}
