import { render, staticRenderFns } from "./StoredTripCard.vue?vue&type=template&id=8f8cabcc&scoped=true"
import script from "./StoredTripCard.vue?vue&type=script&lang=ts"
export * from "./StoredTripCard.vue?vue&type=script&lang=ts"
import style0 from "./StoredTripCard.vue?vue&type=style&index=0&id=8f8cabcc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f8cabcc",
  null
  
)

export default component.exports