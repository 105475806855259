<template>
  <v-card flat v-if="destination">
    <v-card-title class="tertiary--text" style="word-break: normal">
      <v-row justify="space-between" no-gutters>
        <v-col cols="8">
          <span class="pr-3"> {{ destination.address }} </span>
        </v-col>
        <v-col cols="4" align-self="start">
          <ElevatedBtn @click="emitPlanTrip">Plan a trip here</ElevatedBtn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <p class="mb-0">Number of chargers nearby: {{ nearbyChargers.length }}</p>
      <!-- <p class="mb-0">
        Nearest charger:
        <a style="text-decoration: underline">charger name/link</a>
      </p> -->
    </v-card-text>
    <!-- <v-card-actions class="px-4">
      <CardBtn
        label="Add"
        color="white"
        iconColor="primary"
        icon="mdi-map-marker-plus-outline"
        :iconSize="44"
      />
    </v-card-actions> -->
    <v-card-title class="tertiary--text" style="word-break: normal">
      Charging nearby
    </v-card-title>
    <v-card-text v-if="!nearbyChargers.length">
      There are no nearby chargers.
    </v-card-text>
    <v-card-text v-else>
      <ChargingStationCard
        v-for="charger in nearbyChargers"
        :key="charger.id"
        :station="charger"
      />
    </v-card-text>
  </v-card>
  <v-card v-else flat>
    <v-card-title class="tertiary--text" style="word-break: normal">
      Select a destination to continue.
    </v-card-title>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import ElevatedBtn from "@/ui/components/ui-elements/buttons/ElevatedBtn.vue";
// import CardBtn from "@/ui/components/ui-elements/buttons/CardBtn.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import Charger from "@/logic/classes/charger_classes/charger";
import ChargingStationCard from "../../charging-stations/details/info-panel-content/ChargingStationCard.vue";
export default Vue.extend({
  name: "DestinationPlanningCard",
  props: {
    destination: {
      type: Object as PropType<processedAddressObj | undefined>,
    },
    nearbyChargers: {
      type: Array as PropType<Charger[]>,
    },
  },
  components: {
    ElevatedBtn,
    // CardBtn,
    ChargingStationCard,
  },
  methods: {
    emitPlanTrip() {
      this.$emit("planTrip");
    },
  },
});
</script>
