import Coordinate from "../common_classes/coordinate";
import ItineraryLocation from "./itineraryLocation";

export default class OriginLocation extends ItineraryLocation {
  // ----------------------------------------------------------------------- //
  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //

  // --- load data --- //

  /** The load in kg leaving at this location */
  departingLoadWeight: number;

  // --- energy data --- //

  /**
   * The user provided kWh remaining in the vehicles battery upon leaving this
   * location.
   */
  departingEnergy: number;

  /**
   * The user provided state of charge for the vehicles battery upon leaving
   * this location.
   */
  departingSoC: number;

  // --- time data --- //

  /** the time stamp of the expected departure time at this location. */
  expectedDepartureTime?: string;

  // ----------------------------------------------------------------------- //
  // ---------------------------- Constructor ------------------------------ //
  // ----------------------------------------------------------------------- //

  constructor({
    addressDisplayStr,
    coordinate,
    departingLoadWeight = 0,
    departingEnergy,
    departingSoC,
    expectedDepartureTime = undefined,
    name = undefined,
  }: {
    /** they display ready string for this locations address. */
    addressDisplayStr: string;

    /**
     * Standardised format for coordinate values with supported converters and
     * validation.
     */
    coordinate: Coordinate;

    /** The load in kg leaving at this location */
    departingLoadWeight?: number;

    /**
     * The user provided kWh remaining in the vehicles battery upon leaving
     * this location.
     */
    departingEnergy: number;

    /**
     * The user provided state of charge for the vehicles battery upon leaving
     * this location.
     */
    departingSoC: number;

    /** the time stamp of the expected departure time at this location. */
    expectedDepartureTime?: string;

    /** Option name for this location */
    name?: string;
  }) {
    super({
      locationType: "origin",
      addressDisplayStr,
      coordinate,
      name,
    });

    this.departingLoadWeight = departingLoadWeight;
    this.departingEnergy = departingEnergy;
    this.departingSoC = departingSoC;
    this.expectedDepartureTime = expectedDepartureTime;
  }
}
