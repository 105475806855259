<template>
  <InfoPanelCardWrapper>
    <TspTripItinerary
      v-if="trip"
      @cancel="cancel"
      @dirty="flagAsDirty"
      @back="back"
      :trip="trip"
    />
    <NoTripContent v-else />
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import { GettersTypes, MutationTypes } from "@/logic/store/store_types";
import Vue from "vue";
import InfoPanelCardWrapper from "@/ui/components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import TspTripItinerary from "@/ui/components/trips/Itinerary/info-panel-content/tsp-trips/TspTripItinerary.vue";
import { mapGetters } from "vuex";
import NoTripContent from "../components/trips/NoTripContent.vue";
export default Vue.extend({
  name: "PlanningLogisticsTripVehicleView",
  components: { InfoPanelCardWrapper, TspTripItinerary, NoTripContent },
  data() {
    return {
      dirty: false,
      expectedNextStep: RouteNames.optimisedRefine,
    };
  },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
  },
  methods: {
    // Routing
    back() {
      this.$router.back();
    },
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    cancel() {
      this.$store.commit(MutationTypes.setSelectedTrip, undefined);
      this.dirty = false;
      this.pushRoute(RouteNames.trips);
    },
    // validation
    flagAsDirty(): void {
      this.dirty = true;
    },
  },
  beforeRouteLeave(to, from, next) {
    // moving to next step in planning, just continue.
    if (to.name === this.expectedNextStep) {
      next();
      return;
    }

    // if there are no changes, just continue.
    if (!this.dirty) {
      next();
      return;
    }

    // if there are unsaved changes check if user wants to continue.
    const answer = window.confirm(
      "Doing this will mean you lose changes to your planned trip, would you like to proceed?"
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
});
</script>
