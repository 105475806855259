<template>
  <v-card flat class="background">
    <EditUserContent />
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import EditUserContent from "@/ui/components/user/profile/dialog-content/EditUserContent.vue";

export default Vue.extend({
  name: "AccountMain",
  components: {
    EditUserContent,
  },
});
</script>
