import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

export enum RouteNames {
  home = "home",
  trips = "trips",
  planning = "planning",
  itinerary = "itinerary",
  tripEdit = "tripEdit",
  stats = "stats",
  saved = "saved",
  unsaved = "unsaved",
  stations = "stations",
  vehicles = "vehicles",
  optimisedPlanning = "optimisedPlanning",
  optimisedVehicles = "optimisedVehicles",
  optimisedEdit = "optimisedEdit",
  optimisedRefine = "optimisedRefine",
  tripV2AddDestination = "tripV2AddDestination",
  tripV2AddOrigin = "tripV2AddOrigin",
  tripV2AddDetails = "tripV2AddDetails",
  tripV2AddStops = "tripV2AddStops",
  tripV2Itinerary = "tripV2Itinerary",
  tripV2Stats = "tripV2Stats",
}

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: RouteNames.home,
    redirect: "/trips",
  },
  {
    path: "/trips",
    component: () => import("@/ui/views/NestedRouteWrapper.vue"),
    children: [
      {
        path: "",
        name: RouteNames.trips,
        component: () => import("@/ui/views/HistoryMainView.vue"),
      },
      {
        path: "saved",
        name: RouteNames.saved,
        component: () => import("@/ui/views/HistorySavedView.vue"),
      },
      {
        path: "unsaved",
        name: RouteNames.unsaved,
        component: () => import("@/ui/views/HistoryUnsavedView.vue"),
      },
    ],
  },
  {
    path: "/trip/v2",
    component: () => import("@/ui/views/NestedRouteWrapper.vue"),
    children: [
      {
        path: "add-destination",
        name: RouteNames.tripV2AddDestination,
        component: () => import("@/ui/views/PlanningV2AddDestinationView.vue"),
      },
      {
        path: "add-origin",
        name: RouteNames.tripV2AddOrigin,
        component: () => import("@/ui/views/PlanningV2AddOriginView.vue"),
      },
      {
        path: "add-details",
        name: RouteNames.tripV2AddDetails,
        component: () => import("@/ui/views/PlanningV2AddDetailsView.vue"),
      },
      {
        path: "add-stops",
        name: RouteNames.tripV2AddStops,
        component: () => import("@/ui/views/PlanningV2AddStopsView.vue"),
      },
      {
        path: "itinerary",
        name: RouteNames.tripV2Itinerary,
        component: () => import("@/ui/views/PlanningV2ItineraryView.vue"),
      },
      {
        path: "trip-stats",
        name: RouteNames.tripV2Stats,
        component: () => import("@/ui/views/V2TripStatsView.vue"),
      },
    ],
  },
  {
    path: "/trip/planning",
    name: RouteNames.planning,
    component: () => import("@/ui/views/PlanningBaseTripView.vue"),
  },
  {
    path: "/trip/itinerary",
    name: RouteNames.itinerary,
    component: () =>
      import(
        "@/ui/components/trips/Itinerary/info-panel-content/ItineraryMain.vue"
      ),
  },
  {
    path: "/trip/edit",
    name: RouteNames.tripEdit,
    component: () =>
      import(
        "@/ui/components/trips/planing/info-panel-content/TripRecalculationForm.vue"
      ),
  },
  {
    path: "/trip/stats",
    name: RouteNames.stats,
    component: () =>
      import(
        "@/ui/components/trips/statistics/info-panel-content/TripStatsWrapper.vue"
      ),
  },
  {
    path: "/trip/optimised-route",
    component: () => import("@/ui/views/NestedRouteWrapper.vue"),
    children: [
      {
        path: "add-stops",
        name: RouteNames.optimisedPlanning,
        component: () =>
          import("@/ui/views/PlanningLogisticsTripStopsView.vue"),
      },
      {
        path: "add-chargers",
        name: RouteNames.optimisedRefine,
        component: () =>
          import("@/ui/views/PlanningLogisticsTripChargersView.vue"),
      },
      {
        path: "add-vehicle",
        name: RouteNames.optimisedVehicles,
        component: () =>
          import("@/ui/views/PlanningLogisticsTripVehicleView.vue"),
      },
      {
        path: "edit",
        name: RouteNames.optimisedEdit,
        component: () =>
          import("@/ui/views/PlanningLogisticsTripStopsView.vue"),
      },
    ],
  },
  {
    path: "/stations",
    name: RouteNames.stations,
    component: () =>
      import(
        "@/ui/components/charging-stations/details/info-panel-content/StationsContent.vue"
      ),
  },
  {
    path: "/vehicles",
    name: RouteNames.vehicles,
    component: () =>
      import("@/ui/components/vehicles/info-panel-content/VehicleContent.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import("@/ui/components/info-panel/PageNotFoundContent.vue"),
  },
];

const basename = (function () {
  return window.location.pathname;
})();

const router = new VueRouter({
  // mode: "history",
  base: basename,
  routes,
});

export default router;
