<template>
  <v-list style="min-width: 250px" class="background rounded-lg pa-3">
    <v-list-item>
      <v-row no-gutters>
        <v-avatar color="primary" class="mr-3 mt-1">
          <img v-if="avatarSrc" :src="avatarSrc" />
          <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>
        <v-card-subtitle class="d-flex flex-column grey--text pt-0">
          <span class="font-weight-medium text-h6">
            {{ user.fullName }}
          </span>
          <span>
            {{ user.email }}
          </span>
        </v-card-subtitle>
        <v-spacer />
      </v-row>
    </v-list-item>
    <v-divider class="mb-3" />
    <v-list-item @click="emitViewAccount">
      <v-list-item-title> Manage account </v-list-item-title>
    </v-list-item>
    <v-list-item @click="emitViewAllVehicles">
      <v-list-item-title> My fleet </v-list-item-title>
    </v-list-item>
    <v-list-item @click="emitViewVehicle">
      <v-list-item-title> Vehicle settings </v-list-item-title>
    </v-list-item>
    <v-list-item @click="emitViewDefaults">
      <v-list-item-title> Trip planning defaults </v-list-item-title>
    </v-list-item>
    <v-divider class="mb-3" />
    <v-list-item @click="handleLogout">
      <v-list-item-title
        class="text-decoration-underline"
        :style="`text-underline-offset: 4px; color: ${powerTripDarkBlue}`"
      >
        Sign out
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import User from "@/logic/classes/user_classes/user";
import Vue from "vue";

export default Vue.extend({
  name: "AvatarMenuListContent",
  props: {
    avatarSrc: String,
    user: User,
    emitViewAccount: Function,
    emitViewAllVehicles: Function,
    emitViewVehicle: Function,
    emitViewDefaults: Function,
    handleLogout: Function,
    powerTripDarkBlue: String,
  },
});
</script>
