<template>
  <v-row no-gutters justify="space-between" class="flex-nowrap">
    <v-text-field
      v-model="hoursValue"
      type="number"
      label="Hours"
      class="pwt-number-font"
      @input="updateStayDuration"
    />
    <v-text-field
      v-model="minuteValue"
      type="number"
      label="Minutes"
      class="pwt-number-font ml-3"
      @input="updateStayDuration"
    />
  </v-row>
</template>
<script lang="ts">
import { Duration } from "luxon";
import Vue from "vue";

export interface StayDurationInputUpdateObj {
  identifier: string;
  duration: number;
}

/** Vue component: renders a reusable input filed group emits an object with a passed identifier and a stay in seconds.
 *
 * @prop `identifier` - string - passed identifier included in the emitted return.
 * @prop `initialValue` - number - stay in seconds.
 * @emits update - with a value in the shape of:
 * `{
 *    identifier: string \\ tha passed id value
 *    duration: number \\ the selected value
 * }`
 */
export default Vue.extend({
  name: "StayDurationInput",
  props: {
    initialValue: Number,
    identifier: String,
  },
  data() {
    return {
      hoursValue: 0,
      minuteValue: 0,
      error: false,
      errorMsg: "",
    };
  },
  methods: {
    setInitialValue() {
      const durationObj = Duration.fromObject({ seconds: this.initialValue })
        .shiftTo("hours", "minutes")
        .toObject();
      this.hoursValue = durationObj.hours || 0;
      this.minuteValue = durationObj.minutes || 0;
    },
    updateStayDuration() {
      const durationObj = Duration.fromObject({
        hours: this.hoursValue,
        minutes: this.minuteValue,
      })
        .shiftTo("seconds")
        .toObject();
      this.$emit("update", {
        identifier: this.identifier,
        duration: durationObj.seconds,
      } as StayDurationInputUpdateObj);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValue();
    });
  },
});
</script>
