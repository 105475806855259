<template>
  <v-container>
    <ItineraryHeader :trip="trip" />
    <v-card-text v-if="trip.ICETripData">
      <!-- origin card -->
      <v-card class="primary rounded-lg mb-5" dark>
        <v-card-title
          style="word-break: normal"
          class="font-weight-bold text-body-1"
        >
          <v-row align="center" no-gutters>
            <v-col cols="2" sm="1">
              <div
                class="primary rounded-circle"
                style="
                  height: 20px;
                  width: 20px;
                  border: 3px solid white !important;
                "
              ></div>
            </v-col>
            <v-col cols="10" sm="11">
              <span>
                {{ trip.locations[0].address }}
              </span>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <!-- travel legs -->
      <template v-for="(location, index) in trip.ICETripData.trip.locations">
        <div :key="'travel-leg-' + index" v-if="index !== 0">
          <!-- traveling card -->
          <v-card flat class="pl-10">
            <v-card-title
              class="font-weight-bold text-body-2 pt-0 d-flex justify-space-between"
              style="word-break: normal"
            >
              <span>
                Driving
                {{
                  trip.ICETripData.trip.legs[index - 1].summary.has_ferry
                    ? calcDrivingTime(trip.ICETripData.trip.legs[index - 1])
                    : niceDuration(
                        trip.ICETripData.trip.legs[index - 1].summary.time
                      )
                }}
                for
                {{
                  Math.round(
                    trip.ICETripData.trip.legs[index - 1].summary.length
                  )
                }}km
              </span>
            </v-card-title>
            <v-card-subtitle
              class="grey--text text--darken-2 pt-1"
              v-if="trip.ICETripData.trip.legs[index - 1].summary.has_ferry"
            >
              <v-row no-gutters>
                <v-icon color="primary" class="mr-1">mdi-ferry</v-icon>
                Ferry for
                {{ calcFerryTime(trip.ICETripData.trip.legs[index - 1]) }}
              </v-row>
            </v-card-subtitle>
          </v-card>
          <!-- destination card -->
          <v-card
            v-if="index === trip.locations.length - 1"
            class="primary rounded-lg mb-5"
            dark
          >
            <v-card-title
              style="word-break: normal"
              class="font-weight-bold text-body-1"
            >
              <v-row align="center" no-gutters>
                <v-col cols="2" sm="1">
                  <div
                    class="primary rounded-circle"
                    style="
                      height: 20px;
                      width: 20px;
                      border: 3px solid white !important;
                    "
                  ></div>
                </v-col>
                <v-col cols="10" sm="11">
                  <span>
                    {{ trip.locations[trip.locations.length - 1].address }}
                  </span>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
          <!-- scheduled stop card -->
          <v-card v-else flat class="pl-10">
            <v-card class="grey lighten-2 rounded-lg mb-5">
              <v-card-title
                style="word-break: normal"
                class="font-weight-bold text-body-1"
              >
                <v-row align="center" no-gutters>
                  <v-col cols="2" sm="1">
                    <div
                      class="rounded-circle grey darken-1"
                      style="width: 20px; height: 20px"
                    ></div>
                  </v-col>
                  <v-col cols="10" sm="11">
                    <span>
                      {{ trip.locations[location.original_index].address }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-card>
        </div>
      </template>
    </v-card-text>
  </v-container>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import { Valhalla_Leg } from "@/logic/types/valhalla_types";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import Vue, { PropType } from "vue";
import ItineraryHeader from "./ItineraryHeader.vue";
export default Vue.extend({
  name: "ItineraryICEContent",
  props: {
    trip: {
      required: true,
      type: Object as PropType<Trip>,
    },
  },
  methods: {
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
    calcFerryTime(leg: Valhalla_Leg): string {
      const ferryTime = leg.maneuvers.reduce((accumulator, currentItem) => {
        if (currentItem.ferry) {
          return accumulator + currentItem.time;
        }

        return accumulator;
      }, 0);
      return this.niceDuration(ferryTime);
    },
    calcDrivingTime(leg: Valhalla_Leg): string {
      const drivingTime = leg.maneuvers.reduce((accumulator, currentItem) => {
        if (!currentItem.ferry) {
          return accumulator + currentItem.time;
        }

        return accumulator;
      }, 0);
      return this.niceDuration(drivingTime);
    },
  },
  components: {
    ItineraryHeader,
  },
});
</script>
