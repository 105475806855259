<template>
  <v-container
    v-if="loadingStations"
    class="d-flex justify-center align-center"
    style="height: 80vh"
  >
    <LoadingCard />
  </v-container>
  <v-container v-else>
    <v-card-title class="pb-0 tertiary--text pt-5"> Filters </v-card-title>
    <SettingsContent />
    <SettingsBtn />
    <ChargersNearbyCard v-if="!trip" />
    <v-card-title class="pb-0 tertiary--text pt-5">
      Charging Stations
    </v-card-title>
    <v-toolbar flat v-if="!trip">
      <v-text-field
        :value="searchCriteria"
        placeholder="Search for charging stations near a location"
        hide-details="auto"
        clearable
        @input="handleSearchCriteriaInput"
        @click:clear="handleSearchCriteriaClear"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </v-toolbar>
    <TextBtn v-else @click="handleToggleShowAll" class="mb-3">
      {{
        isShowingAll
          ? "Show only charging stops"
          : "Show all stations along the route"
      }}
    </TextBtn>
    <v-container>
      <v-virtual-scroll
        bench="3"
        :items="filteredStations"
        height="600"
        :item-height="trip ? 94 : 134"
      >
        <template v-slot:default="{ item }">
          <ChargingStationCard
            :key="`charger-list-card-${item.id}`"
            :station="item"
          />
        </template>
      </v-virtual-scroll>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import ChargingStationCard from "./ChargingStationCard.vue";
import ChargersNearbyCard from "../../../range-finder/info-panel-content/ChargersNearbyCard.vue";
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  type State,
} from "../../../../../logic/store/store_types";
import LoadingCard from "@/ui/components/ui-elements/loaders/LoadingCard.vue";
import SettingsBtn from "@/ui/components/settings/settings-btn/SettingsBtn.vue";
import SettingsContent from "@/ui/components/settings/dialog-content/SettingsContent.vue";
import TextBtn from "@/ui/components/ui-elements/buttons/TextBtn.vue";

/** `Vue component:` renders a list of charging stations to be displayed
 * in the `MainDialog` and `MobileDialog` components*/
export default Vue.extend({
  name: "StationsContent",
  computed: {
    ...mapGetters({
      filteredStations: GettersTypes.filterChargingStations,
      trip: GettersTypes.selectedTripData,
    }),
    ...mapState({
      isShowingAll: (state: unknown) =>
        (state as State).displayAllChargersAlongRoute,
      searchCriteria: (state: unknown) =>
        (state as State).chargersSearchCriteria,
      loadingStations: (state: unknown) => (state as State).chargerFetchingFlag,
    }),
  },
  components: {
    ChargingStationCard,
    ChargersNearbyCard,
    LoadingCard,
    SettingsBtn,
    SettingsContent,
    TextBtn,
  },
  methods: {
    handleFilterChargers() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.SETTINGS
      );
    },
    handleSearchCriteriaInput(value: string) {
      value &&
        this.$store.commit(MutationTypes.setChargersSearchCriteria, value);
    },
    handleSearchCriteriaClear() {
      this.$store.commit(MutationTypes.setChargersSearchCriteria, undefined);
    },
    handleToggleShowAll() {
      if (!this.trip?.chargersAlongRouteCDBIDs.length) {
        this.$store.dispatch(ActionTypes.showAllChargersOnRoute);
      }
      if (!this.isShowingAll) {
        // assumes changing form false to true is toggling on.
        // Notify analytics server
        Vue.prototype.$Countly.q.push([
          "add_event",
          {
            key: "View all chargers along trip feature used",
            count: 1,
          },
        ]);
      }
      this.$store.commit(
        MutationTypes.setDisplayAllChargersAlongRoute,
        !this.isShowingAll
      );
    },
  },
  data() {
    return {
      tab: 0,
    };
  },
});
</script>

<style scoped>
#pwt-custom-active-tab {
  border-radius: 10px 10px 0 0;
  background-color: #2eaeff;
}

.pwt-custom-active-tab-text {
  z-index: 1101;
}

.pwt-tabs-border {
  border-bottom: 2px solid var(--v-primary-base);
}
</style>
