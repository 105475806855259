<template>
  <v-card
    class="blue-grey ml-4 my-auto pa-0 overflow-hidden d-flex justify-end rounded-lgs"
    style="width: calc(100% - 28px); height: 10px"
    flat
  >
    <v-card
      class="primary ma-0 pa-0"
      :width="`${startPercentage - endPercentage}%`"
      flat
      tile
    ></v-card>
    <v-card
      class="grey lighten-2 ma-0 pa-0"
      :width="`${100 - startPercentage}%`"
      flat
      tile
    ></v-card>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "BatteryChangeGage",
  props: {
    /** The starting percentage as a whole number. e.g. 20% = 20 */
    startPercentage: { type: Number, required: true },
    /** The ending percentage as a whole number. e.g. 20% = 20 */
    endPercentage: { type: Number, required: true },
  },
});
</script>
