<template>
  <v-col justify="center" cols="6" sm="4" md="3">
    <!-- conditionally render logo if we have one -->
    <v-img
      v-if="networkBranding && networkBranding.iconLogoUUID"
      :src="networkBranding.iconLogoSrc"
      contain
      width="40px"
      height="auto"
      class="mx-auto"
    />

    <!-- conditionally render logo if we have one that needs a circular -->
    <div
      class="overflow-hidden rounded-circle mx-auto"
      style="width: 40px; height: 40px"
      v-else-if="networkBranding && networkBranding.textBakedLogoUUID"
    >
      <v-img
        :src="networkBranding.textBakedLogoSrc"
        contain
        width="40px"
        height="auto"
      />
    </div>

    <!-- else default to station icon -->
    <div style="width: 100%; height: 40px" v-else>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-icon color="grey" x-large> $unKnownNetworkIcon </v-icon>
      </v-row>
    </div>

    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </template>

    <!-- conditionally render the display name if branding object can be found -->
    <p v-if="!!networkBranding" class="text-center">
      {{ networkBranding.displayName }} access
    </p>

    <!-- else default to full network name  -->
    <p v-else-if="!!network" class="text-center" style="word-break: none">
      {{ network }} access
    </p>

    <!-- catch for records with no network (should not happen but has due to DB errors) -->
    <p v-else class="text-center" style="word-break: none">
      unknown network access
    </p>
  </v-col>
</template>

<script lang="ts">
import NetworkBranding from "@/logic/classes/charger_classes/networkBranding";
import { State } from "@/logic/store/store_types";
import Vue from "vue";

/** Vue Component: renders charging station network access reminder, to be displayed in the `ItineraryBringSection` component.
 *
 * @props `network` - the network name recognized by the DB.
 */
export default Vue.extend({
  name: "OperatorAccessCard",
  props: {
    network: String,
  },
  computed: {
    networkBranding(): NetworkBranding | undefined {
      // find network details
      const data = (this.$store.state as State).networkBranding.find((item) =>
        item.isThisNetwork(this.network)
      );

      // not undefined guard clause
      if (!data) return;

      // return network details on successful find operation
      return data;
    },
  },
});
</script>
