<template>
  <v-card flat>
    <!-- current vehicle -->
    <SelectedVehicleCard />
    <!-- button band -->
    <v-card-text>
      <ElevatedBlockBtn class="mb-3" @click="handleViewBooking">
        Book Vehicle
      </ElevatedBlockBtn>
      <OutlinedBlockBtn class="mb-3" @click="handleViewAllVehicles">
        View All Vehicle Options
      </OutlinedBlockBtn>
      <TextBlockBtn @click="handleShowEVs">
        {{
          showingConnectedVehicles
            ? "Hide connected vehicles"
            : "Show connected vehicles"
        }}
      </TextBlockBtn>
    </v-card-text>
    <!-- range adjustment -->
    <v-card-title class="d-flex justify-space-between">
      <span>Range adjustment</span>
      <span>
        <StyledToolTip v-if="tooltipContent" :data="tooltipContent" />
      </span>
    </v-card-title>
    <v-card-subtitle>
      Battery health and vehicle performance change over time. Make adjustments
      to this vehicles' effective range to create more accurate, personalised
      trip plans
    </v-card-subtitle>
    <v-card-text class="d-flex flex-column">
      <v-row no-gutters justify="space-between">
        <span>Range adjustment</span>
        <span class="align-self-end mr-4">
          {{ SOHVal }}%{{ getMaxRange() }}
        </span>
      </v-row>
      <v-slider
        v-model="SOHVal"
        hide-details
        track-color="grey lighten-2"
        @end="updateSOH"
        class="ml-n2"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import SelectedVehicleCard from "./SelectedVehicleCard.vue";
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  ManagedContentNames,
  MutationTypes,
  SecondaryDialogContent,
  type State,
} from "@/logic/store/store_types";
import StyledToolTip from "../../ui-elements/StyledToolTip.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import ElevatedBlockBtn from "../../ui-elements/buttons/ElevatedBlockBtn.vue";
import OutlinedBlockBtn from "../../ui-elements/buttons/OutlinedBlockBtn.vue";
import TextBlockBtn from "../../ui-elements/buttons/TextBlockBtn.vue";

/** `Vue component:` renders a condensed list of selectable vehicles and
 * the driver profile to be displayed in the `NavPanel` and
 * `MobileDialog` components*/
export default Vue.extend({
  name: "VehicleContent",
  components: {
    SelectedVehicleCard,
    StyledToolTip,
    ElevatedBlockBtn,
    OutlinedBlockBtn,
    TextBlockBtn,
  },
  computed: {
    ...mapGetters({
      vehicle: GettersTypes.selectedVehicleData,
    }),
    ...mapState({
      showingConnectedVehicles: (state: unknown) =>
        (state as State).displayConnectedVehicles,
      tooltipContent: (state: unknown) =>
        (state as State).managedContent.find(
          (content) => content.Name === ManagedContentNames.SoHToolTip
        ),
    }),
  },
  methods: {
    handleViewAllVehicles() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_GALLERY
      );
    },
    handleConnectorsAndCables() {
      this.$store.commit(
        MutationTypes.setSecondaryDialogContent,
        SecondaryDialogContent.CONNECTORS_AND_CABLE_SETTINGS
      );
    },
    handleRefresh() {
      this.$store.dispatch(ActionTypes.updateVehiclesData);
    },
    handleShowEVs() {
      this.$store.commit(
        MutationTypes.setDisplayConnectedVehicles,
        !this.showingConnectedVehicles
      );
    },
    getMaxRange(): string {
      const range = (this.vehicle as Vehicle | undefined)?.calcMaxRange(
        (this.$store.state as State).extraWeight
      );
      if (range) return ` - ${Math.floor(range / 1000)}km max range`;
      return " - unknown max range";
    },
    updateSOH() {
      if (!this.vehicle) return;
      (this.vehicle as Vehicle).updateSOH(this.SOHVal / 100);
      this.$store.commit(MutationTypes.updateIndividualVehicle, this.vehicle);
    },
    handleViewBooking() {
      if (this.vehicle) {
        this.$store.commit(
          MutationTypes.setViewedFleetVehicle,
          (this.vehicle as Vehicle).localId
        );
        this.$store.commit(
          MutationTypes.setMainDialogContent,
          MainDialogContent.VEHICLE_BOOKING
        );
      }
    },
    setInitialSOH() {
      if (this.vehicle && !this.initialValueHasBeenSet) {
        this.SOHVal = (this.vehicle as Vehicle).stateOfHealth
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (this.vehicle as Vehicle).stateOfHealth! * 100
          : 50;
        this.initialValueHasBeenSet = true;
      }
    },
  },
  data() {
    return {
      SOHVal: 50,
      initialValueHasBeenSet: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialSOH();
    });
  },
  watch: {
    vehicle() {
      this.setInitialSOH();
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
