<template>
  <GenericDialog :activator="activator" @close="closeAndReset">
    <v-card flat>
      <v-card-title class="mb-5"> Add a charging stop </v-card-title>
      <v-card flat class="px-16 pb-16 pt-8" v-if="planning">
        <v-card-text class="primary--text text-center text-h5">
          Power trip is recalculating your trip
        </v-card-text>
        <v-card-text class="d-flex justify-center primary--text text-center">
          <LoadingCard />
        </v-card-text>
        <v-card-text class="primary--text text-center text-subtitle-2">
          This may take a few moments
        </v-card-text>
      </v-card>
      <v-card v-else-if="selectedCharger">
        <v-card class="mb-4 rounded-lg" color="background">
          <v-row no-gutters>
            <v-col cols="1" class="d-flex align-start justify-center">
              <v-img
                contain
                width="42"
                height="42"
                :src="getSrc('icons/DC.svg')"
                class="mt-5"
                aspect-ratio="1"
                max-height="42"
                max-width="42"
              />
            </v-col>
            <v-col cols="10">
              <v-card-title style="word-break: normal">
                {{ selectedCharger.name }}
              </v-card-title>
              <v-card-subtitle>
                {{ selectedCharger.addressString }}
              </v-card-subtitle>
              <v-card-text>
                {{ selectedCharger.evses.length }} charging bays
              </v-card-text>
            </v-col>
            <!-- <v-col cols="1" v-if="branding()">
              <OperatorLogo :networkBranding="branding()" />
            </v-col> -->
          </v-row>
          <v-card-text>
            <v-slider
              v-model="chargeTo"
              min="1"
              max="100"
              step="1"
              track-color="grey lighten-2"
              :label="'Charge up to ' + chargeTo + '%'"
            />
            <ElevatedBlockBtn class="mt-5" @click="addChargingStop">
              Add charging stop
            </ElevatedBlockBtn>
          </v-card-text>
        </v-card>
      </v-card>
      <v-card-text v-else>
        <v-row no-gutters justify="space-between">
          <v-card
            v-for="charger in chargersInRange"
            :key="charger.id"
            width="49%"
            class="mb-4 rounded-lg"
            color="background"
            @click="selectCharger(charger)"
          >
            <v-row no-gutters>
              <v-col cols="2" class="d-flex align-start justify-center">
                <v-img
                  contain
                  width="42"
                  height="42"
                  :src="getSrc('icons/DC.svg')"
                  class="mt-5"
                  aspect-ratio="1"
                  max-height="42"
                  max-width="42"
                />
              </v-col>
              <v-col cols="10">
                <v-card-title style="word-break: normal">
                  {{ charger.name }}
                </v-card-title>
                <v-card-subtitle>
                  {{ charger.addressString }}
                </v-card-subtitle>
                <v-card-text>
                  {{ charger.evses.length }} charging bays
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
  </GenericDialog>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { mapGetters, mapState } from "vuex";
import { GettersTypes, MutationTypes, State } from "@/logic/store/store_types";
import Charger from "@/logic/classes/charger_classes/charger";
import TripV2 from "@/logic/classes/trip_v2_classes/trip_v2";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import ElevatedBlockBtn from "../../ui-elements/buttons/ElevatedBlockBtn.vue";
// import OperatorLogo from "../../charging-stations/details/common/OperatorLogo.vue";
import NetworkBranding from "@/logic/classes/charger_classes/networkBranding";
import GenericDialog from "../../dialog-wrappers/GenericDialog.vue";
import LoadingCard from "../../ui-elements/loaders/LoadingCard.vue";

export default Vue.extend({
  name: "AddChargingStopContent",
  components: {
    ElevatedBlockBtn,
    // OperatorLogo,
    GenericDialog,
    LoadingCard,
  },
  props: {
    centerLatLng: {
      type: Object as PropType<
        { latitude: number; longitude: number } | undefined
      >,
    },
    stepIndex: Number,
    activator: Boolean,
  },
  data() {
    return {
      selectedCharger: null as Charger | null,
      chargeTo: 80,
      planning: false,
    };
  },
  computed: {
    ...mapGetters({
      tripDetails: GettersTypes.selectedTripData,
    }),
    ...mapState({
      networkBrandingList: (state: unknown): NetworkBranding[] =>
        (state as State).networkBranding,
      chargingCostPerMin: (state: unknown): number =>
        (state as State).defaultCostPerMinDC,
      publicCostPerKWh: (state: unknown): number =>
        (state as State).defaultPublicCostPerKWh,
      stations: (state: unknown): Charger[] => (state as State).chargers,
    }),
    chargersInRange(): Charger[] {
      const typedTrip = this.tripDetails as TripV2 | undefined;
      if (!typedTrip || !this.centerLatLng) return [];
      const chargerIds = typedTrip.findChargerIdsInRangeOfPoint(
        this.centerLatLng,
        Math.floor(
          typedTrip.itinerary.steps[this.stepIndex].drivingDistance / 10 / 1000
        )
      );
      return (
        (this.stations as Charger[]).filter((c) => chargerIds.includes(c.id)) ||
        []
      );
    },
  },
  methods: {
    getSrc(partialFilePath: string) {
      return getAssetSrc(partialFilePath);
    },
    selectCharger(charger: Charger) {
      this.selectedCharger = charger;
    },
    branding(): NetworkBranding | undefined {
      if (!this.selectedCharger?.operator?.name) return undefined;
      return this.networkBrandingList.find((network) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        network.isThisNetwork(this.selectedCharger!.operator!.name!)
      );
    },
    closeAndReset() {
      this.$emit("close");
      this.selectedCharger = null;
      this.chargeTo = 80;
    },
    async addChargingStop() {
      this.planning = true;
      if (!this.selectedCharger) return;
      if (!this.tripDetails) return;

      const trip = this.tripDetails as TripV2;
      await trip.addChargerToStep({
        charger: this.selectedCharger,
        stepIndex: this.stepIndex,
        stateOfChargeAfterCharging: this.chargeTo / 100,
        defaultCostPerKWh: this.publicCostPerKWh,
        defaultCostPerMinDC: this.chargingCostPerMin,
      });
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
      this.closeAndReset();
      this.planning = false;
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
