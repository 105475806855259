import Trip from "../classes/trip_classes/trip";
import { CalcVsType } from "../store/store_types";
import { TripFrequency } from "../types/trip_specific_types";

/** the estimated totals over five years based on the stored trips and there
 * expected frequency over this period. */
export interface FiveYearSavings {
  /** The total expected cost if only public charging over five years. */
  totalPublicChargingCost: number;
  /** The total expected cost of private charging if not using public networks
   * over five years. */
  totalPrivateChargingCost: number;
  /** The total expected cost of petrol over five years if not driving and EV. */
  totalEquivalentPetrolCost: number;
  /** The total avoided CO2 emissions expected over five years. */
  totalAvoidedEmissions: number;
  /** The total number of trips expected over five years. */
  totalNumberOfTrips: number;
  /** The total cost of expected EV maintenance over five years */
  totalEVMaintenanceCost: number;
  /** The total cost of expected petrol vehicle maintenance over five years */
  totalPetrolMaintenanceCost: number;
  /** The expected total cost of road user charges based on the predicted distance traveled*/
  totalRoadUserCharges: number;
}

export interface FiveYearSavingsSettings {
  perAnnumMaintenanceEV: number;
  perAnnumMaintenanceCurrentV: number;
  kWhCostHome: number;
  calcVs: CalcVsType;
  petrolCostPerLitre: number;
  dieselCostPerLitre: number;
  petrolKmPerLitre: number;
  dieselKmPerLitre: number;
  roadUserCharges: number;
}

/**
 * Calculates and returns the five year savings base on the estimated cost and
 * amount of times all stored trips are expected to accumulate of a five year
 * period.
 *
 * @param storedTrips the full `storedRoutePlans` array. See `routePlaningStore`.
 * @param settings the whole settings store global state object. See `settingsStore`.
 * @returns returns a calculated `FiveYearSavings` object.
 */
export default function calcFiveYearSavings(
  storedTrips: Trip[],
  settings: FiveYearSavingsSettings
): FiveYearSavings {
  // create accumulating variables
  let totalPublicChargingCost = 0;
  let totalPrivateChargingCost = 0;
  let totalEquivalentPetrolCost = 0;
  let totalAvoidedEmissions = 0;
  let totalNumberOfTrips = 0;
  let totalRoadUserCharges = 0;

  // filter out one off trips
  const filteredTrips = storedTrips.filter((trip) => !!trip.frequency);

  filteredTrips.forEach((trip) => {
    // calculate this trips over five years totals
    const numberOfTrips = trip.frequency
      ? calcFrequencyAdjustment(trip.frequency)
      : 0;
    const publicChargingCost = calcPublicChargingCost(trip) * numberOfTrips;
    const privateChargingCost =
      trip.calcPrivateChargingCost(settings.kWhCostHome) ?? 0 * numberOfTrips;
    const equivalentPetrolCost =
      calcFuelCost(calcEVTripDistance(trip), settings) * numberOfTrips;
    const avoidedEmissions =
      calcPerTripAvoidedEmissions(trip, settings) * numberOfTrips;
    const roadUserCharges = calcRoadUserCharges(trip, settings);

    // set accumulatingVariables
    totalPublicChargingCost += publicChargingCost;
    totalPrivateChargingCost += privateChargingCost;
    totalEquivalentPetrolCost += equivalentPetrolCost;
    totalAvoidedEmissions += avoidedEmissions;
    totalNumberOfTrips += numberOfTrips;
    totalRoadUserCharges += roadUserCharges;
  });

  return {
    totalPublicChargingCost: parseFloat(totalPublicChargingCost.toFixed(2)),
    totalPrivateChargingCost: parseFloat(totalPrivateChargingCost.toFixed(2)),
    totalEquivalentPetrolCost: parseFloat(totalEquivalentPetrolCost.toFixed(2)),
    totalAvoidedEmissions: Math.round(totalAvoidedEmissions),
    totalNumberOfTrips: Math.round(totalNumberOfTrips),
    totalEVMaintenanceCost: parseFloat(
      (settings.perAnnumMaintenanceEV * 5).toFixed(2)
    ),
    totalPetrolMaintenanceCost: parseFloat(
      (settings.perAnnumMaintenanceCurrentV * 5).toFixed(2)
    ),
    totalRoadUserCharges: parseFloat(totalRoadUserCharges.toFixed(2)),
  };
}

/**
 * Calculates and returns the total number of times this trip is expected to be taken
 * over a five year period.
 *
 * @param frequency the stored trips `TripFrequency` object. See 'types' for details.
 * @returns the total number of trips over five years.
 */
function calcFrequencyAdjustment(frequency: TripFrequency): number {
  let timeFrameAdjustment = 0;

  switch (frequency.timeFrame) {
    case "day":
      // approximate result, multiply number of years 5 by 365.3.
      // This should cover leap year in the time frame without
      // exact knowledge of the year.
      timeFrameAdjustment = 1826.25;
      break;
    case "week":
      // n-years * 52, 5 * 52
      timeFrameAdjustment = 260;
      break;
    case "weekday":
      // (n-years * 52) * 5, (5 * 52) * 5
      timeFrameAdjustment = 1300;
      break;
    case "fortnight":
      // n-years * 26, 5 * 26
      timeFrameAdjustment = 130;
      break;
    case "month":
      // n-years * 12, 5 * 12
      timeFrameAdjustment = 60;
      break;
    case "quarter":
      // n-years * 4, 5 * 4
      timeFrameAdjustment = 20;
      break;
    case "half-year":
      // n-years * 2, 5 * 2
      timeFrameAdjustment = 10;
      break;
    case "year":
      // n-years, 5
      timeFrameAdjustment = 5;
      break;
  }

  return timeFrameAdjustment * frequency.numberOfTimes;
}

/**
 * Calculates and returns the total cost of petrol for a single run of this trip.
 *
 * @param distance the number of meters traveled in a single run fo this trip.
 * @param settings the whole settings store global state object. see `settingsStore`.
 * @returns the total cost of petrol for a single run of this trip.
 */
/** */
function calcFuelCost(distance: number, settings: FiveYearSavingsSettings) {
  const mPerLitre =
    (settings.calcVs === CalcVsType.PETROL
      ? settings.petrolKmPerLitre
      : settings.dieselKmPerLitre) * 1000;
  const costPerLitre =
    settings.calcVs === CalcVsType.PETROL
      ? settings.petrolCostPerLitre
      : settings.dieselCostPerLitre;
  return (distance / mPerLitre) * costPerLitre;
}

/**
 * Calculates and returns the total cost of public charging for a single run of this trip.
 *
 * @param trip the full `Trip` object for this trip.
 * @returns the total cost of public charging for a single run of this trip in NZD.
 *
 * NOTE: only successfully planned trips will return a value other than `0`.
 */
export function calcPublicChargingCost(trip: Trip): number {
  if (trip.totalPublicChargingCost) return trip.totalPublicChargingCost;
  let totalCost = 0;
  trip.evTripData?.forEach((plan) => {
    totalCost += plan.cost || 0;
  });
  return totalCost;
}

/**
 * Calculates and returns the total distance traveled in an EV in a single run of this trip.
 *
 * @param trip the full `Trip` object for this trip.
 * @returns the total distance traveled in an EV in a single run of this trip in meters.
 *
 * NOTE: only successfully planned trips will return a value other than `0`.
 */
export function calcEVTripDistance(trip: Trip): number {
  if (trip.totalDistance) return trip.totalDistance;
  let totalDistance = 0;
  trip.evTripData?.forEach((plan) => {
    totalDistance += plan.distance;
  });
  return totalDistance;
}

/**
 * Calculates and returns the total energy consumed in a single run of this trip.
 *
 * @param trip the full `Trip` object for this trip.
 * @returns the total energy consumed in a single run of this trip in kWh.
 *
 * NOTE: only successfully planned trips will return a value other than `0`.
 */
export function calcTotalEnergy(trip: Trip): number {
  if (trip.totalEnergy) return trip.totalEnergy;
  let totalEnergy = 0;
  trip.evTripData?.forEach((plan) => {
    totalEnergy += plan.energy;
  });
  return totalEnergy;
}

function calcPerTripAvoidedEmissions(
  trip: Trip,
  settings: FiveYearSavingsSettings
) {
  // user petrol or diesel km traveled per litre of fuel consumed
  const efficiency =
    settings.calcVs === CalcVsType.PETROL
      ? settings.petrolKmPerLitre
      : settings.dieselKmPerLitre;
  // 2.8 for petrol and 3.2 for diesel
  const co2PerLitre = settings.calcVs === CalcVsType.PETROL ? 2.8 : 3.2;
  // distance in kilometers = (distance in meters / 1000).
  const distanceInKm = calcEVTripDistance(trip) / 1000;
  const avoidedCO2 = (distanceInKm / efficiency) * co2PerLitre;
  const emittedC02 = calcTotalEnergy(trip) * 0.13;
  return avoidedCO2 - emittedC02;
}

function calcRoadUserCharges(
  trip: Trip,
  settings: FiveYearSavingsSettings
): number {
  if (settings.calcVs === CalcVsType.DIESEL) {
    return (calcEVTripDistance(trip) / 10000) * settings.roadUserCharges;
  }

  return 0;
}
