<template>
  <v-card class="rounded-lg mb-5" @click="openChargingStationDetails">
    <v-row no-gutters>
      <v-col cols="1">
        <v-card-title>
          <v-btn icon @click.stop="emitRemove">
            <v-icon> mdi-close-circle </v-icon>
          </v-btn>
        </v-card-title>
      </v-col>
      <v-col cols="11">
        <v-card-title>
          <v-row no-gutters>
            <v-col :cols="branding ? 9 : 12">
              {{
                chargerData && chargerData.name
                  ? chargerData.name
                  : "unnamed charger"
              }}
            </v-col>
            <v-col
              cols="3"
              v-if="branding"
              class="d-flex align-center justify-end"
            >
              <OperatorLogo :networkBranding="branding" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle>
          {{
            chargerData
              ? chargerData.simpleDisplayRating
              : itineraryLocation.addressDisplayStr
          }}
        </v-card-subtitle>
        <v-card-text>
          <ul style="list-style: none" class="pl-0">
            <li v-if="batteryPercentage">
              Charge to
              <span class="font-weight-bold">{{ batteryPercentage }}%</span>
            </li>
            <li v-if="itineraryLocation.chargingTime">
              Charging time
              <span class="font-weight-bold">{{
                niceDuration(itineraryLocation.chargingTime)
              }}</span>
            </li>
            <li v-if="itineraryLocation.expectedArrivalTime">
              Arrive at {{ itineraryLocation.expectedArrivalTime }}
            </li>
            <li v-if="itineraryLocation.expectedDepartureTime">
              Depart at {{ itineraryLocation.expectedDepartureTime }}
            </li>
          </ul>
          <!-- Note: input is firing update on change to reduce impact of on input can be altered if impact is low -->
          <v-text-field
            :value="value"
            suffix="kWh"
            type="number"
            label="Added charge"
            @change="handleChargeChange"
            :error-messages="errorMsg"
            @click.stop="null"
            @mousedown.stop
            @touchstart.stop
          />
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script lang="ts">
import Charger from "@/logic/classes/charger_classes/charger";
import ChargerLocation from "@/logic/classes/itinerary_data_classes/ChargerLocation";
import NetworkBranding from "@/logic/classes/charger_classes/networkBranding";
import {
  MainDialogContent,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import to2DP from "@/logic/utils/to2DP";
import OperatorLogo from "@/ui/components/charging-stations/details/common/OperatorLogo.vue";
import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "ChargerLocationCard",
  props: {
    itineraryLocation: {
      type: Object as PropType<ChargerLocation>,
      required: true,
    },
    batterySize: {
      type: Number,
    },
  },
  computed: {
    chargerData(): Charger | undefined {
      return (this.$store.state as State).chargers.find(
        (charger) => charger.id === this.itineraryLocation.relatedCharger
      );
    },
    branding(): NetworkBranding | undefined {
      if (this.chargerData?.operator?.name)
        return (this.$store.state as State).networkBranding.find((network) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          network.isThisNetwork(this.chargerData!.operator!.name!)
        );
      return undefined;
    },
    batteryPercentage(): number | undefined {
      if (!this.batterySize) return;
      return Math.round(
        (this.itineraryLocation.departingEnergy / this.batterySize) * 100
      );
    },
    value(): number | null {
      return this.itineraryLocation.energyAdded
        ? to2DP(this.itineraryLocation.energyAdded)
        : null;
    },
  },
  components: {
    OperatorLogo,
  },
  methods: {
    openChargingStationDetails(): void {
      // open charger details in modal
      this.$store.commit(
        MutationTypes.setSelectedCharger,
        this.itineraryLocation.relatedCharger
      );
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.CHARGING_STATION_DETAILS
      );
    },
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
    emitRemove() {
      this.$emit("remove");
    },
    emitUpdate(updatedLocation: ChargerLocation) {
      this.$emit("update", updatedLocation);
    },
    handleChargeChange(val: string | number | null): void {
      // NOTE: strings have to be considered as this version of vuetify (v2) returns stringify
      // numbers to input changes but can also accept numbers added programmatically that will
      // also return a number. Its inbuilt clear function returns a null. Further more 0 is a
      // valid input and needs to be considered hence the expanded checking for a simple value
      // change.

      // clear previous error message.
      this.errorMsg = null;

      // handle cleared value.
      // NOTE: clear function returns null, manually deleting input returns an empty string.
      if (val === null || val === "") {
        if (this.itineraryLocation.energyAdded !== 0) {
          // update and emit updated obj.
          const copyLocation = new ChargerLocation({
            ...this.itineraryLocation,
          }); // copy obj to prevent prop abuse.
          copyLocation.energyAdded = 0;
          this.emitUpdate(copyLocation); // triggers rerender and recalc.
          return;
        } // extra layer of nesting for TS to infer all null instances have been handled.
        return; // assumes energyAdded is already 0, no need to trigger a rerender/recalc.
      } // null or empty string as matcher to ensure 0 is not excluded as falsy

      // parse val.
      const parsedVal = parseIntOrFloat(val);

      // display error in unable to parse val.
      if (parsedVal === undefined) {
        this.errorMsg = "must be a number";
        return; // triggers rerender but no recalc.
      } // undefined as matcher to ensure 0 is not excluded as falsy

      // display error if value is not valid.
      if (parsedVal < 0) {
        this.errorMsg = "must not be a negative number";
        return; // triggers rerender but no recalc.
      }

      // avoid rerender/recalc if new value and old value are the same.
      if (parsedVal === this.value) return; // prevents life cycle events looping if triggered with no change.

      // update and emit updated obj.
      const copyLocation = new ChargerLocation({ ...this.itineraryLocation }); // copy obj to prevent prop abuse.
      // check not exceeding max if known.
      if (
        this.batterySize &&
        parsedVal + this.itineraryLocation.arrivalEnergy > this.batterySize
      ) {
        // charge to max.
        copyLocation.energyAdded =
          this.batterySize - this.itineraryLocation.arrivalEnergy;
      } else {
        // charge to value given.
        copyLocation.energyAdded = parsedVal;
      }
      this.emitUpdate(copyLocation); // triggers rerender and recalc.
    },
  },
  data() {
    return {
      errorMsg: null as null | string,
    };
  },
});
</script>
