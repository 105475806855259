<template>
  <!-- Trip details section -->
  <v-card flat class="mb-5">
    <v-card-title
      class="d-flex justify-space-between align-start"
      style="word-break: normal"
    >
      <div style="max-width: 80%">
        Trip to
        {{ trip.itinerary.destination ? trip.itinerary.destination.name : "" }}
      </div>
    </v-card-title>
    <v-card-text>
      <ul style="list-style: none" class="pl-0">
        <li class="d-flex justify-space-between">
          <span>Total driving time</span>
          <span>{{ niceDuration(trip.itinerary.totalTravelTime) }}</span>
        </li>
        <li class="d-flex justify-space-between">
          <span>Km driven</span>
          <span>
            {{ Math.round(trip.itinerary.totalDrivingDistance / 1000) }}km
          </span>
        </li>
        <li class="d-flex justify-space-between">
          <span>Number of planned stops along the way</span>
          <span>{{ trip.numberOfScheduledStops }}</span>
        </li>
        <li class="d-flex justify-space-between">
          <span>Number additional charging stops</span>
          <span>{{ trip.chargingStopCDBIDs.length }}</span>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import TripV2 from "@/logic/classes/trip_v2_classes/trip_v2";
import Vue, { PropType } from "vue";
import { getNiceDuration } from "@/logic/utils/timeUtils";

export default Vue.extend({
  name: "TripDetailsSection",
  props: {
    trip: {
      type: Object as PropType<TripV2>,
    },
  },
  methods: {
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
  },
});
</script>
