<template>
  <v-card class="primary rounded-lg pb-5" dark elevation="4">
    <v-row no-gutters class="pt-3">
      <v-col cols="1">
        <div
          class="white rounded-circle ml-3 mt-1"
          style="height: 20px; width: 20px"
        ></div>
      </v-col>
      <v-col cols="10">
        <v-card-title
          class="font-weight-bold text-body-1 pt-0"
          style="word-break: normal"
        >
          {{ storedTrip.locations[storedTrip.locations.length - 1].address }}.
        </v-card-title>

        <!-- Arrival time section -->
        <v-card-subtitle
          class="white--text"
          v-if="storedTrip.primaryTimeLocation"
        >
          {{ calcArrivalTime.displayArrivalTime }}
        </v-card-subtitle>
        <v-card-subtitle
          v-if="calcArrivalTime.timeDifType === 'before'"
          class="pt-0 mt-n2"
        >
          {{ calcArrivalTime.displayTimeDif }} earlier than expected
        </v-card-subtitle>
        <v-card-subtitle
          v-if="calcArrivalTime.timeDifType === 'after'"
          class="yellow--text pt-0 mt-n2"
        >
          <v-icon color="yellow">mdi-alert-circle</v-icon>
          {{ calcArrivalTime.displayTimeDif }} later than expected
        </v-card-subtitle>
        <v-card-text>
          <v-row
            no-gutters
            class="d-flex justify-space-between font-weight-bold text-body-2"
          >
            <span>Arrival charge</span>
            <span>
              {{
                step.EndCharge
                  ? `${Math.round(step.EndCharge * 100)}%`
                  : "unknown"
              }}
            </span>
          </v-row>
        </v-card-text>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { DateTime, Duration } from "luxon";
import type { EVNavStep } from "@/logic/types/ev_nav_types";
import Trip from "@/logic/classes/trip_classes/trip";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import { getDifference } from "@/logic/utils/calcTimeData";

/** Vue Component: renders the final trailing card in a trips itinerary. To be used in the `ItineraryContent` component.
 *
 * @prop `step` - the object for the current step in the route plan. See `routePlanningStore` for details.
 * @prop `storedTrip` - the full stored trip object.
 */
export default Vue.extend({
  name: "ArrivalCard",
  props: {
    step: Object as PropType<EVNavStep>,
    storedTrip: Object as PropType<Trip>,
  },
  computed: {
    calcArrivalTime(): {
      displayArrivalTime: string;
      displayTimeDif?: string;
      timeDifType?: string;
    } {
      const baseTime = (this.storedTrip.locations as TripLocation[]).find(
        (location) => location.localId === this.storedTrip.primaryTimeLocation
      )?.time;

      let totalTime = 0;

      this.storedTrip.evTripData?.forEach((plan) => {
        totalTime += plan.time;
      });

      this.storedTrip.locations.forEach((location) => {
        if (location.stay) {
          totalTime += location.stay;
        }
      });

      const expectedTime =
        this.storedTrip.locations[this.storedTrip.locations.length - 1].time;
      const expectedTimeObj = expectedTime
        ? DateTime.fromISO(expectedTime)
        : undefined;

      // case for no primary time
      if (
        this.storedTrip.primaryTimeLocation === undefined ||
        baseTime === undefined
      ) {
        return {
          displayArrivalTime: `${Duration.fromObject({
            hours: 0,
            minutes: Math.floor(totalTime / 60),
          })
            .normalize()
            .toHuman({ listStyle: "narrow" })
            .replace(",", "")} total travel time`,
        };
      }

      // case for initial departure time as the primary time
      if (
        this.storedTrip.primaryTimeLocation ===
        this.storedTrip.locations[0].localId
      ) {
        const timeObj = DateTime.fromISO(baseTime).plus({
          hours: 0,
          minutes: 0,
          seconds: totalTime,
        });

        const difData =
          expectedTime && expectedTimeObj
            ? getDifference(expectedTimeObj, timeObj)
            : undefined;

        return {
          displayArrivalTime: `${timeObj.toLocaleString(
            DateTime.TIME_SIMPLE
          )} arrival`,
          displayTimeDif: difData
            ? difData.differenceObj
                // correct rounding as luxon only supports floor rounding in its current version.
                .set({
                  minutes: Math.round(difData.differenceObj.minutes),
                })
                .normalize()
                .toHuman({ listStyle: "narrow" })
                .replace(",", "")
            : undefined,
          timeDifType: difData ? difData.differenceType : undefined,
        };
      }

      // case for final destination arrival time as the primary time
      if (this.storedTrip.primaryTimeLocation === "location-end") {
        return {
          displayArrivalTime: `${DateTime.fromISO(baseTime).toLocaleString(
            DateTime.TIME_SIMPLE
          )} arrival`,
        };
      }

      // case for a waypoints arrival time as the primary time

      const steps = this.storedTrip.evTripData?.flatMap((plan) => plan.steps);
      if (!steps)
        return {
          displayArrivalTime:
            "Whoops! something went wrong in calculating your arrival time.",
        };

      // find index of waypoint selected as primary time in steps array
      const waypointStepsIndex = steps.findIndex(
        (step) =>
          step.From === `Waypoint: ${this.storedTrip.primaryTimeLocation}`
      );
      if (waypointStepsIndex === -1)
        return {
          displayArrivalTime:
            "Whoops! something went wrong in calculating your arrival time.",
        };

      // find index of waypoint selected as primary time in locations array
      const waypointLocationsIndex = this.storedTrip.locations.findIndex(
        (location) => location.localId === this.storedTrip.primaryTimeLocation
      );
      if (waypointLocationsIndex === -1)
        return {
          displayArrivalTime:
            "Whoops! something went wrong in calculating your arrival time.",
        };

      let accumulatedTimeSinceWaypoint = 0;
      let stayTimeSinceWaypoint = 0;

      steps.forEach((step, index) => {
        if (index >= waypointStepsIndex) {
          const totalStepTime =
            (step.ChargeTime ?? 0) + step.FerryTime + step.TravelTime;
          accumulatedTimeSinceWaypoint += totalStepTime;
        }
      });

      this.storedTrip.locations.forEach((location, index) => {
        if (index >= waypointLocationsIndex) {
          stayTimeSinceWaypoint += location.stay || 0;
        }
      });

      const timeObj = DateTime.fromISO(baseTime).plus({
        hours: 0,
        minutes: 0,
        seconds: accumulatedTimeSinceWaypoint + stayTimeSinceWaypoint,
      });

      const difData =
        expectedTime && expectedTimeObj
          ? getDifference(expectedTimeObj, timeObj)
          : undefined;

      return {
        displayArrivalTime: `${timeObj.toLocaleString(
          DateTime.TIME_SIMPLE
        )} arrival`,
        displayTimeDif: difData
          ? difData.differenceObj
              // correct rounding as luxon only supports floor rounding in its current version.
              .set({
                minutes: Math.round(difData.differenceObj.minutes),
              })
              .normalize()
              .toHuman({ listStyle: "narrow" })
              .replace(",", "")
          : undefined,
        timeDifType: difData ? difData.differenceType : undefined,
      };
    },
  },
});
</script>
