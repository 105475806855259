import { decodePolyline } from "@/logic/utils/polylineUtils";
import { ItineraryDataType } from "./types";

/** an itinerary leg representing traveling between two itinerary locations. */
export default class TravelLeg {
  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  // --- map data --- //

  /** the polyline for this leg */
  polyline: string;

  /** the distance for this leg in km */
  distance: number;

  // --- energy data --- //

  /** the number of kWh consumed in this leg of the trip. */
  energyUsed: number;

  /** The kWh remaining in the vehicles battery upon starting this leg of the trip. */
  startingEnergy: number;

  // --- time data --- //

  /** expected time spent driving during this leg in seconds. */
  drivingTime: number;

  /** expected time spent on a ferry during this leg in seconds. */
  ferryTime: number;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    polyline,
    energyUsed,
    startingEnergy,
    drivingTime,
    ferryTime,
    distance,
  }: {
    /** the polyline for this leg */
    polyline: string;
    /** the number of kWh consumed in this leg of the trip. */
    energyUsed: number;
    /** The kWh remaining in the vehicles battery upon starting this leg of the trip. */
    startingEnergy: number;
    /** expected time spent driving during this leg in seconds. */
    drivingTime: number;
    /** expected time spent on a ferry during this leg in seconds. */
    ferryTime: number;
    /** the distance for this leg in km */
    distance: number;
  }) {
    this.polyline = polyline;
    this.energyUsed = energyUsed;
    this.startingEnergy = startingEnergy;
    this.drivingTime = drivingTime;
    this.ferryTime = ferryTime;
    this.distance = distance;
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * decoded polyline as array of [lat,lon] points.
   *
   * @returns a series of lat lon points in order of point traveled
   */
  public get points(): [number, number][] {
    return decodePolyline(this.polyline) ?? [];
  }

  // --- energy data --- //

  /** The estimated energy at the end of this leg in kWh. */
  public get endingEnergy(): number {
    return this.startingEnergy - this.energyUsed;
  }

  // --- time data --- //

  /**
   * expected total time spent traveling during this leg in seconds.
   *
   * Notes:
   * - should be calculates as driving time + ferry time = total travel time.
   */
  public get totalTravelTime(): number {
    return this.drivingTime + this.ferryTime;
  }

  /**
   * Returns the type of itinerary item this object relates to.
   *
   * NOTE: for use in vue templates where instance of is not available.
   */
  public get itineraryDataType(): ItineraryDataType {
    return "travel-leg";
  }
}
