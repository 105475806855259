<template>
  <v-card
    flat
    class="pb-16 pt-8 px-5 d-flex justify-center flex-column"
    v-if="recalcLoading"
  >
    <v-card-text class="primary--text text-center text-h5">
      Power trip is recalculating your trip
    </v-card-text>
    <v-card flat class="d-flex justify-center">
      <LoadingCard />
    </v-card>
    <v-card-text class="primary--text text-center text-subtitle-2">
      This may take a few moments <PulseLoader color="primary" />
    </v-card-text>
  </v-card>
  <v-card flat class="px-16 pb-16 pt-8" v-else-if="loading">
    <v-card-text class="d-flex justify-center primary--text text-center">
      <LoadingCard />
    </v-card-text>
    <v-card-text class="primary--text text-center text-subtitle-2">
      loading <PulseLoader color="primary" />
    </v-card-text>
  </v-card>
  <v-card flat v-else-if="trip" class="px-5 pb-16">
    <v-card-title class="tertiary--text pt-5">
      <BackBtn />
      Edit Your Trip
    </v-card-title>
    <v-card-subtitle class="d-flex justify-space-between pt-3">
      <v-btn text class="text-none" color="grey" @click="showItinerary">
        <v-icon color="grey" class="pt-1 mr-1 ml-n3"> $tripsIcon </v-icon>
        Full trip itinerary
      </v-btn>
      <ElevatedBtn :disabled="!updateFlag" @click="recalculateTrip">
        Recalculate
      </ElevatedBtn>
    </v-card-subtitle>
    <v-card-text v-if="!!errorMsg" class="error--text pt-1">{{
      errorMsg
    }}</v-card-text>
    <!-- trip name card -->
    <v-card class="rounded-lg mb-5">
      <v-card-text class="px-10">
        <v-text-field
          v-model="tripName"
          label="Trip name"
          @change="flagForUpdate"
          clearable
          :append-icon="tripName ? '' : 'mdi-pencil'"
        ></v-text-field>
      </v-card-text>
    </v-card>
    <!-- departure card -->
    <v-card
      class="rounded-lg"
      v-if="locations.length"
      :key="locations[0].localId"
    >
      <v-row no-gutters class="pt-2 pb-2 pt-sm-5 pb-sm-3">
        <v-col
          cols="1"
          class="align-center col col-1 d-flex flex-column pt-2 pl-1"
        >
          <div
            class="primary rounded-circle"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'width: 12px; height: 12px'
                : 'width: 18px; height: 18px'
            "
          ></div>
          <v-icon
            color="primary"
            @click="
              handleMoveLocation({
                id: locations[0].localId,
                oldIndex: 0,
                newIndex: 1,
              })
            "
          >
            mdi-arrow-down-bold
          </v-icon>
        </v-col>
        <v-col cols="10" class="pl-2">
          <AddressAutocompleteInput
            v-if="!useCarLastKnownLocation"
            :id="locations[0].localId"
            :initialValue="{
              address: locations[0].address,
              waypoint: locations[0].coordinates,
            }"
            label="Starting from"
            @update="addressChange"
            :geoLocation="anchorGeoLocation"
            :allowFavLocations="true"
          />
          <v-switch
            v-if="trip && trip.vehicleID"
            v-model="useCarLastKnownLocation"
            inset
            :label="
              useCarLastKnownLocation
                ? 'Using your EV\'s location as your starting point'
                : 'User your EV last known location'
            "
            color="primary"
            @change="handleUseCarToggle"
            class="pt-0 mt-3"
          />
          <v-card-text
            v-if="carIsNotAtStartFlag"
            class="error--text text-caption px-0 pt-0"
          >
            <v-icon color="error"> mdi-alert-circle </v-icon>
            Your EV appears to have moved from your starting location. We
            suggest recalculating this trip.
          </v-card-text>
          <TimePickerInput
            :identifier="locations[0].localId"
            :initialValue="locations[0].time"
            label="Depart at"
            @update="timeChange"
          />
          <DatePickerInput
            :identifier="locations[0].localId"
            :initialValue="locations[0].date"
            label="Departure date"
            @update="dateChange"
          />
          <SOCSlider
            :identifier="locations[0].localId"
            :initialValue="startingCharge"
            label="Departing charge"
            @update="SOCChange"
          />
        </v-col>
      </v-row>
    </v-card>
    <!-- additional stops -->
    <v-row no-gutters>
      <v-col cols="1" class="py-0 pl-6">
        <v-divider vertical></v-divider>
      </v-col>
      <v-col cols="11" class="px-2 py-4">
        <OutlinedBlockBtn @click="addWaypoint(1)">
          Add a Waypoint
        </OutlinedBlockBtn>
        <template v-for="(location, index) in locations">
          <div
            v-if="
              location.localId !== locations[0].localId &&
              location.localId !== locations[locations.length - 1].localId
            "
            :key="location.localId + location.address"
          >
            <v-card class="mt-4 rounded-lg">
              <v-row class="justify-space-between" no-gutters>
                <v-container style="width: 20px" class="d-flex flex-column">
                  <!-- <v-icon color="primary"> mdi-map-marker </v-icon> -->
                  <v-icon
                    color="primary"
                    @click="
                      handleMoveLocation({
                        id: location.localId,
                        oldIndex: index,
                        newIndex: index - 1,
                      })
                    "
                  >
                    mdi-arrow-up-bold
                  </v-icon>
                  <v-icon
                    color="primary"
                    @click="
                      handleMoveLocation({
                        id: location.localId,
                        oldIndex: index,
                        newIndex: index + 1,
                      })
                    "
                  >
                    mdi-arrow-down-bold
                  </v-icon>
                </v-container>
                <v-container
                  class="flex-grow-1 flex-shrink-1 mx-1"
                  style="max-width: calc(100% - 70px)"
                >
                  <AddressAutocompleteInput
                    :id="location.localId"
                    :initialValue="{
                      address: location.address,
                      waypoint: location.coordinates,
                    }"
                    label="Waypoint"
                    @update="addressChange"
                    :dark="false"
                    :geoLocation="anchorGeoLocation"
                    :allowFavLocations="true"
                  />

                  <TimePickerInput
                    :identifier="location.localId"
                    label="Arrive at"
                    :initialValue="location.time"
                    @update="timeChange"
                  />
                  <DatePickerInput
                    :identifier="location.localId"
                    :initialValue="location.date"
                    label="Arrival date"
                    @update="dateChange"
                  />
                  <v-card-text
                    class="pl-0 pt-1 text-caption pb-1 secondary--text"
                  >
                    Stay Duration
                  </v-card-text>
                  <StayDurationInput
                    :identifier="location.localId"
                    :initialValue="location.stay"
                    @update="stayChange"
                  />
                  <SOCSlider
                    v-if="location.chargeHere"
                    :identifier="location.localId"
                    :initialValue="location.stateOfChargeAfterCharging"
                    label="How much will you charge to"
                    @update="SOCChange"
                  />
                </v-container>
                <v-container style="width: 20px" class="ml-n5 mr-7 pt-7">
                  <v-btn icon @click="removeWaypoint(location.localId)">
                    <v-icon id="pwt-remove-waypoint-btn">mdi-trash-can</v-icon>
                  </v-btn>
                </v-container>
              </v-row>
            </v-card>
            <v-card class="rounded-lg mb-2 mt-4"> </v-card>
            <v-row class="mt-2 mx-1 mb-1">
              <ElevatedBtn @click="chargeHereChange(location.localId)">
                {{ location.chargeHere ? "Don't charge here" : "Charge here" }}
              </ElevatedBtn>
              <OutlinedBtn @click="addWaypoint(index + 1)" class="flex-grow-1">
                Add a stop
              </OutlinedBtn>
            </v-row>
          </div>
        </template>
      </v-col>
    </v-row>
    <!-- arrival card -->
    <v-card
      class="primary white--text rounded-lg"
      v-if="locations.length"
      :key="locations[locations.length - 1].localId"
    >
      <v-row no-gutters class="pt-2 pb-3 pt-sm-5">
        <v-col
          cols="1"
          class="align-center col col-1 d-flex flex-column pt-2 pl-1"
        >
          <v-icon
            color="white"
            @click="
              handleMoveLocation({
                id: locations[locations.length - 1].localId,
                oldIndex: locations.length - 1,
                newIndex: locations.length - 2,
              })
            "
          >
            mdi-arrow-up-bold
          </v-icon>
          <div
            class="white rounded-circle"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'width: 12px; height: 12px'
                : 'width: 18px; height: 18px'
            "
          ></div>
        </v-col>
        <v-col cols="10" class="pl-2">
          <AddressAutocompleteInput
            :id="locations[locations.length - 1].localId"
            :initialValue="{
              address: locations[locations.length - 1].address,
              coordinates: locations[locations.length - 1].coordinates,
            }"
            label="Final destination"
            @update="addressChange"
            dark
            :geoLocation="anchorGeoLocation"
            :allowFavLocations="true"
          />
          <TimePickerInput
            :identifier="locations[locations.length - 1].localId"
            :initialValue="locations[locations.length - 1].time"
            label="Arrive at"
            @update="timeChange"
            dark
          />
          <DatePickerInput
            :identifier="locations[locations.length - 1].localId"
            :initialValue="locations[locations.length - 1].date"
            label="Arrival date"
            @update="dateChange"
            dark
          />
          <SOCSlider
            :identifier="locations[locations.length - 1].localId"
            :initialValue="finalCharge"
            label="Minimum charge on arrival"
            @update="SOCChange"
            dark
          />
          <v-btn
            text
            class="text-none ml-n4"
            color="white"
            @click="showItinerary"
          >
            <v-icon color="white" class="pt-1 mr-1"> $tripsIcon</v-icon>
            Full trip itinerary
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-divider vertical light class="ml-6" style="min-height: 30px"></v-divider>
    <!-- Frequency/Primary time section -->
    <v-card class="rounded-lg">
      <v-card-text class="pb-0">
        Add this trip to your list of frequent trips to calculate your
        <a @click="showFiveYearSavings">long-term savings</a>
      </v-card-text>
      <FrequencySelectInput
        v-if="!loading"
        :initialValue="frequency"
        @update="frequencyChange"
      />
      <PrimaryTimeSelectContent
        v-if="!loading"
        :locationsWithTime="locations.filter((location) => location.time)"
        :showDialog="showPrimaryTimeSelectDialog"
        @update="primaryTimeChange"
      />
    </v-card>
  </v-card>
  <v-card flat v-else>
    <v-card flat class="d-flex flex-column justify-center align-center">
      <v-card-title class="tertiary--text">No Trip Selected</v-card-title>
      <v-card-subtitle>
        Plan a new trip or select a previous one
      </v-card-subtitle>
    </v-card>
    <v-card-text>
      <ElevatedBlockBtn class="mb-3" @click="toPlanning">
        Plan a New Trip
      </ElevatedBlockBtn>
      <OutlinedBlockBtn class="mb-3" @click="toOptimisedTripPlanning">
        Plan a New Optimised Trip
      </OutlinedBlockBtn>
      <TextBlockBtn @click="toTrips"> Select previous trips </TextBlockBtn>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { DateTime } from "luxon";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../../../ui-elements/inputs/AddressAutocompleteInput.vue";
import TimePickerInput, {
  TimePickerInputUpdateObj,
} from "../../../ui-elements/inputs/TimePickerInput.vue";
import StayDurationInput, {
  StayDurationInputUpdateObj,
} from "../../../ui-elements/inputs/StayDurationInput.vue";
import SOCSlider, {
  SOCSliderUpdateObj,
} from "../../../ui-elements/inputs/SOCSlider.vue";
import DatePickerInput, {
  DatePickerInputUpdateObj,
} from "../../../ui-elements/inputs/DatePickerInput.vue";
import FrequencySelectInput from "../../../ui-elements/inputs/FrequencySelectInput.vue";
import PrimaryTimeSelectContent from "../dialog-content/PrimaryTimeSelectContent.vue";
import PulseLoader from "../../../../components/ui-elements/loaders/PulseLoader.vue";
import LoadingCard from "../../../ui-elements/loaders/LoadingCard.vue";
import { TripFrequency } from "@/logic/types/trip_specific_types";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import Trip from "@/logic/classes/trip_classes/trip";
import OptimiserDefaultData from "@/logic/data/optimiserDefaultData";
import { ActionTypes, GettersTypes } from "@/logic/store/store_types";
import haversineDistance from "@/logic/utils/haversineDistance";
import { State } from "@/logic/store/store_types";
import { RouteNames } from "@/logic/router";
import BackBtn from "@/ui/components/ui-elements/buttons/BackBtn.vue";
import { powerTripDarkBlue } from "@/logic/data/const";
import ElevatedBtn from "@/ui/components/ui-elements/buttons/ElevatedBtn.vue";
import OutlinedBlockBtn from "@/ui/components/ui-elements/buttons/OutlinedBlockBtn.vue";
import OutlinedBtn from "@/ui/components/ui-elements/buttons/OutlinedBtn.vue";
import ElevatedBlockBtn from "@/ui/components/ui-elements/buttons/ElevatedBlockBtn.vue";
import TextBlockBtn from "@/ui/components/ui-elements/buttons/TextBlockBtn.vue";
import Coordinate from "@/logic/classes/common_classes/coordinate";

export default Vue.extend({
  name: "TripRecalculationForm",
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
      // form data
      tripID: undefined as string | undefined,
      frequency: undefined as TripFrequency | undefined,
      locations: [] as TripLocation[],
      primaryTimeLocationID: undefined as string | undefined,
      primaryTimeLocationObj: undefined as TripLocation | undefined,
      startingCharge: 0.5,
      finalCharge: 0.5,
      useCarLastKnownLocation: false,
      tripName: "",
      // UI flags
      reCalcFlag: false,
      updateFlag: false,
      updating: false,
      errorMsg: undefined as string | undefined,
      showPrimaryTimeSelectDialog: false,
      carIsNotAtStartFlag: false,
      loading: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.trip) this.setInitialValues(this.trip);
      else this.loading = false;
    });
  },
  beforeDestroy() {
    this.clearForm();
  },
  methods: {
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
    toPlanning() {
      this.pushRoute(RouteNames.planning);
    },
    toTrips() {
      this.pushRoute(RouteNames.trips);
    },
    toOptimisedTripPlanning() {
      this.pushRoute(RouteNames.optimisedPlanning);
    },
    showItinerary() {
      this.pushRoute(RouteNames.itinerary);
    },
    showFiveYearSavings() {
      this.pushRoute(RouteNames.saved);
    },
    async setInitialValues(details: Trip) {
      this.loading = true;
      this.tripID = details.localId;
      this.tripName = details.name ?? "";
      this.frequency = details.frequency;
      this.locations = [...details.locations];
      this.primaryTimeLocationID = details.primaryTimeLocation;
      this.primaryTimeLocationObj = details.locations.find(
        (location) => location.localId === details.primaryTimeLocation
      );
      this.startingCharge =
        details.parameters?.SOCAct ?? OptimiserDefaultData.SOCAct;
      this.finalCharge =
        details.parameters?.SOCEnd ?? OptimiserDefaultData.SOCEnd;
      if (details.locations[0].address === "EV last known location") {
        this.useCarLastKnownLocation = true;
        await this.findLocation().then((data) => {
          if (data) {
            this.calcIfEVNotAtStart([data.Longitude, data.Latitude]);
          }
        });
      }
      this.loading = false;
    },
    clearForm() {
      this.tripID = undefined;
      this.frequency = undefined;
      this.locations = [];
      this.primaryTimeLocationID = undefined;
      this.primaryTimeLocationObj = undefined;
      this.startingCharge = 0.5;
      this.finalCharge = 0.5;
      this.reCalcFlag = false;
      this.updateFlag = false;
      this.updating = false;
      this.errorMsg = undefined;
      this.showPrimaryTimeSelectDialog = false;
      this.useCarLastKnownLocation = false;
      this.carIsNotAtStartFlag = false;
    },
    flagForReCalc() {
      this.reCalcFlag = true;
      this.flagForUpdate();
    },
    flagForUpdate() {
      this.updateFlag = true;
    },
    recalculateTrip() {
      this.loading = true;
      // clear any error messages
      this.errorMsg = undefined;
      // guard clauses
      if (!this.tripID) {
        this.errorMsg =
          "Whoops! Looks like something went wrong in loading your trips details. Please close this trip and try again.";
        return;
      }
      if (this.locations.length < 2) {
        this.errorMsg =
          "Whoops! We can't plan a trip with out at least a starting location and a destination.";
        return;
      }

      // Check for primary time change
      const locationsWithTime = this.locations.filter(
        (location) => location.time
      );

      if (!locationsWithTime.length && this.primaryTimeLocationID === null) {
        this.primaryTimeLocationID = undefined;
      }

      if (
        locationsWithTime.length === 1 &&
        this.primaryTimeLocationID === null
      ) {
        this.primaryTimeLocationID = locationsWithTime[0].localId;
      }

      if (locationsWithTime.length > 1 && this.primaryTimeLocationID === null) {
        // open primary time picker
        this.showPrimaryTimeSelectDialog = true;
        return;
      }

      // prep payload.
      const updatedTrip = this.trip;
      if (!updatedTrip) return;
      updatedTrip.locations = this.locations;
      updatedTrip.stateOfChargeAct = this.startingCharge;
      updatedTrip.stateOfChargeEnd = this.finalCharge;
      updatedTrip.frequency = this.frequency;
      updatedTrip.primaryTimeLocation = this.primaryTimeLocationID;

      if (this.tripName) {
        updatedTrip.name = this.tripName;
      }

      if (this.reCalcFlag) {
        // case for EVNav recalculation needed.
        this.$store.dispatch(ActionTypes.recalculateTrip, updatedTrip);
        // Notify analytics server
        // eslint-disable-next-line
        // @ts-ignore
        Vue.prototype.$Countly.q.push([
          "add_event",
          {
            key: "Individual Trip Recalculated",
            count: 1,
          },
        ]);
      } else if (this.updateFlag) {
        // case for no EVNav recalculation needed.
        this.$store.dispatch(ActionTypes.showPlannedTrip, updatedTrip);
      } else {
        this.errorMsg = "Looks like there is nothing to update.";
      }
      // clear flags
      this.reCalcFlag = false;
      this.updateFlag = false;
      this.carIsNotAtStartFlag = false;
      this.loading = false;
    },
    async findLocation() {
      if (
        this.trip?.vehicle &&
        this.trip.vehicle.latitude &&
        this.trip.vehicle.longitude
      ) {
        return {
          Latitude: this.trip.vehicle.latitude,
          Longitude: this.trip.vehicle.longitude,
        };
      }
    },
    calcIfEVNotAtStart(lngLat: [number, number]) {
      if (!this.trip) return;
      const tripStartCoords: [number, number] = [
        this.trip.locations[0].coordinates.longitude,
        this.trip.locations[0].coordinates.latitude,
      ];
      const distance = haversineDistance(tripStartCoords, lngLat);
      if (distance > 1) {
        this.carIsNotAtStartFlag = true;
        this.locations[0].coordinates.longitude = lngLat[0];
        this.locations[0].coordinates.latitude = lngLat[1];
        this.flagForReCalc();
      } else {
        this.carIsNotAtStartFlag = false;
      }
    },
    // change handlers
    async handleUseCarToggle(val: boolean) {
      if (!this.trip) return;
      if (val) {
        await this.findLocation().then((data) => {
          if (data) {
            this.locations[0] = new TripLocation({
              ...this.locations[0],
              address: "EV last known location",
              coordinates: new Coordinate({
                latitude: data.Latitude,
                longitude: data.Longitude,
              }),
            });
            this.calcIfEVNotAtStart([data.Longitude, data.Latitude]);
          }
        });
        this.flagForReCalc();
      } else {
        if (this.trip.locations[0].address === "EV last known location") {
          this.locations[0] = new TripLocation({});
          this.flagForReCalc();
        } else {
          this.locations[0] = this.trip.locations[0];
        }
      }
    },
    addressChange(val: AddressAutocompleteInputUpdateObj) {
      if (!val.addressData) {
        this.errorMsg =
          "Whoops! Looks like something went wrong with updating that address, please try agin.";
        return;
      }

      const locationsIndex = this.locations.findIndex(
        (location) => location.localId === val.id
      );

      if (locationsIndex === -1) {
        this.errorMsg =
          "Whoops! Looks like something went wrong with updating that address, please try agin.";
        return;
      }

      this.locations[locationsIndex].address = val.addressData.address;
      this.locations[locationsIndex].coordinates = new Coordinate({
        latitude: val.addressData.coordinates.Latitude,
        longitude: val.addressData.coordinates.Longitude,
      });
      this.flagForReCalc();
    },
    timeChange(val: TimePickerInputUpdateObj) {
      const locationsIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );

      if (locationsIndex === -1) {
        this.errorMsg =
          "Whoops! Looks like something went wrong with updating that time, please try agin.";
        return;
      }

      this.locations[locationsIndex].time = val.time;
      this.flagForUpdate();
      this.$nextTick(() => {
        this.checkIfNeedPrimaryTimeUpdate();
      });
    },
    dateChange(val: DatePickerInputUpdateObj) {
      const locationsIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );

      if (locationsIndex === -1) {
        this.errorMsg =
          "Whoops! Looks like something went wrong with updating that date, please try agin.";
        return;
      }

      this.locations[locationsIndex].date = val.date;
      this.flagForUpdate();
    },
    SOCChange(val: SOCSliderUpdateObj) {
      // not null guard clause
      if (!val.SOC) return;
      // find location to update
      const location = this.locations.find(
        (location) => location.localId === val.identifier
      );
      const locationIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );
      // find operation failed guard clause
      if (!location) return;
      if (locationIndex === -1) return;
      // create new object
      const tempObj = new TripLocation({
        ...location,
        stateOfChargeAfterCharging: val.SOC,
      });
      // update local state
      if (val.identifier === this.locations[0].localId)
        this.startingCharge = val.SOC;
      if (val.identifier === this.locations[this.locations.length - 1].localId)
        this.finalCharge = val.SOC;
      this.locations.splice(locationIndex, 1, tempObj);
      this.flagForReCalc();
    },
    frequencyChange(val: TripFrequency | undefined) {
      this.frequency = val;
      this.flagForUpdate();
    },
    stayChange(val: StayDurationInputUpdateObj) {
      const locationsIndex = this.locations.findIndex(
        (location) => location.localId === val.identifier
      );

      if (locationsIndex === -1) {
        this.errorMsg =
          "Whoops! Looks like something went wrong with updating that scheduled stops stay duration, please try agin.";
        return;
      }

      this.locations[locationsIndex].stay = val.duration;
      this.flagForUpdate();
    },
    addWaypoint(addAtIndex: number) {
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Waypoint added",
          count: 1,
        },
      ]);
      // create empty location data object with ID for later accessing
      this.locations.splice(addAtIndex, 0, new TripLocation({}));
    },
    removeWaypoint(waypointId: string) {
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Waypoint Removed",
          count: 1,
        },
      ]);

      this.locations = this.locations.filter(
        (location) => location.localId !== waypointId
      );
      this.flagForReCalc();
    },
    primaryTimeChange(id: string | undefined) {
      this.showPrimaryTimeSelectDialog = false;
      this.primaryTimeLocationID = id;
      this.primaryTimeLocationObj = this.locations.find(
        (location) => location.localId === id
      );
      this.flagForUpdate();
      this.recalculateTrip();
    },
    chargeHereChange(locationID: string) {
      const locationsIndex = this.locations.findIndex(
        (location) => location.localId === locationID
      );
      if (locationsIndex === -1) {
        this.errorMsg =
          "Whoops! Looks like something went wrong setting up that waypoint as a charging stop, please try agin.";
        return;
      }
      const updatedLocation = this.locations[locationsIndex];
      updatedLocation.chargeHere = !updatedLocation.chargeHere;
      this.locations.splice(locationsIndex, 1, updatedLocation);
      this.flagForReCalc();
    },
    // helper functions
    checkIfNeedPrimaryTimeUpdate() {
      const locationsWithTime = this.locations.filter(
        (location) => location.time
      );

      if (!locationsWithTime.length) return;
      if (locationsWithTime.length === 1) {
        // check if primary time is set to the only location with time.
        if (locationsWithTime[0].localId !== this.trip?.primaryTimeLocation) {
          // update primary time.
          this.primaryTimeLocationID = locationsWithTime[0].localId;
          this.primaryTimeLocationObj = locationsWithTime[0];
          this.flagForUpdate();
          return;
        }
        // do nothing as already set.
        return;
      }

      // multiple possible primary times prompt user select which is the primary time.
      this.showPrimaryTimeSelectDialog = true;
    },
    timeFormatter(time: string) {
      return DateTime.fromISO(time).toLocaleString(DateTime.TIME_SIMPLE);
    },
    handleMoveLocation({
      id,
      oldIndex,
      newIndex,
    }: {
      id: string;
      oldIndex: number;
      newIndex: number;
    }) {
      const location = this.locations.find(
        (location) => location.localId === id
      );
      // not undefined guard clause.
      if (!location) return;

      this.locations = TripLocation.reOrderTripLocations(
        this.locations,
        oldIndex,
        newIndex
      );
      return;
    },
  },
  components: {
    AddressAutocompleteInput,
    TimePickerInput,
    StayDurationInput,
    SOCSlider,
    DatePickerInput,
    PrimaryTimeSelectContent,
    FrequencySelectInput,
    PulseLoader,
    LoadingCard,
    BackBtn,
    ElevatedBlockBtn,
    ElevatedBtn,
    OutlinedBlockBtn,
    OutlinedBtn,
    TextBlockBtn,
  },
  computed: {
    anchorGeoLocation(): Coordinate | undefined {
      const waypoint = this.locations.find((stop) => !stop.isNullIsland);
      if (waypoint) return new Coordinate(waypoint.coordinates);
      return undefined;
    },
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    ...mapState({
      recalcLoading: (state: unknown) => (state as State).routePlanningStatus,
    }),
  },
  watch: {
    trip(val: Trip | undefined) {
      if (val) this.setInitialValues(val);
    },
  },
});
</script>
<style scoped>
#pwt-remove-waypoint-btn {
  color: var(--v-secondary-base);
}

#pwt-remove-waypoint-btn:hover {
  color: var(--v-error-base);
}
</style>
