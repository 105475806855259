<template>
  <v-card flat class="px-16 pb-16 pt-8">
    <v-card-text class="primary--text text-center text-h5">
      {{ message }}
    </v-card-text>
    <v-card-text class="d-flex justify-center primary--text text-center">
      <LoadingCard />
    </v-card-text>
    <v-card-text class="primary--text text-center text-subtitle-2">
      This may take a few moments
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import LoadingCard from "./LoadingCard.vue";
export default Vue.extend({
  name: "DescriptiveLoader",
  props: {
    message: {
      type: String,
      default: "Power Trip is loading",
    },
  },
  components: {
    LoadingCard,
  },
});
</script>
