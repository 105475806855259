<template>
  <!-- processing update view -->
  <v-card
    flat
    class="px-5 d-flex flex-column justify-center align-center"
    v-if="processing"
  >
    <v-card-title>
      Processing
      <PulseLoader />
    </v-card-title>
    <LoadingCard />
  </v-card>
  <!-- normal view -->
  <v-card flat class="px-5 background" v-else>
    <v-card-text>
      <v-row no-gutters v-if="user">
        <v-avatar color="primary" class="mr-3 mt-1">
          <img v-if="avatarSrc" :src="avatarSrc" />
          <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>
        <v-card-subtitle class="d-flex flex-column grey--text pt-0">
          <span class="font-weight-medium text-h6">
            {{ user.fullName }}
          </span>
          <span>
            {{ user.email }}
          </span>
        </v-card-subtitle>
        <v-spacer />
      </v-row>
      <v-row no-gutters align="center" v-else>
        <v-skeleton-loader type="avatar" class="mr-3" />
        <v-skeleton-loader type="text, text" width="30%" />
        <v-spacer />
      </v-row>
    </v-card-text>
    <v-card elevation="1" class="pa-5 rounded-lg mb-5">
      <v-card-text class="d-flex pb-0">
        <v-row no-gutters align="center">
          <span class="mr-3">Profile picture</span>
          <v-avatar color="primary" class="mr-3">
            <v-img v-if="avatarSrc" :src="avatarSrc" />
            <v-icon v-else dark> mdi-account-circle </v-icon>
          </v-avatar>
          <v-spacer v-if="!showFileInput" />
          <TextBtn v-if="!showFileInput" @click="showFileInput = true">
            Change picture
          </TextBtn>
          <v-file-input
            v-if="showFileInput"
            accept="image/*"
            label="Upload a new profile image"
            dense
            class="pt-4"
            v-model="avatar"
            prepend-icon="mdi-camera"
          ></v-file-input>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row no-gutters>
          <v-text-field
            class="rounded-lg mr-sm-2"
            label="First name"
            v-model="firstName"
          ></v-text-field>
          <v-text-field
            class="rounded-lg ml-sm-2"
            label="Last name"
            v-model="lastName"
          ></v-text-field>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <ElevatedBtn class="mt-n2" @click="saveAndUpload">
          Save changes
        </ElevatedBtn>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import LoadingCard from "../../../ui-elements/loaders/LoadingCard.vue";
import PulseLoader from "../../../ui-elements/loaders/PulseLoader.vue";
import Driver from "@/logic/classes/user_classes/driver";
import { GettersTypes, type State } from "@/logic/store/store_types";
import type { DataToUpdate } from "../../../../../logic/types/directus_types";
import { ActionTypes } from "@/logic/store/store_types";
import ElevatedBtn from "@/ui/components/ui-elements/buttons/ElevatedBtn.vue";
import TextBtn from "@/ui/components/ui-elements/buttons/TextBtn.vue";

export default Vue.extend({
  name: "EditUserContent",
  data() {
    return {
      firstName: "",
      lastName: "",
      avatar: null,
      showFileInput: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValues();
    });
  },
  methods: {
    setInitialValues() {
      this.firstName = this.user?.firstName ?? "";
      this.lastName = this.user?.lastName ?? "";
    },
    saveAndUpload() {
      // guard clause
      if (!this.user) return;
      // check if data has changed from initial values and prep object for dispatch.
      const data: DataToUpdate = {
        avatar: this.avatar ?? undefined,
        firstName:
          this.firstName !== this.user.firstName ? this.firstName : undefined,
        lastName:
          this.lastName !== this.user.lastName ? this.lastName : undefined,
      };
      this.$store.dispatch(ActionTypes.updateUser, data);
    },
  },
  computed: {
    ...mapGetters({
      driver: GettersTypes.currentDriver,
    }),
    ...mapState({
      processing: (state: unknown) => (state as State).updatingUserStatus,
      authenticated: (state: unknown): boolean =>
        (state as State).authorizedFlag,
      user: (state: unknown) => (state as State).user,
    }),
    avatarSrc(): string | undefined {
      return (this.driver as Driver | undefined)?.profilePicSrc;
    },
  },
  components: { LoadingCard, PulseLoader, ElevatedBtn, TextBtn },
});
</script>
